import PaginationListView from "./../view/pagination-list.js";
import PaginationItemView from "./../view/pagination-item.js";

import {
  replace,
  removeElement
} from "./../../render/render.js";

import {
  PageType,
  DefaultAction,
  PAGE_KEY
} from "./../const.js";

export default class Pagination {
  constructor(element, actions = DefaultAction, pageKey = PAGE_KEY) {
    this._listView = new PaginationListView(element);
    this._actions = actions;
    this._pageKey = pageKey;

    this._itemsViews = [];
    this._observers = [];

    this._handleItemClick = this._handleItemClick.bind(this);

    this._init();
  }

  addObserver(observer) {
    this._observers.push(observer);
  }

  removeObserver(observer) {
    this._observers = this._observers.filter((existedObserver) => existedObserver !== observer);
  }

  getElement() {
    return this._listView.getElement();
  }

  update(template) {
    if (!template) {
      this._destroy();
      return;
    }

    const oldElement = this._listView.getElement();
    const newElement = this._listView.getTemplate(template);

    this._listView.setElement(newElement);
    this._init();

    if (!oldElement) {
      this._notify(this._actions.RENDER_PAGINATION);
      return;
    }

    replace(newElement, oldElement);
  }

  _init() {
    const items = this._listView.getItems();

    if (!items) {
      return;
    }

    this._itemsViews = [];

    Array
      .from(items)
      .forEach((item) => {
        const itemView = new PaginationItemView(item);
        itemView.setItemClickHandler(this._handleItemClick);

        this._itemsViews.push(itemView);
      });
  }

  _destroy() {
    if (!this._listView.getElement()) {
      return;
    }

    removeElement(this._listView.getElement());
    this._itemsViews = [];
    this._listView.setElement(null);
  }

  _notify(event, payload) {
    this._observers.forEach((observer) => observer(event, payload));
  }

  _getCurrentPage() {
    const regExpString = `(${this._pageKey}=)([0-9]*)`;
    const regExp = new RegExp(regExpString);
    const pageMatches = document.location.href.match(regExp);

    if (pageMatches && pageMatches[2]) {
      return +pageMatches[2];
    }

    return 1;
  }

  _getCurrentQueryParams() {
    const search = document.location.search.replace(`?`, ``);

    const searchParts = search.split(`&`);
    const queryParams = {};

    searchParts.forEach((item) => {
      const [property, value] = item.split(`=`);

      if (property === this._pageKey) {
        return;
      }

      queryParams[property] = value;
    });

    return queryParams;
  }

  _handleItemClick(newPage) {
    const currentPage = this._getCurrentPage();
    const currentParams = this._getCurrentQueryParams();

    switch (newPage) {
      case PageType.PREV:
        newPage = currentPage - 1;
        break;
      case PageType.NEXT:
        newPage = currentPage + 1;
        break;
      default:
        newPage = +newPage;
    }

    this._notify(this._actions.CHANGE_PAGE, Object.assign(
        currentParams,
        {
          page: newPage
        }
    ));
  }
}
