import {
  YANDEX_MAPS_API_KEY
} from "../const.js";

const URL = `https://api-maps.yandex.ru/2.1/`;
const YANDEX_MAPS_LOAD_CHECK_INTERVAL_TIME = 200;

export const yandexMaps = {
  loadScript(callback) {
    const observerOptions = {
      root: null,
      rootMargin: `500px`
    };

    const observerCallback = (entries, observer) => {
      let isIntersecting = false;

      entries.forEach((entry) => {
        if (isIntersecting === true) {
          return;
        }

        isIntersecting = entry.isIntersecting;
      });

      if (isIntersecting === false) {
        return;
      }

      observer.unobserve(this._container);
      const scriptSrc = `${this._url}?apikey=${this._apiKey}&lang=ru_RU`;

      if (document.querySelector(`[src$="${scriptSrc}"]`)) {
        this.checkMapLoading(callback);
      } else {
        const scriptElement = document.createElement(`script`);
        scriptElement.src = scriptSrc;

        this._document.head.append(scriptElement);

        scriptElement.onload = () => {
          this.checkMapLoading(callback);
        };
      }
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(this._container);
  },

  checkMapLoading(callback) {
    const loadingInterval = setInterval(() => {
      if (window.ymaps) {
        clearInterval(loadingInterval);
        callback();
      }
    }, YANDEX_MAPS_LOAD_CHECK_INTERVAL_TIME);
  },

  createPlacemark(coords, options = {}) {
    const placemark = new this._ymaps.Placemark(
        coords,
        {},
        Object.assign(
            {},
            {
              iconLayout: `default#image`,
              iconImageHref: this._iconUrl,
              iconImageSize: this._iconSize,
              iconOffset: [-this._iconRealSize[0] / 2, -this._iconRealSize[1] / 2]
            },
            options
        )
    );

    this._map.geoObjects
      .add(placemark);

    return placemark;
  },

  setCopyrightsStyle() {
    this._map.panes
      .get(`copyrights`)
      .getElement().style.display = `flex`;
  }
};

yandexMaps._apiKey = YANDEX_MAPS_API_KEY;
yandexMaps._url = URL;
yandexMaps._iconUrl = yandexMaps._iconUrl;
yandexMaps._iconSize = yandexMaps._iconSize;
yandexMaps._iconRealSize = yandexMaps._iconRealSize;
yandexMaps._ymaps = window.ymaps;
yandexMaps._map = yandexMaps._map;
yandexMaps._document = document;
yandexMaps._container = yandexMaps._container;
