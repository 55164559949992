import Abstract from "../../abstract-view/abstract.js";

export default class ScrollAppearance extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  getTopOffset() {
    return this._element.getBoundingClientRect().top;
  }

  getHeight() {
    return this._element.clientHeight;
  }
}
