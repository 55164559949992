import FileAttachView from "../view/file-attach.js";
import UploadedListView from "../view/uploaded-list.js";
import UploadedFileView from "../view/uploaded-file.js";

import FileAttachModel from "../model/file-attach.js";

import Animation from "../../animation/animation.js";

import {
  AnimationClass
} from "../../animation/const.js";

import {
  removeElement,
  render,
  RenderPosition
} from "../../render/render.js";

import {
  defaultFileAttachModelData,
  ActionType
} from "../const.js";

import {
  generateId
} from "../../../utils/generate-id.js";

import {
  IMAGE_FILE_TYPES
} from "../const.js";

export default class FileAttach {
  constructor(input) {
    this._input = input;

    this._animation = new Animation();

    this._fileAttachView = null;
    this._files = [];

    this._listView = null;
    this._filesViews = [];

    this._model = null;

    this._handleElementChange = this._handleElementChange.bind(this);
    this._handleLabelDragEnter = this._handleLabelDragEnter.bind(this);
    this._handleLabelDragLeave = this._handleLabelDragLeave.bind(this);
    this._handleLabelDragOver = this._handleLabelDragOver.bind(this);
    this._handleLabelDrop = this._handleLabelDrop.bind(this);
    this._handleUploadedItemRemoveButtonClick = this._handleUploadedItemRemoveButtonClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._fileAttachView = new FileAttachView(this._input);

    this._fileAttachView.setElementChangeHandler(this._handleElementChange);
    this._fileAttachView.setLabelDragEnterHandler(this._handleLabelDragEnter);
    this._fileAttachView.setLabelDragLeaveHandler(this._handleLabelDragLeave);
    this._fileAttachView.setLabelDragOverHandler(this._handleLabelDragOver);
    this._fileAttachView.setLabelDropHandler(this._handleLabelDrop);

    this._model = new FileAttachModel(Object.assign(
        {},
        defaultFileAttachModelData
    ));
    this._model.addObserver(this._handleChangeData);
  }

  reset() {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isDragEnter: false,
          isDragOver: false
        }
    ), ActionType.RESET);
  }

  _processReset() {
    this._filesViews.forEach((view) => {
      const itemId = view.getId();
      this._processRemoveUploadedItem(itemId);
    });
  }

  _processDragEnter() {
    this._fileAttachView.setLabelHighlights();
  }

  _processDragLeave() {
    this._fileAttachView.removeLabelHighlights();
  }

  _processDragOver() {
    this._fileAttachView.setLabelHighlights();
  }

  _processDrop(dataTransfer) {
    this._fileAttachView.removeLabelHighlights();

    this._handleElementChange([...dataTransfer.files]);
  }

  _processRenderUploadedList() {
    this._listView = new UploadedListView();

    render(this._fileAttachView.getLabelElement(), this._listView.getElement(), RenderPosition.AFTEREND);

    this._animation.fadeIn(
        this._listView.getElement()
    );
  }

  _processAddUploadedItem(fileName, imageHref) {
    const nameParts = fileName.split(`.`);
    const extension = (nameParts.length > 1) ?
      nameParts[nameParts.length - 1].toUpperCase() :
      null;

    const viewId = generateId();

    const fileView = new UploadedFileView(viewId, fileName, extension, imageHref);
    render(this._listView.getListElement(), fileView.getElement());

    this._animation.fadeIn(
        fileView.getElement(),
        null,
        null,
        AnimationClass.SHOW_FLEX
    );

    fileView.setRemoveButtonClickHandler(this._handleUploadedItemRemoveButtonClick);

    this._filesViews.push(fileView);
  }

  _processRemoveUploadedItem(itemId) {
    const itemToRemove = this._filesViews.find((view) => view.getId() === itemId);

    if (!itemToRemove) {
      return;
    }

    const fileName = itemToRemove.getFileName();
    const elementToRemove = itemToRemove.getElement();

    this._filesViews = this._filesViews.filter((view) => view !== itemToRemove);
    this._files = this._files.filter((file) => file.name !== fileName);

    this._animation.fadeOut(
        elementToRemove,
        null,
        () => {
          removeElement(elementToRemove);
        }
    );

    if (this._filesViews.length === 0) {
      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            isUploadedListRendered: false
          }
      ), ActionType.DESTROY_UPLOADED_LIST);
    }
  }

  _processDestroyUploadedList() {
    const listElement = this._listView.getElement();
    this._listView = null;

    this._animation.fadeOut(
        listElement,
        null,
        () => {
          removeElement(listElement);
        }
    );
  }

  _handleElementChange(files) {
    Array.from(files)
      .forEach((file) => {
        const isFileUploaded = this._files.find((item) => item.name === file.name);

        if (!isFileUploaded) {
          this._files.push(file);

          if (!this._model.getData().isUploadedListRendered) {
            this._model.changeData(Object.assign(
                {},
                this._model.getData(),
                {
                  isUploadedListRendered: true
                }
            ), ActionType.RENDER_UPLOADED_LIST);
          }

          const payload = {
            fileName: file.name
          };

          if (IMAGE_FILE_TYPES.indexOf(file.type) !== -1) {
            const imageHref = URL.createObjectURL(file);
            payload.imageHref = imageHref;
          }

          this._model.changeData(Object.assign(
              {},
              this._model.getData()
          ), ActionType.ADD_UPLOADED_ITEM, payload);
        }
      });
  }

  _handleUploadedItemRemoveButtonClick(itemId) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData()
    ), ActionType.REMOVE_UPLOADED_ITEM, {
      itemId
    });
  }

  _handleLabelDragEnter() {
    if (this._model.getData().isDragEnter) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isDragEnter: true
        }
    ), ActionType.DRAG_ENTER);
  }

  _handleLabelDragLeave() {
    if (!this._model.getData().isDragOver && !this._model.getData().isEnter) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isDragEnter: false,
          isDragOver: false
        }
    ), ActionType.DRAG_LEAVE);
  }

  _handleLabelDragOver() {
    if (this._model.getData().isDragOver) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isDragOver: true
        }
    ), ActionType.DRAG_OVER);
  }

  _handleLabelDrop(evt) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isDragOver: false,
          isDragEnter: false,
        }
    ), ActionType.DROP, {
      dataTransfer: evt.dataTransfer
    });
  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.RENDER_UPLOADED_LIST:
        this._processRenderUploadedList();
        break;
      case ActionType.ADD_UPLOADED_ITEM:
        this._processAddUploadedItem(payload.fileName, payload.imageHref);
        break;
      case ActionType.REMOVE_UPLOADED_ITEM:
        this._processRemoveUploadedItem(payload.itemId);
        break;
      case ActionType.DESTROY_UPLOADED_LIST:
        this._processDestroyUploadedList();
        break;
      case ActionType.DRAG_ENTER:
        this._processDragEnter();
        break;
      case ActionType.DRAG_LEAVE:
        this._processDragLeave();
        break;
      case ActionType.DRAG_OVER:
        this._processDragOver();
        break;
      case ActionType.DROP:
        this._processDrop(payload.dataTransfer);
        break;
      case ActionType.RESET:
        this._processReset();
        break;
    }
  }
}
