import ModalFeedbackFormPresenter from "../../modal-feedback-form/presenter/modal-feedback-form.js";
import UISPresenter from "../../uis/presenter/uis.js";

import ModalSectionView from "../view/section.js";

export default class OneClickBuyModalPresenter extends ModalFeedbackFormPresenter {
  constructor(productData) {
    super();

    this._modalSectionView = new ModalSectionView(productData);
    this._submitCallbacks = {
      loadSuccess: (response, formData) => {
        // Send the Yandex Metrika goal.
        if (window.ym) {
          window.ym(29276100, `reachGoal`, `one_click_purchase_form_submit`);
        }

        const uisComponent = new UISPresenter();
        uisComponent.addRequest(formData);
      }
    };
  }
}
