import Abstract from "../../abstract-view/abstract.js";

export default class CatalogNavigation extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._subcategoriesWrapperElement = null;
  }

  getSubcategoriesWrapperElement() {
    if (!this._subcategoriesWrapperElement) {
      this._subcategoriesWrapperElement = this._element.querySelector(`.header-subcategories-wrapper`);
    }

    return this._subcategoriesWrapperElement;
  }

  setSubcategoriesWrapperVisible() {
    this.getSubcategoriesWrapperElement().style.display = `flex`;
  }

  getCategoriesLinks() {
    return this._element.querySelectorAll(`.header-category__link`);
  }

  getSubcategoriesItems() {
    return this._element.querySelectorAll(`.header-subcategories__item`);
  }
}
