import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

import {
  SHRINK_BUTTON_TEXT,
  EXPAND_BUTTON_TEXT
} from "../lexicon.js";

const createButtonTemplate = (remainingCount) => {
  return (
    `<li class="category-links__item">
        <button class="category-links__button">${EXPAND_BUTTON_TEXT} ${remainingCount}</button>
      </li>`
  );
};

export default class Button extends Abstract {
  constructor(remainingCount) {
    super();

    this._remainingCount = remainingCount;
    this._buttonElement = null;

    this._clickHandler = this._clickHandler.bind(this);
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getButtonElement() {
    if (!this._buttonElement) {
      this._buttonElement = this._element.querySelector(`button`);
    }

    return this._buttonElement;
  }

  setActive() {
    this.getButtonElement().classList.add(`${this.getButtonElement().classList[0]}_active`);
    this.getButtonElement().textContent = SHRINK_BUTTON_TEXT;
  }

  reset() {
    this.getButtonElement().classList.remove(`${this.getButtonElement().classList[0]}_active`);
    this.getButtonElement().textContent = `${EXPAND_BUTTON_TEXT} ${this._remainingCount}`;
    this._defaultButtonText = null;
  }

  setRemaingCount(value) {
    this._remainingCount = value;
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    this._element.addEventListener(`click`, this._clickHandler);
  }

  _getTemplate() {
    return createButtonTemplate(this._remainingCount);
  }

  _clickHandler() {
    this._callback.click();
  }
}
