import Abstract from "../../abstract-view/abstract.js";

import {
  formatNumber
} from "../../../utils/format-number.js";

import {
  declinationNumber
} from "../../../utils/declination-number.js";

import {
  QUANTITY_UNIT_MEASURE,
  PRICE_CURRENCY
} from "../lexicon.js";

export default class Summary extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._totalPositionsElement = null;
    this._totalCostElement = null;
  }

  getTotalPositionsElement() {
    if (!this._totalPositionsElement) {
      this._totalPositionsElement = this._element.querySelector(`.add-to-cart-modal-summary__total-positions`);
    }

    return this._totalPositionsElement;
  }

  setTotalPositionsValue(value) {
    this.getTotalPositionsElement().textContent = `${value}\u00A0${declinationNumber(value, QUANTITY_UNIT_MEASURE)}`;
  }

  getTotalCostElement() {
    if (!this._totalCostElement) {
      this._totalCostElement = this._element.querySelector(`.add-to-cart-modal-summary__total-cost`);
    }

    return this._totalCostElement;
  }

  setTotalCostValue(value) {
    if (this.getTotalCostElement()) {
      this.getTotalCostElement().textContent = `${formatNumber(value, 2, `,`, `\u00A0`)} ${PRICE_CURRENCY}`;
    }
  }
}
