import Abstract from "../../abstract-view/abstract.js";

export default class Wrapper extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._listElement = null;
  }

  getSlideImageWrapper() {
    const visibleSlide = this._element.querySelector(`.glide__slide--visible`);

    return (visibleSlide) ? visibleSlide.querySelector(`.product-item-image-wrapper`)
      : null;
  }

  getSlideImage() {
    return this._element.querySelector(`.glide__slide--visible`).querySelector(`img`);
  }

  getListElement() {
    if (!this._listElement) {
      this._listElement = this._element.querySelector(`.products-list`);
    }

    return this._listElement;
  }
}
