import {
  RuleName
} from "../submit-form/const";

export const MODAL_MAIN_CLASS = `modal-feedback-form-wrapper modal-feedback-form-wrapper_add-review`;

export const VALIDATION_RULES = [
  {
    name: `comment`,
    rules: [
      RuleName.NOT_EMPTY
    ]
  },
  {
    name: `name`,
    rules: [
      RuleName.NOT_EMPTY
    ]
  }, {
    name: `policy`,
    rules: [
      RuleName.IS_CHECKED
    ]
  }
];

export const CONNECTOR_URL = `/assets/components/reviews/frontend-connector.php`;
export const RequestAction = {
  SAVE: `save`
};
