import Glide from "@glidejs/glide";

import {
  Fancybox
} from "@fancyapps/ui";

import ru from "@fancyapps/ui/src/Fancybox/l10n/ru";

import {
  VisibleClass
} from "../../glide/components/visible-class.js";

import {
  NavigationDisabler
} from "../../glide/components/navigation-disabler.js";

import {
  ResizeGap
} from "../../glide/components/resize-gap.js";

import {
  ControlsExtended
} from "../../glide/components/controls-extended.js";

import ControlsView from "../../slider/controls/view/controls";
import WrapperView from "../view/wrapper.js";

import {
  render
} from "../../render/render.js";

import {
  modalSliderClosing
} from "../../../mixins/modal-slider-closing";

import {
  FancyboxOptions
} from "../../../const.js";

export default class ContentGallery {
  constructor(wrapperElement, instanceIndex, options = {}) {
    this._wrapperElement = wrapperElement;
    this._instanceIndex = instanceIndex;
    this._controlsView = null;
    this._wrapperView = null;
    this._options = options;

    this._slider = null;
  }

  init() {
    const sliderOptions = Object.assign(
        {},
        {
          perView: 4,
          gap: 20,
          type: `slider`,
          bound: true,
          animationDuration: 500,
          keyboard: false,
          rewind: false,
          breakpoints: {
            900: {
              perView: 3
            },
            760: {
              perView: 2
            },
            500: {
              perView: 1
            }
          }
        },
        this._options
    );

    this._slider = new Glide(this._wrapperElement, sliderOptions);

    this._wrapperView = new WrapperView(this._wrapperElement);
    this._controlsView = new ControlsView({
      postfix: `gallery`
    });

    render(this._wrapperView.getElement(), this._controlsView.getElement());

    this._slider.on(`run.after`, this.sliderRunAfterHandler.bind(this));
    this._slider.mount({
      'ControlsExtended': ControlsExtended,
      'VisibleClass': VisibleClass,
      'NavigationDisabler': NavigationDisabler,
      'ResizeGap': ResizeGap
    });

    this._initModal();
  }

  _initModal() {
    this._wrapperView.setInstanceClass(this._instanceIndex);
    const selector = `.${this._wrapperView.getInstanceClass()} a`;

    Fancybox.defaults.l10n = ru;
    Fancybox.bind(selector, Object.assign(
        {},
        FancyboxOptions,
        {
          on: {
            closing: this.closingModalHandler.bind(this)
          }
        }
    ));
  }
}

Object.assign(ContentGallery.prototype, modalSliderClosing);
