export const defaultProductItemModelData = {
  isHovered: false,
  initComparisonTrigger: true,
  initAddToCartTrigger: true,
  initOneClickBuyTrigger: true,
  initProductPropertiesTrigger: true,
  isAddedToCart: false
};

export const ActionType = {
  ITEM_ENTER: `ITEM_ENTER`,
  ITEM_LEAVE: `ITEM_LEAVE`,
  FORCE_RESET: `FORCE_RESET`,
  SET_ADDED_TO_CART: `SET_ADDED_TO_CART`,
  UNSET_ADDED_TO_CART: `UNSET_ADDED_TO_CART`
};

export const FORM_ANIMATION_DURATION = 100;

export const MOBILE_MEDIA_QUERY = `(max-width: 560px)`;
