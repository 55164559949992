export const CLOSE_BUTTON_TEXT = `Закрыть`;
export const MODAL_HEADING = `Товар добавлен в корзину`;

export const QUANTITY_UNIT_MEASURE = [
  `товар`,
  `товара`,
  `товаров`
];

export const PRICE_CURRENCY = `руб.`;
export const ARTICLE_TEXT = `Артикул`;
export const DECREASE_TEXT = `Уменьшить количество товара`;
export const INCREASE_TEXT = `Увеличить количество товара`;
export const DISCOUNT_TEXT = `скидка`;
export const CONTAIN_IN_CART = `В корзине`;
export const ON_PREPOSITION = `на`;
export const CONTINUE_SHOPPING = `Продолжить покупки`;
export const ADD_TO_CART = `Добавьте к заказу`;
export const GO_TO_CART = `Перейти в корзину`;
export const ON_REQUEST = `По запросу`;
