import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

import {
  REMOVE_BUTTON_TEXT
} from "../lexicon.js";

const createUploadedFileTemplate = (fileName, extension, imageHref) => {
  const extensionMarkup = (extension) ? createExtensionTemplate(extension) : ``;
  const imageMarkup = (imageHref) ?
    createImageMarkup(imageHref) :
    null;

  return (
    `<li class="uploaded-file" style="display: none;">
      ${(imageMarkup) ? imageMarkup : extensionMarkup}
      <p class="uploaded-file__name">${fileName}</p>
      <button class="button uploaded-file__remove" type="button">
        <span class="visually-hidden">${REMOVE_BUTTON_TEXT}</span>
      </button>
    </li>`
  );
};

const createImageMarkup = (imageHref) => {
  return (
    `<img
      class="uploaded-file__image lazyload"

      alt=""
      width="49"

      src="${imageHref}"
      srcset="/assets/templates/main/images/lazy-load-placeholder.gif"

      data-src="${imageHref}"
      data-srcset="${imageHref}"
    >`
  );
};

const createExtensionTemplate = (extension) => {
  return (
    `<figure class="uploaded-file__icon uploaded-file__icon_${extension.toLowerCase()}">
      <p class="uploaded-file__ext">${extension}</p>
    </figure>`
  );
};

export default class UploadedFile extends Abstract {
  constructor(id, fileName, extension, imageHref) {
    super();

    this._id = id;
    this._fileName = fileName;
    this._extension = extension;
    this._imageHref = imageHref;

    this._removeButtonElement = null;

    this._removeButtonClickHandler = this._removeButtonClickHandler.bind(this);
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getRemoveButtonElement() {
    if (!this._removeButtonElement) {
      this._removeButtonElement = this._element.querySelector(`button`);
    }

    return this._removeButtonElement;
  }

  getId() {
    return this._id;
  }

  getFileName() {
    return this._fileName;
  }

  setRemoveButtonClickHandler(callback) {
    this._callback.removeButtonClick = callback;
    this.getRemoveButtonElement().addEventListener(`click`, this._removeButtonClickHandler);
  }

  _getTemplate() {
    return createUploadedFileTemplate(this._fileName, this._extension, this._imageHref);
  }

  _removeButtonClickHandler() {
    this._callback.removeButtonClick(this.getId());
  }
}
