import Abstract from "../../abstract-view/abstract.js";

export default class SortingButton extends Abstract {
  constructor(element, index) {
    super();

    this._element = element;
    this._index = index;

    this._textElement = null;

    this._clickHandler = this._clickHandler.bind(this);
  }

  activate() {
    this._element.classList.add(`${this._element.classList[0]}_active`);

    const textElement = this.getTextElement();
    textElement.classList.add(`${textElement.classList[0]}_active`);
  }

  deactivate() {
    this._element.classList.remove(`${this._element.classList[0]}_active`);

    const textElement = this.getTextElement();
    textElement.classList.remove(`${textElement.classList[0]}_active`);
  }

  getTextElement() {
    if (!this._textElement) {
      this._textElement = this._element.querySelector(`.sorting-list__button-text`);
    }

    return this._textElement;
  }

  getIndex() {
    return this._index;
  }

  getSortName() {
    return this._element.dataset.sortName;
  }

  getSortDir() {
    return this._element.dataset.sortDir;
  }

  setSortDir(newSortDir, oldSortDir) {
    this._element.dataset.sortDir = newSortDir;
    this._element.classList.remove(`${this._element.classList[0]}_${oldSortDir}`);
    this._element.classList.add(`${this._element.classList[0]}_${newSortDir}`);
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    this._element.addEventListener(`click`, this._clickHandler);
  }

  _clickHandler() {
    this._callback.click({
      sortName: this.getSortName(),
      sortDir: this.getSortDir(),
      index: this.getIndex()
    });
  }
}
