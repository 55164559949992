import Abstract from "./../../abstract-view/abstract.js";

export default class SubmitForm extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._changeFormHandler = this._changeFormHandler.bind(this);
    this._focusOutHandler = this._focusOutHandler.bind(this);
    this._inputHandler = this._inputHandler.bind(this);
    this._submitHandler = this._submitHandler.bind(this);
  }

  hide() {
    this._element.style.opacity = 0;
  }

  show() {
    this._element.style.opacity = null;
  }

  getRequiredInfoIds() {
    const infoElements = this._element.querySelectorAll(`.form-label__required`);
    const ids = [];

    Array.from(infoElements)
      .forEach((element) => {
        const parentElement = element.parentElement;

        if (parentElement.tagName === `LABEL` && parentElement.getAttribute(`for`)) {
          ids.push(parentElement.getAttribute(`for`));
        }
      });

    return ids;
  }

  getRequiredInfoElement(id) {
    return this._element.querySelector(`label[for="${id}"] .form-label__required`);
  }

  setChangeFormHandler(callback) {
    this._callback.changeForm = callback;

    this._element.addEventListener(`change`, this._changeFormHandler);
  }

  setFocusOutHandler(callback) {
    this._callback.focusOut = callback;

    this._element.addEventListener(`focusout`, this._focusOutHandler);
  }

  setInputHandler(callback) {
    this._callback.input = callback;

    this._element.addEventListener(`input`, this._inputHandler);
  }

  setSubmit(callback) {
    this._callback.submit = callback;

    this._element.addEventListener(`submit`, this._submitHandler);
  }

  _changeFormHandler(evt) {
    this._callback.changeForm(evt);
  }

  _focusOutHandler(evt) {
    this._callback.focusOut(evt);
  }

  _inputHandler(evt) {
    this._callback.input(evt);
  }

  _submitHandler(evt) {
    evt.preventDefault();
    this._callback.submit(evt);
  }
}
