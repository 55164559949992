import Abstract from "../../abstract-view/abstract.js";

import {
  LOAD_BUTTON_TEXT
} from "../lexicon.js";

export default class SectionView extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._listElement = null;
    this._moreButtonElement = null;

    this._moreButtonText = null;

    this._moreButtonClickHandler = this._moreButtonClickHandler.bind(this);
  }

  getListElement() {
    if (!this._listElement) {
      this._listElement = this._element.querySelector(`.reviews-list`);
    }

    return this._listElement;
  }

  getSectionClass() {
      return this._element.classList[1];
  }

  setListElement(element) {
    this._listElement = element;
  }

  getListItems() {
    return this._element.querySelectorAll(`.reviews-item`);
  }

  getMoreButtonElement() {
    if (!this._moreButtonElement) {
      this._moreButtonElement = this._element.querySelector(`.button_reviews-more`);
    }

    return this._moreButtonElement;
  }

  setMoreButtonElement(element) {
    this._moreButtonElement = element;
  }

  setMoreButtonElement(element) {
    this._moreButtonElement = element;
  }

  getSortingButtons() {
    return this._element.querySelectorAll(`.sorting-list__button`);
  }

  getActiveSortingName() {
    const activeSortingElement = this._element.querySelector(`.sorting-list__button_active`);

    return (activeSortingElement) ? activeSortingElement.dataset.sortName : null;
  }

  getActiveSortingDir() {
    const activeSortingElement = this._element.querySelector(`.sorting-list__button_active`);

    return (activeSortingElement) ? activeSortingElement.dataset.sortDir : null;
  }

  getItemTemplate() {
    return this._element.dataset.itemTemplate;
  }

  setLoading() {
    this.getListElement().classList.add(`${this.getListElement().classList[0]}_loading`);
  }

  setMoreButtonLoading() {
    if (!this.getMoreButtonElement()) {
      return;
    }

    this.getMoreButtonElement().classList.add(`${this.getMoreButtonElement().classList[0]}_loading`);
    this._moreButtonText = this.getMoreButtonElement().textContent;
    this.getMoreButtonElement().textContent = LOAD_BUTTON_TEXT;
  }

  resetMoreButtonView() {
    if (!this.getMoreButtonElement()) {
      return;
    }

    this.getMoreButtonElement().classList.remove(`${this.getMoreButtonElement().classList[0]}_loading`);
    this.getMoreButtonElement().textContent = this._moreButtonText;
    this._moreButtonText = null;
  }

  resetView() {
    this.getListElement().classList.remove(`${this.getListElement().classList[0]}_loading`);
  }

  setMoreButtonClickHandler(callback) {
    this._callback.moreButtonClick = callback;
    this.getMoreButtonElement().addEventListener(`click`, this._moreButtonClickHandler);
  }

  _moreButtonClickHandler() {
    this._callback.moreButtonClick();
  }
}
