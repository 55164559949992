import ModalFeedbackTriggerPresenter from "../../modal-feedback-form/presenter/modal-feedback-trigger.js";
import CallbackFeedbackFormModalPresenter from "./modal.js";
export default class ModalCallbackFeedbackForm extends ModalFeedbackTriggerPresenter {
  constructor(triggerElement) {
    super(triggerElement);
  }

  _processShowModal() {
    const firstClassname = this._triggerView.getFirstClassname();

    this._modalFeedbackFormPresenter = new CallbackFeedbackFormModalPresenter(firstClassname);
    this._modalFeedbackFormPresenter.build();
  }
}
