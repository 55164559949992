import Observer from "./../../observer/observer.js";

export default class SubmitForm extends Observer {
  constructor() {
    super();

    this._validationData = {};
  }


  setValidationData(data) {
    this._validationData = data;
  }

  getValidationData() {
    return this._validationData;
  }

  changeElementValidation(element, status, notifications) {
    this._validationData[element.name] = status;
    this._notify(null, [element, status, notifications]);
  }
}
