import {
  ActionType as ProductQuantityActionType
} from "../product-quantity/const.js";

import {
  ActionType as CartDataActionType
} from "../cart-data/const.js";

export const defaultAddToCartModelData = {
  isAdding: false,
  isModalShown: false,
  quantityToAdd: 1
};

export const defaultModalModelData = {
  isActive: false,
  isInit: false
};

export const MODAL_MAIN_CLASS = `add-to-cart-modal-wrapper`;

export const ActionType = Object.assign(
    {},
    ProductQuantityActionType,
    CartDataActionType,
    {
      INIT_RELATED: `INIT_RELATED`,
      DESTROY_RELATED: `DESTROY_RELATED`,
      SHOW_MODAL: `SHOW_MODAL`,
      ADDING_TO_CART: `ADDING_TO_CART`,
      ADDED_TO_CART: `ADDED_TO_CART`,
    }
);

export const CONNECTOR_URL = `/assets/components/ecommerce/connector.php`;

export const RequestAction = {
  ADD_TO_CART: `add_to_cart`
};

export const defaultSliderOptions = {
  perView: 3,
  gap: 20,
  type: `slider`,
  bound: true,
  animationDuration: 500,
  keyboard: false,
  rewind: false,
  breakpoints: {
    1180: {
      perView: 3
    },
    960: {
      perView: 2,
      gap: 20
    },
    660: {
      perView: 2,
      gap: 10
    },
    640: {
      perView: 1,
      gap: 0
    }
  }
};

export const TriggerSource = {
  LISTING: `listing`,
  PRODUCT: `product`
};
