import AddToCartModalPresenter from "./modal.js";

import TriggerView from "../view/trigger";

import AddToCartModel from "../model/add-to-cart.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  ActionType,
  defaultAddToCartModelData,
  RequestAction,
  CONNECTOR_URL,
  TriggerSource
} from "../const.js";

import {
  ActionType as ProductQuantityActionType
} from "../../product-quantity/const.js";

import {
  ActionType as CartDataActionType
} from "../../cart-data/const.js";
export default class AddToCart {
  constructor(trigger, productId, animationComponent) {
    this._trigger = trigger;
    this._productId = productId;
    this._animation = animationComponent;

    this._connectorUrl = CONNECTOR_URL;

    this._modalPresenter = null;
    this._triggerView = null;
    this._model = null;

    this._handleTriggerClick = this._handleTriggerClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
    this._productQuantityObservationHandler = this._productQuantityObservationHandler.bind(this);
  }

  init() {
    this._triggerView = new TriggerView(this._trigger);
    this._triggerView.setClickHandler(this._handleTriggerClick);

    this._model = new AddToCartModel(Object.assign(
        {},
        defaultAddToCartModelData
    ));
    this._model.addObserver(this._handleChangeData);
  }

  getProductQuantityObservationHandler() {
    return this._productQuantityObservationHandler;
  }

  _processShowModal(data) {
    if (window.ym) {
      window.ym(29276100, `reachGoal`, `product_added_to_cart`);

      switch (this._triggerView.getSource()) {
        case TriggerSource.LISTING:
          window.ym(29276100, `reachGoal`, `product_added_to_cart_listing`);
          break;
        case TriggerSource.PRODUCT:
          window.ym(29276100, `reachGoal`, `product_added_to_cart_product`);
          break;
      }
    }

    if (window.CartData) {
      window.CartData.changeData(Object.assign(
          {},
          window.CartData.getData(),
          {
            totalCost: data.cart.totalCost,
            totalQuantity: data.cart.totalQuantity,
            currentItemQuantity: data.addedProduct.quantity,
            totalPositions: data.cart.totalPositions,
          }
      ), CartDataActionType.UPDATE_DATA);

      window.CartData.changeData(Object.assign(
          {},
          window.CartData.getData()
      ), CartDataActionType.ADD_PRODUCT, {
        addedProductId: data.addedProduct.productId
      });
    }

    this._modalPresenter = new AddToCartModalPresenter(data);
    this._modalPresenter.build();
  }

  _processChangeQuantity(quantity) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          quantityToAdd: quantity
        }
    ));
  }

  _processAddingToCart() {
    const requestArgs = {
      body: [
        `action=${RequestAction.ADD_TO_CART}`,
        `product_id=${this._productId}`,
        `quantity_to_add=${this._model.getData().quantityToAdd}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        let data = JSON.parse(response);

        if (data.error) {
          console.error(data.error);
          return;
        }

        data = Object.assign(
            {},
            data
        );

        this._model.changeData(Object.assign(
            {},
            this._model.getData(),
            {
              isModalShown: true
            }
        ), ActionType.SHOW_MODAL, {
          data
        });
      },
      loadendCallback: () => {
        this._model.changeData(Object.assign(
            {},
            this._model.getData(),
            {
              isAdding: false
            }
        ), ActionType.ADDED_TO_CART);
      }
    };
    sendXhr(requestArgs);
  }

  _handleTriggerClick() {
    if (this._model.getData().isAdding) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isAdding: true
        }
    ), ActionType.ADDING_TO_CART);
  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.ADDING_TO_CART:
        this._processAddingToCart();
        break;
      case ActionType.SHOW_MODAL:
        this._processShowModal(payload.data);
        break;
    }
  }

  _productQuantityObservationHandler(action, payload) {
    switch (action) {
      case ProductQuantityActionType.INIT:
      case ProductQuantityActionType.REMOVE_QUANTITY:
      case ProductQuantityActionType.ADD_QUANTITY:
      case ProductQuantityActionType.CHANGE:
        this._processChangeQuantity(payload.quantity);
        break;
    }
  }
}
