import ProductItem from "../../products-list/view/product-item.js";

import {
  AnimationClass
} from "../../animation/const.js";

import {
  FORM_ANIMATION_DURATION,
  TABLET_MEDIA_QUERY
} from "../const.js";

export default class ProductCard extends ProductItem {
  constructor(element, animationComponent) {
    super(element);

    this._animation = animationComponent;

    this._formElement = null;
    this._itemActionsWrapperElement = null;
  }

  getFormElement() {
    if (!this._formElement) {
      this._formElement = this._element.querySelector(`.product-item-form`);
    }

    return this._formElement;
  }

  getItemActionsWrapperElement() {
    if (!this._itemActionsWrapperElement) {
      this._itemActionsWrapperElement = this._element.querySelector(`.product-item-actions-wrapper`);
    }

    return this._itemActionsWrapperElement;
  }

  setHovered() {
    super.setHovered();

    this.getItemActionsWrapperElement().style.height = `${this.getItemActionsWrapperElement().clientHeight}px`;
    this._element.style.height = `${this._element.offsetHeight}px`;

    if (!window.matchMedia(TABLET_MEDIA_QUERY).matches) {
      this._animation.fadeIn(
          this.getFormElement(),
          FORM_ANIMATION_DURATION,
          null,
          AnimationClass.SHOW_FLEX
      );
    }
  }

  resetView() {
    super.resetView();

    if (!window.matchMedia(TABLET_MEDIA_QUERY).matches) {
      this._animation.fadeOut(
          this.getFormElement(),
          FORM_ANIMATION_DURATION,
          () => {
            this._element.style.height = null;
            this.getItemActionsWrapperElement().style.height = null;
          }
      );
    }
  }

  forceReset() {
    super.forceReset();

    this.getFormElement().style.display = null;
    this._element.style.height = null;
  }
}
