export const defaultFavoriteData = {
  quantity: null,
  isInit: false
};

export const ActionType = {
  ADD_PRODUCT: `ADD_PRODUCT`,
  REMOVE_PRODUCT: `REMOVE_PRODUCT`,
  CLEAR: `CLEAR`,
  GET_DATA: `GET_DATA`,
  CHANGE_CATEGORY: `CHANGE_CATEGORY`,
  SORT: `SORT`,
  UPDATE_DATA: `UPDATE_DATA`,
  CHANGE_PAGE: `CHANGE_PAGE`
};

export const CONNECTOR_URL = `/assets/components/favorites/connector.php`;

export const RequestAction = {
  ADD_PRODUCT: `add_product`,
  REMOVE_PRODUCT: `remove_product`,
  GET_DATA: `get_data`,
  GET_PRODUCTS: `get_products`,
  RENDER_FAVORITES: `render_favorites`
};
