import Abstract from "../../abstract-view/abstract.js";

export default class FaqList extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  getItems() {
    return this._element.querySelectorAll(`li`);
  }
}
