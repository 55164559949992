export const SECTION_HEADING = `Список сравнения`;
export const REMOVE_ITEM_LEXICON = `Удалить`;
export const BUTTON_TEXT = `Перейти в сравнение`;

export const QUANTITY_UNIT_MEASURE = [
  `товар`,
  `товара`,
  `товаров`
];
export const ON_REQUEST = `По запросу`;
