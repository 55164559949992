export const defaultFaqItemModelData = {
  active: false
};

export const ActionType = {
  EXPAND: `EXPAND`,
  SHRINK: `SHRINK`
};

export const ANIMATION_DURATION = 100;
