export const MapParam = {
  ZOOM: 16,
  ICON: {
    URL: `/assets/templates/main/images/icons/pin-filled.svg`,
    SIZE: [80, 80],
    REAL_SIZE: [60, 80]
  }
};

export const ANIMATION_DURATION = 200;
