import Abstract from "../../abstract-view/abstract.js";

export default class List extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  getVisible() {
    return this._element.dataset.visible;
  }

  getItems() {
    return this._element.querySelectorAll(`li`);
  }

  getActiveItem() {
    return this._element.querySelector(`[data-active="true"]`)
  }

  getRelatedIds() {
    return this.getElement().dataset.relatedProductsIds;
  }

  setLoaded() {
    this.getElement().classList.add(`${this.getElement().classList[0]}_loaded`);
  }
}
