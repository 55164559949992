import Abstract from "../../abstract-view/abstract.js";

export default class FileAttach extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._labelElement = null;

    this._elementChangeHandler = this._elementChangeHandler.bind(this);
    this._labelDragEnterHandler = this._labelDragEnterHandler.bind(this);
    this._labelDragLeaveHandler = this._labelDragLeaveHandler.bind(this);
    this._labelDragOverHandler = this._labelDragOverHandler.bind(this);
    this._labelDropHandler = this._labelDropHandler.bind(this);
  }

  getLabelElement() {
    if (!this._labelElement) {
      this._labelElement = this._element.closest(`label`);
    }

    return this._labelElement;
  }

  setLabelHighlights() {
    this.getLabelElement().classList.add(`${this.getLabelElement().classList[0]}_highlights`);
  }

  removeLabelHighlights() {
    this.getLabelElement().classList.remove(`${this.getLabelElement().classList[0]}_highlights`);
  }

  setElementChangeHandler(callback) {
    this._callback.inputChange = callback;
    this._element.addEventListener(`change`, this._elementChangeHandler);
  }

  setLabelDragEnterHandler(callback) {
    this._callback.labelDragEnter = callback;
    this.getLabelElement().addEventListener(`dragenter`, this._labelDragEnterHandler);
  }

  setLabelDragLeaveHandler(callback) {
    this._callback.labelDragLeave = callback;
    this.getLabelElement().addEventListener(`dragleave`, this._labelDragLeaveHandler);
  }

  setLabelDragOverHandler(callback) {
    this._callback.labelDragOver = callback;
    this.getLabelElement().addEventListener(`dragover`, this._labelDragOverHandler);
  }

  setLabelDropHandler(callback) {
    this._callback.labelDrop = callback;
    this.getLabelElement().addEventListener(`drop`, this._labelDropHandler);
  }

  _elementChangeHandler() {
    this._callback.inputChange(this._element.files);
  }

  _labelDragEnterHandler(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this._callback.labelDragEnter();
  }

  _labelDragLeaveHandler(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this._callback.labelDragLeave();
  }

  _labelDragOverHandler(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this._callback.labelDragOver();
  }

  _labelDropHandler(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this._callback.labelDrop(evt);
  }
}
