import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

const createStickySectionTemplate = () => {
  return (
    `<section class="sticky-header sticky-header_top" style="display: none;">
      <div class="sticky-header-wrapper">
        <div class="container">
          <ul class="header-list header-list_sticky">
            <li class="header-list__item header-list__item_middle header-list__item_logo header-list__item_logo_sticky">
              <a class="logo-link" href="/">
                <picture>
                  <img
                    class="logo lazyloaded"
                    alt=""
                    width="140"
                    src="/assets/templates/main/images/logo.svg"
                    srcset="/assets/templates/main/images/logo.svg"
                    data-src="/assets/templates/main/images/logo.svg"
                    data-srcset="/assets/templates/main/images/logo.svg" style="">
                </picture>
              </a>
            </li>
            <li class="header-list__item header-list__item_button header-list__item_button_sticky">
              <button class="button button_catalog">Каталог</button>
            </li>
            <li class="header-list__item header-list__item_middle header-list__item_search-form">
              <form
                class="search-form"
                action="/search"
                method="get"
                >
                <input
                  class="form-input form-input_search"
                  name="query"
                  placeholder="Введите строительные материалы или артикулы..."
                  value>
                <button class="search-form__button" type="submit">
                  <span class="visually-hidden">Поиск</span>
                </button>
              </form>
            </li>
            <li class="header-list__item header-list__item_middle header-list__item_phone header-list__item_phone-sticky">
              <a class="phone-link" href="tel:+78126043323">+7 (812) 604-33-23</a>
              <button class="phone-button phone-button_sticky">Перезвоните мне</button>
            </li>
            <li class="header-list__item header-list__item_middle header-list__item_compare header-list__item_compare-sticky">
              <a class="header-compare" href="/comparison">
                <span class="visually-hidden">Список сравнения</span>
              </a>
            </li>
            <li class="header-list__item header-list__item_middle header-list__item_favorite header-list__item_favorite-sticky">
              <a class="header-favorite" href="/favorites">
                <span class="visually-hidden">Избранное</span>
              </a>
            </li>
            <li class="header-list__item header-list__item_middle">
              <a class="header-cart header-cart_sticky" href="/cart">
                <p class="header-cart-text"></p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>`
  );
};

export default class TopStickySection extends Abstract {
  constructor() {
    super();

    this._catalogButton = null;
    this._catalogNavigationElement = null;
    this._searchInputElement = null;
    this._phoneButton = null;
    this._cartTriggerElement = null;
    this._favoriteTriggerElement = null;
    this._comparisonTriggerElement = null;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getCatalogButton() {
    if (!this._catalogButton) {
      this._catalogButton = this.getElement().querySelector(`.button_catalog`);
    }

    return this._catalogButton;
  }

  getCatalogNavigationElement() {
    if (!this._catalogNavigationElement) {
      this._catalogNavigationElement = this.getElement().querySelector(`.header-catalog`);
    }

    return this._catalogNavigationElement;
  }

  getSearchInputElement() {
    if (!this._searchInputElement) {
      this._searchInputElement = this.getElement().querySelector(`[name="query"]`);
    }

    return this._searchInputElement;
  }

  getPhoneButtonElement() {
    if (!this._phoneButton) {
      this._phoneButton = this.getElement().querySelector(`.phone-button`);
    }

    return this._phoneButton;
  }

  getCartTrigger() {
    if (!this._cartTriggerElement) {
      this._cartTriggerElement = this._element.querySelector(`.header-cart`);
    }

    return this._cartTriggerElement;
  }

  getFavoriteTrigger() {
    if (!this._favoriteTriggerElement) {
      this._favoriteTriggerElement = this._element.querySelector(`.header-favorite`);
    }

    return this._favoriteTriggerElement;
  }

  getComparisonTrigger() {
    if (!this._comparisonTriggerElement) {
      this._comparisonTriggerElement = this._element.querySelector(`.header-compare`);
    }

    return this._comparisonTriggerElement;
  }

  setScrollable() {
    this._element.classList.add(`${this._element.classList[0]}_is-scrollable`);
  }

  unsetScrollable() {
    this._element.classList.remove(`${this._element.classList[0]}_is-scrollable`);
  }

  setSectionZIndex(value) {
    this.getElement().style.zIndex = value;
  }

  _getTemplate() {
    return createStickySectionTemplate();
  }
}
