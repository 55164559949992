import Abstract from "../../abstract-view/abstract.js";

import {
  TriggerSource
} from "../const.js";

export default class Trigger extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._clickHandler = this._clickHandler.bind(this);
  }

  getSource() {
    if (this._element.classList.contains(`product-item-form__button_add-to-cart_listing`)) {
      return TriggerSource.LISTING;
    } else if (this._element.classList.contains(`product-item-form__button_add-to-cart_product`)) {
      return TriggerSource.PRODUCT;
    }

    return null;
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    if (this._element) {
      this._element.addEventListener(`click`, this._clickHandler);
    }
  }

  _clickHandler(evt) {
    evt.preventDefault();
    this._callback.click();
  }
}
