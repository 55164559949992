import Observer from "../../observer/observer.js";
import {
  modelData
} from "../../../mixins/model-data.js";

export default class AddToCart extends Observer {
  constructor(data) {
    super();

    this._data = data;
  }
}

Object.assign(AddToCart.prototype, modelData);
