import ContentView from "../view/content.js";
import MapView from "../view/map.js";
import ContentModel from "../model/content.js";

import {
  yandexMaps
} from "../../../mixins/yandex-maps.js";

import Animation from "../../animation/animation.js";

import {
  MapParam,
  districts,
  defaultContentModelData,
  ActionType,
  VISIBLE_ITEMS_COUNT,
  ANIMATION_DURATION,
  CHANGE_HEIGHT_TIMEOUT
} from "../const.js";

import {
  render,
  RenderPosition
} from "../../render/render.js";

export default class DeliveryMap {
  constructor(container) {
    this._container = container;
    this._zoom = MapParam.ZOOM;
    this._coords = MapParam.COORDS;
    this._contentView = null;
    this._mapView = null;
    this._contentModel = null;
    this._animation = new Animation();

    this._renderMap = this._renderMap.bind(this);
    this._handlePoligonMouseEnter = this._handlePoligonMouseEnter.bind(this);
    this._handlePoligonMouseLeave = this._handlePoligonMouseLeave.bind(this);
    this._handlePoligonMouseClick = this._handlePoligonMouseClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
    this._handleListButtonClick = this._handleListButtonClick.bind(this);
  }

  init() {
    this._mapView = new MapView(this._container);
    this._initContent();
    this.loadScript(this._renderMap);
  }

  _initContent() {
    const contentElement = this._container.parentElement.querySelector(`.content_delivery-map-content`);

    if (!contentElement) {
      return;
    }

    this._contentView = new ContentView(contentElement);
    this._contentModel = new ContentModel(Object.assign(
        {},
        defaultContentModelData
    ));
    this._contentModel.addObserver(this._handleChangeData);
    this._contentModel.changeData(Object.assign(
        {},
        defaultContentModelData,
        {
          expanded: false
        }
    ), ActionType.SHRINK);
  }

  _refreshMap() {
    if (!this._map) {
      return;
    }

    setTimeout(() => {
      this._mapView.setHeight(this._contentView.getHeight());
      this._map.container.fitToViewport();
    }, CHANGE_HEIGHT_TIMEOUT);
  }

  _processShrink() {
    const items = this._contentView.getListItems();

    if (!items) {
      return;
    }

    if (items.length < VISIBLE_ITEMS_COUNT) {
      return;
    }

    Array.from(items)
      .forEach((item, number) => {
        if (number + 1 <= VISIBLE_ITEMS_COUNT) {
          return;
        }

        this._animation.fadeOut(
            item,
            ANIMATION_DURATION
        );
      });

    const modelData = this._contentModel.getData();

    if (modelData.buttonRendered) {
      this._contentView.setShow();
      this._refreshMap();
      return;
    }

    this._contentModel.changeData(Object.assign(
        {},
        modelData,
        {
          buttonRendered: true
        }
    ), ActionType.RENDER_BUTTON);
  }

  _processExpand() {
    const items = this._contentView.getListItems();

    if (!items) {
      return;
    }

    if (items.length < VISIBLE_ITEMS_COUNT) {
      return;
    }

    Array.from(items)
      .forEach((item, number) => {
        if (number + 1 <= VISIBLE_ITEMS_COUNT) {
          return;
        }

        this._animation.fadeIn(
            item,
            ANIMATION_DURATION,
            () => {
              item.removeAttribute(`class`);
              item.removeAttribute(`style`);
            }
        );
      });

    const modelData = this._contentModel.getData();

    if (modelData.buttonRendered) {
      this._contentView.setHide();
    }

    this._refreshMap();
  }

  _processRenderButton() {
    const listElement = this._contentView.getListElement();
    const buttonElement = this._contentView.getButtonElement();

    render(listElement, buttonElement, RenderPosition.AFTEREND);
    this._contentView.setButtonClickHandler(this._handleListButtonClick);
    this._refreshMap();
  }

  _renderMap() {
    this._ymaps = window.ymaps;
    this._ymaps.ready(() => {
      this._map = new this._ymaps.Map(
          this._container,
          {
            center: [this._coords.latitude, this._coords.longitude],
            zoom: this._zoom,
            controls: {}
          },
          {
            suppressMapOpenBlock: true
          }
      );

      this._map.controls.add(`zoomControl`);
      this._map.behaviors.disable([`scrollZoom`, `dblClickZoom`]);
      this.setCopyrightsStyle();

      districts.forEach((district, index) => {
        district.coords.forEach((coord) => {
          const poligon = new this._ymaps.GeoObject({
            geometry: {
              type: `Polygon`,
              coordinates: coord
            },
            properties: {
              hintContent: district.name,
              balloonContentHeader: district.name,
              balloonContent: ``,
              districtId: index,
              districtName: district.name,
              polygonColor: district.color
            }
          }, {
            fillColor: district.color,
            strokeColor: MapParam.DISTRICT.strokeColor,
            fillOpacity: MapParam.DISTRICT.fillOpacity,
            strokeWidth: MapParam.DISTRICT.strokeWidth,
            strokeStyle: MapParam.DISTRICT.strokeStyle
          });

          poligon.events.add(`mouseenter`, this._handlePoligonMouseEnter);
          poligon.events.add(`mouseleave`, this._handlePoligonMouseLeave);
          poligon.events.add(`click`, this._handlePoligonMouseClick);

          this._map.geoObjects.add(poligon);
        });
      });
    });
  }

  _handlePoligonMouseEnter(evt) {
    const id = evt.originalEvent.target.properties.get(`districtId`);

    this._map.geoObjects.each((item) => {
      if (
        item.options.get(`fillOpacity`) !== MapParam.DISTRICT.clickOpacity &&
        item.properties.get(`districtId`) === id
      ) {
        item.options.set({
          fillOpacity: MapParam.DISTRICT.hoverOpacity
        });
      } else if (
        item.options.get(`fillOpacity`) === MapParam.DISTRICT.clickOpacity &&
        item.properties.get(`districtId`) === id
      ) {
        item.options.set({
          fillOpacity: MapParam.DISTRICT.clickOpacity
        });
      } else {
        item.options.set({
          fillOpacity: MapParam.DISTRICT.fillOpacity
        });
      }
    });
  }

  _handlePoligonMouseLeave() {
    this._map.geoObjects.each((item) => {
      if (
        item.options.get(`fillOpacity`) !== MapParam.DISTRICT.clickOpacity
      ) {
        item.options.set({
          fillOpacity: MapParam.DISTRICT.fillOpacity
        });
      }
    });
  }

  _handlePoligonMouseClick(evt) {
    const id = evt.originalEvent.target.properties.get(`districtId`);

    this._map.geoObjects.each((item) => {
      if (
        item.properties.get(`districtId`) === id
      ) {
        item.options.set({
          fillOpacity: MapParam.DISTRICT.clickOpacity
        });
      } else {
        item.options.set({
          fillOpacity: MapParam.DISTRICT.fillOpacity
        });
      }
    });
  }

  _handleListButtonClick() {
    const modelData = this._contentModel.getData();
    const action = (modelData.expanded) ? ActionType.SHRINK : ActionType.EXPAND;

    this._contentModel.changeData(Object.assign(
        {},
        modelData,
        {
          expanded: !modelData.expanded
        }
    ), action);
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.SHRINK:
        this._processShrink();
        break;
      case ActionType.EXPAND:
        this._processExpand();
        break;
      case ActionType.RENDER_BUTTON:
        this._processRenderButton();
        break;
    }
  }
}

Object.assign(DeliveryMap.prototype, yandexMaps);
