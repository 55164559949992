import ModalSectionView from "../../modal/view/section.js";

import {
  formatNumber
} from "../../../utils/format-number.js";

import {
  declinationNumber
} from "../../../utils/declination-number.js";

import {
  CLOSE_BUTTON_TEXT,
  MODAL_HEADING,
  QUANTITY_UNIT_MEASURE,
  PRICE_CURRENCY,
  ARTICLE_TEXT,
  DECREASE_TEXT,
  INCREASE_TEXT,
  CONTAIN_IN_CART,
  ON_PREPOSITION,
  CONTINUE_SHOPPING,
  GO_TO_CART,
  ADD_TO_CART,
  ON_REQUEST
} from "../lexicon.js";

const createAddToCartModalSectionTemplate = ({
  addedProduct,
  cart,
  relatedProductsListWrapperTemplate
}) => {

  const productMarkup = createAddToCartProductTemplate(addedProduct);
  const summaryMarkup = createSummaryTemplate(cart);
  const relatedProductsMarkup = ((window.ModalRelatedProducts && window.ModalRelatedProducts.getData().isSliderInitialized) || !relatedProductsListWrapperTemplate) ?
    `` :
    createRelatedProductsTemplate(relatedProductsListWrapperTemplate);

  return (
    `<section class="add-to-cart-modal-section">
      <button class="add-to-cart-modal-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
      <div class="add-to-cart-modal">
        <h2 class="heading heading_h3 add-to-cart-modal-heading">${MODAL_HEADING}</h2>
        ${productMarkup}
        ${summaryMarkup}
        ${relatedProductsMarkup}
      </div>
    </section>`
  );
};

const createProductNotificationTemplate = (productNotification) => {
  return (
    `<div class="product-attention product-attention_add-to-cart">
      <div class="product-attention-text">
        <p class="product-attention__title product-attention__title_add-to-cart">Внимание</p>
        <p class="product-attention__description">${productNotification}</p>
      </div>
    </div>`
  );
};

const createAddToCartProductTemplate = ({
  productId,
  imageSrc,
  title,
  uri,
  article,
  quantity,
  price,
  oldPrice,
  totalCost,
  totalOldCost,
  sessionElementId,
  step,
  unitMeasurePriceRod,
  productNotification
}) => {
  const productTotalCost = (totalCost) ?
    totalCost :
    price;
  const priceMarkup = (productTotalCost > 0) ?
    createPriceTemplate(productTotalCost) : ON_REQUEST;
  const oldPriceMarkup = (totalOldCost) ?
    createOldPriceTemplate(totalOldCost) : ``;
  const productNotificationMarkup = (productNotification) ?
    createProductNotificationTemplate(productNotification) : ``;

  // Fixing the quantity value.
  quantity = (quantity < step) ? step : quantity;

  return (
    `<div class="product-item add-to-cart-product-container" data-product-id="${productId}" data-session-element-id="${sessionElementId}" data-price="${price}" data-old-price="${oldPrice}">
      <div class="add-to-cart-image-title-container">
        <a class="product-item-image-wrapper product-item-image-wrapper_add-to-cart" href="${uri}">
          <picture class="product-item__picture">
            <img
              class="product-item__image lazyload"

              alt=""
              width="240"

              src="${imageSrc.jpg[`@2x`]}"
              srcset="/assets/templates/main/images/lazy-load-placeholder.gif"

              data-src="${imageSrc.jpg[`@2x`]}"
              data-srcset="${imageSrc.jpg[`@2x`]}"

              data-aspectratio="240/240"
            >
          </picture>
        </a>
        <div class="add-to-cart-modal-product-title-wrapper">
          <a class="product-item__title product-item__title_add-to-cart" href="${uri}">${title}</a>
          <p class="product-item__article product-item__article_add-to-cart">${ARTICLE_TEXT}: ${article}</p>
        </div>
      </div>
      <div class="product-item-form_add-to-cart-wrapper">
        ${productNotificationMarkup}
        <p class="product-quantity-title">Количество ${unitMeasurePriceRod}:</p>
        <div class="product-item-form-container">
          <form class="product-item-form product-item-form_add-to-cart" method="post">
            <div class="product-quantity">
              <button class="product-quantity__button product-quantity__button_remove" type="button">
                <span class="visually-hidden">${DECREASE_TEXT}</span>
              </button>
              <input class="form-input form-input_product-quantity" type="number" min="1" step="${step}" value="${quantity}">
              <button class="product-quantity__button product-quantity__button_add" type="button">
                <span class="visually-hidden">${INCREASE_TEXT}</span>
              </button>
            </div>
          </form>
          <div class="add-to-cart-modal-price">
            ${priceMarkup}
            ${oldPriceMarkup}
          </div>
        </div>
      </div>
    </div>`
  );
};

const createPriceTemplate = (productTotalCost) => {
  return (
    `<p class="product-item__price product-item__price_add-to-cart">${formatNumber(productTotalCost, 2, `,`, `\u00A0`)} ${PRICE_CURRENCY}</p>`
  );
};

const createOldPriceTemplate = (totalOldCost) => {
  return (
    `<div class="add-to-cart-modal-old-price-wrapper">
      <p class="product-total__old product-total__old_add-to-cart">${formatNumber(totalOldCost, 2, `,`, `\u00A0`)} ${PRICE_CURRENCY}</p>
    </div>`
  );
};

const createSummaryTemplate = ({
  totalCost,
  totalPositions,
  cartPageUrl
}) => {
  const totalCostMarkup = (totalCost > 0) ?
    ` ${ON_PREPOSITION} <span class="add-to-cart-modal-summary__total-cost">${formatNumber(totalCost, 2, `,`, `\u00A0`)} ${PRICE_CURRENCY}</span>` :
    ``;

  return (
    `<div class="add-to-cart-modal-summary">
      <p class="add-to-cart-modal-summary__text">${CONTAIN_IN_CART} <span class="add-to-cart-modal-summary__total-positions">${totalPositions}\u00A0${declinationNumber(totalPositions, QUANTITY_UNIT_MEASURE)}</span>${totalCostMarkup}</p>
      <div class="add-to-cart-modal-summary__actions">
        <button class="button button_outline button_continue-shopping" type="button">${CONTINUE_SHOPPING}</button>
        <a class="button add-to-cart-modal-summary__cart-link" href="${cartPageUrl}">${GO_TO_CART}</a>
      </div>
    </div>`
  );
};

const createRelatedProductsTemplate = (productsListWrapperTemplate) => {
  return (
    `<div class="add-to-cart-modal-related">
      <p class="add-to-cart-modal-related__text">${ADD_TO_CART}:</p>
      ${productsListWrapperTemplate}
    </div>`
  );
};

export default class AddToCartModalSection extends ModalSectionView {
  constructor(data) {
    super();

    this._closeButtonClass = `add-to-cart-modal-close-button`;
    this._createModalSectionTemplate = createAddToCartModalSectionTemplate.bind(this, data);

    this._itemId = data.addedProduct.productId;
    this._sessionElementId = data.addedProduct.sessionElementId;
    this._addedProductTotalCost = data.addedProduct.totalCost;
    this._addedProductTotalOldCost = data.addedProduct.totalOldCost;

    this._addedProductElement = null;
    this._quantityElement = null;
    this._pricesElement = null;
    this._relatedProductsWrapperElement = null;
    this._oldTotalCostWrapperElement = null;
    this._summaryElement = null;
    this._continueShoppingButton = null;

    this._continueShoppingButtonClickHandler = this._continueShoppingButtonClickHandler.bind(this);
  }

  getContinueShoppingButton() {
    if (!this._continueShoppingButton) {
      this._continueShoppingButton = this._element.querySelector(`.button_continue-shopping`);
    }

    return this._continueShoppingButton;
  }

  getAddedProductElement() {
    if (!this._addedProductElement) {
      this._addedProductElement = this._element.querySelector(`.product-item`);
    }

    return this._addedProductElement;
  }

  getQuantityElement() {
    if (!this._quantityElement) {
      this._quantityElement = this._element.querySelector(`.product-quantity`);
    }

    return this._quantityElement;
  }

  getOldTotalCostWrapperElement() {
    if (!this._oldTotalCostWrapperElement) {
      this._oldTotalCostWrapperElement = this._element.querySelector(`.add-to-cart-modal-old-price-wrapper`);
    }

    return this._oldTotalCostWrapperElement;
  }

  getPricesElement() {
    if (!this._pricesElement) {
      this._pricesElement = this._element.querySelector(`.add-to-cart-modal-price`);
    }

    return this._pricesElement;
  }

  getSummaryElement() {
    if (!this._summaryElement) {
      this._summaryElement = this._element.querySelector(`.add-to-cart-modal-summary__text`);
    }

    return this._summaryElement;
  }

  getItemId() {
    return this._itemId;
  }

  getSessionElementId() {
    return this._sessionElementId;
  }

  getAddedProductTotalCost() {
    return this._addedProductTotalCost;
  }

  getAddedProductTotalOldCost() {
    return this._addedProductTotalOldCost;
  }

  getRelatedProductsWrapperElement() {
    if (!this._relatedProductsWrapperElement) {
      this._relatedProductsWrapperElement = this._element.querySelector(`.products-list-related-wrapper`);
    }

    return this._relatedProductsWrapperElement;
  }

  setContinueShoppingButtonClickHandler(callback) {
    this._callback.continueShoppingButtonClick = callback;
    this.getContinueShoppingButton().addEventListener(`click`, this._continueShoppingButtonClickHandler);
  }

  _continueShoppingButtonClickHandler() {
    this._callback.continueShoppingButtonClick();
  }
}
