export const Action = {
  RENDER: `RENDER`,
  DESTROY: `DESTROY`
};

export const Style = {
  ERROR: `error`,
  INFO: `info`,
  SUCCESS: `success`
};
