import ActionTriggerPresenter from "../../../action-item/presenter/action-trigger.js";

import {
  sendXhr
} from "../../../../utils/send-xhr/send-xhr.js";

import {
  CONNECTOR_URL,
  LINK_HREF
} from "../const.js";

import {
  LINK_TITLE,
  ACTIVE_TEXT
} from "../lexicon.js";

import {
  ActionType,
  RequestAction
} from "../../favorite-data/const.js";

import {
  ActionType as ActionItemActionType
} from "../../../action-item/const.js";
export default class FavoriteTrigger extends ActionTriggerPresenter {
  constructor(element, productId, generatedId, animationComponent) {
    super(element, productId, generatedId, animationComponent);

    this._linkTitle = LINK_TITLE;
    this._linkHref = LINK_HREF;
    this._connectorUrl = CONNECTOR_URL;
    this._activeText = ACTIVE_TEXT;

    this._handleFavoriteDataChange = this._handleFavoriteDataChange.bind(this);
  }

  init() {
    super.init();

    window.FavoriteData.addObserver(this._handleFavoriteDataChange);
  }

  destroy() {
    super.destroy();

    window.FavoriteData.removeObserver(this._handleFavoriteDataChange);
  }

  _processTriggerClick(newStatus) {
    super._processTriggerClick(newStatus);

    const args = {
      body: [
        `action=${RequestAction.GET_DATA}`,
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);
        const favoriteDataAction = (newStatus) ?
          ActionType.ADD_PRODUCT :
          ActionType.REMOVE_PRODUCT;

        if (window.FavoriteData) {
          window.FavoriteData.changeData(Object.assign(
              {},
              window.FavoriteData.getData(),
              data
          ), favoriteDataAction,
          {
            productId: this._productId,
            generatedId: this._generatedId
          });
        }
      }
    };

    sendXhr(args);
  }

  _processRemoveProductFromList() {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isActive: false
        }
    ), ActionItemActionType.REMOVE);
  }

  _handleFavoriteDataChange(action, payload) {
    switch (action) {
      case ActionType.UPDATE_DATA:
        if (payload.removedProductId === this._model.getData().productId) {
          this._processRemoveProductFromList();
        }
        break;
    }
  }
}
