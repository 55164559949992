export const PageType = {
  PREV: `Назад`,
  NEXT: `Вперед`
};

export const DefaultAction = {
  RENDER_PAGINATION: `RENDER_PAGINATION`,
  CHANGE_PAGE: `CHANGE_PAGE`
};

export const PAGE_KEY = `page`;
