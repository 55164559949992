import Abstract from "../../abstract-view/abstract.js";

import {
  CLOSE_BUTTON_TEXT
} from "../lexicon.js";

const createModalSectionTemplate = () => {
  return (
    `<section class="modal-section">
      <button class="modal-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
    </section>`
  );
};

export default class ModalSection extends Abstract {
  constructor() {
    super();

    this._closeButton = null;
    this._closeButtonClass = `modal-close-button`;
    this._createModalSectionTemplate = createModalSectionTemplate;

    this._closeButtonClickHandler = this._closeButtonClickHandler.bind(this);
  }

  setElement(element) {
    this._element = element;
  }

  getCloseButton() {
    if (!this._closeButton) {
      this._closeButton = this._element.querySelector(`.${this._closeButtonClass}`);
    }

    return this._closeButton;
  }

  getTemplate() {
    return this._createModalSectionTemplate();
  }

  reset() {
    this._element = null;
    this._closeButton = null;
    this._callback = null;
  }

  setCloseButtonClickHandler(callback) {
    this._callback.closeButtonClick = callback;
    this.getCloseButton().addEventListener(`click`, this._closeButtonClickHandler);
  }

  _closeButtonClickHandler() {
    this._callback.closeButtonClick();
  }
}
