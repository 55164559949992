import Observer from "./../../observer/observer.js";

export default class ElementNotification extends Observer {
  constructor(data) {
    super();
    this._data = data;
  }

  getData() {
    return this._data;
  }

  changeData(data, action, payload) {
    this._data = data;
    this._notify(action, payload);
  }
}
