export const defaultTriggerModelData = {
  isActive: false,
  productId: null
};

export const ActionType = {
  ADD_TO_FAVORITES: `ADD_TO_FAVORITES`,
  REMOVE_FROM_FAVORITES: `REMOVE_FROM_FAVORITES`
};

export const CONNECTOR_URL = `/assets/components/favorites/connector.php`;
export const RequestAction = {
  INIT: `init`,
  ADD_TO_FAVORITES: `add_to_favorites`,
  REMOVE_FROM_FAVORITES: `remove_from_favorites`
};

export const LINK_HREF = `/favorites/`;
