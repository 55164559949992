export const defaultContentModelData = {
  expanded: true,
  buttonRendered: false
};

export const VISIBLE_ITEMS_COUNT = 18;
export const ANIMATION_DURATION = 200;
export const CHANGE_HEIGHT_TIMEOUT = 400;

export const ActionType = {
  SHRINK: `shrink`,
  EXPAND: `expand`,
  RENDER_BUTTON: `render_button`
};

export const MapParam = {
  ZOOM: 6,
  COORDS: {
    latitude: 59.963647,
    longitude: 30.382110
  },
  DISTRICT: {
    strokeColor: `#259379`,
    fillOpacity: 0.3,
    hoverOpacity: 0.8,
    clickOpacity: 0.9,
    strokeWidth: 1,
    strokeStyle: `solid`
  },
};

export const districts = [{
  name: `Адмиралтейский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.90138, 30.250364],
        [59.902584, 30.253405],
        [59.903436, 30.254838],
        [59.904113, 30.255466],
        [59.905178, 30.255907],
        [59.906609, 30.257476],
        [59.909963, 30.26035],
        [59.913186, 30.262134],
        [59.915656, 30.261985],
        [59.916449, 30.262306],
        [59.918418, 30.264018],
        [59.919505, 30.266268],
        [59.920208, 30.267209],
        [59.922215, 30.268623],
        [59.926267, 30.270585],
        [59.928043, 30.271699],
        [59.929441, 30.273277],
        [59.931121, 30.275982],
        [59.932266, 30.278239],
        [59.933033, 30.280865],
        [59.934264, 30.286993],
        [59.935553, 30.292421],
        [59.93807, 30.300435],
        [59.939354, 30.304001],
        [59.941109, 30.308204],
        [59.938061, 30.312623],
        [59.937259, 30.312476],
        [59.936373, 30.310143],
        [59.925047, 30.327064],
        [59.926194, 30.330067],
        [59.923451, 30.335846],
        [59.923218, 30.335253],
        [59.916993, 30.342798],
        [59.916762, 30.342106],
        [59.91597, 30.34211],
        [59.915664, 30.340155],
        [59.908776, 30.321147],
        [59.908632, 30.320365],
        [59.908531, 30.318441],
        [59.896317, 30.319262],
        [59.896056, 30.300362],
        [59.896094, 30.29523],
        [59.898734, 30.296756],
        [59.900853, 30.297725],
        [59.901825, 30.297422],
        [59.902587, 30.296759],
        [59.902893, 30.294813],
        [59.903157, 30.290683],
        [59.903898, 30.282956],
        [59.903888, 30.281446],
        [59.903484, 30.279935],
        [59.901023, 30.27583],
        [59.901505, 30.274595],
        [59.901437, 30.274189],
        [59.901724, 30.273552],
        [59.901632, 30.272941],
        [59.90232, 30.269152],
        [59.90218, 30.269004],
        [59.901961, 30.268137],
        [59.901876, 30.266414],
        [59.901749, 30.265744],
        [59.900626, 30.264771],
        [59.900231, 30.263774],
        [59.899837, 30.26226],
        [59.899928, 30.259846],
        [59.900397, 30.256842],
        [59.900465, 30.255823],
        [59.900408, 30.255026],
        [59.90008, 30.253892],
        [59.900467, 30.252437]
      ]
    ]
  ]
}, {
  name: `Василеостровский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.943109, 30.180838],
        [59.943731, 30.178277],
        [59.944043, 30.17796],
        [59.944705, 30.178094],
        [59.945244, 30.179101],
        [59.943542, 30.190459],
        [59.950803, 30.194804],
        [59.9525, 30.183442],
        [59.953326, 30.183191],
        [59.953725, 30.183435],
        [59.95433, 30.184499],
        [59.959424, 30.187582],
        [59.960349, 30.188805],
        [59.961167, 30.190651],
        [59.96164, 30.192753],
        [59.963921, 30.207001],
        [59.964287, 30.209812],
        [59.964408, 30.212525],
        [59.964289, 30.215294],
        [59.963967, 30.21819],
        [59.961621, 30.23276],
        [59.962761, 30.234279],
        [59.960141, 30.241077],
        [59.959072, 30.250091],
        [59.958229, 30.255548],
        [59.957753, 30.260449],
        [59.957234, 30.262993],
        [59.956579, 30.26552],
        [59.955926, 30.267312],
        [59.955508, 30.268233],
        [59.953809, 30.270755],
        [59.952102, 30.274589],
        [59.951291, 30.278056],
        [59.947268, 30.290842],
        [59.946686, 30.293537],
        [59.94636, 30.299437],
        [59.946164, 30.308387],
        [59.945502, 30.316532],
        [59.943236, 30.311835],
        [59.942513, 30.310479],
        [59.941742, 30.309515],
        [59.941109, 30.308204],
        [59.939354, 30.304001],
        [59.93807, 30.300435],
        [59.935553, 30.292421],
        [59.934264, 30.286993],
        [59.932932, 30.28045],
        [59.931881, 30.277356],
        [59.929441, 30.273277],
        [59.928043, 30.271699],
        [59.926267, 30.270585],
        [59.922215, 30.268623],
        [59.920208, 30.267209],
        [59.919505, 30.266268],
        [59.918418, 30.264018],
        [59.918364, 30.258969],
        [59.919408, 30.254027],
        [59.920147, 30.251669],
        [59.920292, 30.251861],
        [59.921212, 30.250771],
        [59.922936, 30.242374],
        [59.923758, 30.240308],
        [59.924592, 30.238771],
        [59.924193, 30.237624],
        [59.924643, 30.236538],
        [59.924941, 30.236852],
        [59.926743, 30.230746],
        [59.926993, 30.231031],
        [59.926317, 30.233368],
        [59.927764, 30.234872],
        [59.928751, 30.234468],
        [59.929999, 30.230151],
        [59.931787, 30.230819],
        [59.93185, 30.231779],
        [59.932347, 30.232578],
        [59.932812, 30.232574],
        [59.933261, 30.23183],
        [59.933335, 30.23071],
        [59.932989, 30.229742],
        [59.931747, 30.229159],
        [59.931261, 30.229246],
        [59.931065, 30.22884],
        [59.929044, 30.228026],
        [59.928694, 30.227368],
        [59.928541, 30.225859],
        [59.9289, 30.223015],
        [59.92876, 30.221051],
        [59.929087, 30.219432],
        [59.928215, 30.217896],
        [59.928193, 30.203949],
        [59.92835, 30.202225],
        [59.931301, 30.19184],
        [59.932627, 30.189866]
      ]
    ]
  ]
}, {
  name: `Выборгский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.095925, 30.070301],
        [60.104087, 30.085329],
        [60.107607, 30.087778],
        [60.107884, 30.088719],
        [60.107356, 30.091539],
        [60.107557, 30.092876],
        [60.106745, 30.094964],
        [60.107384, 30.097051],
        [60.106356, 30.101266],
        [60.106212, 30.106377],
        [60.106769, 30.110328],
        [60.107145, 30.109035],
        [60.108278, 30.109816],
        [60.108615, 30.108481],
        [60.109836, 30.109094],
        [60.111364, 30.107599],
        [60.112518, 30.108434],
        [60.11331, 30.110353],
        [60.113071, 30.110863],
        [60.113547, 30.111649],
        [60.113678, 30.113282],
        [60.114359, 30.113739],
        [60.112608, 30.120145],
        [60.114292, 30.123955],
        [60.113609, 30.12502],
        [60.114582, 30.127396],
        [60.112103, 30.133847],
        [60.114203, 30.137853],
        [60.116507, 30.137822],
        [60.118491, 30.138738],
        [60.119069, 30.138062],
        [60.119953, 30.138983],
        [60.113294, 30.173822],
        [60.118739, 30.177761],
        [60.117355, 30.186404],
        [60.116836, 30.208415],
        [60.115112, 30.218893],
        [60.115484, 30.220017],
        [60.116547, 30.219287],
        [60.118337, 30.227396],
        [60.118603, 30.233692],
        [60.118065, 30.234203],
        [60.122549, 30.251041],
        [60.125596, 30.259466],
        [60.1233, 30.265323],
        [60.120209, 30.266667],
        [60.119374, 30.266302],
        [60.116494, 30.270624],
        [60.111519, 30.271615],
        [60.110475, 30.271037],
        [60.10974, 30.272984],
        [60.107952, 30.273172],
        [60.107951, 30.274887],
        [60.105698, 30.274659],
        [60.102723, 30.275645],
        [60.096241, 30.287551],
        [60.096321, 30.289625],
        [60.095883, 30.291699],
        [60.093872, 30.291718],
        [60.091001, 30.303082],
        [60.095827, 30.308468],
        [60.089848, 30.332948],
        [60.090858, 30.34142],
        [60.092151, 30.363675],
        [60.090091, 30.369254],
        [60.087746, 30.370827],
        [60.080509, 30.373432],
        [60.079738, 30.375317],
        [60.077395, 30.377691],
        [60.061077, 30.385886],
        [60.05986, 30.385487],
        [60.059088, 30.387752],
        [60.052375, 30.381234],
        [60.033695, 30.365217],
        [60.032135, 30.364828],
        [60.007505, 30.369902],
        [60.001439, 30.366058],
        [59.998901, 30.364925],
        [59.998215, 30.365285],
        [59.996313, 30.363757],
        [59.994326, 30.360276],
        [59.991186, 30.352628],
        [59.99085, 30.348052],
        [59.990095, 30.347172],
        [59.989778, 30.347364],
        [59.989866, 30.350231],
        [59.989514, 30.351953],
        [59.983771, 30.362485],
        [59.980403, 30.36586],
        [59.977501, 30.367526],
        [59.977532, 30.365777],
        [59.979034, 30.360592],
        [59.977344, 30.343737],
        [59.973196, 30.347354],
        [59.962493, 30.352033],
        [59.959771, 30.354052],
        [59.954159, 30.350223],
        [59.951856, 30.349632],
        [59.951852, 30.344008],
        [59.955564, 30.339372],
        [59.958382, 30.337245],
        [59.966211, 30.335684],
        [59.972089, 30.331857],
        [59.975438, 30.327913],
        [59.978462, 30.321819],
        [59.982672, 30.331151],
        [59.984084, 30.329582],
        [59.984978, 30.330328],
        [59.984849, 30.330933],
        [59.993606, 30.326381],
        [59.994325, 30.328809],
        [59.995927, 30.326015],
        [59.997952, 30.324093],
        [60.051704, 30.294733],
        [60.068632, 30.274523],
        [60.051298, 30.222838],
        [60.061388, 30.21413],
        [60.060546, 30.210273],
        [60.062266, 30.20192],
        [60.060528, 30.195796],
        [60.063175, 30.193588],
        [60.062088, 30.190733],
        [60.047943, 30.135175],
        [60.048296, 30.134063],
        [60.051895, 30.133374],
        [60.05322, 30.134313],
        [60.054455, 30.136473],
        [60.055974, 30.134907],
        [60.057633, 30.135505],
        [60.05643, 30.126258],
        [60.057568, 30.1263],
        [60.059689, 30.135887],
        [60.064354, 30.135509],
        [60.064956, 30.136171],
        [60.065341, 30.137898],
        [60.069773, 30.135499],
        [60.07253, 30.132802],
        [60.070047, 30.119428],
        [60.071796, 30.116221],
        [60.07261, 30.112332],
        [60.081194, 30.10245],
        [60.085454, 30.093808],
        [60.086844, 30.086297],
        [60.090905, 30.075838],
        [60.095035, 30.071996]
      ]
    ]
  ]
}, {
  name: `Калининский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.990095, 30.347172],
        [59.99085, 30.348052],
        [59.991186, 30.352628],
        [59.994326, 30.360276],
        [59.996313, 30.363757],
        [59.997565, 30.364324],
        [59.998215, 30.365285],
        [59.998901, 30.364925],
        [60.001439, 30.366058],
        [60.007505, 30.369902],
        [60.00956, 30.36912],
        [60.010784, 30.369145],
        [60.01747, 30.367549],
        [60.032135, 30.364828],
        [60.033695, 30.365217],
        [60.035967, 30.366762],
        [60.052375, 30.381234],
        [60.059088, 30.387752],
        [60.058761, 30.389001],
        [60.058081, 30.388268],
        [60.057885, 30.389118],
        [60.057084, 30.388369],
        [60.049585, 30.418287],
        [60.04671, 30.415377],
        [60.044874, 30.424285],
        [60.044473, 30.423756],
        [60.042073, 30.435329],
        [60.041468, 30.436667],
        [60.040883, 30.43734],
        [60.039956, 30.437891],
        [60.038312, 30.437926],
        [60.02294, 30.437313],
        [60.019613, 30.437757],
        [60.01559, 30.436515],
        [60.000128, 30.442456],
        [59.972224, 30.412651],
        [59.959431, 30.407156],
        [59.955543, 30.39961],
        [59.956712, 30.395885],
        [59.95749, 30.392349],
        [59.957853, 30.388908],
        [59.9578, 30.385166],
        [59.956919, 30.382921],
        [59.953901, 30.376838],
        [59.952557, 30.371985],
        [59.95188, 30.368563],
        [59.951382, 30.363819],
        [59.951357, 30.360263],
        [59.951856, 30.349632],
        [59.954159, 30.350223],
        [59.959771, 30.354052],
        [59.962493, 30.352033],
        [59.973196, 30.347354],
        [59.977344, 30.343737],
        [59.979034, 30.360592],
        [59.978865, 30.362099],
        [59.977532, 30.365777],
        [59.977501, 30.367526],
        [59.980403, 30.36586],
        [59.983771, 30.362485],
        [59.989514, 30.351953],
        [59.989866, 30.350231],
        [59.989778, 30.347364]
      ]
    ]
  ]
}, {
  name: `Кировский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.886916, 30.168022],
        [59.887501, 30.168826],
        [59.888276, 30.169065],
        [59.88824, 30.171804],
        [59.88779, 30.174776],
        [59.887631, 30.178894],
        [59.887714, 30.182244],
        [59.888666, 30.190969],
        [59.889404, 30.19267],
        [59.889707, 30.194097],
        [59.8901, 30.194604],
        [59.891633, 30.194703],
        [59.892669, 30.195291],
        [59.893672, 30.197191],
        [59.894048, 30.197329],
        [59.894398, 30.198674],
        [59.896221, 30.202873],
        [59.898413, 30.206854],
        [59.899382, 30.20937],
        [59.902115, 30.212699],
        [59.902158, 30.213604],
        [59.904097, 30.216235],
        [59.905175, 30.21604],
        [59.905324, 30.216385],
        [59.905683, 30.216091],
        [59.908146, 30.207431],
        [59.908574, 30.207061],
        [59.915892, 30.213544],
        [59.916185, 30.214198],
        [59.916109, 30.215378],
        [59.914681, 30.221946],
        [59.914019, 30.22406],
        [59.913481, 30.228848],
        [59.913648, 30.232412],
        [59.913531, 30.232635],
        [59.913609, 30.238613],
        [59.91374, 30.240561],
        [59.914078, 30.240821],
        [59.914461, 30.251927],
        [59.915841, 30.260765],
        [59.91578, 30.261995],
        [59.913186, 30.262134],
        [59.909963, 30.26035],
        [59.905178, 30.255907],
        [59.903436, 30.254838],
        [59.902584, 30.253405],
        [59.90138, 30.250364],
        [59.900134, 30.253527],
        [59.900465, 30.255823],
        [59.899928, 30.259846],
        [59.899837, 30.26226],
        [59.900626, 30.264771],
        [59.901749, 30.265744],
        [59.901961, 30.268137],
        [59.90232, 30.269152],
        [59.901632, 30.272941],
        [59.901724, 30.273552],
        [59.901023, 30.27583],
        [59.903484, 30.279935],
        [59.903944, 30.282261],
        [59.902893, 30.294813],
        [59.902587, 30.296759],
        [59.901825, 30.297422],
        [59.900853, 30.297725],
        [59.896094, 30.29523],
        [59.896098, 30.297379],
        [59.894281, 30.297273],
        [59.894094, 30.299472],
        [59.889021, 30.297639],
        [59.886043, 30.294608],
        [59.882317, 30.293715],
        [59.882578, 30.30037],
        [59.881586, 30.300064],
        [59.880916, 30.297639],
        [59.879528, 30.29499],
        [59.878233, 30.29369],
        [59.877144, 30.293166],
        [59.858468, 30.290084],
        [59.858436, 30.290876],
        [59.858021, 30.291525],
        [59.85782, 30.292773],
        [59.855265, 30.291546],
        [59.851973, 30.290675],
        [59.848243, 30.288668],
        [59.846013, 30.286768],
        [59.846199, 30.286199],
        [59.844963, 30.285196],
        [59.842527, 30.282278],
        [59.842474, 30.28135],
        [59.835496, 30.269629],
        [59.834215, 30.269393],
        [59.834951, 30.269185],
        [59.825802, 30.230578],
        [59.824331, 30.222191],
        [59.822653, 30.200809],
        [59.82289, 30.18423],
        [59.823743, 30.184751],
        [59.83213, 30.193535],
        [59.841349, 30.206109],
        [59.863917, 30.239433],
        [59.864227, 30.22325],
        [59.864692, 30.215124],
        [59.865635, 30.210023],
        [59.866929, 30.205364],
        [59.86843, 30.201785],
        [59.868861, 30.199966],
        [59.867816, 30.195076],
        [59.867735, 30.193689],
        [59.87944, 30.168049],
        [59.880734, 30.165818],
        [59.884077, 30.17239],
        [59.886338, 30.167854]
      ]
    ]
  ]
}, {
  name: `Колпинский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.829213, 30.441129],
        [59.832009, 30.456162],
        [59.834036, 30.475052],
        [59.807794, 30.511314],
        [59.807973, 30.512446],
        [59.810993, 30.508293],
        [59.812598, 30.50823],
        [59.814406, 30.512271],
        [59.818525, 30.519357],
        [59.81979, 30.520752],
        [59.821738, 30.521502],
        [59.823395, 30.520202],
        [59.82402, 30.520296],
        [59.812961, 30.549495],
        [59.817047, 30.555615],
        [59.817849, 30.553581],
        [59.819596, 30.55206],
        [59.821258, 30.554388],
        [59.819477, 30.55885],
        [59.820233, 30.560137],
        [59.820721, 30.563338],
        [59.822222, 30.559979],
        [59.821657, 30.566233],
        [59.823655, 30.568328],
        [59.822012, 30.575011],
        [59.816296, 30.590571],
        [59.813456, 30.595113],
        [59.810768, 30.598162],
        [59.808848, 30.601157],
        [59.80421, 30.611991],
        [59.797072, 30.622706],
        [59.795216, 30.626117],
        [59.793778, 30.629857],
        [59.791943, 30.63946],
        [59.790184, 30.657298],
        [59.788461, 30.663954],
        [59.785889, 30.671265],
        [59.785261, 30.675509],
        [59.784683, 30.686129],
        [59.781767, 30.695774],
        [59.78118, 30.698858],
        [59.780942, 30.704518],
        [59.781947, 30.71747],
        [59.781988, 30.721856],
        [59.781601, 30.727094],
        [59.780705, 30.731954],
        [59.779414, 30.735963],
        [59.770723, 30.725528],
        [59.769082, 30.735289],
        [59.765066, 30.740159],
        [59.759531, 30.737173],
        [59.756821, 30.742425],
        [59.755916, 30.745427],
        [59.755422, 30.749578],
        [59.755569, 30.752699],
        [59.756443, 30.757661],
        [59.754471, 30.759501],
        [59.753651, 30.759358],
        [59.752855, 30.756937],
        [59.75268, 30.75123],
        [59.750884, 30.748962],
        [59.749889, 30.748875],
        [59.748039, 30.750663],
        [59.745844, 30.75471],
        [59.743855, 30.756898],
        [59.746086, 30.725817],
        [59.74621, 30.714566],
        [59.746876, 30.703444],
        [59.744616, 30.695427],
        [59.732625, 30.681874],
        [59.732482, 30.680372],
        [59.731597, 30.679078],
        [59.729835, 30.680497],
        [59.728067, 30.681035],
        [59.724775, 30.684175],
        [59.721893, 30.650772],
        [59.721188, 30.64855],
        [59.73594, 30.63015],
        [59.735523, 30.622373],
        [59.735884, 30.621358],
        [59.735487, 30.619926],
        [59.735285, 30.616697],
        [59.730849, 30.617448],
        [59.730061, 30.59244],
        [59.72908, 30.593041],
        [59.729085, 30.591324],
        [59.729759, 30.588611],
        [59.729494, 30.582828],
        [59.730764, 30.570343],
        [59.730515, 30.569143],
        [59.734475, 30.560153],
        [59.737516, 30.556077],
        [59.739889, 30.555404],
        [59.741092, 30.556283],
        [59.741311, 30.557359],
        [59.741887, 30.557438],
        [59.74207, 30.556915],
        [59.742573, 30.557605],
        [59.74302, 30.557241],
        [59.74383, 30.558364],
        [59.749557, 30.556649],
        [59.748007, 30.523087],
        [59.752439, 30.523903],
        [59.753663, 30.522058],
        [59.757117, 30.510288],
        [59.758216, 30.508289],
        [59.759089, 30.508574],
        [59.759526, 30.508044],
        [59.759443, 30.50959],
        [59.759951, 30.509253],
        [59.760219, 30.509941],
        [59.760571, 30.508978],
        [59.761365, 30.508802],
        [59.762701, 30.507326],
        [59.763859, 30.509032],
        [59.765997, 30.50932],
        [59.766643, 30.510154],
        [59.766969, 30.50981],
        [59.767295, 30.510635],
        [59.768496, 30.510579],
        [59.768139, 30.512048],
        [59.769624, 30.513193],
        [59.770084, 30.512682],
        [59.770106, 30.51085],
        [59.770449, 30.510695],
        [59.771207, 30.511694],
        [59.771956, 30.514345],
        [59.772885, 30.513841],
        [59.77323, 30.512995],
        [59.773745, 30.513386],
        [59.773602, 30.512619],
        [59.773946, 30.51205],
        [59.77533, 30.513533],
        [59.776431, 30.512902],
        [59.776369, 30.51432],
        [59.775968, 30.514413],
        [59.775634, 30.516196],
        [59.776499, 30.518474],
        [59.776978, 30.518604],
        [59.777432, 30.516193],
        [59.778029, 30.516289],
        [59.779391, 30.518771],
        [59.77921, 30.519694],
        [59.780019, 30.521162],
        [59.780798, 30.521588],
        [59.781323, 30.520923],
        [59.780583, 30.520159],
        [59.780584, 30.519154],
        [59.782654, 30.520303],
        [59.78272, 30.518558],
        [59.782117, 30.517635],
        [59.782103, 30.516358],
        [59.78387, 30.517113],
        [59.783835, 30.518791],
        [59.784205, 30.518951],
        [59.784954, 30.516809],
        [59.78469, 30.51414],
        [59.784273, 30.513648],
        [59.786491, 30.505135],
        [59.788878, 30.497732],
        [59.79111, 30.493154],
        [59.791775, 30.489602],
        [59.791548, 30.485491],
        [59.793018, 30.478833],
        [59.802538, 30.458071],
        [59.809498, 30.456132],
        [59.810885, 30.461324],
        [59.817405, 30.450825],
        [59.817414, 30.454195],
        [59.824063, 30.448968],
        [59.823816, 30.447661],
        [59.824498, 30.44644],
        [59.827365, 30.444261],
        [59.827904, 30.442886],
        [59.827656, 30.440118],
        [59.827299, 30.439249],
        [59.827451, 30.438788]
      ]
    ]
  ]
}, {
  name: `Кронштадтский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.98948, 29.812733],
        [59.989948, 29.813377],
        [59.989668, 29.814224],
        [59.98919, 29.813591]
      ]
    ],
    [
      [
        [59.988864, 29.718666],
        [59.98924, 29.716692],
        [59.989778, 29.716327],
        [59.99039, 29.717421],
        [59.990068, 29.719374]
      ]
    ],
    [
      [
        [60.026659, 29.791591],
        [60.028023, 29.787621],
        [60.029376, 29.78775],
        [60.029247, 29.792127],
        [60.028539, 29.794144],
        [60.027465, 29.79438]
      ]
    ],
    [
      [
        [60.055751, 29.714998],
        [60.057714, 29.714333],
        [60.060053, 29.71959],
        [60.061115, 29.727873],
        [60.060568, 29.730877],
        [60.059516, 29.730405],
        [60.056781, 29.720448]
      ]
    ],
    [
      [
        [60.08165, 29.845633],
        [60.08302, 29.844168],
        [60.084761, 29.844445],
        [60.088009, 29.848822],
        [60.089081, 29.85305],
        [60.087302, 29.855281],
        [60.082476, 29.84941]
      ]
    ],
    [
      [
        [59.948132, 29.702588],
        [59.949198, 29.701387],
        [59.950651, 29.702524],
        [59.951211, 29.704691],
        [59.949499, 29.70688]
      ]
    ],
    [
      [
        [59.956481, 29.703515],
        [59.957202, 29.702443],
        [59.958514, 29.703151],
        [59.959052, 29.705597],
        [59.958202, 29.706648],
        [59.957148, 29.70594]
      ]
    ],
    [
      [
        [59.968858, 29.765079],
        [59.969133, 29.765712],
        [59.968772, 29.766227],
        [59.968557, 29.765519]
      ]
    ],
    [
      [
        [59.971271, 29.696082],
        [59.971572, 29.694558],
        [59.972261, 29.694129],
        [59.973444, 29.694966],
        [59.973057, 29.697283]
      ]
    ],
    [
      [
        [59.975342, 29.730779],
        [59.975896, 29.730188],
        [59.976106, 29.731154],
        [59.975541, 29.731948]
      ]
    ],
    [
      [
        [59.978628, 29.743836],
        [59.980897, 29.744393],
        [59.980639, 29.748041],
        [59.981219, 29.749007],
        [59.979337, 29.753277],
        [59.978757, 29.752698],
        [59.977939, 29.74817]
      ]
    ],
    [
      [
        [59.974444, 29.714535],
        [59.975552, 29.717046],
        [59.974842, 29.719213],
        [59.974089, 29.719449],
        [59.97339, 29.717174]
      ]
    ],
    [
      [
        [59.937583, 29.671266],
        [59.940425, 29.672323],
        [59.940549, 29.671579],
        [59.941621, 29.671874],
        [59.961208, 29.679805],
        [59.961208, 29.680525],
        [59.963475, 29.681398],
        [59.963646, 29.680743],
        [59.974316, 29.685222],
        [59.98142, 29.687535],
        [59.983918, 29.687457],
        [59.987742, 29.690323],
        [59.988475, 29.689835],
        [59.988885, 29.688121],
        [59.990311, 29.686905],
        [59.993543, 29.689313],
        [59.997842, 29.698291],
        [59.999227, 29.699137],
        [60.003843, 29.697051],
        [60.007046, 29.693134],
        [60.013406, 29.688598],
        [60.018239, 29.683281],
        [60.022447, 29.676319],
        [60.02315, 29.673229],
        [60.023923, 29.672547],
        [60.02418, 29.67132],
        [60.025679, 29.668693],
        [60.025557, 29.661915],
        [60.026349, 29.66024],
        [60.026294, 29.659509],
        [60.027065, 29.659339],
        [60.027987, 29.657623],
        [60.028299, 29.655789],
        [60.027663, 29.651008],
        [60.03014, 29.645567],
        [60.030334, 29.643541],
        [60.029762, 29.641602],
        [60.031355, 29.63781],
        [60.03134, 29.636156],
        [60.030428, 29.63497],
        [60.031683, 29.633952],
        [60.03301, 29.636],
        [60.033704, 29.638055],
        [60.033654, 29.638945],
        [60.032398, 29.640912],
        [60.031964, 29.643056],
        [60.031399, 29.647159],
        [60.031647, 29.650346],
        [60.030464, 29.653741],
        [60.029994, 29.661385],
        [60.028319, 29.667602],
        [60.028481, 29.668952],
        [60.027801, 29.671107],
        [60.028734, 29.672421],
        [60.028589, 29.673887],
        [60.029135, 29.67391],
        [60.029294, 29.677053],
        [60.028217, 29.677905],
        [60.027626, 29.681589],
        [60.026801, 29.683459],
        [60.027004, 29.684132],
        [60.026404, 29.685145],
        [60.02585, 29.690982],
        [60.025101, 29.691398],
        [60.024892, 29.690646],
        [60.024243, 29.692476],
        [60.024527, 29.695171],
        [60.023674, 29.698365],
        [60.022651, 29.699548],
        [60.022105, 29.699208],
        [60.021401, 29.700503],
        [60.022633, 29.701506],
        [60.022765, 29.702774],
        [60.022398, 29.704172],
        [60.021618, 29.704425],
        [60.022267, 29.706577],
        [60.021739, 29.708341],
        [60.022013, 29.709318],
        [60.020981, 29.713674],
        [60.020904, 29.719838],
        [60.01957, 29.72277],
        [60.019298, 29.725608],
        [60.018514, 29.726431],
        [60.020682, 29.734982],
        [60.021158, 29.744344],
        [60.026775, 29.74676],
        [60.027709, 29.748626],
        [60.028989, 29.753508],
        [60.02935, 29.753143],
        [60.030167, 29.756958],
        [60.030066, 29.758734],
        [60.029438, 29.759732],
        [60.028946, 29.753977],
        [60.027685, 29.749412],
        [60.026588, 29.747215],
        [60.024953, 29.746061],
        [60.021152, 29.745096],
        [60.021755, 29.772945],
        [60.02216, 29.778012],
        [60.021802, 29.782864],
        [60.022137, 29.782935],
        [60.022077, 29.78751],
        [60.021689, 29.78757],
        [60.021838, 29.790169],
        [60.022247, 29.791027],
        [60.025054, 29.818594],
        [60.025509, 29.81982],
        [60.025969, 29.824574],
        [60.028267, 29.830018],
        [60.02881, 29.834385],
        [60.027635, 29.841141],
        [60.029194, 29.862861],
        [60.028911, 29.863038],
        [60.029318, 29.867617],
        [60.029643, 29.86757],
        [60.0329, 29.903747],
        [60.032537, 29.903854],
        [60.03297, 29.908741],
        [60.033381, 29.908592],
        [60.033505, 29.914083],
        [60.034243, 29.918977],
        [60.034381, 29.923462],
        [60.036981, 29.950006],
        [60.036617, 29.950112],
        [60.037194, 29.955594],
        [60.036694, 29.955687],
        [60.036191, 29.950345],
        [60.035901, 29.950445],
        [60.033605, 29.923296],
        [60.032619, 29.92148],
        [60.032168, 29.922543],
        [60.031426, 29.922101],
        [60.031654, 29.91938],
        [60.030565, 29.917123],
        [60.030763, 29.915741],
        [60.032337, 29.917274],
        [60.032823, 29.916135],
        [60.032071, 29.904055],
        [60.031433, 29.899393],
        [60.025166, 29.902133],
        [60.020218, 29.873065],
        [60.019106, 29.845806],
        [60.024073, 29.839772],
        [60.023099, 29.833081],
        [60.023223, 29.829281],
        [60.02278, 29.824147],
        [60.022145, 29.82248],
        [60.022851, 29.821223],
        [60.022494, 29.81855],
        [60.023695, 29.816916],
        [60.021486, 29.791932],
        [60.021549, 29.787578],
        [60.020795, 29.783453],
        [60.021351, 29.782887],
        [60.021349, 29.77814],
        [60.020681, 29.777997],
        [60.020776, 29.773186],
        [60.020242, 29.765276],
        [60.01699, 29.759041],
        [60.015856, 29.758058],
        [60.014653, 29.757998],
        [60.014674, 29.757497],
        [60.01548, 29.757449],
        [60.013561, 29.754141],
        [60.011627, 29.752413],
        [60.012119, 29.752198],
        [60.010424, 29.748598],
        [60.009854, 29.750514],
        [60.009042, 29.750168],
        [60.007968, 29.752614],
        [60.007138, 29.755221],
        [60.007171, 29.7572],
        [60.006209, 29.75924],
        [60.006487, 29.761527],
        [60.006824, 29.761815],
        [60.006989, 29.765327],
        [60.005286, 29.765943],
        [60.005095, 29.764874],
        [60.004482, 29.763821],
        [60.002642, 29.762095],
        [60.002854, 29.762975],
        [60.004903, 29.764829],
        [60.004339, 29.768331],
        [60.002365, 29.769851],
        [60.002471, 29.77064],
        [60.001869, 29.772031],
        [60.002985, 29.773001],
        [60.003491, 29.77473],
        [60.002604, 29.773258],
        [60.001533, 29.773009],
        [60.002454, 29.774107],
        [60.00322, 29.776123],
        [60.002813, 29.776091],
        [60.001756, 29.773995],
        [60.00139, 29.774335],
        [60.001911, 29.776085],
        [60.000648, 29.774375],
        [60.001652, 29.776366],
        [60.000441, 29.775295],
        [60.000859, 29.776722],
        [59.999597, 29.779897],
        [59.997165, 29.780246],
        [59.996343, 29.781575],
        [59.99527, 29.785751],
        [59.99498, 29.788687],
        [59.993451, 29.790868],
        [59.992743, 29.793776],
        [59.991349, 29.797036],
        [59.991204, 29.798002],
        [59.992179, 29.800705],
        [59.99204, 29.801383],
        [59.991131, 29.798303],
        [59.989596, 29.799744],
        [59.988439, 29.798934],
        [59.985211, 29.801351],
        [59.984683, 29.804359],
        [59.983794, 29.803519],
        [59.983173, 29.8052],
        [59.983441, 29.805712],
        [59.98305, 29.805242],
        [59.984314, 29.800258],
        [59.983446, 29.801697],
        [59.984358, 29.799898],
        [59.984525, 29.797892],
        [59.985608, 29.794888],
        [59.984046, 29.792521],
        [59.984178, 29.791392],
        [59.983665, 29.790951],
        [59.983231, 29.793205],
        [59.982159, 29.793007],
        [59.981743, 29.794763],
        [59.980606, 29.793834],
        [59.979353, 29.793917],
        [59.978776, 29.792975],
        [59.977927, 29.793386],
        [59.97932, 29.791787],
        [59.978756, 29.785983],
        [59.978167, 29.784812],
        [59.974248, 29.789638],
        [59.973548, 29.79219],
        [59.973149, 29.792475],
        [59.972276, 29.791086],
        [59.972029, 29.788823],
        [59.970876, 29.785027],
        [59.970545, 29.785349],
        [59.970162, 29.784378],
        [59.970977, 29.784108],
        [59.973072, 29.781663],
        [59.975413, 29.778801],
        [59.975637, 29.777505],
        [59.976213, 29.77772],
        [59.977873, 29.775659],
        [59.977853, 29.77463],
        [59.978396, 29.774503],
        [59.979146, 29.77072],
        [59.978887, 29.769006],
        [59.979691, 29.768428],
        [59.980468, 29.764549],
        [59.980218, 29.762966],
        [59.980934, 29.762383],
        [59.981783, 29.758373],
        [59.981442, 29.757395],
        [59.982064, 29.757069],
        [59.983, 29.752514],
        [59.987406, 29.752155],
        [59.987944, 29.750779],
        [59.988776, 29.752348],
        [59.99075, 29.752843],
        [59.992921, 29.749696],
        [59.993545, 29.747524],
        [59.993786, 29.741591],
        [59.98991, 29.735993],
        [59.989335, 29.736591],
        [59.989221, 29.735185],
        [59.988909, 29.736013],
        [59.989237, 29.737149],
        [59.988706, 29.736328],
        [59.989367, 29.734008],
        [59.989102, 29.732499],
        [59.990321, 29.731745],
        [59.990115, 29.735949],
        [59.993139, 29.74014],
        [59.993857, 29.73969],
        [59.995467, 29.733597],
        [59.99699, 29.735153],
        [59.996661, 29.737275],
        [59.997002, 29.737907],
        [59.996697, 29.738658],
        [59.997396, 29.739588],
        [59.999892, 29.738753],
        [59.999951, 29.736202],
        [60.000477, 29.734175],
        [60.001552, 29.732637],
        [60.00105, 29.732065],
        [60.001504, 29.730694],
        [60.001366, 29.729025],
        [60.002071, 29.728119],
        [60.003414, 29.723207],
        [60.004179, 29.722253],
        [60.004537, 29.719714],
        [60.005266, 29.717744],
        [60.005453, 29.716289],
        [60.004766, 29.715817],
        [60.005568, 29.712769],
        [59.997921, 29.703488],
        [59.996261, 29.703667],
        [59.995688, 29.702356],
        [59.994864, 29.702892],
        [59.994416, 29.707041],
        [59.993675, 29.706768],
        [59.992869, 29.704693],
        [59.988085, 29.700496],
        [59.987308, 29.697313],
        [59.982533, 29.693654],
        [59.979453, 29.689146],
        [59.963374, 29.683127],
        [59.963409, 29.682206],
        [59.961108, 29.681386],
        [59.960977, 29.68213],
        [59.946589, 29.676253],
        [59.944037, 29.67578],
        [59.942497, 29.674577],
        [59.940319, 29.674152],
        [59.940336, 29.673102],
        [59.937482, 29.671998]
      ]
    ]
  ]
}, {
  name: `Красногвардейский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.927293, 30.394941],
        [59.929097, 30.394173],
        [59.929884, 30.394354],
        [59.93179, 30.395106],
        [59.936747, 30.39909],
        [59.939087, 30.400399],
        [59.948685, 30.402967],
        [59.950517, 30.403202],
        [59.952967, 30.402537],
        [59.954895, 30.401034],
        [59.955543, 30.39961],
        [59.959431, 30.407156],
        [59.972224, 30.412651],
        [60.000128, 30.442456],
        [60.01559, 30.436515],
        [60.018806, 30.437713],
        [60.02294, 30.437313],
        [60.038312, 30.437926],
        [60.040512, 30.442804],
        [60.037591, 30.458172],
        [60.035782, 30.461083],
        [60.03449, 30.461626],
        [60.033489, 30.465378],
        [60.033754, 30.467299],
        [60.033512, 30.468397],
        [60.032115, 30.468123],
        [60.031431, 30.466955],
        [60.031013, 30.464534],
        [60.028379, 30.462249],
        [60.028004, 30.462733],
        [60.027307, 30.4686],
        [60.026681, 30.467884],
        [60.026556, 30.465121],
        [60.025545, 30.464326],
        [60.024296, 30.467102],
        [60.023104, 30.46737],
        [60.0226, 30.467937],
        [60.022965, 30.469428],
        [60.024653, 30.471443],
        [60.025155, 30.475195],
        [60.024706, 30.476137],
        [60.023543, 30.475797],
        [60.022622, 30.476204],
        [60.022429, 30.476817],
        [60.022666, 30.479003],
        [60.022526, 30.479857],
        [60.021066, 30.480858],
        [60.020313, 30.482846],
        [60.020623, 30.484487],
        [60.023071, 30.487668],
        [60.023216, 30.488788],
        [60.022978, 30.489424],
        [60.02227, 30.489891],
        [60.020665, 30.489628],
        [60.017966, 30.490196],
        [60.015423, 30.487482],
        [60.014332, 30.487075],
        [60.011106, 30.48833],
        [60.008062, 30.490797],
        [60.009217, 30.49437],
        [60.007977, 30.495605],
        [60.007474, 30.497401],
        [60.006973, 30.499447],
        [60.007677, 30.5002],
        [60.00675, 30.504135],
        [60.003549, 30.502901],
        [60.000531, 30.499285],
        [60.000201, 30.499681],
        [59.99996, 30.502342],
        [59.999442, 30.502712],
        [59.998221, 30.501987],
        [59.996381, 30.504024],
        [59.995528, 30.503776],
        [59.99514, 30.503132],
        [59.994337, 30.503484],
        [59.993786, 30.504666],
        [59.993378, 30.506668],
        [59.992749, 30.507233],
        [59.9906, 30.506212],
        [59.989282, 30.508513],
        [59.988516, 30.508633],
        [59.987192, 30.507888],
        [59.986814, 30.510272],
        [59.986553, 30.510095],
        [59.986317, 30.51151],
        [59.985081, 30.512682],
        [59.984025, 30.514721],
        [59.992475, 30.534782],
        [59.992018, 30.536128],
        [59.990765, 30.536588],
        [59.98976, 30.534893],
        [59.990449, 30.534544],
        [59.990391, 30.533949],
        [59.98865, 30.533976],
        [59.988523, 30.534799],
        [59.989016, 30.535053],
        [59.989066, 30.535979],
        [59.988565, 30.537893],
        [59.988028, 30.537864],
        [59.987211, 30.536471],
        [59.986577, 30.536437],
        [59.986545, 30.534877],
        [59.987014, 30.534063],
        [59.986922, 30.533223],
        [59.986403, 30.533228],
        [59.985817, 30.532444],
        [59.984457, 30.533206],
        [59.984059, 30.53227],
        [59.98454, 30.530903],
        [59.984186, 30.530639],
        [59.983186, 30.5312],
        [59.982976, 30.530107],
        [59.982019, 30.528126],
        [59.981822, 30.531455],
        [59.982782, 30.532579],
        [59.982834, 30.534362],
        [59.982626, 30.534938],
        [59.982971, 30.536206],
        [59.982617, 30.536565],
        [59.982676, 30.537314],
        [59.982392, 30.537432],
        [59.982449, 30.538154],
        [59.981866, 30.540383],
        [59.981197, 30.540758],
        [59.981038, 30.542748],
        [59.980792, 30.543154],
        [59.98092, 30.543758],
        [59.980417, 30.544534],
        [59.980534, 30.547062],
        [59.980252, 30.5478],
        [59.981212, 30.550013],
        [59.981265, 30.551026],
        [59.979512, 30.547601],
        [59.979398, 30.548041],
        [59.978952, 30.547581],
        [59.978494, 30.551291],
        [59.979575, 30.551575],
        [59.979315, 30.554176],
        [59.977212, 30.554061],
        [59.974747, 30.556485],
        [59.973934, 30.55838],
        [59.973355, 30.556178],
        [59.972734, 30.557082],
        [59.973474, 30.559605],
        [59.970851, 30.566432],
        [59.970374, 30.564239],
        [59.97027, 30.561583],
        [59.969845, 30.560581],
        [59.969817, 30.554802],
        [59.969447, 30.553376],
        [59.968848, 30.552669],
        [59.971423, 30.550113],
        [59.976899, 30.543295],
        [59.975778, 30.540659],
        [59.973033, 30.528629],
        [59.973081, 30.528137],
        [59.974269, 30.526892],
        [59.970788, 30.513067],
        [59.932697, 30.5051],
        [59.934367, 30.474987],
        [59.934388, 30.47444],
        [59.933945, 30.474314],
        [59.934287, 30.465492],
        [59.93478, 30.460604],
        [59.932679, 30.469161],
        [59.935127, 30.457921],
        [59.9357, 30.448241],
        [59.935516, 30.447348],
        [59.932342, 30.44349],
        [59.932111, 30.444058],
        [59.930824, 30.442567],
        [59.930987, 30.44203],
        [59.928519, 30.438807],
        [59.927993, 30.436717],
        [59.921819, 30.428937],
        [59.920751, 30.427031],
        [59.919481, 30.424046],
        [59.915398, 30.409461],
        [59.918125, 30.406911],
        [59.920668, 30.402382],
        [59.921804, 30.401134],
        [59.92447, 30.396909]
      ]
    ]
  ]
}, {
  name: `Красносельский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.674285, 30.065239],
        [59.676468, 30.065362],
        [59.679935, 30.073339],
        [59.680046, 30.087325],
        [59.667434, 30.07354],
        [59.668373, 30.065447]
      ]
    ],
    [
      [
        [59.730118, 30.050214],
        [59.730147, 30.05101],
        [59.733884, 30.056137],
        [59.735164, 30.055048],
        [59.741662, 30.062218],
        [59.747823, 30.076174],
        [59.74987, 30.070572],
        [59.749821, 30.064947],
        [59.750601, 30.063909],
        [59.751437, 30.063283],
        [59.752197, 30.067442],
        [59.755127, 30.066456],
        [59.755122, 30.072043],
        [59.755915, 30.071969],
        [59.755969, 30.070194],
        [59.756929, 30.069431],
        [59.757017, 30.062413],
        [59.762052, 30.060725],
        [59.761813, 30.05648],
        [59.763287, 30.055994],
        [59.762468, 30.043241],
        [59.76376, 30.042839],
        [59.766467, 30.057995],
        [59.772882, 30.077174],
        [59.775824, 30.084588],
        [59.77939, 30.083833],
        [59.779792, 30.087042],
        [59.781408, 30.091066],
        [59.782018, 30.091444],
        [59.782654, 30.097233],
        [59.783286, 30.096424],
        [59.783869, 30.097933],
        [59.786291, 30.098838],
        [59.790525, 30.097956],
        [59.796607, 30.099248],
        [59.79684, 30.105353],
        [59.802658, 30.104973],
        [59.804428, 30.123372],
        [59.809168, 30.127899],
        [59.817389, 30.104767],
        [59.814771, 30.09105],
        [59.81612, 30.085907],
        [59.815316, 30.075302],
        [59.83832, 30.069709],
        [59.834551, 30.086193],
        [59.835232, 30.086683],
        [59.835703, 30.091574],
        [59.85279, 30.090536],
        [59.853779, 30.0958],
        [59.853369, 30.100253],
        [59.854051, 30.10054],
        [59.853782, 30.099749],
        [59.854078, 30.099197],
        [59.856421, 30.096805],
        [59.861011, 30.096816],
        [59.860881, 30.101878],
        [59.862324, 30.105564],
        [59.862605, 30.106905],
        [59.862313, 30.107935],
        [59.863198, 30.108192],
        [59.862062, 30.108756],
        [59.861588, 30.110569],
        [59.862092, 30.112452],
        [59.861061, 30.115429],
        [59.866931, 30.115731],
        [59.85863, 30.117201],
        [59.865024, 30.117319],
        [59.862564, 30.118134],
        [59.862533, 30.131308],
        [59.861625, 30.133048],
        [59.862135, 30.138774],
        [59.863501, 30.142171],
        [59.863814, 30.141953],
        [59.864269, 30.143892],
        [59.863436, 30.145173],
        [59.863472, 30.146383],
        [59.862457, 30.149769],
        [59.863241, 30.150939],
        [59.865564, 30.148119],
        [59.870155, 30.153551],
        [59.870295, 30.154998],
        [59.871172, 30.154814],
        [59.87185, 30.155622],
        [59.871191, 30.15775],
        [59.871039, 30.161972],
        [59.871704, 30.171744],
        [59.874201, 30.161481],
        [59.879162, 30.16673],
        [59.879483, 30.168295],
        [59.867735, 30.193689],
        [59.868861, 30.199966],
        [59.865635, 30.210023],
        [59.864692, 30.215124],
        [59.863917, 30.239433],
        [59.841349, 30.206109],
        [59.83213, 30.193535],
        [59.82289, 30.18423],
        [59.822653, 30.200809],
        [59.824331, 30.222191],
        [59.825802, 30.230578],
        [59.834951, 30.269185],
        [59.832914, 30.268845],
        [59.832638, 30.26454],
        [59.822819, 30.2477],
        [59.812905, 30.240224],
        [59.81265, 30.237067],
        [59.813174, 30.227498],
        [59.812011, 30.224288],
        [59.808676, 30.223938],
        [59.808574, 30.222469],
        [59.802638, 30.219314],
        [59.802323, 30.217342],
        [59.801502, 30.217463],
        [59.803419, 30.200034],
        [59.801495, 30.199035],
        [59.80124, 30.200085],
        [59.799672, 30.200254],
        [59.797659, 30.211648],
        [59.795743, 30.20148],
        [59.791425, 30.188087],
        [59.790921, 30.178988],
        [59.79141, 30.177645],
        [59.793302, 30.17979],
        [59.795923, 30.176413],
        [59.801044, 30.159669],
        [59.798741, 30.156978],
        [59.801182, 30.150127],
        [59.782457, 30.129316],
        [59.781874, 30.133186],
        [59.78591, 30.137999],
        [59.785574, 30.139521],
        [59.785859, 30.140857],
        [59.796723, 30.154262],
        [59.796817, 30.160449],
        [59.796289, 30.162934],
        [59.792615, 30.159027],
        [59.792449, 30.159585],
        [59.790023, 30.158756],
        [59.787905, 30.15899],
        [59.786556, 30.154081],
        [59.785889, 30.155689],
        [59.784916, 30.155364],
        [59.777903, 30.147319],
        [59.77764, 30.14853],
        [59.774395, 30.145931],
        [59.771808, 30.15262],
        [59.76245, 30.143017],
        [59.761442, 30.140519],
        [59.762175, 30.11689],
        [59.760531, 30.117633],
        [59.755885, 30.117146],
        [59.74815, 30.119638],
        [59.746435, 30.119558],
        [59.741462, 30.115882],
        [59.741216, 30.114705],
        [59.737704, 30.108782],
        [59.737509, 30.110004],
        [59.736162, 30.108735],
        [59.735396, 30.111638],
        [59.731, 30.108883],
        [59.730314, 30.105625],
        [59.729179, 30.104758],
        [59.728936, 30.106621],
        [59.726238, 30.106275],
        [59.724422, 30.107044],
        [59.723393, 30.110125],
        [59.715446, 30.11236],
        [59.715498, 30.113214],
        [59.713595, 30.114055],
        [59.713326, 30.11277],
        [59.707918, 30.120416],
        [59.708117, 30.12155],
        [59.708508, 30.121245],
        [59.708923, 30.122506],
        [59.706742, 30.124299],
        [59.706668, 30.132667],
        [59.705907, 30.13264],
        [59.700358, 30.137435],
        [59.700356, 30.135341],
        [59.698594, 30.136346],
        [59.696265, 30.135852],
        [59.692283, 30.124881],
        [59.691799, 30.121708],
        [59.692198, 30.119871],
        [59.693496, 30.119674],
        [59.693435, 30.11713],
        [59.694312, 30.118046],
        [59.694036, 30.112877],
        [59.695046, 30.112602],
        [59.695273, 30.114268],
        [59.697106, 30.113922],
        [59.69864, 30.115986],
        [59.699378, 30.116065],
        [59.699578, 30.11379],
        [59.699008, 30.113334],
        [59.699128, 30.112096],
        [59.699523, 30.110545],
        [59.700605, 30.109207],
        [59.699577, 30.106916],
        [59.699154, 30.106982],
        [59.699072, 30.101183],
        [59.710178, 30.086245],
        [59.71584, 30.080663],
        [59.716184, 30.078216],
        [59.715881, 30.074099],
        [59.716307, 30.073906],
        [59.716085, 30.072968],
        [59.716543, 30.072506],
        [59.712418, 30.055251],
        [59.717515, 30.0497],
        [59.715507, 30.04068],
        [59.71788, 30.035169],
        [59.720771, 30.038783],
        [59.719558, 30.045917],
        [59.723836, 30.047584],
        [59.725395, 30.047501],
        [59.728654, 30.050042]
      ]
    ]
  ]
}, {
  name: `Курортный район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.190677, 29.425114],
        [60.218848, 29.476574],
        [60.224416, 29.491055],
        [60.222556, 29.492607],
        [60.221338, 29.49674],
        [60.232502, 29.520288],
        [60.233636, 29.521235],
        [60.232734, 29.522666],
        [60.230411, 29.523064],
        [60.230371, 29.521744],
        [60.225521, 29.522639],
        [60.223655, 29.524221],
        [60.221962, 29.531013],
        [60.220795, 29.536778],
        [60.223651, 29.538445],
        [60.225003, 29.551059],
        [60.220701, 29.580944],
        [60.222399, 29.585431],
        [60.227603, 29.589118],
        [60.228982, 29.592444],
        [60.229107, 29.598006],
        [60.234148, 29.601755],
        [60.231166, 29.607532],
        [60.233764, 29.612286],
        [60.233511, 29.613621],
        [60.235249, 29.615172],
        [60.234755, 29.62067],
        [60.237281, 29.622111],
        [60.237372, 29.623492],
        [60.233835, 29.635964],
        [60.236009, 29.638083],
        [60.237355, 29.643844],
        [60.238963, 29.63964],
        [60.239776, 29.641193],
        [60.240429, 29.64031],
        [60.242261, 29.644036],
        [60.239766, 29.661882],
        [60.237259, 29.672747],
        [60.236995, 29.677914],
        [60.237903, 29.681621],
        [60.240571, 29.682512],
        [60.240587, 29.696067],
        [60.244769, 29.69972],
        [60.243801, 29.706926],
        [60.239721, 29.707916],
        [60.236469, 29.699288],
        [60.236636, 29.6972],
        [60.235416, 29.696393],
        [60.235716, 29.697173],
        [60.233101, 29.699666],
        [60.231646, 29.703883],
        [60.2283, 29.709226],
        [60.225804, 29.720419],
        [60.228074, 29.721227],
        [60.230659, 29.715316],
        [60.23261, 29.714312],
        [60.233959, 29.717874],
        [60.234497, 29.717032],
        [60.235213, 29.718708],
        [60.23684, 29.714163],
        [60.239462, 29.713089],
        [60.239837, 29.71033],
        [60.240531, 29.711562],
        [60.241263, 29.713245],
        [60.241093, 29.718886],
        [60.24174, 29.722115],
        [60.239807, 29.728015],
        [60.239562, 29.734171],
        [60.241763, 29.745364],
        [60.242745, 29.758154],
        [60.236658, 29.760467],
        [60.232833, 29.770961],
        [60.23047, 29.770357],
        [60.226067, 29.776581],
        [60.222201, 29.790628],
        [60.222505, 29.793644],
        [60.221145, 29.802533],
        [60.219681, 29.804134],
        [60.218139, 29.802334],
        [60.216782, 29.80289],
        [60.2151, 29.806867],
        [60.212754, 29.808506],
        [60.210126, 29.807733],
        [60.206959, 29.812953],
        [60.207131, 29.820709],
        [60.20552, 29.825278],
        [60.202395, 29.827824],
        [60.203217, 29.836077],
        [60.202762, 29.841546],
        [60.200756, 29.842889],
        [60.198932, 29.849253],
        [60.19658, 29.850132],
        [60.196738, 29.864044],
        [60.196198, 29.864085],
        [60.19635, 29.865474],
        [60.195138, 29.865351],
        [60.194585, 29.867721],
        [60.19529, 29.86718],
        [60.195707, 29.868995],
        [60.194616, 29.871983],
        [60.195253, 29.891976],
        [60.193189, 29.893793],
        [60.18911, 29.892676],
        [60.181953, 29.9254],
        [60.18339, 29.92679],
        [60.181643, 29.934305],
        [60.181997, 29.937047],
        [60.179746, 29.941792],
        [60.176961, 29.951277],
        [60.176953, 29.954806],
        [60.17548, 29.955773],
        [60.176043, 29.956184],
        [60.17524, 29.958293],
        [60.175779, 29.95942],
        [60.176432, 29.958325],
        [60.176824, 29.962208],
        [60.176121, 29.964434],
        [60.175275, 29.96435],
        [60.175677, 29.965975],
        [60.174422, 29.966626],
        [60.174396, 29.96962],
        [60.173535, 29.969302],
        [60.173436, 29.971215],
        [60.172961, 29.970956],
        [60.173717, 29.972603],
        [60.17279, 29.973103],
        [60.173617, 29.974216],
        [60.17287, 29.975886],
        [60.172044, 29.97527],
        [60.17172, 29.977149],
        [60.172653, 29.980634],
        [60.171175, 29.980815],
        [60.171392, 29.982531],
        [60.170135, 29.981581],
        [60.169476, 29.984777],
        [60.167173, 29.982365],
        [60.167378, 29.985268],
        [60.168231, 29.986494],
        [60.166697, 29.98515],
        [60.166564, 29.987388],
        [60.165582, 29.986452],
        [60.163637, 29.989869],
        [60.163205, 29.994759],
        [60.162377, 29.995067],
        [60.162004, 29.997905],
        [60.161165, 29.998784],
        [60.161905, 29.999342],
        [60.161844, 30.001386],
        [60.165554, 30.002821],
        [60.164949, 30.007556],
        [60.164399, 30.008627],
        [60.162463, 30.007235],
        [60.158789, 30.012127],
        [60.156423, 30.012409],
        [60.154399, 30.018912],
        [60.152369, 30.021147],
        [60.14426, 30.018413],
        [60.136003, 30.059269],
        [60.13438, 30.147312],
        [60.129321, 30.162297],
        [60.129629, 30.164348],
        [60.131931, 30.166502],
        [60.133891, 30.171412],
        [60.136359, 30.174346],
        [60.135704, 30.177026],
        [60.133254, 30.17352],
        [60.130872, 30.174095],
        [60.128801, 30.171805],
        [60.129437, 30.182787],
        [60.127464, 30.183078],
        [60.127773, 30.192901],
        [60.121578, 30.19476],
        [60.118585, 30.189561],
        [60.118389, 30.194956],
        [60.117247, 30.195449],
        [60.117441, 30.1853],
        [60.118739, 30.177761],
        [60.113294, 30.173822],
        [60.119953, 30.138983],
        [60.114203, 30.137853],
        [60.112103, 30.133847],
        [60.114582, 30.127396],
        [60.113609, 30.12502],
        [60.114292, 30.123955],
        [60.112608, 30.120145],
        [60.114359, 30.113739],
        [60.112518, 30.108434],
        [60.111364, 30.107599],
        [60.109836, 30.109094],
        [60.108615, 30.108481],
        [60.108278, 30.109816],
        [60.107145, 30.109035],
        [60.106769, 30.110328],
        [60.106356, 30.101266],
        [60.107384, 30.097051],
        [60.106745, 30.094964],
        [60.107884, 30.088719],
        [60.104087, 30.085329],
        [60.095925, 30.070301],
        [60.090905, 30.075838],
        [60.086844, 30.086297],
        [60.085454, 30.093808],
        [60.081194, 30.10245],
        [60.07261, 30.112332],
        [60.071796, 30.116221],
        [60.070047, 30.119428],
        [60.07253, 30.132802],
        [60.065341, 30.137898],
        [60.064354, 30.135509],
        [60.059689, 30.135887],
        [60.057568, 30.1263],
        [60.05643, 30.126258],
        [60.057633, 30.135505],
        [60.055974, 30.134907],
        [60.054455, 30.136473],
        [60.051895, 30.133374],
        [60.047247, 30.133631],
        [60.045113, 30.142319],
        [60.043409, 30.13926],
        [60.041353, 30.13853],
        [60.038443, 30.143048],
        [60.037127, 30.142657],
        [60.034963, 30.147441],
        [60.033915, 30.12168],
        [60.031122, 30.108251],
        [60.027488, 30.078475],
        [60.003599, 30.078246],
        [60.002448, 30.073363],
        [60.006914, 30.042878],
        [60.010851, 30.046846],
        [60.015931, 30.027153],
        [60.018051, 30.028937],
        [60.019641, 30.022916],
        [60.017458, 30.02073],
        [60.019836, 30.010452],
        [60.02566, 30.004871],
        [60.035535, 29.980066],
        [60.037468, 29.978032],
        [60.044131, 29.975414],
        [60.043386, 29.971212],
        [60.044447, 29.965378],
        [60.048363, 29.962687],
        [60.050351, 29.964483],
        [60.051085, 29.968718],
        [60.053003, 29.967158],
        [60.053414, 29.96427],
        [60.058153, 29.963226],
        [60.061816, 29.95944],
        [60.063295, 29.956968],
        [60.063516, 29.953157],
        [60.066181, 29.94828],
        [60.066483, 29.944722],
        [60.069012, 29.936043],
        [60.069984, 29.937397],
        [60.071034, 29.948065],
        [60.072138, 29.948223],
        [60.071645, 29.951395],
        [60.072248, 29.952559],
        [60.072541, 29.950968],
        [60.072483, 29.955086],
        [60.074162, 29.9561],
        [60.074255, 29.952971],
        [60.074801, 29.954337],
        [60.076522, 29.953406],
        [60.07644, 29.947558],
        [60.077175, 29.948453],
        [60.076771, 29.944895],
        [60.077885, 29.947061],
        [60.07816, 29.950256],
        [60.079008, 29.947621],
        [60.077451, 29.944277],
        [60.078128, 29.942682],
        [60.077742, 29.93944],
        [60.078346, 29.941934],
        [60.079652, 29.943252],
        [60.080041, 29.940996],
        [60.081779, 29.94067],
        [60.081932, 29.937105],
        [60.083549, 29.938501],
        [60.083652, 29.934141],
        [60.082529, 29.932093],
        [60.084519, 29.929564],
        [60.086069, 29.930497],
        [60.08869, 29.918937],
        [60.090489, 29.920589],
        [60.093883, 29.93808],
        [60.098501, 29.943818],
        [60.103331, 29.945991],
        [60.112795, 29.946141],
        [60.117468, 29.944701],
        [60.118771, 29.942569],
        [60.127336, 29.938773],
        [60.142349, 29.928896],
        [60.147894, 29.919609],
        [60.150885, 29.908293],
        [60.15263, 29.897213],
        [60.154598, 29.890459],
        [60.157285, 29.884982],
        [60.161916, 29.868237],
        [60.162904, 29.859833],
        [60.161217, 29.853517],
        [60.161137, 29.850223],
        [60.16402, 29.835341],
        [60.166676, 29.826712],
        [60.16669, 29.824627],
        [60.165484, 29.822584],
        [60.166608, 29.82382],
        [60.175964, 29.794263],
        [60.176914, 29.785999],
        [60.180377, 29.770092],
        [60.182501, 29.747695],
        [60.186512, 29.720888],
        [60.187809, 29.700105],
        [60.186861, 29.699845],
        [60.18681, 29.701689],
        [60.18664, 29.701242],
        [60.190035, 29.67445],
        [60.190021, 29.664686],
        [60.188921, 29.664407],
        [60.18894, 29.663851],
        [60.189755, 29.664032],
        [60.190357, 29.660538],
        [60.191553, 29.632825],
        [60.195113, 29.618747],
        [60.197185, 29.591616],
        [60.196953, 29.569875],
        [60.193642, 29.561268],
        [60.194586, 29.55102],
        [60.193792, 29.546743],
        [60.194336, 29.545148],
        [60.193241, 29.544295],
        [60.192953, 29.539134],
        [60.191216, 29.538968],
        [60.190818, 29.538408],
        [60.192918, 29.53876],
        [60.191443, 29.53525],
        [60.191597, 29.533776],
        [60.191458, 29.531567],
        [60.191365, 29.534558],
        [60.190572, 29.535266],
        [60.189337, 29.529333],
        [60.182955, 29.525329],
        [60.182106, 29.517238],
        [60.180013, 29.509748],
        [60.177757, 29.480152],
        [60.175561, 29.470376],
        [60.172135, 29.461609],
        [60.171596, 29.456778],
        [60.17226, 29.455391],
        [60.17206, 29.452212],
        [60.171112, 29.449303],
        [60.172376, 29.449854],
        [60.172533, 29.447799],
        [60.173772, 29.447253],
        [60.173275, 29.444705],
        [60.17435, 29.442774],
        [60.173556, 29.442069],
        [60.174939, 29.442131],
        [60.174896, 29.440508],
        [60.176422, 29.438696],
        [60.180509, 29.451894],
        [60.18175, 29.472426],
        [60.184708, 29.469275],
        [60.183801, 29.460019],
        [60.184852, 29.459199],
        [60.184866, 29.454628],
        [60.185936, 29.450793],
        [60.187431, 29.450371],
        [60.189018, 29.447582]
      ]
    ]
  ]
}, {
  name: `Московский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.80174, 30.19909],
        [59.803542, 30.200349],
        [59.802084, 30.210416],
        [59.801502, 30.217463],
        [59.802323, 30.217342],
        [59.802638, 30.219314],
        [59.808574, 30.222469],
        [59.808676, 30.223938],
        [59.812183, 30.224629],
        [59.813174, 30.227498],
        [59.81265, 30.237067],
        [59.812905, 30.240224],
        [59.822819, 30.2477],
        [59.832638, 30.26454],
        [59.832951, 30.26584],
        [59.832914, 30.268845],
        [59.835496, 30.269629],
        [59.842474, 30.28135],
        [59.842527, 30.282278],
        [59.846199, 30.286199],
        [59.846013, 30.286768],
        [59.850464, 30.289979],
        [59.85782, 30.292773],
        [59.858468, 30.290084],
        [59.877144, 30.293166],
        [59.879528, 30.29499],
        [59.880916, 30.297639],
        [59.881586, 30.300064],
        [59.882578, 30.30037],
        [59.882317, 30.293715],
        [59.886043, 30.294608],
        [59.889021, 30.297639],
        [59.894094, 30.299472],
        [59.894281, 30.297273],
        [59.896098, 30.297379],
        [59.896317, 30.319262],
        [59.908531, 30.318441],
        [59.908776, 30.321147],
        [59.912979, 30.332627],
        [59.910514, 30.335067],
        [59.907306, 30.336245],
        [59.904147, 30.33933],
        [59.891596, 30.345598],
        [59.886, 30.345547],
        [59.881106, 30.348379],
        [59.874134, 30.354463],
        [59.843367, 30.369834],
        [59.832451, 30.374173],
        [59.818659, 30.381143],
        [59.817368, 30.374609],
        [59.816583, 30.366111],
        [59.816021, 30.36594],
        [59.815245, 30.362957],
        [59.815099, 30.359495],
        [59.814186, 30.353319],
        [59.81286, 30.349629],
        [59.812905, 30.348276],
        [59.812025, 30.345714],
        [59.809039, 30.341728],
        [59.808243, 30.338021],
        [59.80674, 30.33626],
        [59.804701, 30.330441],
        [59.804614, 30.325544],
        [59.803769, 30.325583],
        [59.803751, 30.324771],
        [59.797491, 30.32498],
        [59.795389, 30.327224],
        [59.794125, 30.327646],
        [59.792631, 30.327011],
        [59.791334, 30.325267],
        [59.776989, 30.326138],
        [59.775852, 30.327033],
        [59.772359, 30.332609],
        [59.771368, 30.333113],
        [59.771962, 30.335486],
        [59.770788, 30.335971],
        [59.768685, 30.335526],
        [59.762223, 30.332697],
        [59.762164, 30.33019],
        [59.762892, 30.329619],
        [59.74474, 30.310798],
        [59.744505, 30.30977],
        [59.762792, 30.258772],
        [59.764802, 30.261443],
        [59.771627, 30.265936],
        [59.773398, 30.272669],
        [59.774704, 30.268632],
        [59.777613, 30.27363],
        [59.778775, 30.270606],
        [59.782644, 30.275755],
        [59.791376, 30.289818],
        [59.793282, 30.290982],
        [59.794778, 30.289033],
        [59.795744, 30.281914],
        [59.794685, 30.27107],
        [59.789876, 30.269602],
        [59.796976, 30.222163],
        [59.797826, 30.21017],
        [59.799672, 30.200254],
        [59.80124, 30.200085],
        [59.801495, 30.199035]
      ]
    ]
  ]
}, {
  name: `Невский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.913804, 30.363342],
        [59.914346, 30.371771],
        [59.917048, 30.38738],
        [59.918156, 30.393283],
        [59.920836, 30.402128],
        [59.918125, 30.406911],
        [59.915398, 30.409461],
        [59.919481, 30.424046],
        [59.920751, 30.427031],
        [59.921819, 30.428937],
        [59.927993, 30.436717],
        [59.928519, 30.438807],
        [59.930987, 30.44203],
        [59.930824, 30.442567],
        [59.932111, 30.444058],
        [59.932342, 30.44349],
        [59.935516, 30.447348],
        [59.9357, 30.448241],
        [59.935127, 30.457921],
        [59.932679, 30.469161],
        [59.93478, 30.460604],
        [59.934287, 30.465492],
        [59.933945, 30.474314],
        [59.934388, 30.47444],
        [59.934367, 30.474987],
        [59.932697, 30.5051],
        [59.891669, 30.495846],
        [59.891555, 30.497715],
        [59.886605, 30.500199],
        [59.886403, 30.499321],
        [59.885901, 30.499699],
        [59.886148, 30.50125],
        [59.877876, 30.508021],
        [59.870464, 30.515574],
        [59.869829, 30.515602],
        [59.863356, 30.521199],
        [59.86114, 30.508851],
        [59.8595, 30.507381],
        [59.859136, 30.505362],
        [59.85939, 30.503872],
        [59.858819, 30.498717],
        [59.859097, 30.498175],
        [59.858384, 30.493536],
        [59.855106, 30.493376],
        [59.851217, 30.492133],
        [59.848522, 30.492453],
        [59.846897, 30.49362],
        [59.845719, 30.496036],
        [59.845077, 30.499374],
        [59.844669, 30.50376],
        [59.843332, 30.508664],
        [59.841603, 30.513567],
        [59.836679, 30.523563],
        [59.834365, 30.531797],
        [59.833508, 30.537207],
        [59.833544, 30.540291],
        [59.836301, 30.552156],
        [59.836784, 30.556338],
        [59.836591, 30.558918],
        [59.83551, 30.561256],
        [59.833532, 30.562681],
        [59.827811, 30.560745],
        [59.82583, 30.562219],
        [59.82498, 30.563496],
        [59.823655, 30.568328],
        [59.821657, 30.566233],
        [59.822222, 30.559979],
        [59.820721, 30.563338],
        [59.820233, 30.560137],
        [59.819477, 30.55885],
        [59.821258, 30.554388],
        [59.819596, 30.55206],
        [59.817849, 30.553581],
        [59.817047, 30.555615],
        [59.812961, 30.549495],
        [59.82402, 30.520296],
        [59.823395, 30.520202],
        [59.821738, 30.521502],
        [59.81979, 30.520752],
        [59.818525, 30.519357],
        [59.814406, 30.512271],
        [59.812598, 30.50823],
        [59.810993, 30.508293],
        [59.807973, 30.512446],
        [59.807794, 30.511314],
        [59.845355, 30.459026],
        [59.845099, 30.456649],
        [59.846999, 30.455765],
        [59.852392, 30.448585],
        [59.854301, 30.445715],
        [59.857048, 30.44003],
        [59.869449, 30.42055],
        [59.879446, 30.406804],
        [59.888202, 30.396103],
        [59.893926, 30.391137],
        [59.904061, 30.37706],
        [59.910729, 30.365744],
        [59.912771, 30.363744]
      ]
    ]
  ]
}, {
  name: `Петроградский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.976982, 30.224035],
        [59.977111, 30.224657],
        [59.97732, 30.224753],
        [59.977718, 30.225537],
        [59.977799, 30.226491],
        [59.978899, 30.226497],
        [59.979362, 30.230436],
        [59.980263, 30.234171],
        [59.980946, 30.235839],
        [59.981243, 30.237054],
        [59.981949, 30.245898],
        [59.982374, 30.253326],
        [59.982484, 30.261076],
        [59.982384, 30.265204],
        [59.981507, 30.271167],
        [59.981223, 30.274352],
        [59.981469, 30.279022],
        [59.982212, 30.283856],
        [59.982637, 30.2879],
        [59.982816, 30.290417],
        [59.982827, 30.295609],
        [59.982664, 30.298667],
        [59.982357, 30.301457],
        [59.980739, 30.310339],
        [59.979421, 30.319042],
        [59.978923, 30.320784],
        [59.97626, 30.326544],
        [59.974359, 30.329304],
        [59.972089, 30.331857],
        [59.967175, 30.335155],
        [59.965736, 30.335888],
        [59.959478, 30.336929],
        [59.957523, 30.337648],
        [59.954762, 30.340259],
        [59.951852, 30.344008],
        [59.951833, 30.342728],
        [59.95151, 30.339776],
        [59.949906, 30.332442],
        [59.948419, 30.326557],
        [59.945502, 30.316532],
        [59.946164, 30.308387],
        [59.94636, 30.299437],
        [59.946563, 30.294941],
        [59.946805, 30.292926],
        [59.947268, 30.290842],
        [59.948128, 30.287955],
        [59.951291, 30.278056],
        [59.952102, 30.274589],
        [59.953809, 30.270755],
        [59.955508, 30.268233],
        [59.955926, 30.267312],
        [59.956579, 30.26552],
        [59.957234, 30.262993],
        [59.957753, 30.260449],
        [59.958229, 30.255548],
        [59.959072, 30.250091],
        [59.959337, 30.247255],
        [59.960207, 30.24077],
        [59.962761, 30.234279],
        [59.963989, 30.236092],
        [59.964398, 30.235277],
        [59.964899, 30.235014],
        [59.965318, 30.235116],
        [59.965815, 30.23569],
        [59.966103, 30.236774],
        [59.966734, 30.237291],
        [59.967992, 30.224814],
        [59.968539, 30.224993],
        [59.968621, 30.223973],
        [59.968823, 30.223989],
        [59.968744, 30.224938],
        [59.968903, 30.224979],
        [59.969344, 30.224457],
        [59.96945, 30.223598],
        [59.968689, 30.223412],
        [59.968896, 30.220573],
        [59.969754, 30.220814],
        [59.969898, 30.21889],
        [59.970737, 30.215322],
        [59.972189, 30.213369],
        [59.97604, 30.214249],
        [59.976696, 30.216481],
        [59.977061, 30.217274],
        [59.976879, 30.223047]
      ]
    ]
  ]
}, {
  name: `Петродворцовый район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.929691, 29.647108],
        [59.934799, 29.668576],
        [59.937595, 29.670488],
        [59.937355, 29.672994],
        [59.934545, 29.672093],
        [59.93335, 29.672522],
        [59.932197, 29.674346],
        [59.931023, 29.682291],
        [59.931069, 29.683278],
        [59.932545, 29.683541],
        [59.93069, 29.684689],
        [59.930612, 29.686813],
        [59.931769, 29.687655],
        [59.931131, 29.688106],
        [59.929882, 29.687076],
        [59.93216, 29.689608],
        [59.930458, 29.688084],
        [59.93055, 29.689002],
        [59.9294, 29.690032],
        [59.929012, 29.691947],
        [59.929456, 29.692521],
        [59.929168, 29.693449],
        [59.929623, 29.695584],
        [59.929198, 29.697354],
        [59.929871, 29.703716],
        [59.932518, 29.7049],
        [59.930846, 29.704841],
        [59.931923, 29.706338],
        [59.929806, 29.707813],
        [59.928934, 29.712099],
        [59.929044, 29.71565],
        [59.928202, 29.719357],
        [59.92849, 29.722259],
        [59.927149, 29.725263],
        [59.928019, 29.727968],
        [59.928165, 29.731637],
        [59.926336, 29.736126],
        [59.927952, 29.738561],
        [59.926627, 29.73892],
        [59.926444, 29.741275],
        [59.9257, 29.742981],
        [59.925665, 29.746189],
        [59.92513, 29.746313],
        [59.925744, 29.7465],
        [59.92587, 29.744714],
        [59.926172, 29.744467],
        [59.925921, 29.746028],
        [59.927822, 29.751403],
        [59.928773, 29.751366],
        [59.929247, 29.75282],
        [59.928331, 29.75643],
        [59.929096, 29.758206],
        [59.928665, 29.759906],
        [59.929368, 29.759434],
        [59.92941, 29.761298],
        [59.927638, 29.762003],
        [59.927422, 29.76653],
        [59.928394, 29.768048],
        [59.929373, 29.77155],
        [59.929185, 29.772456],
        [59.930588, 29.779384],
        [59.929164, 29.781866],
        [59.924168, 29.778662],
        [59.921902, 29.775574],
        [59.920883, 29.77738],
        [59.921645, 29.778793],
        [59.921161, 29.780115],
        [59.920088, 29.778654],
        [59.918023, 29.78092],
        [59.915222, 29.781293],
        [59.914883, 29.784749],
        [59.917745, 29.788308],
        [59.916513, 29.787016],
        [59.914686, 29.793927],
        [59.913881, 29.793616],
        [59.913653, 29.795454],
        [59.912466, 29.794863],
        [59.912559, 29.792703],
        [59.911165, 29.798308],
        [59.912345, 29.795122],
        [59.913503, 29.79681],
        [59.911596, 29.798727],
        [59.911525, 29.801566],
        [59.910881, 29.801576],
        [59.910612, 29.802896],
        [59.910028, 29.803003],
        [59.910028, 29.805313],
        [59.909247, 29.807706],
        [59.909045, 29.810891],
        [59.907382, 29.814923],
        [59.907508, 29.817614],
        [59.906648, 29.81816],
        [59.905805, 29.820461],
        [59.90554, 29.825307],
        [59.904838, 29.825404],
        [59.904829, 29.826752],
        [59.903844, 29.827472],
        [59.902286, 29.836988],
        [59.900749, 29.838635],
        [59.901057, 29.840158],
        [59.901986, 29.840871],
        [59.901127, 29.84399],
        [59.900121, 29.843098],
        [59.901003, 29.844477],
        [59.900332, 29.8474],
        [59.900726, 29.851322],
        [59.901476, 29.853462],
        [59.90125, 29.85418],
        [59.900067, 29.853974],
        [59.899352, 29.854765],
        [59.899466, 29.860521],
        [59.898689, 29.865044],
        [59.898924, 29.867688],
        [59.900292, 29.868213],
        [59.900078, 29.870502],
        [59.900135, 29.868376],
        [59.89839, 29.867823],
        [59.897763, 29.874465],
        [59.895854, 29.87545],
        [59.89371, 29.880476],
        [59.893012, 29.884063],
        [59.893568, 29.885864],
        [59.892727, 29.88641],
        [59.892617, 29.887537],
        [59.893514, 29.891577],
        [59.891741, 29.891166],
        [59.891538, 29.892194],
        [59.892008, 29.892783],
        [59.891171, 29.894229],
        [59.890483, 29.894923],
        [59.88966, 29.894434],
        [59.890105, 29.895448],
        [59.888855, 29.90523],
        [59.889123, 29.909612],
        [59.889438, 29.910978],
        [59.891919, 29.912012],
        [59.891583, 29.914598],
        [59.891742, 29.912075],
        [59.890245, 29.911796],
        [59.890138, 29.913456],
        [59.890784, 29.913822],
        [59.890201, 29.913695],
        [59.890119, 29.914305],
        [59.88843, 29.911371],
        [59.887721, 29.912405],
        [59.88727, 29.919531],
        [59.886122, 29.920755],
        [59.885758, 29.923329],
        [59.885744, 29.928632],
        [59.884973, 29.935767],
        [59.885259, 29.939161],
        [59.88602, 29.94107],
        [59.885699, 29.94231],
        [59.88596, 29.946131],
        [59.885712, 29.947285],
        [59.884936, 29.947737],
        [59.884711, 29.951287],
        [59.887567, 29.951828],
        [59.884295, 29.95231],
        [59.883749, 29.953037],
        [59.883097, 29.955989],
        [59.883479, 29.956727],
        [59.883359, 29.957841],
        [59.882049, 29.959019],
        [59.881431, 29.964165],
        [59.88001, 29.965691],
        [59.878579, 29.969855],
        [59.878605, 29.972771],
        [59.877895, 29.976585],
        [59.875155, 29.982042],
        [59.874175, 29.982836],
        [59.873552, 29.984687],
        [59.873594, 29.986924],
        [59.872514, 29.986505],
        [59.870988, 29.988564],
        [59.870182, 29.991098],
        [59.871121, 29.993468],
        [59.870382, 29.992554],
        [59.869302, 29.994773],
        [59.869816, 29.996261],
        [59.868509, 29.996221],
        [59.867703, 29.998338],
        [59.868373, 29.999031],
        [59.867016, 30.000568],
        [59.866258, 30.00253],
        [59.86627, 30.004997],
        [59.865765, 30.005284],
        [59.864312, 30.012094],
        [59.865187, 30.011864],
        [59.865832, 30.012971],
        [59.864119, 30.016855],
        [59.864135, 30.017852],
        [59.864842, 30.018912],
        [59.864006, 30.01802],
        [59.863461, 30.019009],
        [59.864149, 30.019963],
        [59.863079, 30.019343],
        [59.860886, 30.023298],
        [59.861088, 30.025627],
        [59.860776, 30.027035],
        [59.861843, 30.03181],
        [59.86115, 30.033366],
        [59.862131, 30.040495],
        [59.861902, 30.04299],
        [59.862447, 30.044441],
        [59.864874, 30.044945],
        [59.864069, 30.045056],
        [59.863899, 30.046931],
        [59.863914, 30.045046],
        [59.863102, 30.044998],
        [59.862813, 30.045728],
        [59.862517, 30.04486],
        [59.860757, 30.044899],
        [59.860601, 30.045877],
        [59.863497, 30.049036],
        [59.862241, 30.052548],
        [59.861236, 30.05231],
        [59.861116, 30.055984],
        [59.861745, 30.058544],
        [59.860717, 30.061624],
        [59.860659, 30.063585],
        [59.86264, 30.072764],
        [59.862262, 30.07672],
        [59.862302, 30.084778],
        [59.864161, 30.091205],
        [59.863589, 30.091339],
        [59.8635, 30.090572],
        [59.862315, 30.089515],
        [59.861859, 30.093047],
        [59.861425, 30.093688],
        [59.86166, 30.097649],
        [59.860756, 30.096591],
        [59.856421, 30.096805],
        [59.854078, 30.099197],
        [59.853782, 30.099749],
        [59.854051, 30.10054],
        [59.853369, 30.100253],
        [59.853779, 30.0958],
        [59.85279, 30.090536],
        [59.835703, 30.091574],
        [59.835232, 30.086683],
        [59.834551, 30.086193],
        [59.83832, 30.069709],
        [59.81534, 30.075296],
        [59.815867, 30.069087],
        [59.81519, 30.068204],
        [59.815932, 30.066005],
        [59.817069, 30.058692],
        [59.817568, 30.057785],
        [59.816865, 30.054143],
        [59.817675, 30.047651],
        [59.819354, 30.04526],
        [59.819933, 30.042762],
        [59.821589, 30.040383],
        [59.821709, 30.038805],
        [59.822716, 30.037873],
        [59.823268, 30.038825],
        [59.823972, 30.038782],
        [59.824264, 30.040697],
        [59.824942, 30.040195],
        [59.825421, 30.040972],
        [59.826355, 30.039401],
        [59.828961, 30.038595],
        [59.830357, 30.038982],
        [59.830815, 30.039368],
        [59.830562, 30.04017],
        [59.831912, 30.040501],
        [59.83241, 30.04248],
        [59.833254, 30.042698],
        [59.834098, 30.041667],
        [59.836543, 30.0444],
        [59.837341, 30.044426],
        [59.837527, 30.030143],
        [59.833166, 30.021329],
        [59.833356, 30.020079],
        [59.834805, 30.019323],
        [59.834984, 30.0178],
        [59.835719, 30.018684],
        [59.836177, 30.017571],
        [59.836158, 30.016557],
        [59.83503, 30.014799],
        [59.836238, 30.013236],
        [59.835925, 30.0114],
        [59.836371, 30.009377],
        [59.836363, 30.006528],
        [59.837123, 30.003672],
        [59.83613, 30.002053],
        [59.836442, 29.999604],
        [59.835163, 29.997697],
        [59.835465, 29.996027],
        [59.834275, 29.994779],
        [59.83343, 29.992207],
        [59.835113, 29.97904],
        [59.834199, 29.977874],
        [59.828866, 29.944266],
        [59.83045, 29.942924],
        [59.830898, 29.939566],
        [59.830146, 29.938351],
        [59.830497, 29.936633],
        [59.844425, 29.945099],
        [59.848048, 29.925383],
        [59.848771, 29.925357],
        [59.851167, 29.910087],
        [59.849015, 29.909292],
        [59.850147, 29.902266],
        [59.853626, 29.903804],
        [59.854735, 29.893701],
        [59.842641, 29.886094],
        [59.84354, 29.881248],
        [59.847729, 29.879585],
        [59.847902, 29.876216],
        [59.848577, 29.876399],
        [59.84849, 29.877697],
        [59.849192, 29.878641],
        [59.851676, 29.87984],
        [59.852457, 29.878842],
        [59.852641, 29.879494],
        [59.855758, 29.875597],
        [59.855643, 29.875045],
        [59.857843, 29.873407],
        [59.857757, 29.871737],
        [59.856387, 29.872044],
        [59.853895, 29.869078],
        [59.853779, 29.867409],
        [59.85532, 29.862594],
        [59.85788, 29.861635],
        [59.858459, 29.859525],
        [59.861125, 29.858813],
        [59.862076, 29.860021],
        [59.868015, 29.836457],
        [59.868304, 29.83412],
        [59.866126, 29.834078],
        [59.865677, 29.830006],
        [59.866567, 29.826338],
        [59.869017, 29.823265],
        [59.858918, 29.800513],
        [59.865688, 29.786687],
        [59.871073, 29.772566],
        [59.868803, 29.76712],
        [59.86813, 29.75376],
        [59.871448, 29.751258],
        [59.871816, 29.738447],
        [59.870925, 29.737091],
        [59.870891, 29.729206],
        [59.870297, 29.727323],
        [59.873376, 29.718738],
        [59.888114, 29.716073],
        [59.893439, 29.729456],
        [59.900795, 29.724425],
        [59.901895, 29.720217],
        [59.902887, 29.719691],
        [59.903853, 29.717198],
        [59.904306, 29.7154],
        [59.90403, 29.714821],
        [59.905549, 29.709662],
        [59.904865, 29.709144],
        [59.905241, 29.707426],
        [59.905826, 29.707545],
        [59.906535, 29.706006],
        [59.91018, 29.692971],
        [59.913195, 29.675905],
        [59.913812, 29.667833],
        [59.915143, 29.659082],
        [59.91674, 29.65625],
        [59.925086, 29.661353],
        [59.926989, 29.659983],
        [59.928157, 29.65238],
        [59.92821, 29.647818]
      ]
    ]
  ]
}, {
  name: `Приморский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.037194, 29.955594],
        [60.037612, 29.955436],
        [60.037961, 29.958438],
        [60.043347, 29.96634],
        [60.04406, 29.965903],
        [60.044368, 29.966973],
        [60.043893, 29.968554],
        [60.044093, 29.969627],
        [60.043386, 29.971212],
        [60.044131, 29.975414],
        [60.037468, 29.978032],
        [60.035535, 29.980066],
        [60.02566, 30.004871],
        [60.02355, 30.00774],
        [60.019836, 30.010452],
        [60.017458, 30.02073],
        [60.019641, 30.022916],
        [60.018051, 30.028937],
        [60.015931, 30.027153],
        [60.010851, 30.046846],
        [60.006914, 30.042878],
        [60.00506, 30.05347],
        [60.004435, 30.060089],
        [60.002448, 30.073363],
        [60.003599, 30.078246],
        [60.027488, 30.078475],
        [60.031122, 30.108251],
        [60.032139, 30.114456],
        [60.033915, 30.12168],
        [60.034963, 30.147441],
        [60.035911, 30.146397],
        [60.036011, 30.144972],
        [60.037127, 30.142657],
        [60.038443, 30.143048],
        [60.039818, 30.141485],
        [60.040504, 30.139217],
        [60.041353, 30.13853],
        [60.042258, 30.138355],
        [60.043409, 30.13926],
        [60.044071, 30.141103],
        [60.045113, 30.142319],
        [60.046108, 30.140326],
        [60.046871, 30.134076],
        [60.047612, 30.133585],
        [60.048296, 30.134063],
        [60.047943, 30.135175],
        [60.050348, 30.144913],
        [60.062088, 30.190733],
        [60.063175, 30.193588],
        [60.060528, 30.195796],
        [60.062266, 30.20192],
        [60.060546, 30.210273],
        [60.061388, 30.21413],
        [60.051298, 30.222838],
        [60.068632, 30.274523],
        [60.053218, 30.29319],
        [60.050949, 30.29526],
        [59.997952, 30.324093],
        [59.995927, 30.326015],
        [59.994325, 30.328809],
        [59.993606, 30.326381],
        [59.984849, 30.330933],
        [59.984978, 30.330328],
        [59.984084, 30.329582],
        [59.982672, 30.331151],
        [59.978462, 30.321819],
        [59.979722, 30.317541],
        [59.982357, 30.301457],
        [59.982858, 30.292807],
        [59.982637, 30.2879],
        [59.981469, 30.279022],
        [59.981223, 30.274352],
        [59.982384, 30.265204],
        [59.982224, 30.250429],
        [59.981243, 30.237054],
        [59.979362, 30.230436],
        [59.978899, 30.226497],
        [59.980095, 30.226502],
        [59.981403, 30.225278],
        [59.981594, 30.223326],
        [59.982806, 30.223187],
        [59.98292, 30.22234],
        [59.981864, 30.22129],
        [59.982086, 30.219401],
        [59.981422, 30.218271],
        [59.982054, 30.216977],
        [59.981401, 30.216378],
        [59.981272, 30.214422],
        [59.980323, 30.213024],
        [59.979386, 30.208345],
        [59.979044, 30.203892],
        [59.980413, 30.199954],
        [59.981465, 30.1953],
        [59.98159, 30.193871],
        [59.981094, 30.193062],
        [59.982379, 30.19229],
        [59.982405, 30.189984],
        [59.989682, 30.184321],
        [59.98954, 30.182412],
        [59.989059, 30.182009],
        [59.988573, 30.18315],
        [59.988314, 30.182178],
        [59.988573, 30.181798],
        [59.98772, 30.180755],
        [59.988151, 30.182027],
        [59.986103, 30.181466],
        [59.985549, 30.181956],
        [59.985855, 30.179357],
        [59.985841, 30.171683],
        [59.985436, 30.169581],
        [59.986301, 30.168152],
        [59.98549, 30.167719],
        [59.98593, 30.163385],
        [59.984501, 30.163022],
        [59.984177, 30.165982],
        [59.984642, 30.167068],
        [59.983885, 30.166167],
        [59.983969, 30.163257],
        [59.98435, 30.161809],
        [59.987109, 30.162345],
        [59.987843, 30.158619],
        [59.988373, 30.149707],
        [59.988048, 30.141278],
        [59.988958, 30.134419],
        [59.988615, 30.12867],
        [59.990059, 30.120926],
        [59.989556, 30.110524],
        [59.990013, 30.105385],
        [59.991698, 30.10041],
        [59.991578, 30.099161],
        [59.990673, 30.098299],
        [59.990506, 30.097925],
        [59.991002, 30.098324],
        [59.991301, 30.097719],
        [59.991706, 30.098647],
        [59.992611, 30.098189],
        [59.99254, 30.096507],
        [59.991502, 30.096969],
        [59.991686, 30.095801],
        [59.99212, 30.095326],
        [59.992973, 30.095756],
        [59.994206, 30.092817],
        [59.994805, 30.088908],
        [59.995075, 30.080621],
        [59.996957, 30.071595],
        [59.997802, 30.064844],
        [59.997683, 30.060627],
        [59.998525, 30.056292],
        [59.998331, 30.055553],
        [59.998721, 30.054488],
        [59.999373, 30.04747],
        [59.999155, 30.045207],
        [59.998199, 30.042076],
        [59.998037, 30.037418],
        [59.998954, 30.032625],
        [59.998029, 30.029629],
        [59.997869, 30.022672],
        [59.999673, 30.014954],
        [59.998843, 30.014215],
        [59.999985, 30.014597],
        [59.998571, 30.013795],
        [60.000141, 30.014192],
        [59.998984, 30.013383],
        [60.000075, 30.013367],
        [59.999631, 30.011002],
        [59.999842, 30.007405],
        [60.001081, 30.006239],
        [60.002278, 30.00384],
        [60.003004, 29.999305],
        [60.002778, 29.99847],
        [60.003279, 29.998694],
        [60.003649, 29.99639],
        [60.005014, 29.99329],
        [60.005629, 29.990034],
        [60.006582, 29.987686],
        [60.006709, 29.985201],
        [60.007092, 29.984655],
        [60.006883, 29.983998],
        [60.007308, 29.983639],
        [60.007418, 29.98205],
        [60.008175, 29.979962],
        [60.008, 29.978922],
        [60.008591, 29.977088],
        [60.008449, 29.976335],
        [60.009029, 29.974432],
        [60.009529, 29.974051],
        [60.009757, 29.971511],
        [60.010779, 29.969385],
        [60.011069, 29.96635],
        [60.009683, 29.96305],
        [60.011195, 29.965872],
        [60.013227, 29.964561],
        [60.013937, 29.962762],
        [60.014824, 29.964215],
        [60.015051, 29.965444],
        [60.015727, 29.96386],
        [60.016712, 29.966812],
        [60.016211, 29.969552],
        [60.01683, 29.973862],
        [60.017256, 29.972091],
        [60.018166, 29.975884],
        [60.019301, 29.977039],
        [60.020008, 29.976005],
        [60.020527, 29.977414],
        [60.020792, 29.976581],
        [60.018364, 29.969925],
        [60.017693, 29.969015],
        [60.017975, 29.963651],
        [60.024065, 29.961887],
        [60.032946, 29.957928],
        [60.033529, 29.963935],
        [60.033944, 29.965432],
        [60.035521, 29.964674],
        [60.034733, 29.95673]
      ]
    ]
  ]
}, {
  name: `Пушкинский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.673124, 30.218651],
        [59.684084, 30.229113],
        [59.685987, 30.236517],
        [59.689354, 30.231466],
        [59.691849, 30.237056],
        [59.69295, 30.235211],
        [59.695244, 30.240491],
        [59.695499, 30.240024],
        [59.69839, 30.245794],
        [59.700953, 30.252161],
        [59.701675, 30.255805],
        [59.704804, 30.258795],
        [59.707368, 30.260063],
        [59.714203, 30.260295],
        [59.715388, 30.262154],
        [59.714051, 30.268147],
        [59.716507, 30.269627],
        [59.715119, 30.276019],
        [59.718795, 30.279774],
        [59.720607, 30.271504],
        [59.723717, 30.272718],
        [59.732234, 30.290178],
        [59.73393, 30.290564],
        [59.734688, 30.289164],
        [59.736103, 30.291012],
        [59.735861, 30.291766],
        [59.740708, 30.293833],
        [59.747611, 30.301045],
        [59.744505, 30.30977],
        [59.74474, 30.310798],
        [59.762892, 30.329619],
        [59.762164, 30.33019],
        [59.762223, 30.332697],
        [59.771313, 30.335952],
        [59.771962, 30.335486],
        [59.771368, 30.333113],
        [59.772359, 30.332609],
        [59.775852, 30.327033],
        [59.776989, 30.326138],
        [59.791334, 30.325267],
        [59.792631, 30.327011],
        [59.794125, 30.327646],
        [59.795389, 30.327224],
        [59.797491, 30.32498],
        [59.803751, 30.324771],
        [59.803769, 30.325583],
        [59.804614, 30.325544],
        [59.804701, 30.330441],
        [59.80674, 30.33626],
        [59.808243, 30.338021],
        [59.809039, 30.341728],
        [59.812025, 30.345714],
        [59.814186, 30.353319],
        [59.815245, 30.362957],
        [59.816021, 30.36594],
        [59.816583, 30.366111],
        [59.817368, 30.374609],
        [59.818659, 30.381143],
        [59.81649, 30.382167],
        [59.818748, 30.403511],
        [59.819423, 30.403865],
        [59.824842, 30.430634],
        [59.826446, 30.435623],
        [59.828904, 30.439228],
        [59.829213, 30.441129],
        [59.827451, 30.438788],
        [59.827904, 30.442886],
        [59.827365, 30.444261],
        [59.824498, 30.44644],
        [59.823816, 30.447661],
        [59.824063, 30.448968],
        [59.817414, 30.454195],
        [59.817405, 30.450825],
        [59.810885, 30.461324],
        [59.809498, 30.456132],
        [59.802538, 30.458071],
        [59.793018, 30.478833],
        [59.791548, 30.485491],
        [59.791775, 30.489602],
        [59.79111, 30.493154],
        [59.788878, 30.497732],
        [59.786491, 30.505135],
        [59.784273, 30.513648],
        [59.78469, 30.51414],
        [59.784954, 30.516809],
        [59.784205, 30.518951],
        [59.783835, 30.518791],
        [59.78387, 30.517113],
        [59.782103, 30.516358],
        [59.782117, 30.517635],
        [59.78272, 30.518558],
        [59.782654, 30.520303],
        [59.780584, 30.519154],
        [59.780583, 30.520159],
        [59.781323, 30.520923],
        [59.780798, 30.521588],
        [59.77921, 30.519694],
        [59.779391, 30.518771],
        [59.778029, 30.516289],
        [59.777432, 30.516193],
        [59.776978, 30.518604],
        [59.776499, 30.518474],
        [59.775634, 30.516196],
        [59.776431, 30.512902],
        [59.77533, 30.513533],
        [59.773946, 30.51205],
        [59.773745, 30.513386],
        [59.77323, 30.512995],
        [59.771956, 30.514345],
        [59.771207, 30.511694],
        [59.770449, 30.510695],
        [59.770053, 30.511035],
        [59.770084, 30.512682],
        [59.769525, 30.513166],
        [59.768139, 30.512048],
        [59.768496, 30.510579],
        [59.763859, 30.509032],
        [59.762701, 30.507326],
        [59.760219, 30.509941],
        [59.759951, 30.509253],
        [59.759443, 30.50959],
        [59.759526, 30.508044],
        [59.758141, 30.508387],
        [59.757117, 30.510288],
        [59.753663, 30.522058],
        [59.752439, 30.523903],
        [59.748007, 30.523087],
        [59.749557, 30.556649],
        [59.74383, 30.558364],
        [59.74302, 30.557241],
        [59.741311, 30.557359],
        [59.741092, 30.556283],
        [59.739889, 30.555404],
        [59.737163, 30.556381],
        [59.734475, 30.560153],
        [59.730515, 30.569143],
        [59.730764, 30.570343],
        [59.729978, 30.576503],
        [59.711945, 30.568343],
        [59.704978, 30.564254],
        [59.70727, 30.530778],
        [59.704487, 30.530136],
        [59.7043, 30.524472],
        [59.700387, 30.524419],
        [59.699515, 30.522127],
        [59.696555, 30.519707],
        [59.695856, 30.522247],
        [59.693681, 30.52326],
        [59.690919, 30.520547],
        [59.688466, 30.48798],
        [59.684876, 30.486094],
        [59.680787, 30.477163],
        [59.677945, 30.477988],
        [59.677677, 30.478882],
        [59.678308, 30.483722],
        [59.677677, 30.484177],
        [59.678291, 30.487377],
        [59.677884, 30.488884],
        [59.676384, 30.489577],
        [59.673912, 30.488963],
        [59.673929, 30.490775],
        [59.670039, 30.489859],
        [59.67017, 30.494782],
        [59.669774, 30.494846],
        [59.668892, 30.501888],
        [59.658665, 30.50278],
        [59.645991, 30.505457],
        [59.643012, 30.48907],
        [59.641855, 30.488925],
        [59.640972, 30.485746],
        [59.639304, 30.485491],
        [59.63914, 30.483086],
        [59.638076, 30.482611],
        [59.637573, 30.481035],
        [59.63749, 30.479377],
        [59.639382, 30.475626],
        [59.639977, 30.472187],
        [59.639659, 30.471378],
        [59.640026, 30.469932],
        [59.639195, 30.467202],
        [59.663112, 30.436736],
        [59.662373, 30.423847],
        [59.660493, 30.424186],
        [59.659571, 30.423036],
        [59.656609, 30.421917],
        [59.654156, 30.417196],
        [59.652, 30.4145],
        [59.652088, 30.413844],
        [59.65102, 30.413102],
        [59.648985, 30.408834],
        [59.651178, 30.401727],
        [59.650431, 30.400753],
        [59.652276, 30.396518],
        [59.647322, 30.385976],
        [59.64969, 30.379145],
        [59.650571, 30.365796],
        [59.655523, 30.351914],
        [59.658154, 30.352049],
        [59.656763, 30.326431],
        [59.661945, 30.306959],
        [59.633713, 30.254003],
        [59.638451, 30.242698],
        [59.644142, 30.252342],
        [59.648509, 30.261624],
        [59.65254, 30.253077],
        [59.653268, 30.245466],
        [59.654032, 30.244107],
        [59.658416, 30.242405],
        [59.660857, 30.24259],
        [59.661429, 30.238722],
        [59.670982, 30.250407],
        [59.67077, 30.242632],
        [59.673649, 30.232002],
        [59.673269, 30.231524],
        [59.673399, 30.229817],
        [59.669271, 30.228223],
        [59.670607, 30.222624]
      ]
    ]
  ]
}, {
  name: `Фрунзенский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.912979, 30.332627],
        [59.915664, 30.340155],
        [59.915976, 30.3422],
        [59.915963, 30.345116],
        [59.914175, 30.357103],
        [59.913804, 30.363342],
        [59.912771, 30.363744],
        [59.910729, 30.365744],
        [59.904061, 30.37706],
        [59.893926, 30.391137],
        [59.888202, 30.396103],
        [59.879446, 30.406804],
        [59.869449, 30.42055],
        [59.86038, 30.435144],
        [59.857048, 30.44003],
        [59.854301, 30.445715],
        [59.846999, 30.455765],
        [59.845099, 30.456649],
        [59.845355, 30.459026],
        [59.834036, 30.475052],
        [59.832009, 30.456162],
        [59.83149, 30.453096],
        [59.831177, 30.45255],
        [59.829535, 30.442902],
        [59.829656, 30.442813],
        [59.829492, 30.442778],
        [59.828904, 30.439228],
        [59.826446, 30.435623],
        [59.826538, 30.435301],
        [59.824842, 30.430634],
        [59.824274, 30.42808],
        [59.824539, 30.427801],
        [59.820936, 30.412393],
        [59.81979, 30.406751],
        [59.819423, 30.403865],
        [59.81912, 30.404101],
        [59.818748, 30.403511],
        [59.817578, 30.392539],
        [59.817622, 30.391527],
        [59.817412, 30.391035],
        [59.81649, 30.382167],
        [59.822102, 30.379532],
        [59.826567, 30.37692],
        [59.82719, 30.377099],
        [59.830336, 30.375562],
        [59.832451, 30.374173],
        [59.843367, 30.369834],
        [59.874134, 30.354463],
        [59.881106, 30.348379],
        [59.886, 30.345547],
        [59.887546, 30.345278],
        [59.890498, 30.345914],
        [59.891596, 30.345598],
        [59.904147, 30.33933],
        [59.904985, 30.338852],
        [59.904915, 30.338207],
        [59.907306, 30.336245],
        [59.908545, 30.335486],
        [59.910514, 30.335067],
        [59.911542, 30.334391]
      ]
    ]
  ]
}, {
  name: `Центральный район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.941109, 30.308204],
        [59.941742, 30.309515],
        [59.942513, 30.310479],
        [59.943236, 30.311835],
        [59.945502, 30.316532],
        [59.948419, 30.326557],
        [59.949906, 30.332442],
        [59.95151, 30.339776],
        [59.951833, 30.342728],
        [59.951933, 30.347421],
        [59.951376, 30.358826],
        [59.951382, 30.363819],
        [59.951567, 30.366349],
        [59.95188, 30.368563],
        [59.952557, 30.371985],
        [59.953901, 30.376838],
        [59.95467, 30.378647],
        [59.956919, 30.382921],
        [59.9578, 30.385166],
        [59.957906, 30.38759],
        [59.957853, 30.388908],
        [59.957709, 30.390929],
        [59.957254, 30.393615],
        [59.955998, 30.398351],
        [59.955327, 30.400207],
        [59.954895, 30.401034],
        [59.953685, 30.40216],
        [59.951344, 30.403099],
        [59.950517, 30.403202],
        [59.948685, 30.402967],
        [59.939087, 30.400399],
        [59.936747, 30.39909],
        [59.93179, 30.395106],
        [59.929097, 30.394173],
        [59.928007, 30.39455],
        [59.926176, 30.395553],
        [59.92447, 30.396909],
        [59.923921, 30.397597],
        [59.921804, 30.401134],
        [59.921222, 30.401581],
        [59.920836, 30.402128],
        [59.919256, 30.397283],
        [59.918008, 30.392636],
        [59.91487, 30.375096],
        [59.914346, 30.371771],
        [59.913996, 30.368471],
        [59.913804, 30.363342],
        [59.913986, 30.359096],
        [59.914314, 30.356089],
        [59.91586, 30.346011],
        [59.915989, 30.344268],
        [59.91597, 30.34211],
        [59.916762, 30.342106],
        [59.916993, 30.342798],
        [59.923218, 30.335253],
        [59.923451, 30.335846],
        [59.926194, 30.330067],
        [59.925047, 30.327064],
        [59.936373, 30.310143],
        [59.937259, 30.312476],
        [59.938061, 30.312623]
      ]
    ]
  ]
}, {
  name: `Бокситогорский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.385453, 33.572016],
        [59.480169999999994, 33.597408],
        [59.48929, 33.60825],
        [59.484241, 33.620982],
        [59.488876999999995, 33.64407],
        [59.502979999999994, 33.646274999999996],
        [59.502348999999995, 33.685390999999996],
        [59.510748, 33.686764],
        [59.510723999999996, 33.69383],
        [59.526759, 33.71562],
        [59.529771, 33.700575],
        [59.548105, 33.698833],
        [59.551649, 33.642136],
        [59.559628, 33.632017999999995],
        [59.561465999999996, 33.623339],
        [59.565611, 33.624497999999996],
        [59.572621, 33.635887],
        [59.572277, 33.617215],
        [59.615147, 33.623675999999996],
        [59.613895, 33.629650999999996],
        [59.619431, 33.63946],
        [59.624900999999994, 33.642224999999996],
        [59.632380999999995, 33.631384],
        [59.634586999999996, 33.643003],
        [59.624551999999994, 33.648707],
        [59.629334, 33.652277999999995],
        [59.631992, 33.646663],
        [59.629569999999994, 33.655344],
        [59.63981, 33.656762],
        [59.633013, 33.669748],
        [59.632906999999996, 33.685660999999996],
        [59.628279, 33.703955],
        [59.642503, 33.699569],
        [59.644399, 33.763051],
        [59.656053, 33.760172],
        [59.654447, 33.749269999999996],
        [59.659772999999994, 33.740843999999996],
        [59.660627, 33.745135],
        [59.661910999999996, 33.74281],
        [59.660671, 33.748163],
        [59.663131, 33.756266],
        [59.669871, 33.762597],
        [59.672518999999994, 33.771405],
        [59.709467999999994, 33.779230999999996],
        [59.785880999999996, 33.780702999999995],
        [59.804887, 33.806863],
        [59.810764, 33.827864999999996],
        [59.816458, 33.837804],
        [59.813927, 33.844718],
        [59.819219999999994, 33.855987],
        [59.820674, 33.852204],
        [59.823435999999994, 33.855467],
        [59.821296, 34.035219999999995],
        [59.79322, 34.045556],
        [59.793389999999995, 34.039511],
        [59.73399, 34.047452],
        [59.737643999999996, 34.055541999999996],
        [59.736566999999994, 34.092399],
        [59.740888, 34.095406],
        [59.745117, 34.109750999999996],
        [59.737449999999995, 34.127106],
        [59.733737, 34.147878],
        [59.734434, 34.180741],
        [59.737449999999995, 34.187121],
        [59.73808, 34.210068],
        [59.731275, 34.22748],
        [59.732414999999996, 34.240489],
        [59.726248999999996, 34.279212],
        [59.725209, 34.301080999999996],
        [59.715855, 34.306726],
        [59.719646, 34.417091],
        [59.690621, 34.41897],
        [59.693666, 34.494872],
        [59.677367, 34.52501],
        [59.677178, 34.539457999999996],
        [59.689102, 34.569555],
        [59.826946, 34.577712999999996],
        [59.826128999999995, 34.748177999999996],
        [59.848364999999994, 34.739629],
        [59.848569, 34.731567999999996],
        [59.853798, 34.72873],
        [59.856609, 34.74254],
        [59.85322, 34.766000999999996],
        [59.861633, 34.807409],
        [59.866626, 34.813189],
        [59.870920999999996, 34.829525],
        [59.87558, 34.83086],
        [59.882864999999995, 34.813331],
        [59.898824999999995, 34.812449],
        [59.902017, 34.818362],
        [59.906048, 34.812796],
        [59.915274, 34.814049],
        [59.915402, 34.834336],
        [59.924628, 34.83659],
        [59.924440999999995, 34.863703],
        [59.921791999999996, 34.872761],
        [59.923522, 34.878735999999996],
        [59.929412, 34.889108],
        [59.948231, 34.880199],
        [59.95285, 34.907807999999996],
        [59.954887, 34.90866],
        [59.956446, 34.899755],
        [59.958783, 34.909406],
        [59.960471999999996, 34.907032],
        [59.963207999999995, 34.890786999999996],
        [59.963514999999994, 34.905246],
        [59.960583, 34.910708],
        [59.973541999999995, 34.92751],
        [59.985927, 34.985327999999996],
        [59.994142999999994, 34.99535],
        [60.050287, 35.001252],
        [60.049943999999996, 34.993995],
        [60.062174999999996, 34.996798999999996],
        [60.062846, 34.987569],
        [60.064173999999994, 34.989471],
        [60.065152999999995, 34.989135999999995],
        [60.067459, 34.977779999999996],
        [60.067626999999995, 34.96704],
        [60.066410999999995, 34.948215999999995],
        [60.062307999999994, 34.94181],
        [60.073935999999996, 34.93145],
        [60.075255999999996, 34.920933],
        [60.079538, 34.914451],
        [60.081328, 34.889085],
        [60.092659, 34.890668],
        [60.093379999999996, 34.913603],
        [60.087616999999995, 34.919003],
        [60.086689, 34.928038],
        [60.083138999999996, 34.932297999999996],
        [60.083292, 34.942477],
        [60.086824, 34.947054],
        [60.087728, 34.955763999999995],
        [60.088871, 34.94883],
        [60.089893999999994, 34.949582],
        [60.088494999999995, 34.95836],
        [60.090900999999995, 34.962903],
        [60.093101, 34.958326],
        [60.097972999999996, 34.959587],
        [60.096723, 35.001051],
        [60.101561999999994, 34.997408],
        [60.107163, 34.980505],
        [60.108473, 34.986115],
        [60.1132, 34.984513],
        [60.118638999999995, 34.96414],
        [60.133877999999996, 34.957665],
        [60.140699, 34.928636],
        [60.180788, 34.931686],
        [60.179480999999996, 35.002752],
        [60.189153999999995, 35.003457],
        [60.188362999999995, 35.010937999999996],
        [60.172869, 35.013945],
        [60.173024999999996, 35.001639],
        [60.170351, 35.002638],
        [60.168445, 35.020454],
        [60.164319, 35.024279],
        [60.1603, 35.037955],
        [60.154377999999994, 35.037803],
        [60.15031, 35.031586],
        [60.151078, 35.011457],
        [60.148188999999995, 35.021992],
        [60.140088999999996, 35.026959],
        [60.137665999999996, 35.009533],
        [60.132222, 35.007926],
        [60.130556999999996, 34.99913],
        [60.125564999999995, 35.00358],
        [60.125111, 35.01535],
        [60.12166, 35.015602],
        [60.121347, 35.031613],
        [60.132968999999996, 35.033668],
        [60.137226999999996, 35.040892],
        [60.130691, 35.040890999999995],
        [60.131004, 35.046800999999995],
        [60.126332999999995, 35.056678],
        [60.128265, 35.061910999999995],
        [60.134356999999994, 35.066876],
        [60.135385, 35.065413],
        [60.130154999999995, 35.057606],
        [60.134665999999996, 35.06152],
        [60.136415, 35.056509999999996],
        [60.134949, 35.046174],
        [60.137795999999994, 35.047801],
        [60.138935999999994, 35.042891],
        [60.140459, 35.056253],
        [60.143049999999995, 35.056596],
        [60.140074999999996, 35.064561999999995],
        [60.141127999999995, 35.071985],
        [60.138920999999996, 35.068301999999996],
        [60.134977, 35.079436],
        [60.139162999999996, 35.080237],
        [60.138476, 35.088462],
        [60.119963999999996, 35.090748],
        [60.120042, 35.081581],
        [60.100035, 35.081299],
        [60.097319999999996, 35.074183],
        [60.091795, 35.080075],
        [60.084775, 35.058284],
        [60.085212999999996, 35.04971],
        [60.089411999999996, 35.046215],
        [60.109719, 35.059830999999996],
        [60.111902, 35.058437999999995],
        [60.112291, 35.050529],
        [60.107236, 35.046229],
        [60.106327, 35.039088],
        [60.098068999999995, 35.038503999999996],
        [60.100615999999995, 35.031946],
        [60.095813, 35.028957999999996],
        [60.091083, 35.033474999999996],
        [60.092211, 35.023857],
        [60.062287999999995, 35.047743],
        [60.061144999999996, 35.061085999999996],
        [60.04866, 35.064254999999996],
        [60.047014999999995, 35.078587],
        [60.04884, 35.112466],
        [60.046988, 35.112542],
        [60.045083, 35.145106999999996],
        [60.019884999999995, 35.141028999999996],
        [60.015636, 35.234241],
        [60.016498999999996, 35.284577999999996],
        [59.997583, 35.289123],
        [59.998003, 35.325007],
        [59.979205, 35.325927],
        [59.981482, 35.475338],
        [59.927681, 35.476135],
        [59.920051, 35.419561],
        [59.86389, 35.37746],
        [59.729323, 35.408355],
        [59.714064, 35.428256],
        [59.703951999999994, 35.469913999999996],
        [59.704491, 35.500147999999996],
        [59.701212999999996, 35.51162],
        [59.710001999999996, 35.549216],
        [59.703365, 35.552821],
        [59.701325999999995, 35.562383],
        [59.709140999999995, 35.570313],
        [59.719556, 35.612784],
        [59.718165, 35.624843],
        [59.617458, 35.609843],
        [59.655879, 35.503304],
        [59.54291, 35.500772],
        [59.546135, 35.464659],
        [59.544135, 35.455593],
        [59.534143, 35.440985999999995],
        [59.538219999999995, 35.435915],
        [59.535053999999995, 35.426538],
        [59.535531999999996, 35.418462],
        [59.531006, 35.410426],
        [59.523571, 35.420105],
        [59.525631, 35.431678999999995],
        [59.518501, 35.454162],
        [59.523446, 35.468134],
        [59.516478, 35.479093999999996],
        [59.521392999999996, 35.491109],
        [59.511274, 35.496111],
        [59.507376, 35.510811],
        [59.504239, 35.509468999999996],
        [59.508047999999995, 35.464773],
        [59.517224, 35.418828999999995],
        [59.522593, 35.368933999999996],
        [59.525731, 35.334151],
        [59.523229, 35.314082],
        [59.492771, 35.312033],
        [59.477874, 35.344882],
        [59.47822, 35.378553],
        [59.469381999999996, 35.379954999999995],
        [59.448986999999995, 35.410708],
        [59.428419999999996, 35.392089999999996],
        [59.428900999999996, 35.399384999999995],
        [59.431076999999995, 35.399149],
        [59.429753999999996, 35.413118],
        [59.423916, 35.413333],
        [59.429809, 35.430198],
        [59.425577, 35.434537],
        [59.408426, 35.429224999999995],
        [59.412428999999996, 35.446949],
        [59.408808, 35.463836],
        [59.407363999999994, 35.447936],
        [59.403152, 35.436799],
        [59.405352, 35.430405],
        [59.394890999999994, 35.415963999999995],
        [59.392275, 35.402575],
        [59.378634999999996, 35.39763],
        [59.370663, 35.379433999999996],
        [59.369842, 35.368919],
        [59.357946, 35.352461],
        [59.358149, 35.328007],
        [59.361345, 35.331047999999996],
        [59.371998999999995, 35.325915],
        [59.372085999999996, 35.315571999999996],
        [59.369743, 35.312332],
        [59.363335, 35.312996999999996],
        [59.350012, 35.296634],
        [59.335392999999996, 35.306917999999996],
        [59.325243, 35.351678],
        [59.328039, 35.36996],
        [59.323927, 35.371719999999996],
        [59.328489, 35.391203],
        [59.320417, 35.393305999999995],
        [59.321686, 35.382441],
        [59.314502, 35.380274],
        [59.317266, 35.369566999999996],
        [59.323079, 35.371455],
        [59.317826, 35.368065],
        [59.314885999999994, 35.359331],
        [59.311935, 35.366091],
        [59.313038999999996, 35.379208],
        [59.304770999999995, 35.37358],
        [59.306559, 35.355340999999996],
        [59.3011, 35.246283999999996],
        [59.288633, 35.19338],
        [59.282075999999996, 35.189130999999996],
        [59.276284999999994, 35.194519],
        [59.261334999999995, 35.177222],
        [59.258514999999996, 35.179079],
        [59.256744999999995, 35.151575],
        [59.249272999999995, 35.13169],
        [59.246120999999995, 35.101696],
        [59.250372999999996, 35.082464],
        [59.249029, 35.050173],
        [59.254317, 35.038809],
        [59.246713, 35.002034],
        [59.224458, 34.977233],
        [59.227888, 34.968909],
        [59.222235999999995, 34.939004],
        [59.226582, 34.920559],
        [59.212928999999995, 34.926563],
        [59.21158, 34.914229],
        [59.218303, 34.899232],
        [59.214368, 34.884836],
        [59.215322, 34.867008],
        [59.210747999999995, 34.866414],
        [59.200896, 34.851811999999995],
        [59.171389999999995, 34.851358],
        [59.172186999999994, 34.843508],
        [59.169013, 34.837851],
        [59.166639999999994, 34.841736],
        [59.147402, 34.834528999999996],
        [59.147088, 34.844453],
        [59.127354, 34.835685],
        [59.130314999999996, 34.814312],
        [59.127615999999996, 34.803737999999996],
        [59.117338999999994, 34.785927],
        [59.101012999999995, 34.783373999999995],
        [59.089245999999996, 34.762012999999996],
        [59.093439999999994, 34.752219],
        [59.084547, 34.721019],
        [59.098254, 34.726119],
        [59.100393999999994, 34.718168999999996],
        [59.098431, 34.707788],
        [59.11139, 34.692237999999996],
        [59.115026, 34.673691999999996],
        [59.122054, 34.659068999999995],
        [59.114163999999995, 34.628188],
        [59.122056, 34.579856],
        [59.118705, 34.555887999999996],
        [59.125614, 34.537454],
        [59.14581, 34.510262],
        [59.141557999999996, 34.480261999999996],
        [59.160286, 34.451051],
        [59.144062999999996, 34.423058],
        [59.156855, 34.423849],
        [59.160785999999995, 34.398496],
        [59.183338, 34.392389],
        [59.183532, 34.383345],
        [59.178486, 34.381051],
        [59.193967, 34.355924],
        [59.196427, 34.362369],
        [59.200826, 34.362874999999995],
        [59.200762999999995, 34.350271],
        [59.204246999999995, 34.350550999999996],
        [59.204376999999994, 34.341609999999996],
        [59.201235, 34.341695],
        [59.201296, 34.335671999999995],
        [59.197599999999994, 34.336562],
        [59.197722999999996, 34.331919],
        [59.190836, 34.319722999999996],
        [59.184042, 34.331368999999995],
        [59.180592, 34.312238],
        [59.182615, 34.291686999999996],
        [59.178414, 34.284894],
        [59.188824, 34.257244],
        [59.207784, 34.249051],
        [59.20599, 34.215562999999996],
        [59.179672, 34.211973],
        [59.173316, 34.205278],
        [59.173229, 34.176837],
        [59.183341999999996, 34.159934],
        [59.170300999999995, 34.106282],
        [59.15918, 34.103543],
        [59.157968, 34.118466],
        [59.141901, 34.116845],
        [59.137851, 34.105185],
        [59.138068999999994, 34.098696],
        [59.144012, 34.099546],
        [59.145925999999996, 34.092846],
        [59.141132, 34.076769999999996],
        [59.150788999999996, 34.068037],
        [59.147379, 34.031296],
        [59.159237999999995, 34.025805],
        [59.164747999999996, 34.016707],
        [59.162126, 34.011686],
        [59.165386999999996, 33.994187],
        [59.171611, 33.984091],
        [59.173462, 33.973898999999996],
        [59.185378, 33.968233999999995],
        [59.187008, 33.949008],
        [59.197796999999994, 33.944587999999996],
        [59.199799999999996, 33.93549],
        [59.212346, 33.939009],
        [59.239357999999996, 33.933901999999996],
        [59.246041, 33.947376999999996],
        [59.249361, 33.936305],
        [59.240325, 33.923645],
        [59.232343, 33.926004999999996],
        [59.225215999999996, 33.91592],
        [59.207636, 33.910384],
        [59.200188999999995, 33.802734],
        [59.215264999999995, 33.794236999999995],
        [59.229476, 33.808442],
        [59.227056999999995, 33.821187],
        [59.234843, 33.820972999999995],
        [59.239965999999995, 33.798485],
        [59.248452, 33.794151],
        [59.249176999999996, 33.807840999999996],
        [59.2592, 33.805867],
        [59.262451999999996, 33.799858],
        [59.264033999999995, 33.785267],
        [59.271108, 33.776555],
        [59.273326999999995, 33.785396],
        [59.283473, 33.774108999999996],
        [59.277103999999994, 33.758874],
        [59.279827999999995, 33.754304],
        [59.286767, 33.766771],
        [59.292322, 33.75896],
        [59.288171999999996, 33.746686],
        [59.298621999999995, 33.73673],
        [59.299620999999995, 33.708898999999995],
        [59.309802999999995, 33.69272],
        [59.310494, 33.674074],
        [59.321222, 33.677593],
        [59.325894, 33.673215],
        [59.327977, 33.623101999999996],
        [59.382089, 33.634926]
      ]
    ]
  ]
}, {
  name: `Волосовский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.188309, 28.903454999999997],
        [59.198114, 28.885983],
        [59.199369, 28.877064999999998],
        [59.202022, 28.872915],
        [59.212303, 28.872723999999998],
        [59.214211999999996, 28.868878],
        [59.219891, 28.865396999999998],
        [59.227402, 28.85125],
        [59.234581999999996, 28.84791],
        [59.247721999999996, 28.848398999999997],
        [59.246984999999995, 28.856012],
        [59.249657, 28.868374],
        [59.249492999999994, 28.879289999999997],
        [59.248013, 28.885229],
        [59.241687999999996, 28.893897],
        [59.243865, 28.899355],
        [59.243947, 28.908185999999997],
        [59.242222, 28.914125],
        [59.237089, 28.91573],
        [59.236554999999996, 28.918058],
        [59.238403, 28.924879999999998],
        [59.238274999999994, 28.942711],
        [59.236835, 28.946973999999997],
        [59.241263999999994, 28.962242],
        [59.244422, 28.962466],
        [59.246117999999996, 28.967841999999997],
        [59.251605, 28.968518],
        [59.256127, 28.964758],
        [59.263855, 28.965859],
        [59.274117999999994, 28.957549999999998],
        [59.291334, 28.958256],
        [59.298815, 28.938036999999998],
        [59.302423999999995, 28.916065],
        [59.333116, 28.915689999999998],
        [59.335234, 28.913542999999997],
        [59.343042, 28.922545999999997],
        [59.343528, 28.940371],
        [59.347331, 28.942660999999998],
        [59.354568, 28.934105],
        [59.358491, 28.943814999999997],
        [59.361691, 28.939000999999998],
        [59.391199, 28.942089],
        [59.391355999999995, 28.936473],
        [59.398896, 28.933142999999998],
        [59.399626, 28.961045],
        [59.405846, 28.965826],
        [59.409454999999994, 28.962287999999997],
        [59.41419, 28.961592999999997],
        [59.428723, 28.947333999999998],
        [59.431117, 28.937908999999998],
        [59.436965, 28.926098],
        [59.445012, 28.90364],
        [59.469662, 28.944426],
        [59.470395999999994, 28.941194],
        [59.474112999999996, 28.940699],
        [59.474258, 28.951392],
        [59.479279999999996, 28.951086999999998],
        [59.477801, 28.991183999999997],
        [59.492461999999996, 28.979274999999998],
        [59.500803999999995, 28.981164999999997],
        [59.501317, 28.983839],
        [59.51269, 28.951155999999997],
        [59.521449999999994, 28.967872],
        [59.516406999999994, 28.975265],
        [59.522385, 28.988239],
        [59.546515, 29.001763999999998],
        [59.541163999999995, 29.012971],
        [59.545871, 29.018749999999997],
        [59.545286, 29.024538],
        [59.549744999999994, 29.024794999999997],
        [59.555445999999996, 29.033765],
        [59.556084999999996, 29.062503999999997],
        [59.573896, 29.056275],
        [59.575907, 29.080948],
        [59.599061999999996, 29.091116],
        [59.606775, 29.10547],
        [59.625637999999995, 29.125253999999998],
        [59.636634, 29.108521],
        [59.641887, 29.112788],
        [59.646198, 29.139284999999997],
        [59.654787, 29.146917],
        [59.660919, 29.172407],
        [59.665063999999994, 29.168079],
        [59.673328, 29.173019],
        [59.67538, 29.175974],
        [59.677383999999996, 29.192183],
        [59.682970999999995, 29.192563999999997],
        [59.683063, 29.200716],
        [59.666487, 29.198469],
        [59.666199, 29.203037],
        [59.681957, 29.221142],
        [59.680127, 29.222958],
        [59.677644, 29.23295],
        [59.679793999999994, 29.234752999999998],
        [59.682849999999995, 29.244204],
        [59.675307999999994, 29.279839],
        [59.663691, 29.302894],
        [59.664528, 29.310133999999998],
        [59.657681, 29.322574],
        [59.657778, 29.338117999999998],
        [59.651539, 29.366979999999998],
        [59.652516, 29.388682],
        [59.650003, 29.388202999999997],
        [59.644489, 29.39818],
        [59.646893999999996, 29.408279999999998],
        [59.657475999999996, 29.422587],
        [59.660173, 29.435067],
        [59.665993, 29.442743999999998],
        [59.669594999999994, 29.453550999999997],
        [59.673472999999994, 29.457662],
        [59.676232, 29.455184],
        [59.677349, 29.473906],
        [59.668763999999996, 29.486541],
        [59.663484999999994, 29.485422],
        [59.659523, 29.491011999999998],
        [59.662003, 29.499222999999997],
        [59.674344999999995, 29.503375],
        [59.6757, 29.52056],
        [59.669959, 29.52874],
        [59.664631, 29.531602999999997],
        [59.662552, 29.531778],
        [59.660472999999996, 29.525907],
        [59.656554, 29.521570999999998],
        [59.64691, 29.523287999999997],
        [59.642886999999995, 29.535218999999998],
        [59.644341, 29.542042],
        [59.642418, 29.542410999999998],
        [59.644231, 29.552429999999998],
        [59.646505999999995, 29.551968],
        [59.647607, 29.557298],
        [59.646938, 29.567435],
        [59.644099, 29.56805],
        [59.643943, 29.577312],
        [59.638225999999996, 29.589456],
        [59.638357, 29.587616999999998],
        [59.636337999999995, 29.588065],
        [59.633401, 29.582814],
        [59.630049, 29.585026],
        [59.628080999999995, 29.579888999999998],
        [59.621216, 29.577495],
        [59.620574, 29.574908999999998],
        [59.605067, 29.579192],
        [59.606469999999995, 29.588874999999998],
        [59.604219, 29.590844999999998],
        [59.60051, 29.609503],
        [59.588823999999995, 29.614762],
        [59.585505, 29.608045999999998],
        [59.577251, 29.604],
        [59.564783, 29.608272999999997],
        [59.570235999999994, 29.630688],
        [59.571766999999994, 29.64651],
        [59.556746999999994, 29.667332],
        [59.550729999999994, 29.666373999999998],
        [59.540963, 29.674084999999998],
        [59.530640999999996, 29.67598],
        [59.524094, 29.686996999999998],
        [59.518584, 29.67887],
        [59.512273, 29.685872999999997],
        [59.504709, 29.677808],
        [59.496497, 29.697312999999998],
        [59.487209, 29.730746999999997],
        [59.482746, 29.734531999999998],
        [59.472431, 29.724068],
        [59.468972, 29.741908],
        [59.463769, 29.742836999999998],
        [59.451169, 29.769277],
        [59.453351, 29.781070999999997],
        [59.431053, 29.803368],
        [59.409814, 29.833028],
        [59.403527, 29.802343999999998],
        [59.396786, 29.8023],
        [59.377344, 29.812210999999998],
        [59.368508, 29.797303],
        [59.368395, 29.785262],
        [59.372733, 29.777179],
        [59.368224999999995, 29.765128999999998],
        [59.367967, 29.749108],
        [59.359792999999996, 29.749271999999998],
        [59.359728, 29.757619],
        [59.340205, 29.757644],
        [59.340126999999995, 29.737333999999997],
        [59.325768, 29.724567],
        [59.332839, 29.715000999999997],
        [59.313404999999996, 29.698159999999998],
        [59.310088, 29.682575999999997],
        [59.306098999999996, 29.678701],
        [59.299302, 29.660777999999997],
        [59.291525, 29.664433],
        [59.264289999999995, 29.66735],
        [59.259164, 29.661769999999997],
        [59.254796999999996, 29.665823],
        [59.201093, 29.718677999999997],
        [59.204533999999995, 29.721498],
        [59.211683, 29.73376],
        [59.204524, 29.751096],
        [59.175959999999996, 29.737588],
        [59.170142999999996, 29.738395999999998],
        [59.151067, 29.810330999999998],
        [59.149910999999996, 29.825702999999997],
        [59.146876999999996, 29.834180999999997],
        [59.141374, 29.823976],
        [59.138306, 29.82837],
        [59.135439, 29.817345999999997],
        [59.127064999999995, 29.803482],
        [59.101569, 29.788876],
        [59.094316, 29.790218999999997],
        [59.089478, 29.776718],
        [59.073871999999994, 29.762693],
        [59.076631, 29.742611],
        [59.081604, 29.730953],
        [59.076972, 29.724946],
        [59.068694, 29.691636],
        [59.086113, 29.628826999999998],
        [59.050306, 29.591966],
        [59.060931999999994, 29.556583],
        [59.032675, 29.54789],
        [59.033877, 29.534333999999998],
        [59.036269999999995, 29.529027],
        [59.043979, 29.524957999999998],
        [59.047895999999994, 29.51071],
        [59.053844, 29.50677],
        [59.054972, 29.493160999999997],
        [59.059877, 29.487638999999998],
        [59.058600999999996, 29.478050999999997],
        [59.061685999999995, 29.472807],
        [59.059447, 29.453623],
        [59.06026, 29.447263999999997],
        [59.063449, 29.442885],
        [59.064073, 29.434562],
        [59.069966, 29.419852],
        [59.07561, 29.417171],
        [59.07794, 29.390224],
        [59.082297, 29.379122],
        [59.083785, 29.367565],
        [59.090523, 29.354364],
        [59.095692, 29.350125],
        [59.098963, 29.334144],
        [59.105985999999994, 29.320203],
        [59.108432, 29.309507],
        [59.113445999999996, 29.299203],
        [59.112300999999995, 29.295199],
        [59.114149999999995, 29.279352],
        [59.109389, 29.25979],
        [59.099266, 29.243533],
        [59.090452, 29.236770999999997],
        [59.088936999999994, 29.216193999999998],
        [59.084792, 29.201074],
        [59.091615, 29.174015999999998],
        [59.088499999999996, 29.169577],
        [59.089755, 29.143416],
        [59.088998, 29.133202999999998],
        [59.096055, 29.111760999999998],
        [59.094507, 29.101741999999998],
        [59.090045999999994, 29.102286],
        [59.090477, 29.107322],
        [59.087551, 29.114482],
        [59.086223999999994, 29.112983],
        [59.087748999999995, 29.108893],
        [59.084039, 29.100382999999997],
        [59.089741, 29.097472],
        [59.091682, 29.086826],
        [59.097082, 29.084435],
        [59.101395, 29.091348],
        [59.108855999999996, 29.082096],
        [59.115272999999995, 29.048057],
        [59.123231999999994, 29.031181],
        [59.124734999999994, 29.012362],
        [59.121441999999995, 29.00668],
        [59.131062, 29.004441],
        [59.135459, 28.996257],
        [59.137138, 28.999215],
        [59.141023999999994, 28.994228],
        [59.142066, 28.986677999999998],
        [59.15372, 28.980179],
        [59.154683, 28.973831999999998],
        [59.160349, 28.969393999999998],
        [59.162692, 28.95807],
        [59.167100999999995, 28.949309],
        [59.171124999999996, 28.931027],
        [59.176002, 28.918015999999998],
        [59.185944, 28.912889999999997]
      ]
    ]
  ]
}, {
  name: `Волховский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.261173, 31.575243999999998],
        [60.846326, 31.899016],
        [60.526934999999995, 32.850685],
        [60.511602999999994, 32.854639],
        [60.484868999999996, 32.840223],
        [60.481091, 32.858011],
        [60.4815, 32.875834],
        [60.490041, 32.929528999999995],
        [60.47517, 32.939054],
        [60.474847999999994, 32.951204],
        [60.470321, 32.928854],
        [60.459317, 32.933364],
        [60.452259, 32.94795],
        [60.454262, 32.967378],
        [60.452655, 32.976341999999995],
        [60.444314999999996, 32.984381],
        [60.4345, 33.006595],
        [60.424402, 33.00589],
        [60.420970999999994, 33.011517],
        [60.418814999999995, 33.032821],
        [60.412865, 33.04117],
        [60.411924, 33.048476],
        [60.424346, 33.040276],
        [60.430664, 33.04182],
        [60.422079, 33.089994],
        [60.423503999999994, 33.094964],
        [60.421769, 33.091909],
        [60.422276999999994, 33.104563],
        [60.420493, 33.108893],
        [60.407712, 33.101551],
        [60.404574, 33.111371],
        [60.398661999999995, 33.111250999999996],
        [60.395849, 33.121187],
        [60.392472999999995, 33.120841],
        [60.395433, 33.133229],
        [60.390564999999995, 33.132989],
        [60.388797, 33.196174],
        [60.392433999999994, 33.197023],
        [60.388535999999995, 33.369974],
        [60.390214, 33.470419],
        [60.399238999999994, 33.472035],
        [60.400138, 33.490663],
        [60.388177, 33.490339999999996],
        [60.38778, 33.509893999999996],
        [60.369006, 33.507529],
        [60.368252999999996, 33.527087],
        [60.358605, 33.525998],
        [60.358183999999994, 33.544543],
        [60.300588, 33.53844],
        [60.299108999999994, 33.608592],
        [60.292587, 33.622088999999995],
        [60.28324, 33.627898],
        [60.278254999999994, 33.643412],
        [60.292215, 33.660312999999995],
        [60.294701999999994, 33.66994],
        [60.299229, 33.670079],
        [60.299065999999996, 33.690998],
        [60.274609, 33.696231],
        [60.25665, 33.733773],
        [60.252967, 33.714493],
        [60.256192, 33.700137],
        [60.253741999999995, 33.7018],
        [60.251653, 33.694961],
        [60.25069, 33.65389],
        [60.246781, 33.649257],
        [60.237348, 33.647513],
        [60.236145, 33.671869],
        [60.221219999999995, 33.676107],
        [60.216746, 33.687779],
        [60.192929, 33.70519],
        [60.173114, 33.704623999999995],
        [60.168347, 33.699774],
        [60.166031999999994, 33.683419],
        [60.174544999999995, 33.68588],
        [60.178290999999994, 33.678459],
        [60.142740999999994, 33.650707],
        [60.133548, 33.632821],
        [60.12668, 33.638413],
        [60.117067999999996, 33.636914],
        [60.110831999999995, 33.607023],
        [60.111081999999996, 33.594195],
        [60.113910999999995, 33.593598],
        [60.113887999999996, 33.568159],
        [60.133744, 33.515561],
        [60.130052, 33.479019],
        [60.121441999999995, 33.476954],
        [60.12081, 33.462331999999996],
        [60.114197, 33.464124999999996],
        [60.110265, 33.458698999999996],
        [60.11627, 33.448654],
        [60.113268999999995, 33.441097],
        [60.122366, 33.429885999999996],
        [60.123867, 33.408167999999996],
        [60.135093999999995, 33.367205999999996],
        [60.130148999999996, 33.355727],
        [60.141248, 33.329412],
        [60.145903999999994, 33.330141],
        [60.147495, 33.304818],
        [60.144262999999995, 33.284793],
        [60.154348, 33.287859],
        [60.17818, 33.266287],
        [60.180906, 33.261719],
        [60.18091, 33.239125],
        [60.210896, 33.212285],
        [60.210891, 33.205767],
        [60.202293, 33.193464999999996],
        [60.205850999999996, 33.170691999999995],
        [60.202315, 33.124840999999996],
        [60.195183, 33.098866],
        [60.195505, 33.079293],
        [60.179232, 33.061883],
        [60.145984999999996, 33.052011],
        [60.126909999999995, 33.07244],
        [60.087714, 33.098659999999995],
        [60.065054999999994, 33.133359999999996],
        [60.053695, 33.132054],
        [60.050427, 33.139776],
        [60.039142999999996, 33.139023],
        [60.027943, 33.155867],
        [60.023077, 33.171454],
        [60.019332, 33.175636],
        [60.013711, 33.171896],
        [60.006485, 33.180651],
        [59.999215, 33.165124],
        [59.997625, 33.125037],
        [59.98037, 33.129872999999996],
        [59.945218999999994, 33.176074],
        [59.9344, 33.147622],
        [59.892443, 33.142888],
        [59.895409, 33.117582999999996],
        [59.893347999999996, 33.113715],
        [59.880165999999996, 33.100206],
        [59.856173, 33.099523],
        [59.858514, 33.010768],
        [59.841885999999995, 33.023767],
        [59.840576999999996, 33.037738],
        [59.849880999999996, 33.048922],
        [59.848216, 33.051989],
        [59.840452, 33.051927],
        [59.833355999999995, 33.041588],
        [59.825649, 33.043824],
        [59.813077, 33.054406],
        [59.810272, 33.062571999999996],
        [59.799696999999995, 33.06681],
        [59.796927, 33.061526],
        [59.79879, 33.058837],
        [59.823014, 33.033831],
        [59.827754999999996, 33.03402],
        [59.838646999999995, 33.016264],
        [59.841563, 33.018775],
        [59.845529, 33.012434999999996],
        [59.845907, 32.991037999999996],
        [59.811381, 32.996727],
        [59.794138, 32.961076],
        [59.798080999999996, 32.957101],
        [59.796239, 32.944564],
        [59.777868999999995, 32.955278],
        [59.776149999999994, 32.950253],
        [59.784369999999996, 32.917994],
        [59.779587, 32.908069999999995],
        [59.780536, 32.888905],
        [59.777319999999996, 32.886808],
        [59.775889, 32.850636],
        [59.766724999999994, 32.851456999999996],
        [59.762502999999995, 32.861115999999996],
        [59.766059999999996, 32.850783],
        [59.750077, 32.762971],
        [59.672621, 32.689771],
        [59.608706, 32.655242],
        [59.593348, 32.47423],
        [59.590075, 32.471911999999996],
        [59.592966999999994, 32.470551],
        [59.591953999999994, 32.455324],
        [59.598158999999995, 32.447441999999995],
        [59.597994, 32.436929],
        [59.592245999999996, 32.432928],
        [59.591978999999995, 32.420362],
        [59.585111999999995, 32.397915],
        [59.59041, 32.37008],
        [59.59153, 32.319404999999996],
        [59.593807, 32.313672],
        [59.599058, 32.32119],
        [59.597657, 32.335149],
        [59.600812999999995, 32.353617],
        [59.617262999999994, 32.359397],
        [59.618882, 32.348669],
        [59.62113, 32.349216999999996],
        [59.633618, 32.363524],
        [59.633669, 32.382626],
        [59.639694999999996, 32.379069],
        [59.643015, 32.383427],
        [59.651998, 32.372651999999995],
        [59.660072, 32.370618],
        [59.674706, 32.334069],
        [59.670854999999996, 32.318519],
        [59.670319, 32.303816999999995],
        [59.673404, 32.303847999999995],
        [59.672650999999995, 32.298704],
        [59.652139, 32.270282],
        [59.653769, 32.241495],
        [59.662755, 32.252548],
        [59.663633999999995, 32.247642],
        [59.658550999999996, 32.239075],
        [59.660720999999995, 32.23079],
        [59.648699, 32.222122],
        [59.657121, 32.192254999999996],
        [59.663850999999994, 32.189219],
        [59.660595, 32.183288],
        [59.660083, 32.148325],
        [59.670908, 32.157678],
        [59.675128, 32.184509999999996],
        [59.714152999999996, 32.229785],
        [59.72575, 32.219769],
        [59.731365999999994, 32.197037],
        [59.740258, 32.197587999999996],
        [59.741276, 32.191902999999996],
        [59.761374999999994, 32.174265],
        [59.758961, 32.135292],
        [59.748076999999995, 32.118024],
        [59.741839999999996, 32.121811],
        [59.724973, 32.108529],
        [59.731263999999996, 32.108284999999995],
        [59.734406, 32.100662],
        [59.734109999999994, 32.080231999999995],
        [59.720675, 32.061669],
        [59.740068, 32.02107],
        [59.750301, 32.015066],
        [59.775921999999994, 32.017285],
        [59.777248, 32.032734],
        [59.79382, 32.037414],
        [59.794897, 32.01217],
        [59.802315, 32.025178],
        [59.805155, 32.019577999999996],
        [59.819032, 32.023281],
        [59.831965, 32.007301999999996],
        [59.862925999999995, 31.998154999999997],
        [59.863293999999996, 32.005162],
        [59.87143, 31.99925],
        [59.889941, 31.998375999999997],
        [59.915602, 32.018848999999996],
        [59.923075999999995, 32.017334],
        [59.928888, 32.003963999999996],
        [59.927094, 31.991788],
        [59.915876999999995, 31.966941],
        [59.917834, 31.951103999999997],
        [59.93828, 31.952309999999997],
        [59.950137999999995, 31.942655],
        [59.951293, 31.921412],
        [59.9633, 31.91557],
        [59.974562, 31.893964999999998],
        [59.983872999999996, 31.904166],
        [59.993466, 31.942345999999997],
        [60.006755, 31.926564],
        [60.011291, 31.926517999999998],
        [60.013034999999995, 31.940562],
        [60.018190999999995, 31.93554],
        [60.027609, 31.938709999999997],
        [60.047884999999994, 31.908012],
        [60.074405999999996, 31.892929],
        [60.075725, 31.877979999999997],
        [60.140747999999995, 31.883395],
        [60.146668999999996, 31.856016999999998],
        [60.146671999999995, 31.804078999999998],
        [60.157683999999996, 31.806497999999998],
        [60.166833999999994, 31.823656],
        [60.167035999999996, 31.722275],
        [60.179199, 31.713735999999997],
        [60.179224999999995, 31.709114],
        [60.194827, 31.704924],
        [60.213944, 31.681635999999997]
      ]
    ]
  ]
}, {
  name: `Всеволожский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.186144, 29.921274],
        [60.188981, 29.92372],
        [60.189426999999995, 29.939676],
        [60.192645, 29.937357],
        [60.196833, 29.956771],
        [60.202622, 29.945097999999998],
        [60.21133, 29.950787],
        [60.211084, 29.939777999999997],
        [60.222984, 29.936646],
        [60.224782, 29.926942],
        [60.240928999999994, 29.928606],
        [60.243111, 29.934161],
        [60.245127999999994, 29.930094],
        [60.248923, 29.936715],
        [60.247544, 29.942064],
        [60.250308, 29.944482999999998],
        [60.254517, 29.937018],
        [60.255122, 29.947174999999998],
        [60.262747999999995, 29.941762999999998],
        [60.267821999999995, 29.949524],
        [60.265592, 29.957268],
        [60.266031999999996, 29.97109],
        [60.268659, 29.981339],
        [60.267275999999995, 29.983352999999997],
        [60.270295, 29.986963],
        [60.270132999999994, 29.996228],
        [60.273802999999994, 30.00169],
        [60.275116, 29.996128],
        [60.276444, 30.003574],
        [60.274291999999996, 30.007154],
        [60.278110999999996, 30.00858],
        [60.279523999999995, 30.024746],
        [60.283955, 30.028181999999997],
        [60.280994, 30.045876999999997],
        [60.283023, 30.055951999999998],
        [60.288252, 30.044401999999998],
        [60.298632999999995, 30.038176],
        [60.309092, 30.044400999999997],
        [60.312619999999995, 30.038010999999997],
        [60.317487, 30.037762999999998],
        [60.33107, 30.019167999999997],
        [60.337838999999995, 30.003435999999997],
        [60.347367, 29.996228],
        [60.352838999999996, 29.997866],
        [60.357502999999994, 30.005402],
        [60.348502999999994, 30.033831],
        [60.34891, 30.052425],
        [60.352396, 30.059389],
        [60.356367999999996, 30.054555999999998],
        [60.359735, 30.068399999999997],
        [60.392412, 30.10672],
        [60.4032, 30.093874999999997],
        [60.417066, 30.099528],
        [60.428540999999996, 30.089205],
        [60.432108, 30.090187],
        [60.438838999999994, 30.080600999999998],
        [60.446540999999996, 30.084121999999997],
        [60.445651, 30.089775999999997],
        [60.448367, 30.092314],
        [60.460407999999994, 30.070769],
        [60.462314, 30.058318],
        [60.470383, 30.059299],
        [60.478775, 30.043162],
        [60.495844999999996, 30.056593999999997],
        [60.496089999999995, 30.066260999999997],
        [60.488302999999995, 30.079041999999998],
        [60.490576, 30.091412],
        [60.490291, 30.114677999999998],
        [60.485913, 30.115087],
        [60.48441, 30.142128],
        [60.480740999999995, 30.151638],
        [60.467926, 30.162653],
        [60.461448999999995, 30.192242],
        [60.459115, 30.188774],
        [60.457232, 30.191988],
        [60.457242, 30.215801],
        [60.468275999999996, 30.227634],
        [60.477112, 30.24356],
        [60.472615, 30.256289],
        [60.468664, 30.254519],
        [60.459526, 30.261587],
        [60.458771, 30.266997],
        [60.462865, 30.267128999999997],
        [60.462976999999995, 30.270771999999997],
        [60.467760999999996, 30.259597999999997],
        [60.471221, 30.258566],
        [60.472122999999996, 30.264270999999997],
        [60.467923, 30.273297],
        [60.469406, 30.278145],
        [60.464527, 30.278554],
        [60.464054, 30.286633],
        [60.458383999999995, 30.290892],
        [60.450773999999996, 30.305123],
        [60.452794, 30.310335],
        [60.452709999999996, 30.332753999999998],
        [60.448494, 30.347254],
        [60.441924, 30.355446999999998],
        [60.444720999999994, 30.375355],
        [60.441154999999995, 30.398702],
        [60.445980999999996, 30.412955999999998],
        [60.444967, 30.427456],
        [60.44237, 30.436223],
        [60.43467, 30.437206999999997],
        [60.43167, 30.444167999999998],
        [60.426195, 30.446382],
        [60.424572, 30.456211999999997],
        [60.428830999999995, 30.464976],
        [60.427817, 30.472348999999998],
        [60.430735999999996, 30.474643],
        [60.430858, 30.481278999999997],
        [60.436656, 30.476609999999997],
        [60.443954, 30.490209999999998],
        [60.450320999999995, 30.491108999999998],
        [60.449712, 30.495286],
        [60.462768, 30.511505999999997],
        [60.461307, 30.516831],
        [60.467304999999996, 30.520180999999997],
        [60.464957999999996, 30.527967999999998],
        [60.453756999999996, 30.528088],
        [60.449262, 30.534532],
        [60.447505, 30.550209],
        [60.478271, 30.628363],
        [60.486154, 30.673351],
        [60.486346, 30.686080999999998],
        [60.515, 30.698608999999998],
        [60.523047999999996, 30.718518],
        [60.846326, 31.899016],
        [60.261173, 31.575243999999998],
        [59.956376999999996, 31.048410999999998],
        [59.955594999999995, 31.037793999999998],
        [59.944722999999996, 31.019198999999997],
        [59.934903999999996, 30.993273],
        [59.916379, 30.983826999999998],
        [59.904357999999995, 30.986683],
        [59.873335999999995, 30.973433],
        [59.858779999999996, 30.962363999999997],
        [59.847052999999995, 30.946711999999998],
        [59.831633, 30.948369999999997],
        [59.819131999999996, 30.936632],
        [59.814975, 30.869505999999998],
        [59.81075, 30.842823],
        [59.804334999999995, 30.831061],
        [59.796647, 30.805833],
        [59.772515999999996, 30.786745999999997],
        [59.766976, 30.760225],
        [59.768725999999994, 30.751023],
        [59.776484999999994, 30.742952],
        [59.780705, 30.731953999999998],
        [59.78118, 30.698857999999998],
        [59.793777999999996, 30.629856999999998],
        [59.816295999999994, 30.590570999999997],
        [59.82498, 30.563495999999997],
        [59.836439, 30.559392],
        [59.833814999999994, 30.534734999999998],
        [59.848521999999996, 30.492452999999998],
        [59.858383999999994, 30.493536],
        [59.863355999999996, 30.521199],
        [59.891669, 30.495846],
        [59.97049, 30.512976],
        [59.977366999999994, 30.543326999999998],
        [59.968773, 30.552497],
        [59.970850999999996, 30.566432],
        [59.973355, 30.556178],
        [59.979315, 30.554176],
        [59.978952, 30.547580999999997],
        [59.981131999999995, 30.552045],
        [59.982018999999994, 30.528126],
        [59.988028, 30.537864],
        [59.98865, 30.533976],
        [59.992594999999994, 30.534565999999998],
        [59.985246999999994, 30.517174999999998],
        [59.98712, 30.508076],
        [60.000530999999995, 30.499284999999997],
        [60.00675, 30.504134999999998],
        [60.008128, 30.490731999999998],
        [60.014064999999995, 30.487084],
        [60.023142, 30.48914],
        [60.020312999999994, 30.482846],
        [60.025155, 30.475195],
        [60.022740999999996, 30.467647999999997],
        [60.025724, 30.464357999999997],
        [60.027307, 30.4686],
        [60.028378999999994, 30.462249],
        [60.033511999999995, 30.468397],
        [60.040150999999994, 30.445138999999998],
        [60.038312, 30.437925999999997],
        [60.041900999999996, 30.435848],
        [60.04671, 30.415377],
        [60.049585, 30.418287],
        [60.057083999999996, 30.388368999999997],
        [60.090484999999994, 30.368353],
        [60.092150999999994, 30.363674999999997],
        [60.089847999999996, 30.332948],
        [60.095827, 30.308467999999998],
        [60.091001, 30.303082],
        [60.093872, 30.291718],
        [60.102723, 30.275644999999997],
        [60.116476, 30.270668999999998],
        [60.125688, 30.259133],
        [60.115111999999996, 30.218892999999998],
        [60.118584999999996, 30.189560999999998],
        [60.121578, 30.19476],
        [60.127354, 30.193368999999997],
        [60.128800999999996, 30.171805],
        [60.136125, 30.176104],
        [60.129737, 30.161189999999998],
        [60.134294, 30.147869999999998],
        [60.136002999999995, 30.059268999999997],
        [60.144259999999996, 30.018413],
        [60.152369, 30.021147],
        [60.156423, 30.012408999999998],
        [60.164949, 30.007555999999997],
        [60.165554, 30.002820999999997],
        [60.161165, 29.998783999999997],
        [60.165582, 29.986452],
        [60.168231, 29.986493999999997],
        [60.167173, 29.982364999999998],
        [60.169475999999996, 29.984776999999998],
        [60.172653, 29.980634],
        [60.172961, 29.970955999999997],
        [60.176823999999996, 29.962208],
        [60.175537999999996, 29.955517]
      ]
    ]
  ]
}, {
  name: `Выборгский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.388664999999996, 27.721145999999997],
        [60.391521, 27.725825999999998],
        [60.433344, 27.68639],
        [60.450871, 27.747836],
        [60.469044, 27.748103],
        [60.471531999999996, 27.756134],
        [60.488958999999994, 27.767833],
        [60.503868, 27.768223],
        [60.508024999999996, 27.776909999999997],
        [60.533362999999994, 27.775146],
        [60.55401, 27.81421],
        [60.561189999999996, 27.84745],
        [60.5659, 27.84153],
        [60.575039999999994, 27.844359999999998],
        [60.578489999999995, 27.838639999999998],
        [60.6046, 27.87341],
        [60.681059999999995, 28.01786],
        [60.68246, 28.01483],
        [60.74066, 28.13522],
        [60.77865, 28.174429999999997],
        [60.782759999999996, 28.220599999999997],
        [60.81015, 28.25656],
        [60.84195, 28.31278],
        [60.85773, 28.324569999999998],
        [60.854397999999996, 28.336056],
        [60.884656, 28.392899],
        [60.883658999999994, 28.397734999999997],
        [60.88733, 28.39813],
        [60.92317, 28.4654],
        [60.92528, 28.48427],
        [60.936968, 28.492941],
        [60.956699, 28.524492],
        [60.954384999999995, 28.528585],
        [60.956019, 28.539368999999997],
        [60.95147, 28.5733],
        [60.95516, 28.61505],
        [60.94793, 28.64855],
        [60.950799999999994, 28.65741],
        [60.97837, 28.6836],
        [61.026559999999996, 28.71012],
        [61.04463, 28.714499999999997],
        [61.09336, 28.80456],
        [61.12153, 28.81916],
        [61.135239999999996, 28.872519999999998],
        [61.14196, 28.883],
        [61.143049999999995, 28.915],
        [61.15101, 28.956709999999998],
        [61.173449999999995, 28.985609999999998],
        [61.24198, 29.172189999999997],
        [61.24088, 29.176],
        [61.24442, 29.17702],
        [61.256121, 29.199680999999998],
        [61.27057, 29.24456],
        [61.2759, 29.2412],
        [61.290986999999994, 29.264487],
        [61.329764, 29.306220999999997],
        [61.27916, 29.364677],
        [61.271620999999996, 29.393397],
        [61.263169, 29.404915],
        [61.262299999999996, 29.414893],
        [61.257081, 29.423602],
        [61.255576, 29.439667],
        [61.257898, 29.444148],
        [61.256060999999995, 29.457295],
        [61.258491, 29.464689999999997],
        [61.256259, 29.478476999999998],
        [61.258224999999996, 29.483255],
        [61.251182, 29.51389],
        [61.240823, 29.519575999999997],
        [61.239166, 29.528536],
        [61.241839, 29.537786999999998],
        [61.238229, 29.547273999999998],
        [61.24142, 29.551664],
        [61.233155999999994, 29.551586999999998],
        [61.223777999999996, 29.564909999999998],
        [61.216463, 29.551108],
        [61.213058999999994, 29.559033999999997],
        [61.200564, 29.561566],
        [61.196856999999994, 29.570047],
        [61.193307, 29.561908],
        [61.181591999999995, 29.564152999999997],
        [61.181082999999994, 29.568967999999998],
        [61.155038999999995, 29.566312],
        [61.142103, 29.576711],
        [61.141107999999996, 29.568993],
        [61.13019, 29.553275],
        [61.056227, 29.551613999999997],
        [61.055839, 29.564556999999997],
        [61.049184999999994, 29.584512],
        [61.030657, 29.585836999999998],
        [61.029796, 29.581552],
        [61.014226, 29.600009],
        [61.014834, 29.604432],
        [61.011691, 29.607808],
        [61.009812999999994, 29.603037999999998],
        [61.002731, 29.6116],
        [60.988755999999995, 29.579365],
        [60.983726999999995, 29.575834999999998],
        [60.976856999999995, 29.583592999999997],
        [60.969286, 29.570342999999998],
        [60.957583, 29.585046],
        [60.946313999999994, 29.587474999999998],
        [60.92135, 29.636231],
        [60.909368, 29.624795],
        [60.842436, 29.702014],
        [60.838316, 29.661692],
        [60.825492, 29.636999],
        [60.826674999999994, 29.619329999999998],
        [60.833416, 29.604848999999998],
        [60.833079, 29.536775],
        [60.822343999999994, 29.543747],
        [60.818922, 29.539123],
        [60.837033, 29.527673],
        [60.836541999999994, 29.499682],
        [60.829170999999995, 29.509877],
        [60.827755999999994, 29.51916],
        [60.817294999999994, 29.521527],
        [60.815875999999996, 29.513472],
        [60.800405, 29.511488999999997],
        [60.794784, 29.523477999999997],
        [60.787369999999996, 29.499955],
        [60.777677999999995, 29.507234999999998],
        [60.766385, 29.497683],
        [60.7521, 29.504234999999998],
        [60.737705999999996, 29.520152],
        [60.714586, 29.574731],
        [60.708977, 29.575906999999997],
        [60.688748999999994, 29.605801999999997],
        [60.679904, 29.639915],
        [60.668420999999995, 29.649834],
        [60.659150999999994, 29.647337999999998],
        [60.657444999999996, 29.640386999999997],
        [60.647883, 29.640859],
        [60.643727, 29.604602999999997],
        [60.637864, 29.617767999999998],
        [60.635901, 29.614303],
        [60.633404, 29.580489],
        [60.63887, 29.523719],
        [60.613260999999994, 29.523308999999998],
        [60.593526999999995, 29.544843999999998],
        [60.587274, 29.540423999999998],
        [60.579639, 29.544822999999997],
        [60.573839, 29.549864],
        [60.573761999999995, 29.55569],
        [60.559881, 29.580033],
        [60.554373, 29.600244999999997],
        [60.550636, 29.600315],
        [60.549595999999994, 29.606647],
        [60.547846, 29.602348],
        [60.536252999999995, 29.625303],
        [60.524341, 29.633163999999997],
        [60.515319999999996, 29.65711],
        [60.517911999999995, 29.664706],
        [60.521307, 29.660853999999997],
        [60.524674999999995, 29.667925],
        [60.53169, 29.657739],
        [60.533128, 29.667029999999997],
        [60.529993, 29.671578999999998],
        [60.530707, 29.682802],
        [60.540623, 29.688530999999998],
        [60.536933999999995, 29.716769],
        [60.540231999999996, 29.718045999999998],
        [60.549284, 29.701717],
        [60.546279999999996, 29.713721],
        [60.547861, 29.730701999999997],
        [60.542668, 29.732046],
        [60.544297, 29.727408999999998],
        [60.536908, 29.717079],
        [60.534177, 29.739031999999998],
        [60.528036, 29.739535999999998],
        [60.528737, 29.742967999999998],
        [60.526661, 29.739213999999997],
        [60.519852, 29.752616999999997],
        [60.503332, 29.756954999999998],
        [60.48976, 29.742649],
        [60.473476, 29.706794],
        [60.472867, 29.693447],
        [60.468188999999995, 29.691464999999997],
        [60.459714, 29.705710999999997],
        [60.458861, 29.715049999999998],
        [60.445848999999995, 29.720005999999998],
        [60.447396999999995, 29.730062999999998],
        [60.443236999999996, 29.735245],
        [60.441184, 29.753626999999998],
        [60.424501, 29.752916],
        [60.422360999999995, 29.758069],
        [60.426936, 29.767397],
        [60.428895, 29.797739999999997],
        [60.421532, 29.816053999999998],
        [60.417832999999995, 29.858952],
        [60.425670999999994, 29.875114999999997],
        [60.425275, 29.891672999999997],
        [60.433153999999995, 29.906323999999998],
        [60.43662, 29.944172],
        [60.462281, 29.973518],
        [60.465123, 29.982964],
        [60.459689, 29.986936],
        [60.459156, 29.993885],
        [60.477542, 30.016755999999997],
        [60.47618, 30.051353],
        [60.470383, 30.059299],
        [60.462314, 30.058318],
        [60.460407999999994, 30.070769],
        [60.448367, 30.092314],
        [60.445651, 30.089775999999997],
        [60.446540999999996, 30.084121999999997],
        [60.438838999999994, 30.080600999999998],
        [60.417066, 30.099528],
        [60.4032, 30.093874999999997],
        [60.392412, 30.10672],
        [60.359735, 30.068399999999997],
        [60.356367999999996, 30.054555999999998],
        [60.352396, 30.059389],
        [60.34891, 30.052425],
        [60.348502999999994, 30.033831],
        [60.357502999999994, 30.005402],
        [60.350853, 29.996309999999998],
        [60.337838999999995, 30.003435999999997],
        [60.317487, 30.037762999999998],
        [60.309092, 30.044400999999997],
        [60.298632999999995, 30.038176],
        [60.288252, 30.044401999999998],
        [60.283023, 30.055951999999998],
        [60.280994, 30.045876999999997],
        [60.283955, 30.028181999999997],
        [60.279523999999995, 30.024746],
        [60.278110999999996, 30.00858],
        [60.274291999999996, 30.007154],
        [60.276444, 30.003574],
        [60.275116, 29.996128],
        [60.273802999999994, 30.00169],
        [60.270132999999994, 29.996228],
        [60.266031999999996, 29.97109],
        [60.267821999999995, 29.949524],
        [60.262747999999995, 29.941762999999998],
        [60.255122, 29.947174999999998],
        [60.254517, 29.937018],
        [60.250308, 29.944482999999998],
        [60.245127999999994, 29.930094],
        [60.243111, 29.934161],
        [60.240928999999994, 29.928606],
        [60.224782, 29.926942],
        [60.222984, 29.936646],
        [60.211084, 29.939777999999997],
        [60.21133, 29.950787],
        [60.202622, 29.945097999999998],
        [60.196833, 29.956771],
        [60.192645, 29.937357],
        [60.189426999999995, 29.939676],
        [60.188981, 29.92372],
        [60.186144, 29.921274],
        [60.182874999999996, 29.929129999999997],
        [60.18911, 29.892675999999998],
        [60.195252999999994, 29.891976],
        [60.19658, 29.850132],
        [60.202762, 29.841545999999997],
        [60.202394999999996, 29.827824],
        [60.207131, 29.820708999999997],
        [60.206959, 29.812953],
        [60.221145, 29.802533],
        [60.226067, 29.776581],
        [60.232833, 29.770961],
        [60.236658, 29.760467],
        [60.242745, 29.758153999999998],
        [60.239562, 29.734171],
        [60.240531, 29.711561999999997],
        [60.235212999999995, 29.718708],
        [60.232609999999994, 29.714312],
        [60.225804, 29.720419],
        [60.234649, 29.69566],
        [60.239715, 29.708302],
        [60.243902, 29.70655],
        [60.244811999999996, 29.699849],
        [60.239925, 29.695134],
        [60.240432999999996, 29.683187999999998],
        [60.236995, 29.677913999999998],
        [60.242261, 29.644036],
        [60.233798, 29.636919],
        [60.237372, 29.623492],
        [60.231165999999995, 29.607532],
        [60.234148, 29.601754999999997],
        [60.220701, 29.580944],
        [60.225002999999994, 29.551059],
        [60.222030999999994, 29.531367],
        [60.225336999999996, 29.521523],
        [60.233785, 29.521615999999998],
        [60.221337999999996, 29.496803999999997],
        [60.22508, 29.490067],
        [60.190676999999994, 29.425113999999997],
        [60.189018, 29.447581999999997],
        [60.184866, 29.454628],
        [60.184708, 29.469275],
        [60.182452, 29.471992999999998],
        [60.176421999999995, 29.438696],
        [60.172376, 29.449854],
        [60.049611, 29.549829],
        [60.039649999999995, 28.769123999999998]
      ]
    ]
  ]
}, {
  name: `Гатчинский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.605067, 29.579192],
        [59.620574, 29.574908999999998],
        [59.621216, 29.577495],
        [59.628080999999995, 29.579888999999998],
        [59.627649, 29.590484],
        [59.624230999999995, 29.599110999999997],
        [59.626162, 29.610198999999998],
        [59.633649, 29.618230999999998],
        [59.636199999999995, 29.635693999999997],
        [59.644203, 29.636951],
        [59.647431999999995, 29.652269],
        [59.641605999999996, 29.653449],
        [59.643136, 29.670697999999998],
        [59.648711999999996, 29.669905],
        [59.649983, 29.695189],
        [59.646941, 29.695528],
        [59.645516, 29.691684],
        [59.635321, 29.693986],
        [59.636106999999996, 29.740326],
        [59.630810999999994, 29.753753],
        [59.643636, 29.757859999999997],
        [59.647399, 29.770715],
        [59.646404999999994, 29.783082],
        [59.649511999999994, 29.796761],
        [59.645049, 29.802508],
        [59.641092, 29.799287999999997],
        [59.632683, 29.819969],
        [59.637755999999996, 29.827918999999998],
        [59.638268999999994, 29.831598],
        [59.627658999999994, 29.830879999999997],
        [59.630680999999996, 29.839657],
        [59.635365, 29.846731],
        [59.650504, 29.85389],
        [59.655972999999996, 29.86893],
        [59.664736, 29.867397999999998],
        [59.666484999999994, 29.862581],
        [59.671602, 29.867032],
        [59.680502999999995, 29.885278999999997],
        [59.687689999999996, 29.917016999999998],
        [59.675931, 29.929087],
        [59.678832, 29.986147],
        [59.655547999999996, 30.002754],
        [59.655640999999996, 30.014152],
        [59.663855999999996, 30.012089],
        [59.663850999999994, 30.017343],
        [59.672000999999995, 30.017701],
        [59.671786, 30.033914],
        [59.674004999999994, 30.034903],
        [59.672267999999995, 30.042241999999998],
        [59.678782, 30.044407],
        [59.678349999999995, 30.060948999999997],
        [59.68244, 30.085144999999997],
        [59.683495, 30.105788999999998],
        [59.685742999999995, 30.118129999999997],
        [59.681712999999995, 30.121201],
        [59.686158999999996, 30.138647],
        [59.679747, 30.155469],
        [59.677045, 30.156792],
        [59.668873, 30.185651],
        [59.671735, 30.203740999999997],
        [59.674079, 30.210198],
        [59.671538999999996, 30.214736],
        [59.673123999999994, 30.218650999999998],
        [59.669270999999995, 30.228223],
        [59.673398999999996, 30.229816999999997],
        [59.673649, 30.232001999999998],
        [59.67077, 30.242632],
        [59.671198999999994, 30.249698],
        [59.661429, 30.238722],
        [59.660857, 30.24259],
        [59.654032, 30.244107],
        [59.652539999999995, 30.253076999999998],
        [59.648509, 30.261623999999998],
        [59.638450999999996, 30.242697999999997],
        [59.633713, 30.254002999999997],
        [59.661944999999996, 30.306959],
        [59.656763, 30.326431],
        [59.658153999999996, 30.352048999999997],
        [59.655522999999995, 30.351913999999997],
        [59.650571, 30.365796],
        [59.64969, 30.379144999999998],
        [59.647321999999996, 30.385976],
        [59.652276, 30.396518],
        [59.648984999999996, 30.408834],
        [59.656608999999996, 30.421916999999997],
        [59.662372999999995, 30.423847],
        [59.663112, 30.436736],
        [59.639195, 30.467202],
        [59.638329999999996, 30.465829],
        [59.637149, 30.469882],
        [59.620672, 30.490859],
        [59.605036999999996, 30.507372],
        [59.604383999999996, 30.488031999999997],
        [59.573474999999995, 30.499520999999998],
        [59.574227, 30.516817],
        [59.565728, 30.515863999999997],
        [59.558831, 30.501254],
        [59.508933, 30.458226],
        [59.499196, 30.424000999999997],
        [59.49552, 30.418991],
        [59.488758, 30.430774],
        [59.480458999999996, 30.519143999999997],
        [59.475317, 30.528972999999997],
        [59.438036999999994, 30.544186],
        [59.423086, 30.574610999999997],
        [59.416554999999995, 30.577422],
        [59.403074999999994, 30.567187999999998],
        [59.397242, 30.570487999999997],
        [59.362933, 30.562236],
        [59.355002, 30.573005],
        [59.340681, 30.570166999999998],
        [59.325634, 30.587968],
        [59.316018, 30.581364999999998],
        [59.308645, 30.581894],
        [59.309774999999995, 30.60439],
        [59.27222, 30.602308999999998],
        [59.269448999999994, 30.614546999999998],
        [59.267238, 30.642781999999997],
        [59.265105, 30.649611999999998],
        [59.251774, 30.631876],
        [59.25282, 30.628881],
        [59.250194, 30.617196],
        [59.250772, 30.611399],
        [59.233579999999996, 30.632323999999997],
        [59.217876, 30.633139],
        [59.194331, 30.663579],
        [59.168924, 30.690264],
        [59.166619999999995, 30.698102],
        [59.162561999999994, 30.695107999999998],
        [59.141014, 30.659722],
        [59.074284999999996, 30.59618],
        [59.072553, 30.564614],
        [59.080284999999996, 30.564026],
        [59.080155999999995, 30.557492],
        [59.090472999999996, 30.558721],
        [59.09128, 30.548005],
        [59.089847999999996, 30.531513999999998],
        [59.083906999999996, 30.527928],
        [59.083602, 30.521123],
        [59.076440999999996, 30.520623999999998],
        [59.075585, 30.518487999999998],
        [59.062199, 30.521445999999997],
        [59.061415, 30.518190999999998],
        [59.060013, 30.519712],
        [59.059919, 30.524753999999998],
        [59.050416999999996, 30.52508],
        [59.049268999999995, 30.506581999999998],
        [59.044309, 30.499368999999998],
        [59.044504999999994, 30.494906],
        [59.037558999999995, 30.489701],
        [59.030429, 30.464689999999997],
        [59.023885, 30.458395],
        [59.026238, 30.447359],
        [59.036358, 30.437531],
        [59.036994, 30.420987],
        [59.029109, 30.421616999999998],
        [59.036223, 30.418701],
        [59.033764, 30.413482],
        [59.032827999999995, 30.369816999999998],
        [59.030784999999995, 30.359137999999998],
        [59.026604999999996, 30.356191],
        [59.01387, 30.240827],
        [59.015128999999995, 30.227445],
        [59.017762, 30.224632],
        [59.019374, 30.216086999999998],
        [59.023941, 30.212336999999998],
        [59.023565, 30.209836],
        [59.020126, 30.209834999999998],
        [59.020502, 30.204417],
        [59.023672, 30.199312],
        [59.028614, 30.196499],
        [59.029581, 30.192956],
        [59.043918, 30.18556],
        [59.048831, 30.179882],
        [59.06155, 30.18103],
        [59.061336, 30.186187999999998],
        [59.063187, 30.188273],
        [59.066272, 30.186553999999997],
        [59.067254999999996, 30.19455],
        [59.181545, 30.142452],
        [59.174710999999995, 30.092948],
        [59.161609, 30.068952999999997],
        [59.157882, 30.029988999999997],
        [59.159878, 30.018729999999998],
        [59.159042, 30.006010999999997],
        [59.180828999999996, 30.007939],
        [59.181134, 30.001979],
        [59.198488999999995, 30.008146],
        [59.20101, 29.999906],
        [59.204373, 30.006202],
        [59.207831999999996, 30.005601],
        [59.208085999999994, 30.002032999999997],
        [59.209813, 30.001196],
        [59.210398999999995, 30.008056999999997],
        [59.221728, 30.010680999999998],
        [59.223307, 30.016848],
        [59.235211, 30.020827999999998],
        [59.237376999999995, 30.020421],
        [59.238338999999996, 30.011236999999998],
        [59.247713, 30.015207999999998],
        [59.252337999999995, 30.001773999999997],
        [59.253465, 30.004948],
        [59.261455999999995, 29.992542],
        [59.264123999999995, 29.996029999999998],
        [59.263681, 30.007361999999997],
        [59.268513999999996, 30.004226],
        [59.271386, 29.996686],
        [59.266791, 29.983233],
        [59.267561, 29.978574],
        [59.263915999999995, 29.97383],
        [59.269130999999994, 29.960708999999998],
        [59.267044999999996, 29.942287999999998],
        [59.268198999999996, 29.936968999999998],
        [59.270056999999994, 29.93753],
        [59.268904, 29.932374],
        [59.267345, 29.932491],
        [59.268639, 29.920689999999997],
        [59.265488, 29.92524],
        [59.264592, 29.919368],
        [59.263562, 29.923105999999997],
        [59.254239999999996, 29.916763],
        [59.253902, 29.909699999999997],
        [59.250426999999995, 29.908313999999997],
        [59.245473999999994, 29.898132],
        [59.242104, 29.890162999999998],
        [59.239776, 29.875670999999997],
        [59.236486, 29.869491],
        [59.236447, 29.863629999999997],
        [59.234061, 29.858925],
        [59.231058999999995, 29.837038],
        [59.227472999999996, 29.83063],
        [59.215944, 29.703253999999998],
        [59.259164, 29.661769999999997],
        [59.264289999999995, 29.66735],
        [59.291525, 29.664433],
        [59.299302, 29.660777999999997],
        [59.306098999999996, 29.678701],
        [59.310088, 29.682575999999997],
        [59.313404999999996, 29.698159999999998],
        [59.332839, 29.715000999999997],
        [59.325768, 29.724567],
        [59.340126999999995, 29.737333999999997],
        [59.340205, 29.757644],
        [59.359728, 29.757619],
        [59.359792999999996, 29.749271999999998],
        [59.367967, 29.749108],
        [59.368224999999995, 29.765128999999998],
        [59.372733, 29.777179],
        [59.368395, 29.785262],
        [59.368508, 29.797303],
        [59.377344, 29.812210999999998],
        [59.396786, 29.8023],
        [59.403527, 29.802343999999998],
        [59.409814, 29.833028],
        [59.431053, 29.803368],
        [59.453351, 29.781070999999997],
        [59.451169, 29.769277],
        [59.463769, 29.742836999999998],
        [59.468972, 29.741908],
        [59.472431, 29.724068],
        [59.482746, 29.734531999999998],
        [59.487209, 29.730746999999997],
        [59.496497, 29.697312999999998],
        [59.504709, 29.677808],
        [59.512273, 29.685872999999997],
        [59.518584, 29.67887],
        [59.524094, 29.686996999999998],
        [59.530640999999996, 29.67598],
        [59.540963, 29.674084999999998],
        [59.550729999999994, 29.666373999999998],
        [59.556746999999994, 29.667332],
        [59.571766999999994, 29.64651],
        [59.570235999999994, 29.630688],
        [59.564186, 29.609073],
        [59.577251, 29.604],
        [59.585505, 29.608045999999998],
        [59.588823999999995, 29.614762],
        [59.60051, 29.609503],
        [59.604219, 29.590844999999998],
        [59.606469999999995, 29.588874999999998]
      ]
    ]
  ]
}, {
  name: `Кингисеппский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.999523999999994, 26.661452999999998],
        [60.136758, 26.908087],
        [60.141777, 26.959518],
        [60.142253, 27.069791],
        [60.200317, 27.292088999999997],
        [60.223406, 27.456024],
        [60.388664999999996, 27.721145999999997],
        [60.039649999999995, 28.769123999999998],
        [59.782951, 28.849041],
        [59.759, 28.845855999999998],
        [59.759708999999994, 28.855752],
        [59.747902999999994, 28.873369999999998],
        [59.742518, 28.861441],
        [59.733064, 28.864492],
        [59.720167999999994, 28.890176],
        [59.702686, 28.892908],
        [59.692848999999995, 28.910968999999998],
        [59.668941999999994, 28.906232],
        [59.664725999999995, 28.945079999999997],
        [59.65929, 28.945643999999998],
        [59.650448, 28.965389],
        [59.644445999999995, 28.965148],
        [59.643432, 28.973817999999998],
        [59.636249, 28.983331],
        [59.631307, 28.982153],
        [59.620732, 29.005429999999997],
        [59.625012999999996, 29.015238999999998],
        [59.622887999999996, 29.09798],
        [59.625637999999995, 29.125253999999998],
        [59.606775, 29.10547],
        [59.599061999999996, 29.091116],
        [59.575907, 29.080948],
        [59.573896, 29.056275],
        [59.556084999999996, 29.062503999999997],
        [59.555445999999996, 29.033765],
        [59.545286, 29.024538],
        [59.545871, 29.018749999999997],
        [59.541163999999995, 29.012971],
        [59.546515, 29.001763999999998],
        [59.522385, 28.988239],
        [59.516406999999994, 28.975265],
        [59.521449999999994, 28.967872],
        [59.51269, 28.951155999999997],
        [59.501317, 28.983839],
        [59.492461999999996, 28.979274999999998],
        [59.477801, 28.991183999999997],
        [59.479279999999996, 28.951086999999998],
        [59.474258, 28.951392],
        [59.474112999999996, 28.940699],
        [59.469662, 28.944426],
        [59.445012, 28.90364],
        [59.428723, 28.947333999999998],
        [59.405846, 28.965826],
        [59.399626, 28.961045],
        [59.398896, 28.933142999999998],
        [59.391355999999995, 28.936473],
        [59.391199, 28.942089],
        [59.361691, 28.939000999999998],
        [59.358491, 28.943814999999997],
        [59.354568, 28.934105],
        [59.347331, 28.942660999999998],
        [59.343528, 28.940371],
        [59.343042, 28.922545999999997],
        [59.335234, 28.913542999999997],
        [59.302423999999995, 28.916065],
        [59.298815, 28.938036999999998],
        [59.291334, 28.958256],
        [59.274117999999994, 28.957549999999998],
        [59.263855, 28.965859],
        [59.246117999999996, 28.967841999999997],
        [59.237019, 28.948615],
        [59.236554999999996, 28.918058],
        [59.243947, 28.908185999999997],
        [59.241687999999996, 28.893897],
        [59.248915, 28.883381999999997],
        [59.249657, 28.868374],
        [59.247721999999996, 28.848398999999997],
        [59.235433, 28.847817],
        [59.226299, 28.851951],
        [59.212303, 28.872723999999998],
        [59.202022, 28.872915],
        [59.188309, 28.903454999999997],
        [59.183411, 28.905331],
        [59.184694, 28.908915],
        [59.174338999999996, 28.910985],
        [59.166194, 28.920779999999997],
        [59.158136, 28.894192],
        [59.144890999999994, 28.882623],
        [59.142075999999996, 28.873268999999997],
        [59.125899999999994, 28.854248],
        [59.125966999999996, 28.841013999999998],
        [59.113211, 28.793146999999998],
        [59.128961999999994, 28.773176],
        [59.156144999999995, 28.770267999999998],
        [59.159563, 28.760545],
        [59.173154, 28.76548],
        [59.167677, 28.780818999999997],
        [59.17044, 28.797466999999997],
        [59.173337, 28.803662],
        [59.176877999999995, 28.801005],
        [59.171532, 28.795844],
        [59.174853999999996, 28.736676],
        [59.180972, 28.716022],
        [59.167035, 28.699365],
        [59.158820999999996, 28.696659999999998],
        [59.151886, 28.679754],
        [59.146958, 28.681780999999997],
        [59.142851, 28.663505999999998],
        [59.140854999999995, 28.665027],
        [59.146046999999996, 28.703819],
        [59.124007999999996, 28.730186],
        [59.118542, 28.726836],
        [59.114020999999994, 28.712498999999998],
        [59.114213, 28.702446],
        [59.107527, 28.693136],
        [59.115080999999996, 28.689915],
        [59.113903, 28.686362],
        [59.127312999999994, 28.669715999999998],
        [59.137847, 28.666711],
        [59.139834, 28.656879999999997],
        [59.151323, 28.646787999999997],
        [59.147019, 28.621629],
        [59.149463999999995, 28.612028],
        [59.14769, 28.598408],
        [59.155564999999996, 28.569356],
        [59.153271999999994, 28.55812],
        [59.162577, 28.557288],
        [59.167722, 28.56292],
        [59.220313999999995, 28.547945],
        [59.233838, 28.556462999999997],
        [59.235685, 28.546191999999998],
        [59.245338, 28.533189],
        [59.267227, 28.532787],
        [59.282519, 28.523321],
        [59.286742, 28.498229],
        [59.293318, 28.497639],
        [59.299827, 28.488204],
        [59.298066999999996, 28.434562999999997],
        [59.304266999999996, 28.389825],
        [59.2876, 28.319239],
        [59.295888999999995, 28.292455999999998],
        [59.318979999999996, 28.158275],
        [59.33454, 28.168889999999998],
        [59.345369999999996, 28.19017],
        [59.352691, 28.191423999999998],
        [59.357639999999996, 28.200879999999998],
        [59.36105, 28.19926],
        [59.369474, 28.209294999999997],
        [59.375023, 28.203034],
        [59.383289, 28.20881],
        [59.399803999999996, 28.192916999999998],
        [59.41959, 28.139995],
        [59.438399999999994, 28.123369999999998],
        [59.457471999999996, 28.088513],
        [59.466265, 28.046975999999997],
        [59.475147, 28.040118999999997],
        [59.793777, 27.503649],
        [59.634485999999995, 26.987959999999998],
        [59.670277, 26.580239],
        [59.972007999999995, 26.661451]
      ]
    ]
  ]
}, {
  name: `Киришский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.374944, 31.596809999999998],
        [59.376524999999994, 31.598595],
        [59.378626999999994, 31.594663999999998],
        [59.382953, 31.594772],
        [59.391434, 31.578329],
        [59.396077999999996, 31.579258],
        [59.397242, 31.574506],
        [59.417918, 31.565880999999997],
        [59.421316, 31.578685],
        [59.425819, 31.584184999999998],
        [59.428394, 31.583858],
        [59.437405, 31.572408],
        [59.437461, 31.567425],
        [59.442656, 31.572765999999998],
        [59.444967, 31.583855999999997],
        [59.45428, 31.581072],
        [59.464074, 31.584021],
        [59.475995, 31.608912999999998],
        [59.536834, 31.602746999999997],
        [59.547422, 31.613183999999997],
        [59.556428, 31.608019],
        [59.580416, 31.634272],
        [59.600001999999996, 31.707811],
        [59.602016, 31.70976],
        [59.604417999999995, 31.723518],
        [59.61078, 31.726951],
        [59.613591, 31.733604999999997],
        [59.619583, 31.773881],
        [59.618679, 31.788687999999997],
        [59.620782999999996, 31.800825],
        [59.623193, 31.791338],
        [59.625254, 31.787401],
        [59.627551999999994, 31.787672999999998],
        [59.627097, 31.784333999999998],
        [59.630978, 31.774234],
        [59.630145999999996, 31.76656],
        [59.635481, 31.751735],
        [59.640575, 31.749121],
        [59.643111999999995, 31.755504],
        [59.653093999999996, 31.758725],
        [59.656568, 31.763545999999998],
        [59.665529, 31.767626999999997],
        [59.671589, 31.762656999999997],
        [59.672157999999996, 31.758236],
        [59.687242999999995, 31.732643],
        [59.689862, 31.761325999999997],
        [59.702656999999995, 31.759428999999997],
        [59.701631, 31.778162],
        [59.765136, 31.775613],
        [59.767312, 31.783049],
        [59.761241, 31.808248],
        [59.763723999999996, 31.810543],
        [59.766231, 31.819117],
        [59.765108, 31.834978999999997],
        [59.760355, 31.854487],
        [59.760424, 31.880032],
        [59.751677, 31.877329999999997],
        [59.749323, 31.883498],
        [59.748217999999994, 31.906273],
        [59.749627999999994, 31.920465999999998],
        [59.753781, 31.919079],
        [59.755184, 31.923295999999997],
        [59.756628, 31.918049999999997],
        [59.761908999999996, 31.917963999999998],
        [59.768625, 31.924021],
        [59.767199999999995, 31.942760999999997],
        [59.768819, 31.948214],
        [59.767019, 31.959705999999997],
        [59.764468, 31.956103],
        [59.759026999999996, 31.959985],
        [59.763282, 31.975652],
        [59.761443, 31.978005],
        [59.759389, 31.972462],
        [59.753336, 31.968873],
        [59.751417999999994, 31.971377999999998],
        [59.752784999999996, 31.984171],
        [59.748622, 31.988951999999998],
        [59.737821999999994, 31.977154],
        [59.739687999999994, 31.998466999999998],
        [59.743199, 31.994325999999997],
        [59.750301, 32.015066],
        [59.740068, 32.02107],
        [59.732206999999995, 32.038886],
        [59.729659999999996, 32.039339999999996],
        [59.723380999999996, 32.060195],
        [59.720675, 32.061669],
        [59.730818, 32.074549999999995],
        [59.730785, 32.079301],
        [59.734109999999994, 32.080231999999995],
        [59.734406, 32.100662],
        [59.732054999999995, 32.1015],
        [59.731263999999996, 32.108284999999995],
        [59.724776, 32.105394],
        [59.724973, 32.108529],
        [59.727345, 32.108191],
        [59.741839999999996, 32.121811],
        [59.748076999999995, 32.118024],
        [59.758961, 32.135292],
        [59.762062, 32.163665],
        [59.761374999999994, 32.174265],
        [59.756949, 32.175779999999996],
        [59.741276, 32.191902999999996],
        [59.740258, 32.197587999999996],
        [59.733574, 32.199298999999996],
        [59.731365999999994, 32.197037],
        [59.728913999999996, 32.201461],
        [59.72575, 32.219769],
        [59.721869999999996, 32.225649],
        [59.71863, 32.224876],
        [59.714152999999996, 32.229785],
        [59.705670999999995, 32.222243],
        [59.692366, 32.204402],
        [59.685821, 32.200120999999996],
        [59.675128, 32.184509999999996],
        [59.671327, 32.170553999999996],
        [59.670908, 32.157678],
        [59.660083, 32.148325],
        [59.658538, 32.155519],
        [59.660595, 32.183288],
        [59.663850999999994, 32.189219],
        [59.657121, 32.192254999999996],
        [59.655305, 32.203981],
        [59.648699, 32.222122],
        [59.660720999999995, 32.23079],
        [59.658338, 32.234997],
        [59.658550999999996, 32.239075],
        [59.663633999999995, 32.247642],
        [59.662755, 32.252548],
        [59.653769, 32.241495],
        [59.652139, 32.270282],
        [59.665661, 32.291812],
        [59.672650999999995, 32.298704],
        [59.673404, 32.303847999999995],
        [59.670319, 32.303816999999995],
        [59.670854999999996, 32.318519],
        [59.674955, 32.329184999999995],
        [59.674706, 32.334069],
        [59.663584, 32.365041999999995],
        [59.660072, 32.370618],
        [59.651998, 32.372651999999995],
        [59.646746, 32.381387],
        [59.641616, 32.383587],
        [59.639694999999996, 32.379069],
        [59.637646, 32.383359999999996],
        [59.633669, 32.382626],
        [59.633618, 32.363524],
        [59.62113, 32.349216999999996],
        [59.618882, 32.348669],
        [59.617262999999994, 32.359397],
        [59.600812999999995, 32.353617],
        [59.597657, 32.335149],
        [59.59932, 32.326494],
        [59.598222, 32.32316],
        [59.599455999999996, 32.324155999999995],
        [59.597854, 32.316124],
        [59.59562, 32.312667],
        [59.59153, 32.319404999999996],
        [59.59041, 32.37008],
        [59.585111999999995, 32.397915],
        [59.591978999999995, 32.420362],
        [59.592245999999996, 32.432928],
        [59.597994, 32.436929],
        [59.598158999999995, 32.447441999999995],
        [59.591953999999994, 32.455324],
        [59.592966999999994, 32.470551],
        [59.590075, 32.471911999999996],
        [59.590534999999996, 32.474537],
        [59.593348, 32.47423],
        [59.608706, 32.655242],
        [59.62592, 32.662861],
        [59.619992999999994, 32.778245999999996],
        [59.541559, 32.771139],
        [59.542061, 32.803185],
        [59.556920999999996, 32.844089],
        [59.536460999999996, 32.844497],
        [59.530051, 32.836283],
        [59.519014, 32.847094999999996],
        [59.509783, 32.844840999999995],
        [59.500201999999994, 32.848565],
        [59.495064, 32.84694],
        [59.489915999999994, 32.801384999999996],
        [59.47425, 32.799811999999996],
        [59.451257999999996, 32.777661],
        [59.441973999999995, 32.780587],
        [59.436690999999996, 32.787461],
        [59.428047, 32.829482],
        [59.438798, 32.880224999999996],
        [59.438969, 32.948595],
        [59.42776, 32.952388],
        [59.424212, 32.966153],
        [59.394081, 32.967169999999996],
        [59.391334, 32.970431],
        [59.383398, 32.960625],
        [59.371857, 32.938567],
        [59.362854, 32.93376],
        [59.347494, 32.919684],
        [59.347142999999996, 32.846728],
        [59.299305999999994, 32.773557],
        [59.286167, 32.764792],
        [59.284003999999996, 32.75226],
        [59.279194, 32.749095],
        [59.275988, 32.738174],
        [59.270863999999996, 32.737873],
        [59.266503, 32.744546],
        [59.262674999999994, 32.746134],
        [59.262916999999995, 32.735996],
        [59.260070999999996, 32.733388],
        [59.25973, 32.729977],
        [59.257746999999995, 32.72988],
        [59.247685999999995, 32.746049],
        [59.239255, 32.735898999999996],
        [59.233669, 32.745126],
        [59.229704999999996, 32.746113],
        [59.22888, 32.760093],
        [59.225379, 32.766365],
        [59.217183999999996, 32.769155],
        [59.21199, 32.763816999999996],
        [59.209207, 32.766906999999996],
        [59.206146999999994, 32.765941999999995],
        [59.205135, 32.760341],
        [59.202472, 32.760298],
        [59.199489, 32.767787],
        [59.189052999999994, 32.758839],
        [59.188193999999996, 32.764525],
        [59.179869, 32.757981],
        [59.175011999999995, 32.74693],
        [59.177082999999996, 32.740041999999995],
        [59.174169, 32.738068],
        [59.174279, 32.729152],
        [59.148483999999996, 32.727371],
        [59.147898999999995, 32.719657],
        [59.14441, 32.712973],
        [59.144923, 32.682815],
        [59.143071, 32.672869],
        [59.140915, 32.669607],
        [59.138942, 32.672278999999996],
        [59.13216, 32.667054],
        [59.132121, 32.660359],
        [59.128504, 32.663556],
        [59.120512, 32.657644999999995],
        [59.11729, 32.65286],
        [59.113692, 32.626232],
        [59.110105999999995, 32.624977],
        [59.115238999999995, 32.578764],
        [59.139883999999995, 32.582076],
        [59.131558, 32.568835],
        [59.120388, 32.537881],
        [59.113555999999996, 32.483429],
        [59.10843, 32.463542],
        [59.106944, 32.449019],
        [59.114146, 32.445087],
        [59.114912, 32.449256999999996],
        [59.117529, 32.449034],
        [59.116046, 32.430236],
        [59.122282999999996, 32.423764999999996],
        [59.123704, 32.427926],
        [59.130117, 32.419087],
        [59.129433, 32.412549],
        [59.131336, 32.409515],
        [59.132414, 32.399944],
        [59.140147, 32.393985],
        [59.140128, 32.389891],
        [59.141765, 32.390043999999996],
        [59.150543, 32.407454],
        [59.151219, 32.433201],
        [59.137277, 32.464275],
        [59.137223999999996, 32.474728],
        [59.137809, 32.477677],
        [59.151568999999995, 32.47654],
        [59.156658, 32.448811],
        [59.159259999999996, 32.422506999999996],
        [59.162468999999994, 32.423587],
        [59.160456999999994, 32.411052],
        [59.162859, 32.393457999999995],
        [59.165116, 32.387612],
        [59.168611, 32.385343999999996],
        [59.172745, 32.359322999999996],
        [59.17216, 32.349985],
        [59.18018, 32.331984999999996],
        [59.181166, 32.321107999999995],
        [59.187822, 32.306579],
        [59.195902999999994, 32.305139],
        [59.200782999999994, 32.282434],
        [59.211475, 32.279748],
        [59.215906, 32.286221],
        [59.214917, 32.302291],
        [59.221599999999995, 32.301698],
        [59.221261, 32.291264999999996],
        [59.224233, 32.28705],
        [59.233159, 32.30347],
        [59.232443999999994, 32.309688],
        [59.242445999999994, 32.317063],
        [59.246807, 32.314240999999996],
        [59.243403, 32.290898],
        [59.259028, 32.280206],
        [59.259600999999996, 32.318517],
        [59.263448999999994, 32.312616],
        [59.261776999999995, 32.298353],
        [59.262899, 32.292397],
        [59.269523, 32.301356999999996],
        [59.278437, 32.298435],
        [59.285298999999995, 32.287693],
        [59.288762999999996, 32.277217],
        [59.288379, 32.253267],
        [59.290352, 32.25333],
        [59.290417, 32.249932],
        [59.276120999999996, 32.220006999999995],
        [59.289637, 32.201108],
        [59.300863, 32.209038],
        [59.310551999999994, 32.235717],
        [59.316091, 32.234370999999996],
        [59.320712, 32.240895],
        [59.321497, 32.252649999999996],
        [59.325925999999995, 32.260663],
        [59.333346999999996, 32.261319],
        [59.342850999999996, 32.247065],
        [59.34366, 32.238521],
        [59.339852, 32.239098],
        [59.332251, 32.247912],
        [59.329148999999994, 32.245143999999996],
        [59.325793999999995, 32.255190999999996],
        [59.322469, 32.246207],
        [59.323181999999996, 32.240154],
        [59.317287, 32.231412999999996],
        [59.313554999999994, 32.232867999999996],
        [59.308111999999994, 32.216282],
        [59.30764, 32.213530999999996],
        [59.310116, 32.212973999999996],
        [59.307964, 32.203784999999996],
        [59.303681, 32.204333999999996],
        [59.30399, 32.191941],
        [59.310840999999996, 32.186292],
        [59.319651, 32.212243],
        [59.32664, 32.216014],
        [59.333355, 32.225546],
        [59.345453, 32.230272],
        [59.355399999999996, 32.226776],
        [59.355816999999995, 32.219426],
        [59.349025, 32.225156999999996],
        [59.347196999999994, 32.220525],
        [59.339369999999995, 32.224762999999996],
        [59.31885, 32.188477999999996],
        [59.328804, 32.165459],
        [59.333386, 32.168317],
        [59.371787, 32.115126],
        [59.382116999999994, 32.119639],
        [59.384415999999995, 32.103849],
        [59.381589, 32.096944],
        [59.386025, 32.080097],
        [59.390876999999996, 32.076798],
        [59.404771999999994, 32.033632],
        [59.393654999999995, 32.031359],
        [59.387795, 32.03396],
        [59.386896, 32.031746999999996],
        [59.400793, 32.016149999999996],
        [59.406459, 32.003386],
        [59.409575, 32.002448],
        [59.410562, 31.987154],
        [59.403993, 31.97638],
        [59.406585, 31.969856999999998],
        [59.410774999999994, 31.974940999999998],
        [59.410739, 31.977583],
        [59.411873, 31.976706],
        [59.412684, 31.982184999999998],
        [59.421803999999995, 31.992406],
        [59.433848999999995, 31.986212],
        [59.434407, 31.979187999999997],
        [59.432398, 31.967064999999998],
        [59.414359999999995, 31.932288],
        [59.403332, 31.921549],
        [59.397403, 31.921537999999998],
        [59.394265, 31.912931999999998],
        [59.390797, 31.917451999999997],
        [59.388003, 31.913304],
        [59.378842999999996, 31.908897],
        [59.373464, 31.90195],
        [59.369602, 31.889871999999997],
        [59.378183, 31.871571],
        [59.368863999999995, 31.877703999999998],
        [59.368423, 31.887749],
        [59.367065999999994, 31.888022999999997],
        [59.366381999999994, 31.867932],
        [59.360484, 31.861432999999998],
        [59.358427999999996, 31.835444],
        [59.353517999999994, 31.826916999999998],
        [59.353770999999995, 31.802777],
        [59.344449, 31.802311999999997],
        [59.338514999999994, 31.788124],
        [59.328098, 31.810039999999997],
        [59.328737999999994, 31.822706999999998],
        [59.327526, 31.822719],
        [59.325421999999996, 31.822536],
        [59.324887, 31.814066999999998],
        [59.32123, 31.808137],
        [59.313418, 31.80211],
        [59.312917999999996, 31.795144999999998],
        [59.315687, 31.793187],
        [59.314319999999995, 31.776381999999998],
        [59.316956999999995, 31.77406],
        [59.315884999999994, 31.764212999999998],
        [59.31959, 31.76065],
        [59.320733, 31.753351],
        [59.315231999999995, 31.741304999999997],
        [59.322581, 31.725535999999998],
        [59.331011999999994, 31.754631999999997],
        [59.334129999999995, 31.759725],
        [59.334295, 31.755630999999997],
        [59.339369, 31.760848],
        [59.342715999999996, 31.747401999999997],
        [59.340391999999994, 31.733565],
        [59.336994, 31.734061999999998],
        [59.335429, 31.711565999999998],
        [59.343875, 31.701283999999998],
        [59.342473999999996, 31.73221],
        [59.344403, 31.736452999999997],
        [59.348211, 31.735901],
        [59.348087, 31.706681999999997],
        [59.360924, 31.687541999999997],
        [59.361522, 31.650271],
        [59.367374999999996, 31.640051999999997],
        [59.375142999999994, 31.616328999999997],
        [59.376512999999996, 31.607772999999998]
      ]
    ]
  ]
}, {
  name: `Кировский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.779413999999996, 30.735962999999998],
        [59.776484999999994, 30.742952],
        [59.768725999999994, 30.751023],
        [59.767272999999996, 30.762317999999997],
        [59.770880999999996, 30.77236],
        [59.772515999999996, 30.786745999999997],
        [59.784963, 30.793650999999997],
        [59.796647, 30.805833],
        [59.804334999999995, 30.831061],
        [59.81075, 30.842823],
        [59.814975, 30.869505999999998],
        [59.81467, 30.886982],
        [59.818524, 30.914243],
        [59.818098, 30.932202999999998],
        [59.820401999999994, 30.939871999999998],
        [59.831633, 30.948369999999997],
        [59.840564, 30.945102],
        [59.847052999999995, 30.946711999999998],
        [59.858779999999996, 30.962363999999997],
        [59.873335999999995, 30.973433],
        [59.883928, 30.975509],
        [59.904357999999995, 30.986683],
        [59.916379, 30.983826999999998],
        [59.934903999999996, 30.993273],
        [59.938398, 30.998492],
        [59.944722999999996, 31.019198999999997],
        [59.955594999999995, 31.037793999999998],
        [59.956376999999996, 31.048410999999998],
        [60.261173, 31.575243999999998],
        [60.213944, 31.681635999999997],
        [60.194827, 31.704924],
        [60.179224999999995, 31.709114],
        [60.179199, 31.713735999999997],
        [60.167035999999996, 31.722275],
        [60.166833999999994, 31.823656],
        [60.161024, 31.815797],
        [60.157683999999996, 31.806497999999998],
        [60.146671999999995, 31.804078999999998],
        [60.146668999999996, 31.856016999999998],
        [60.140747999999995, 31.883395],
        [60.075725, 31.877979999999997],
        [60.074405999999996, 31.892929],
        [60.047884999999994, 31.908012],
        [60.045666999999995, 31.91435],
        [60.027609, 31.938709999999997],
        [60.018190999999995, 31.93554],
        [60.013034999999995, 31.940562],
        [60.011291, 31.926517999999998],
        [60.006755, 31.926564],
        [60.000007999999994, 31.938163999999997],
        [59.993466, 31.942345999999997],
        [59.992452, 31.932896999999997],
        [59.983872999999996, 31.904166],
        [59.975721, 31.898701],
        [59.974562, 31.893964999999998],
        [59.969885999999995, 31.898486],
        [59.9633, 31.91557],
        [59.955887, 31.916915],
        [59.951293, 31.921412],
        [59.950137999999995, 31.942655],
        [59.93828, 31.952309999999997],
        [59.917834, 31.951103999999997],
        [59.915324999999996, 31.958232],
        [59.915876999999995, 31.966941],
        [59.924676, 31.990185999999998],
        [59.927094, 31.991788],
        [59.928888, 32.003963999999996],
        [59.927332, 32.012606],
        [59.923075999999995, 32.017334],
        [59.915602, 32.018848999999996],
        [59.897191, 32.006724999999996],
        [59.896791, 32.00214],
        [59.889941, 31.998375999999997],
        [59.880761, 32.000991],
        [59.87143, 31.99925],
        [59.863293999999996, 32.005162],
        [59.862925999999995, 31.998154999999997],
        [59.831965, 32.007301999999996],
        [59.819032, 32.023281],
        [59.805155, 32.019577999999996],
        [59.802315, 32.025178],
        [59.794897, 32.01217],
        [59.79382, 32.037414],
        [59.777248, 32.032734],
        [59.775766999999995, 32.030038],
        [59.775921999999994, 32.017285],
        [59.752147, 32.017645],
        [59.750301, 32.015066],
        [59.743199, 31.994325999999997],
        [59.739687999999994, 31.998466999999998],
        [59.737821999999994, 31.977154],
        [59.748622, 31.988951999999998],
        [59.752784999999996, 31.984171],
        [59.751417999999994, 31.971377999999998],
        [59.753336, 31.968873],
        [59.759389, 31.972462],
        [59.761443, 31.978005],
        [59.763282, 31.975652],
        [59.759026999999996, 31.959985],
        [59.764468, 31.956103],
        [59.767019, 31.959705999999997],
        [59.768819, 31.948214],
        [59.767199999999995, 31.942760999999997],
        [59.768625, 31.924021],
        [59.761908999999996, 31.917963999999998],
        [59.756628, 31.918049999999997],
        [59.755184, 31.923295999999997],
        [59.753781, 31.919079],
        [59.749627999999994, 31.920465999999998],
        [59.748217999999994, 31.906273],
        [59.749323, 31.883498],
        [59.751677, 31.877329999999997],
        [59.760424, 31.880032],
        [59.760355, 31.854487],
        [59.765108, 31.834978999999997],
        [59.766231, 31.819117],
        [59.763723999999996, 31.810543],
        [59.761241, 31.808248],
        [59.767312, 31.783049],
        [59.765136, 31.775613],
        [59.701631, 31.778162],
        [59.702656999999995, 31.759428999999997],
        [59.689862, 31.761325999999997],
        [59.687242999999995, 31.732643],
        [59.672157999999996, 31.758236],
        [59.671589, 31.762656999999997],
        [59.665529, 31.767626999999997],
        [59.656568, 31.763545999999998],
        [59.653093999999996, 31.758725],
        [59.643111999999995, 31.755504],
        [59.640575, 31.749121],
        [59.635481, 31.751735],
        [59.630145999999996, 31.76656],
        [59.630978, 31.774234],
        [59.627097, 31.784333999999998],
        [59.627551999999994, 31.787672999999998],
        [59.625254, 31.787401],
        [59.623193, 31.791338],
        [59.620782999999996, 31.800825],
        [59.618679, 31.788687999999997],
        [59.619583, 31.773881],
        [59.614717999999996, 31.737723],
        [59.61078, 31.726951],
        [59.604417999999995, 31.723518],
        [59.602016, 31.70976],
        [59.600001999999996, 31.707811],
        [59.580416, 31.634272],
        [59.556428, 31.608019],
        [59.547422, 31.613183999999997],
        [59.536834, 31.602746999999997],
        [59.516059999999996, 31.56128],
        [59.514395, 31.552191999999998],
        [59.508947, 31.549245],
        [59.507172, 31.551094],
        [59.49619, 31.534426999999997],
        [59.508525, 31.520519],
        [59.514793, 31.516185999999998],
        [59.518587999999994, 31.518293999999997],
        [59.526906999999994, 31.504132],
        [59.532945, 31.505799999999997],
        [59.539296, 31.497632],
        [59.541323999999996, 31.486053],
        [59.537068999999995, 31.474304999999998],
        [59.538047, 31.467416999999998],
        [59.536879, 31.454442],
        [59.541365, 31.418646],
        [59.541494, 31.386392999999998],
        [59.542843, 31.384921],
        [59.537602, 31.350821],
        [59.53514, 31.350906],
        [59.531769, 31.340436999999998],
        [59.533321, 31.329532999999998],
        [59.547491, 31.300531999999997],
        [59.551364, 31.305415999999997],
        [59.550703999999996, 31.310309999999998],
        [59.552066999999994, 31.312697999999997],
        [59.559025, 31.306016999999997],
        [59.559785, 31.298479999999998],
        [59.561783999999996, 31.297416],
        [59.561764999999994, 31.292852999999997],
        [59.564294, 31.288891],
        [59.575955, 31.287253999999997],
        [59.578986, 31.278610999999998],
        [59.583918, 31.275408],
        [59.590185999999996, 31.27829],
        [59.594955, 31.274192],
        [59.605577, 31.273660999999997],
        [59.607367999999994, 31.272676999999998],
        [59.607327, 31.268952],
        [59.609583, 31.273540999999998],
        [59.615916999999996, 31.271490999999997],
        [59.616116999999996, 31.26839],
        [59.616884, 31.270951999999998],
        [59.619167999999995, 31.269192],
        [59.621424, 31.279374999999998],
        [59.62578, 31.280503999999997],
        [59.626906999999996, 31.273408],
        [59.628563, 31.272471999999997],
        [59.626179, 31.26192],
        [59.631479, 31.255941999999997],
        [59.635467, 31.259075],
        [59.636444999999995, 31.255619999999997],
        [59.634358999999996, 31.253280999999998],
        [59.638352999999995, 31.243579999999998],
        [59.643134999999994, 31.246958],
        [59.645267999999994, 31.252919],
        [59.64922, 31.252163],
        [59.649054, 31.234862999999997],
        [59.63594, 31.208517999999998],
        [59.636841999999994, 31.20111],
        [59.640722999999994, 31.200014999999997],
        [59.640736, 31.196612],
        [59.637747999999995, 31.189981999999997],
        [59.632799, 31.194012999999998],
        [59.634654, 31.203650999999997],
        [59.632726999999996, 31.206764999999997],
        [59.628875, 31.20129],
        [59.629625999999995, 31.188105],
        [59.6275, 31.181942999999997],
        [59.631606, 31.17787],
        [59.633112999999994, 31.183232999999998],
        [59.636033, 31.183017],
        [59.636193, 31.174947],
        [59.632473, 31.170219],
        [59.634243999999995, 31.159439],
        [59.625049, 31.158582],
        [59.625097999999994, 31.150143999999997],
        [59.630078999999995, 31.149745],
        [59.629825999999994, 31.121131],
        [59.632042, 31.092003],
        [59.633607999999995, 31.093839],
        [59.635498, 31.091506],
        [59.636655999999995, 31.082304999999998],
        [59.636882, 31.047855],
        [59.634409, 31.043633999999997],
        [59.633210999999996, 31.035989999999998],
        [59.634187999999995, 31.032380999999997],
        [59.632104999999996, 31.023688],
        [59.632259, 31.016517999999998],
        [59.635524999999994, 31.004109],
        [59.631764999999994, 30.984249],
        [59.634512, 30.966883],
        [59.645443, 30.958717999999998],
        [59.655446, 30.961789999999997],
        [59.667437, 30.922062999999998],
        [59.674614, 30.948007999999998],
        [59.672779999999996, 30.957829999999998],
        [59.681382, 30.967475999999998],
        [59.690099999999994, 30.970717999999998],
        [59.691381, 30.973923],
        [59.715081999999995, 30.96868],
        [59.726259999999996, 30.963113999999997],
        [59.699441, 30.916141],
        [59.722578999999996, 30.941879999999998],
        [59.718135999999994, 30.891053],
        [59.716581999999995, 30.852037],
        [59.726237999999995, 30.852688999999998],
        [59.727944, 30.856438999999998],
        [59.731597, 30.856973],
        [59.736613999999996, 30.850465],
        [59.737024, 30.840377],
        [59.731961999999996, 30.802912],
        [59.718947, 30.806348999999997],
        [59.715894999999996, 30.795060999999997],
        [59.721, 30.794822999999997],
        [59.726186, 30.784024],
        [59.728141, 30.785997],
        [59.727470999999994, 30.78953],
        [59.732752999999995, 30.786959],
        [59.732769, 30.777131999999998],
        [59.726884999999996, 30.776708],
        [59.726130999999995, 30.764360999999997],
        [59.729006, 30.763230999999998],
        [59.7313, 30.757158999999998],
        [59.738730999999994, 30.760564],
        [59.750884, 30.748962],
        [59.754470999999995, 30.759501],
        [59.756443, 30.757661],
        [59.755458, 30.748787999999998],
        [59.757399, 30.741263999999997],
        [59.759530999999996, 30.737173],
        [59.765066, 30.740159],
        [59.769341, 30.734581],
        [59.770982999999994, 30.726772999999998]
      ]
    ]
  ]
}, {
  name: `Лодейнопольский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.846326, 31.899016],
        [60.833669, 31.997864999999997],
        [60.797155, 32.223452],
        [60.679621, 32.913509999999995],
        [60.710302999999996, 32.923184],
        [60.719766, 33.019327],
        [60.717895, 33.064288],
        [60.737474999999996, 33.15725],
        [60.75839, 33.156492],
        [60.786665, 33.174153],
        [60.794689999999996, 33.265979],
        [60.797470999999994, 33.267934],
        [60.8001, 33.264524],
        [60.79851, 33.258866],
        [60.807838, 33.245695],
        [60.806779999999996, 33.211687999999995],
        [60.814333, 33.185611],
        [60.821864, 33.179963],
        [60.825542, 33.20126],
        [60.830009, 33.209042],
        [60.834896, 33.199462],
        [60.856812, 33.205417],
        [60.853238999999995, 33.213812],
        [60.844547, 33.219631],
        [60.843942999999996, 33.228165],
        [60.849295999999995, 33.238681],
        [60.854699, 33.238197],
        [60.867689, 33.260555],
        [60.874131, 33.250454],
        [60.888174, 33.291897],
        [60.887273, 33.302873],
        [60.900076, 33.331754],
        [60.898295, 33.350929],
        [60.900079999999996, 33.361321],
        [60.911024, 33.386905],
        [60.914362999999994, 33.426366],
        [60.925506999999996, 33.445268999999996],
        [60.928521999999994, 33.467760999999996],
        [60.952253999999996, 33.467206],
        [60.983838, 33.423423],
        [61.004256, 33.448622],
        [61.002767, 33.507873],
        [61.006035, 33.523317999999996],
        [61.004946999999994, 33.543894],
        [60.984762999999994, 33.546603999999995],
        [60.975176999999995, 33.590359],
        [60.958791999999995, 33.608540999999995],
        [60.956838999999995, 33.692538],
        [60.94059, 33.721665],
        [60.936921999999996, 33.800848],
        [60.939007999999994, 33.812537],
        [60.927634, 33.832001999999996],
        [60.926261999999994, 33.840406],
        [60.902403, 33.814547999999995],
        [60.890792, 33.80846],
        [60.881888, 33.780350999999996],
        [60.871891999999995, 33.764680999999996],
        [60.85594, 33.777046],
        [60.835266999999995, 33.749092],
        [60.805158, 33.878240999999996],
        [60.813945999999994, 33.889236],
        [60.814102999999996, 33.925865],
        [60.816503999999995, 33.929083999999996],
        [60.814201, 33.928612],
        [60.814322999999995, 33.957817],
        [60.811598999999994, 33.957536],
        [60.810770999999995, 33.927293999999996],
        [60.793026999999995, 33.91876],
        [60.794002, 33.930538999999996],
        [60.7919, 33.932638],
        [60.789732, 33.922824],
        [60.784315, 33.918977999999996],
        [60.782413999999996, 33.909196],
        [60.77813, 33.904685],
        [60.757344999999994, 33.906264],
        [60.749157999999994, 34.076952999999996],
        [60.714166, 34.071601],
        [60.713502, 34.10853],
        [60.705827, 34.107749],
        [60.714113999999995, 34.127052],
        [60.718351999999996, 34.126824],
        [60.719355, 34.166],
        [60.714459, 34.182314999999996],
        [60.693756, 34.221193],
        [60.681529, 34.222969],
        [60.670857, 34.215852],
        [60.666242, 34.222957],
        [60.650835, 34.198449],
        [60.642877, 34.228079],
        [60.640071999999996, 34.227235],
        [60.632189, 34.221661],
        [60.632106, 34.21561],
        [60.628769, 34.216175],
        [60.626275, 34.20518],
        [60.6188, 34.200860999999996],
        [60.613068999999996, 34.188046],
        [60.611248999999994, 34.171704999999996],
        [60.611388, 34.195445],
        [60.603958999999996, 34.203706],
        [60.606514999999995, 34.214698],
        [60.605776999999996, 34.228924],
        [60.608795, 34.232813],
        [60.610837999999994, 34.249958],
        [60.609784, 34.268616],
        [60.612379999999995, 34.295655],
        [60.608523, 34.297691],
        [60.598873, 34.287788],
        [60.598212, 34.27411],
        [60.573422, 34.272262],
        [60.574186999999995, 34.237715],
        [60.555606999999995, 34.234919],
        [60.552924999999995, 34.27572],
        [60.55493, 34.292241],
        [60.55379, 34.319264],
        [60.561640999999995, 34.347349],
        [60.557935, 34.361331],
        [60.557143999999994, 34.396784],
        [60.536407999999994, 34.387824],
        [60.521027, 34.320295],
        [60.515918, 34.342082999999995],
        [60.517115, 34.346951],
        [60.496291, 34.347532],
        [60.482811, 34.353936],
        [60.486182, 34.368358],
        [60.475322, 34.386945],
        [60.469784999999995, 34.388920999999996],
        [60.464791999999996, 34.380479],
        [60.46543, 34.398787],
        [60.440642999999994, 34.407585999999995],
        [60.432748, 34.437464999999996],
        [60.433467, 34.452993],
        [60.404838999999996, 34.459036999999995],
        [60.411248, 34.531928],
        [60.338339, 34.558529],
        [60.341528, 34.592037],
        [60.339721999999995, 34.593525],
        [60.338936999999994, 34.585958],
        [60.324241, 34.589504999999996],
        [60.314034, 34.617090999999995],
        [60.31597, 34.638666],
        [60.290710999999995, 34.647963],
        [60.281285, 34.54072],
        [60.267542, 34.512980999999996],
        [60.253322999999995, 34.50501],
        [60.254304999999995, 34.499463999999996],
        [60.242121, 34.495225],
        [60.241899, 34.48117],
        [60.239652, 34.479256],
        [60.232122999999994, 34.495939],
        [60.188872999999994, 34.530626999999996],
        [60.183028, 34.506302],
        [60.177633, 34.499587999999996],
        [60.173916, 34.486155],
        [60.173218, 34.467074],
        [60.168029, 34.452249],
        [60.167865, 34.437095],
        [60.157973999999996, 34.423645],
        [60.161159999999995, 34.180893999999995],
        [60.157304999999994, 34.162262999999996],
        [60.155727999999996, 34.170097],
        [60.152335, 34.163548],
        [60.145835999999996, 34.164618],
        [60.139858, 34.108328],
        [60.152333, 34.111667],
        [60.158679, 34.124635999999995],
        [60.165796, 34.116005],
        [60.164784999999995, 34.098479],
        [60.159524, 34.09512],
        [60.15777, 34.086175],
        [60.161313, 34.057629999999996],
        [60.164769, 34.053129],
        [60.167817, 33.970791],
        [60.159417999999995, 33.976189],
        [60.152235999999995, 33.952788999999996],
        [60.15658, 33.937025999999996],
        [60.147495, 33.910517999999996],
        [60.158300999999994, 33.903624],
        [60.15548, 33.895528],
        [60.163506, 33.864849],
        [60.16316, 33.835457],
        [60.169779, 33.832957],
        [60.165941999999994, 33.819885],
        [60.159017999999996, 33.816643],
        [60.158376999999994, 33.797816],
        [60.151022999999995, 33.804249999999996],
        [60.150534, 33.794748999999996],
        [60.155094, 33.785053],
        [60.162946999999996, 33.787251999999995],
        [60.162079999999996, 33.745599999999996],
        [60.166866, 33.746732],
        [60.168347, 33.699774],
        [60.173114, 33.704623999999995],
        [60.192929, 33.70519],
        [60.216746, 33.687779],
        [60.221219999999995, 33.676107],
        [60.236145, 33.671869],
        [60.237348, 33.647513],
        [60.241008, 33.645621999999996],
        [60.25069, 33.65389],
        [60.251653, 33.694961],
        [60.256504, 33.704046],
        [60.252967, 33.714493],
        [60.254303, 33.730737999999995],
        [60.258502, 33.732276],
        [60.274609, 33.696231],
        [60.299065999999996, 33.690998],
        [60.299229, 33.670079],
        [60.294701999999994, 33.66994],
        [60.292215, 33.660312999999995],
        [60.278254999999994, 33.643412],
        [60.28324, 33.627898],
        [60.292587, 33.622088999999995],
        [60.299108999999994, 33.608592],
        [60.300588, 33.53844],
        [60.358183999999994, 33.544543],
        [60.358605, 33.525998],
        [60.368252999999996, 33.527087],
        [60.369006, 33.507529],
        [60.38778, 33.509893999999996],
        [60.388177, 33.490339999999996],
        [60.400138, 33.490663],
        [60.399238999999994, 33.472035],
        [60.390214, 33.470419],
        [60.388535999999995, 33.369974],
        [60.392433999999994, 33.197023],
        [60.388797, 33.196174],
        [60.390564999999995, 33.132989],
        [60.395433, 33.133229],
        [60.392472999999995, 33.120841],
        [60.395849, 33.121187],
        [60.398661999999995, 33.111250999999996],
        [60.404574, 33.111371],
        [60.407712, 33.101551],
        [60.420493, 33.108893],
        [60.422276999999994, 33.104563],
        [60.421769, 33.091909],
        [60.423503999999994, 33.094964],
        [60.422079, 33.089994],
        [60.430664, 33.04182],
        [60.424346, 33.040276],
        [60.411924, 33.048476],
        [60.412865, 33.04117],
        [60.418814999999995, 33.032821],
        [60.420970999999994, 33.011517],
        [60.424402, 33.00589],
        [60.430364999999995, 33.009606],
        [60.4345, 33.006595],
        [60.444314999999996, 32.984381],
        [60.452655, 32.976341999999995],
        [60.454262, 32.967378],
        [60.452259, 32.94795],
        [60.459317, 32.933364],
        [60.470321, 32.928854],
        [60.474847999999994, 32.951204],
        [60.47517, 32.939054],
        [60.490041, 32.929528999999995],
        [60.4815, 32.875834],
        [60.481091, 32.858011],
        [60.484868999999996, 32.840223],
        [60.493747, 32.841587],
        [60.496683, 32.847538],
        [60.511602999999994, 32.854639],
        [60.526934999999995, 32.850685]
      ]
    ]
  ]
}, {
  name: `Ломоносовский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.039649999999995, 28.769123999999998],
        [60.049611, 29.549829],
        [59.92821, 29.647817999999997],
        [59.926989, 29.659983],
        [59.925086, 29.661353],
        [59.91674, 29.65625],
        [59.914671, 29.66171],
        [59.91018, 29.692971],
        [59.900794999999995, 29.724425],
        [59.893439, 29.729456],
        [59.888113999999995, 29.716072999999998],
        [59.873376, 29.718738],
        [59.870297, 29.727323],
        [59.871815999999995, 29.738446999999997],
        [59.871447999999994, 29.751258],
        [59.86813, 29.75376],
        [59.868803, 29.76712],
        [59.871072999999996, 29.772565999999998],
        [59.858917999999996, 29.800513],
        [59.868913, 29.823587999999997],
        [59.865705999999996, 29.83004],
        [59.866155, 29.834111999999998],
        [59.868333, 29.834153999999998],
        [59.868044, 29.836491],
        [59.862086, 29.860021999999997],
        [59.858469, 29.859526],
        [59.853789, 29.86741],
        [59.858532999999994, 29.874094999999997],
        [59.852650999999994, 29.879495],
        [59.84721, 29.875408],
        [59.847443, 29.878835],
        [59.843819999999994, 29.879607999999998],
        [59.842797, 29.88602],
        [59.853885, 29.892438],
        [59.853429999999996, 29.894925999999998],
        [59.854609999999994, 29.895494],
        [59.853705999999995, 29.903709],
        [59.850114, 29.902074],
        [59.849025, 29.909292999999998],
        [59.851177, 29.910089],
        [59.844435, 29.9451],
        [59.830507, 29.936633999999998],
        [59.830459999999995, 29.942925],
        [59.828875999999994, 29.944267],
        [59.835142, 29.979073999999997],
        [59.833427, 29.992285],
        [59.83712, 30.00375],
        [59.835027, 30.014877],
        [59.836174, 30.017649],
        [59.833164, 30.021407],
        [59.837523999999995, 30.030220999999997],
        [59.837337999999995, 30.044504],
        [59.832406999999996, 30.042558],
        [59.83061, 30.039165999999998],
        [59.825328, 30.041044],
        [59.822534, 30.037900999999998],
        [59.817805, 30.047092],
        [59.817567999999994, 30.057785],
        [59.815189999999994, 30.068203999999998],
        [59.816143, 30.085928],
        [59.814768, 30.090653],
        [59.817412, 30.104788],
        [59.809168, 30.127899],
        [59.804455, 30.123329],
        [59.80285, 30.105075],
        [59.797134, 30.105666],
        [59.796623, 30.099263999999998],
        [59.782669999999996, 30.097248999999998],
        [59.77939, 30.08371],
        [59.775824, 30.084588],
        [59.766467, 30.057995],
        [59.765463999999994, 30.045287],
        [59.764182, 30.04338],
        [59.762378, 30.043775],
        [59.763272, 30.056552],
        [59.761889999999994, 30.057064999999998],
        [59.762159, 30.060567],
        [59.757014999999996, 30.062026],
        [59.755992, 30.071816],
        [59.755230999999995, 30.066582],
        [59.752759999999995, 30.068247],
        [59.752147, 30.062531999999997],
        [59.749430999999994, 30.064496],
        [59.749719, 30.070776],
        [59.747867, 30.076217],
        [59.741718999999996, 30.062151],
        [59.735329, 30.054803999999997],
        [59.733875999999995, 30.056182999999997],
        [59.730011999999995, 30.050034],
        [59.719485, 30.045737],
        [59.720655, 30.038517],
        [59.718289, 30.035041999999997],
        [59.715385, 30.040974],
        [59.717375999999994, 30.04986],
        [59.713003, 30.055455],
        [59.716696, 30.071140999999997],
        [59.715813, 30.081239],
        [59.699121, 30.100967999999998],
        [59.699135, 30.106762],
        [59.700641999999995, 30.109049],
        [59.698468999999996, 30.112800999999997],
        [59.699115, 30.115862999999997],
        [59.694067, 30.112944],
        [59.694323999999995, 30.117801999999998],
        [59.693407, 30.116978],
        [59.691863, 30.121641],
        [59.696273999999995, 30.135924],
        [59.700365, 30.135413],
        [59.700368, 30.137507],
        [59.706678, 30.13274],
        [59.706751, 30.124371999999997],
        [59.708946, 30.122522],
        [59.707927, 30.120487999999998],
        [59.713339999999995, 30.112713],
        [59.723416, 30.110141],
        [59.724444999999996, 30.107059999999997],
        [59.729202, 30.104774],
        [59.731023, 30.108898999999997],
        [59.735419, 30.111653999999998],
        [59.737727, 30.108798],
        [59.741485, 30.115897999999998],
        [59.746458, 30.119574],
        [59.762198, 30.116906],
        [59.761167, 30.14179],
        [59.77173, 30.152753999999998],
        [59.774418, 30.145947],
        [59.784938999999994, 30.155379999999997],
        [59.786578999999996, 30.154097],
        [59.788008999999995, 30.158969],
        [59.792628, 30.15897],
        [59.796302999999995, 30.162878],
        [59.796735999999996, 30.154206],
        [59.785394, 30.140074],
        [59.785975, 30.137838],
        [59.782042, 30.133148],
        [59.782441, 30.129669999999997],
        [59.800787, 30.150063],
        [59.798691999999996, 30.156581],
        [59.801282, 30.159605],
        [59.795936999999995, 30.176357],
        [59.793316, 30.179734],
        [59.790935, 30.178932],
        [59.791439, 30.188031],
        [59.797672999999996, 30.211592],
        [59.789896999999996, 30.269257999999997],
        [59.794577, 30.269783],
        [59.795334, 30.282128999999998],
        [59.792052999999996, 30.287402999999998],
        [59.778774999999996, 30.270605999999997],
        [59.777612999999995, 30.273629999999997],
        [59.774704, 30.268632],
        [59.773928, 30.270951999999998],
        [59.770682, 30.264118999999997],
        [59.762542999999994, 30.258515],
        [59.747329, 30.300734],
        [59.735958999999994, 30.292023],
        [59.739340999999996, 30.279535],
        [59.729212, 30.2836],
        [59.723330999999995, 30.272491],
        [59.720569, 30.270742],
        [59.718621999999996, 30.279441],
        [59.715188999999995, 30.275965],
        [59.716556, 30.269658999999997],
        [59.701674999999994, 30.255805],
        [59.692949999999996, 30.235211],
        [59.691849, 30.237056],
        [59.689353999999994, 30.231465999999998],
        [59.685987, 30.236517],
        [59.684084, 30.229112999999998],
        [59.673123999999994, 30.218650999999998],
        [59.671538999999996, 30.214736],
        [59.674079, 30.210198],
        [59.671735, 30.203740999999997],
        [59.668873, 30.185651],
        [59.677045, 30.156792],
        [59.679747, 30.155469],
        [59.686158999999996, 30.138647],
        [59.681712999999995, 30.121201],
        [59.685742999999995, 30.118129999999997],
        [59.683495, 30.105788999999998],
        [59.68244, 30.085144999999997],
        [59.678349999999995, 30.060948999999997],
        [59.678782, 30.044407],
        [59.672267999999995, 30.042241999999998],
        [59.674004999999994, 30.034903],
        [59.671786, 30.033914],
        [59.672000999999995, 30.017701],
        [59.663850999999994, 30.017343],
        [59.663855999999996, 30.012089],
        [59.655640999999996, 30.014152],
        [59.655547999999996, 30.002754],
        [59.678832, 29.986147],
        [59.675931, 29.929087],
        [59.687689999999996, 29.917016999999998],
        [59.680502999999995, 29.885278999999997],
        [59.671602, 29.867032],
        [59.666484999999994, 29.862581],
        [59.664736, 29.867397999999998],
        [59.655972999999996, 29.86893],
        [59.650504, 29.85389],
        [59.635365, 29.846731],
        [59.630680999999996, 29.839657],
        [59.627421999999996, 29.829462],
        [59.638268999999994, 29.831598],
        [59.637755999999996, 29.827918999999998],
        [59.632683, 29.819969],
        [59.641092, 29.799287999999997],
        [59.645049, 29.802508],
        [59.649511999999994, 29.796761],
        [59.646404999999994, 29.783082],
        [59.647399, 29.770715],
        [59.643636, 29.757859999999997],
        [59.630810999999994, 29.753753],
        [59.636106999999996, 29.740326],
        [59.635321, 29.693986],
        [59.645516, 29.691684],
        [59.646941, 29.695528],
        [59.649983, 29.695189],
        [59.648711999999996, 29.669905],
        [59.643136, 29.670697999999998],
        [59.641605999999996, 29.653449],
        [59.647431999999995, 29.652269],
        [59.644203, 29.636951],
        [59.636199999999995, 29.635693999999997],
        [59.633649, 29.618230999999998],
        [59.626162, 29.610198999999998],
        [59.624230999999995, 29.599110999999997],
        [59.627649, 29.590484],
        [59.628695, 29.582518],
        [59.630049, 29.585026],
        [59.633401, 29.582814],
        [59.638225999999996, 29.589456],
        [59.643943, 29.577312],
        [59.644099, 29.56805],
        [59.646938, 29.567435],
        [59.647607, 29.557298],
        [59.646505999999995, 29.551968],
        [59.644231, 29.552429999999998],
        [59.642418, 29.542410999999998],
        [59.644341, 29.542042],
        [59.642886999999995, 29.535218999999998],
        [59.64691, 29.523287999999997],
        [59.656554, 29.521570999999998],
        [59.660472999999996, 29.525907],
        [59.662552, 29.531778],
        [59.664631, 29.531602999999997],
        [59.669959, 29.52874],
        [59.6757, 29.52056],
        [59.674344999999995, 29.503375],
        [59.662003, 29.499222999999997],
        [59.659523, 29.491011999999998],
        [59.663484999999994, 29.485422],
        [59.668763999999996, 29.486541],
        [59.677349, 29.473906],
        [59.676232, 29.455184],
        [59.673472999999994, 29.457662],
        [59.669594999999994, 29.453550999999997],
        [59.665993, 29.442743999999998],
        [59.660173, 29.435067],
        [59.657475999999996, 29.422587],
        [59.646893999999996, 29.408279999999998],
        [59.644489, 29.39818],
        [59.650003, 29.388202999999997],
        [59.652516, 29.388682],
        [59.651539, 29.366979999999998],
        [59.657778, 29.338117999999998],
        [59.657681, 29.322574],
        [59.664528, 29.310133999999998],
        [59.663691, 29.302894],
        [59.675307999999994, 29.279839],
        [59.682849999999995, 29.244204],
        [59.679793999999994, 29.234752999999998],
        [59.677644, 29.23295],
        [59.680127, 29.222958],
        [59.681957, 29.221142],
        [59.666199, 29.203037],
        [59.666487, 29.198469],
        [59.683063, 29.200716],
        [59.682970999999995, 29.192563999999997],
        [59.677383999999996, 29.192183],
        [59.67538, 29.175974],
        [59.673328, 29.173019],
        [59.665063999999994, 29.168079],
        [59.660919, 29.172407],
        [59.654787, 29.146917],
        [59.646198, 29.139284999999997],
        [59.641887, 29.112788],
        [59.636634, 29.108521],
        [59.625637999999995, 29.125253999999998],
        [59.622887999999996, 29.09798],
        [59.625204999999994, 29.075703999999998],
        [59.623399, 29.063541999999998],
        [59.625012999999996, 29.015238999999998],
        [59.620751, 29.009311999999998],
        [59.620732, 29.005429999999997],
        [59.627525, 28.991069],
        [59.628878, 28.992366999999998],
        [59.631307, 28.982153],
        [59.636249, 28.983331],
        [59.643432, 28.973817999999998],
        [59.642661999999994, 28.968521],
        [59.644445999999995, 28.965148],
        [59.650448, 28.965389],
        [59.655111999999995, 28.959289],
        [59.656248, 28.952144999999998],
        [59.658479, 28.9511],
        [59.65929, 28.945643999999998],
        [59.664725999999995, 28.945079999999997],
        [59.668333999999994, 28.923007],
        [59.66647, 28.913857],
        [59.668417, 28.91161],
        [59.668941999999994, 28.906232],
        [59.671538999999996, 28.909361999999998],
        [59.674986, 28.906715],
        [59.692848999999995, 28.910968999999998],
        [59.702686, 28.892908],
        [59.720167999999994, 28.890176],
        [59.722804, 28.88215],
        [59.733064, 28.864492],
        [59.742518, 28.861441],
        [59.74341, 28.866577],
        [59.746168, 28.868021],
        [59.747902999999994, 28.873369999999998],
        [59.759708999999994, 28.855752],
        [59.758201, 28.852186999999997],
        [59.759, 28.845855999999998],
        [59.782951, 28.849041]
      ],
      [
        [59.815276, 28.975758],
        [59.814563, 28.984441],
        [59.809196, 28.99306],
        [59.811011, 29.011335],
        [59.805951, 29.034613999999998],
        [59.799132, 29.038066999999998],
        [59.801686, 29.047363999999998],
        [59.800124, 29.047302],
        [59.80047, 29.054465],
        [59.848122999999994, 29.075111999999997],
        [59.851046, 29.193462999999998],
        [59.892592, 29.186605999999998],
        [59.897307, 29.189370999999998],
        [59.900465999999994, 29.173112999999997],
        [59.900349, 29.152388],
        [59.927608, 29.154080999999998],
        [59.926066, 29.137444],
        [59.938862, 29.137214],
        [59.938618, 29.094558],
        [59.949386999999994, 29.093968999999998],
        [59.951401, 29.089706999999997],
        [59.953702, 29.089816],
        [59.953416, 29.082445999999997],
        [59.94757, 29.072685],
        [59.94629, 29.059466999999998],
        [59.914272, 28.983945]
      ]
    ]
  ]
}, {
  name: `Лужский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [58.876945, 28.69673],
        [58.879374, 28.706507],
        [58.883226, 28.710379999999997],
        [58.882979999999996, 28.701031],
        [58.895498999999994, 28.686232],
        [58.903802999999996, 28.696488],
        [58.904433, 28.702287],
        [58.908573999999994, 28.702593999999998],
        [58.909959, 28.696064],
        [58.91287, 28.69627],
        [58.918645, 28.703922],
        [58.921822, 28.702779],
        [58.921164999999995, 28.695757],
        [58.949172999999995, 28.682527],
        [58.947449, 28.688736],
        [58.959874, 28.677608],
        [58.961459999999995, 28.683678999999998],
        [58.95793, 28.707231999999998],
        [58.98121, 28.831286],
        [58.977318999999994, 28.851858999999997],
        [58.978671999999996, 28.85773],
        [58.982983, 28.860595],
        [58.982679999999995, 28.872597],
        [58.986199, 28.881937999999998],
        [58.984334, 28.897305],
        [58.981972, 28.901308],
        [58.977523, 28.900263],
        [58.971914, 28.911251],
        [58.973565, 28.915194],
        [58.981539999999995, 28.917578],
        [58.982245, 28.914165999999998],
        [58.985071999999995, 28.918376],
        [59.001695, 28.901753],
        [59.006504, 28.907705],
        [59.015753, 28.901249],
        [59.020346999999994, 28.918924999999998],
        [59.029455999999996, 28.933714],
        [59.029726, 28.954468],
        [59.022873999999995, 28.965377],
        [59.024685, 28.979397],
        [59.022476, 28.982979],
        [59.022943, 28.995077],
        [59.018443999999995, 29.001987],
        [59.021215, 29.01041],
        [59.04296, 28.988225999999997],
        [59.044365, 28.975187],
        [59.047509, 28.98311],
        [59.052597, 28.976951999999997],
        [59.056307999999994, 28.978727],
        [59.067690999999996, 28.965773],
        [59.073057, 28.968387999999997],
        [59.076499, 28.98557],
        [59.083495, 28.996439],
        [59.084405999999994, 28.994137],
        [59.087695, 29.013296],
        [59.092199, 29.016833],
        [59.09346, 29.013506999999997],
        [59.098518999999996, 29.024123999999997],
        [59.098445, 29.030514],
        [59.106635, 29.024046],
        [59.115221999999996, 29.007565],
        [59.120937, 29.006148],
        [59.124734999999994, 29.012362],
        [59.123231999999994, 29.031181],
        [59.115272999999995, 29.048057],
        [59.108855999999996, 29.082096],
        [59.101395, 29.091348],
        [59.097082, 29.084435],
        [59.091682, 29.086826],
        [59.089741, 29.097472],
        [59.084039, 29.100382999999997],
        [59.087551, 29.114482],
        [59.090045999999994, 29.102286],
        [59.094507, 29.101741999999998],
        [59.096055, 29.111760999999998],
        [59.088998, 29.133202999999998],
        [59.088499999999996, 29.169577],
        [59.091615, 29.174015999999998],
        [59.084792, 29.201074],
        [59.088936999999994, 29.216193999999998],
        [59.090452, 29.236770999999997],
        [59.099266, 29.243533],
        [59.109389, 29.25979],
        [59.114149999999995, 29.279352],
        [59.113319, 29.300237],
        [59.098963, 29.334144],
        [59.095692, 29.350125],
        [59.083785, 29.367565],
        [59.07794, 29.390224],
        [59.07561, 29.417171],
        [59.069966, 29.419852],
        [59.06026, 29.447263999999997],
        [59.061685999999995, 29.472807],
        [59.058600999999996, 29.478050999999997],
        [59.059877, 29.487638999999998],
        [59.054972, 29.493160999999997],
        [59.053844, 29.50677],
        [59.047895999999994, 29.51071],
        [59.043979, 29.524957999999998],
        [59.035022999999995, 29.530931],
        [59.032503, 29.547373],
        [59.060931999999994, 29.556583],
        [59.050306, 29.591966],
        [59.086113, 29.628826999999998],
        [59.068694, 29.691636],
        [59.076972, 29.724946],
        [59.081604, 29.730953],
        [59.076631, 29.742611],
        [59.073871999999994, 29.762693],
        [59.089478, 29.776718],
        [59.094316, 29.790218999999997],
        [59.101569, 29.788876],
        [59.127064999999995, 29.803482],
        [59.135439, 29.817345999999997],
        [59.138306, 29.82837],
        [59.141374, 29.823976],
        [59.146876999999996, 29.834180999999997],
        [59.170142999999996, 29.738395999999998],
        [59.205951999999996, 29.750168],
        [59.211683, 29.73376],
        [59.201093, 29.718677999999997],
        [59.215944, 29.703253999999998],
        [59.227472999999996, 29.83063],
        [59.231058999999995, 29.837038],
        [59.236486, 29.869491],
        [59.245473999999994, 29.898132],
        [59.253902, 29.909699999999997],
        [59.254239999999996, 29.916763],
        [59.263562, 29.923105999999997],
        [59.264592, 29.919368],
        [59.265488, 29.92524],
        [59.268639, 29.920689999999997],
        [59.267345, 29.932491],
        [59.270025, 29.936238999999997],
        [59.267044999999996, 29.942287999999998],
        [59.269130999999994, 29.960708999999998],
        [59.263915999999995, 29.97383],
        [59.267561, 29.978574],
        [59.266791, 29.983233],
        [59.271386, 29.996686],
        [59.268513999999996, 30.004226],
        [59.263681, 30.007361999999997],
        [59.264123999999995, 29.996029999999998],
        [59.261455999999995, 29.992542],
        [59.253465, 30.004948],
        [59.252337999999995, 30.001773999999997],
        [59.247713, 30.015207999999998],
        [59.238338999999996, 30.011236999999998],
        [59.237376999999995, 30.020421],
        [59.235211, 30.020827999999998],
        [59.223307, 30.016848],
        [59.221728, 30.010680999999998],
        [59.210398999999995, 30.008056999999997],
        [59.209813, 30.001196],
        [59.204373, 30.006202],
        [59.20101, 29.999906],
        [59.198488999999995, 30.008146],
        [59.181134, 30.001979],
        [59.180828999999996, 30.007939],
        [59.159042, 30.006010999999997],
        [59.157882, 30.029988999999997],
        [59.161609, 30.068952999999997],
        [59.174710999999995, 30.092948],
        [59.181545, 30.142452],
        [59.067254999999996, 30.19455],
        [59.066094, 30.185955999999997],
        [59.06155, 30.18103],
        [59.051809999999996, 30.178736999999998],
        [59.029581, 30.192956],
        [59.020502, 30.204417],
        [59.020126, 30.209834999999998],
        [59.023941, 30.212336999999998],
        [59.019374, 30.216086999999998],
        [59.015128999999995, 30.227445],
        [59.01387, 30.240827],
        [59.026604999999996, 30.356191],
        [59.030784999999995, 30.359137999999998],
        [59.032827999999995, 30.369816999999998],
        [59.033764, 30.413482],
        [59.036223, 30.418701],
        [59.029109, 30.421616999999998],
        [59.036994, 30.420987],
        [59.036358, 30.437531],
        [59.026238, 30.447359],
        [59.023885, 30.458395],
        [59.030429, 30.464689999999997],
        [59.037558999999995, 30.489701],
        [59.044504999999994, 30.494906],
        [59.044309, 30.499368999999998],
        [59.049268999999995, 30.506581999999998],
        [59.050416999999996, 30.52508],
        [59.059919, 30.524753999999998],
        [59.061415, 30.518190999999998],
        [59.062199, 30.521445999999997],
        [59.083602, 30.521123],
        [59.083906999999996, 30.527928],
        [59.089847999999996, 30.531513999999998],
        [59.09128, 30.548005],
        [59.090472999999996, 30.558721],
        [59.080155999999995, 30.557492],
        [59.080284999999996, 30.564026],
        [59.072553, 30.564614],
        [59.074284999999996, 30.59618],
        [59.063674, 30.608615999999998],
        [59.063494, 30.656523999999997],
        [59.065104999999996, 30.657898],
        [59.058325999999994, 30.670001],
        [59.058282, 30.703799999999998],
        [59.049408, 30.732837],
        [59.058206999999996, 30.727145],
        [59.070336, 30.744483],
        [59.069562999999995, 30.758388],
        [59.072317, 30.769526],
        [59.076429999999995, 30.826324999999997],
        [59.0789, 30.826656999999997],
        [59.080686, 30.841331999999998],
        [59.097994, 30.844724],
        [59.095009999999995, 30.855103999999997],
        [59.100319999999996, 30.866723],
        [59.089386, 30.885022],
        [59.089357, 30.898625],
        [59.073769999999996, 30.89293],
        [59.075047999999995, 30.918342],
        [59.07742, 30.923043999999997],
        [59.074566, 30.926503999999998],
        [59.080973, 30.924146],
        [59.07787, 30.930593],
        [59.071562, 30.932655999999998],
        [59.068780999999994, 30.92906],
        [59.060258, 30.935496999999998],
        [59.058823999999994, 30.928175],
        [59.052906, 30.921464],
        [59.053717, 30.911158],
        [59.045431, 30.911921999999997],
        [59.041563, 30.926042],
        [59.041084999999995, 30.915739],
        [59.014585999999994, 30.910480999999997],
        [59.014382, 30.900308],
        [59.011429, 30.898415],
        [59.006719999999994, 30.913567999999998],
        [59.009679999999996, 30.910852],
        [59.011244999999995, 30.919991999999997],
        [59.009024, 30.921834999999998],
        [59.007557999999996, 30.917109],
        [59.002494, 30.924239999999998],
        [59.005019, 30.936954],
        [59.008893, 30.940666999999998],
        [59.013127, 30.954469],
        [59.011303999999996, 30.961917],
        [58.99981, 30.956559],
        [58.992951, 30.943786],
        [58.992630999999996, 30.989656999999998],
        [58.989422, 30.980075],
        [58.980916, 30.979893999999998],
        [58.973662, 30.954655],
        [58.963212999999996, 30.965397999999997],
        [58.942989, 30.95235],
        [58.920815, 30.920842999999998],
        [58.902885999999995, 30.872491999999998],
        [58.876579, 30.813316],
        [58.876413, 30.794456999999998],
        [58.878173, 30.791864999999998],
        [58.874354999999994, 30.775171],
        [58.865790999999994, 30.765964999999998],
        [58.856655999999994, 30.762522999999998],
        [58.856759999999994, 30.755150999999998],
        [58.87072, 30.749916],
        [58.867017999999995, 30.740152],
        [58.857344, 30.7369],
        [58.856607999999994, 30.725801999999998],
        [58.859255, 30.71961],
        [58.85388, 30.705728999999998],
        [58.85809, 30.700324],
        [58.859251, 30.683176999999997],
        [58.85236, 30.682734999999997],
        [58.850153999999996, 30.676123999999998],
        [58.842780999999995, 30.67617],
        [58.839659999999995, 30.6818],
        [58.833887999999995, 30.679102999999998],
        [58.836546, 30.692978],
        [58.829066, 30.696381],
        [58.826119999999996, 30.714872],
        [58.814339999999994, 30.728172999999998],
        [58.809124, 30.715187],
        [58.778715999999996, 30.714118],
        [58.757922, 30.703076999999997],
        [58.756550999999995, 30.708624999999998],
        [58.747457999999995, 30.700847999999997],
        [58.744645999999996, 30.702582999999997],
        [58.743390999999995, 30.712502999999998],
        [58.736531, 30.713769],
        [58.733605999999995, 30.707863],
        [58.729867999999996, 30.716199999999997],
        [58.728567999999996, 30.706339999999997],
        [58.713984999999994, 30.682778],
        [58.721698999999994, 30.668733],
        [58.728730999999996, 30.678376999999998],
        [58.736967, 30.664735999999998],
        [58.732989999999994, 30.657576],
        [58.735727, 30.654653],
        [58.732744, 30.645234],
        [58.715354999999995, 30.61984],
        [58.716006, 30.598744],
        [58.704857999999994, 30.587363],
        [58.704617, 30.574496999999997],
        [58.710173, 30.578550999999997],
        [58.726842999999995, 30.563008999999997],
        [58.724045, 30.553365],
        [58.724008, 30.49798],
        [58.716530999999996, 30.486345999999998],
        [58.704519999999995, 30.445476],
        [58.702084, 30.447439],
        [58.703142, 30.408158],
        [58.718796, 30.390141999999997],
        [58.716144, 30.376534999999997],
        [58.719471, 30.369380999999997],
        [58.739591, 30.379493999999998],
        [58.748360999999996, 30.364390999999998],
        [58.746438999999995, 30.348457],
        [58.760569, 30.340774999999997],
        [58.753612, 30.325339],
        [58.744136999999995, 30.324173],
        [58.744507999999996, 30.316297],
        [58.736751, 30.289789],
        [58.748712, 30.283393],
        [58.745568, 30.277036],
        [58.748912999999995, 30.271577999999998],
        [58.742025999999996, 30.245407],
        [58.744412999999994, 30.245762],
        [58.744428, 30.230262999999997],
        [58.74989, 30.23172],
        [58.751343, 30.222078999999997],
        [58.758072, 30.223305],
        [58.761215, 30.217005],
        [58.757278, 30.209947999999997],
        [58.751228999999995, 30.209187],
        [58.750716, 30.204209],
        [58.748318, 30.206000999999997],
        [58.749395, 30.200871],
        [58.751999999999995, 30.203118],
        [58.759761, 30.192989999999998],
        [58.759989, 30.188087],
        [58.746579999999994, 30.169961999999998],
        [58.743046, 30.153851999999997],
        [58.745805, 30.145021],
        [58.745379, 30.122055999999997],
        [58.737019, 30.129402],
        [58.730937, 30.12287],
        [58.727816999999995, 30.126752999999997],
        [58.721042, 30.121903],
        [58.722699999999996, 30.113059],
        [58.728078999999994, 30.104551999999998],
        [58.719972, 30.094302],
        [58.706281999999995, 30.108472],
        [58.707077999999996, 30.115109],
        [58.704721, 30.117898999999998],
        [58.702203, 30.109728999999998],
        [58.696754999999996, 30.118934999999997],
        [58.697860999999996, 30.130679999999998],
        [58.695636, 30.130537999999998],
        [58.693708, 30.137452999999997],
        [58.672380999999994, 30.104582999999998],
        [58.667511999999995, 30.064757],
        [58.663084999999995, 30.060347],
        [58.64924, 30.060019999999998],
        [58.653904, 30.070422999999998],
        [58.649338, 30.074797],
        [58.650971, 30.082879],
        [58.641428999999995, 30.111953999999997],
        [58.637476, 30.101236],
        [58.639305, 30.096156999999998],
        [58.634178999999996, 30.08993],
        [58.630655, 30.093726999999998],
        [58.628429999999994, 30.073826],
        [58.624804, 30.07331],
        [58.618296, 30.077413999999997],
        [58.618081999999994, 30.082826999999998],
        [58.613774, 30.084951999999998],
        [58.607806, 30.097671],
        [58.564443999999995, 30.120718999999998],
        [58.558876999999995, 30.136076],
        [58.554575, 30.134933999999998],
        [58.553771, 30.141343],
        [58.549302, 30.140746999999998],
        [58.54591, 30.152252999999998],
        [58.53398, 30.162754],
        [58.530224, 30.174649],
        [58.525185, 30.164177],
        [58.528644, 30.153131],
        [58.523616, 30.130335],
        [58.514351, 30.114611999999997],
        [58.515322999999995, 30.100199],
        [58.511306999999995, 30.097357],
        [58.511323999999995, 30.102075],
        [58.502441999999995, 30.093132999999998],
        [58.492069, 30.133492],
        [58.487998999999995, 30.123189],
        [58.489847, 30.11983],
        [58.489309, 30.109318],
        [58.480464999999995, 30.095342],
        [58.474466, 30.062321999999998],
        [58.463274999999996, 30.060489999999998],
        [58.462168, 30.065382],
        [58.456328, 30.060449],
        [58.447457, 30.069546],
        [58.442966, 30.043685],
        [58.446557, 30.035944999999998],
        [58.446946, 30.02434],
        [58.438283999999996, 30.005224],
        [58.442412999999995, 29.995289],
        [58.435959, 29.953272],
        [58.439513, 29.945182],
        [58.44887, 29.965920999999998],
        [58.451372, 29.961723],
        [58.450208999999994, 29.95411],
        [58.458667999999996, 29.944374],
        [58.45883, 29.935281],
        [58.454049, 29.928123],
        [58.446838, 29.934264],
        [58.442668, 29.930882999999998],
        [58.447312, 29.911884999999998],
        [58.453325, 29.900886],
        [58.446220999999994, 29.8935],
        [58.452141999999995, 29.873654],
        [58.448837999999995, 29.87492],
        [58.443394, 29.85645],
        [58.433913, 29.854467999999997],
        [58.434315, 29.842831999999998],
        [58.429603, 29.845413999999998],
        [58.432589, 29.855110999999997],
        [58.427181999999995, 29.864269],
        [58.421158, 29.78691],
        [58.417088, 29.778741999999998],
        [58.421014, 29.759897],
        [58.425081, 29.761321],
        [58.421898999999996, 29.754402],
        [58.422734999999996, 29.745976],
        [58.42794, 29.748113],
        [58.429351999999994, 29.742858],
        [58.435131999999996, 29.751286999999998],
        [58.437596, 29.748925999999997],
        [58.440329999999996, 29.745943999999998],
        [58.44079, 29.721435],
        [58.443690999999994, 29.711883],
        [58.458726, 29.707041999999998],
        [58.460384, 29.697188999999998],
        [58.451305, 29.678162],
        [58.446434999999994, 29.68104],
        [58.443433, 29.675172],
        [58.449692999999996, 29.659166],
        [58.446287, 29.652945],
        [58.447199999999995, 29.641548999999998],
        [58.452788999999996, 29.626295],
        [58.451311, 29.612584],
        [58.460325999999995, 29.608219],
        [58.458453, 29.597224999999998],
        [58.468095999999996, 29.589544],
        [58.46774, 29.596946],
        [58.475839, 29.581360999999998],
        [58.475237, 29.571315],
        [58.480152, 29.551025],
        [58.486247, 29.542783],
        [58.494361, 29.540297],
        [58.500761, 29.551589999999997],
        [58.509359999999994, 29.551071],
        [58.518499, 29.576949],
        [58.5207, 29.572757],
        [58.524314, 29.587356999999997],
        [58.534918999999995, 29.577851],
        [58.537042, 29.562959],
        [58.544301, 29.544819999999998],
        [58.54208, 29.508712],
        [58.555764999999994, 29.48432],
        [58.56747, 29.484226999999997],
        [58.567215999999995, 29.473917999999998],
        [58.571506, 29.467367],
        [58.573434, 29.456007],
        [58.577481999999996, 29.454389],
        [58.579277999999995, 29.442204],
        [58.586859, 29.427678999999998],
        [58.591274999999996, 29.426790999999998],
        [58.574743999999995, 29.402362],
        [58.573214, 29.405122],
        [58.554627999999994, 29.39681],
        [58.553086, 29.382012],
        [58.555026, 29.370983],
        [58.572674, 29.357518],
        [58.572058, 29.354397],
        [58.556053999999996, 29.360840999999997],
        [58.560334, 29.331440999999998],
        [58.56715, 29.320797],
        [58.569751999999994, 29.324887999999998],
        [58.568251999999994, 29.333886999999997],
        [58.570930999999995, 29.337201],
        [58.576299, 29.319791],
        [58.575699, 29.329171],
        [58.579117, 29.335668],
        [58.577808999999995, 29.351988],
        [58.58192, 29.361814],
        [58.581235, 29.366522999999997],
        [58.577014999999996, 29.362610999999998],
        [58.573435999999994, 29.372276],
        [58.56914, 29.369564999999998],
        [58.570274, 29.374011],
        [58.568227, 29.376486999999997],
        [58.579280999999995, 29.385835],
        [58.583849, 29.368758999999997],
        [58.586527999999994, 29.369307],
        [58.588919999999995, 29.372515],
        [58.584945999999995, 29.381024],
        [58.585341, 29.387967],
        [58.588739999999994, 29.393323],
        [58.590398, 29.390677],
        [58.591514999999994, 29.395985999999997],
        [58.589411, 29.403475],
        [58.591352, 29.404719],
        [58.597299, 29.389822],
        [58.606047, 29.353813],
        [58.607672, 29.351914999999998],
        [58.614616, 29.359948],
        [58.610453, 29.349234],
        [58.612857999999996, 29.345629],
        [58.611791999999994, 29.342544999999998],
        [58.608157999999996, 29.345816],
        [58.612356, 29.333654],
        [58.612722999999995, 29.315766999999997],
        [58.606227, 29.318917],
        [58.611698999999994, 29.303734],
        [58.611650999999995, 29.291152],
        [58.599644, 29.286171999999997],
        [58.595470999999996, 29.271922],
        [58.595712, 29.268855],
        [58.603970999999994, 29.269461999999997],
        [58.60026, 29.258145],
        [58.623293999999994, 29.248441],
        [58.626877, 29.244339],
        [58.627196, 29.233546999999998],
        [58.632652, 29.233238],
        [58.632529, 29.252048],
        [58.635084, 29.256877],
        [58.635334, 29.269236],
        [58.640592999999996, 29.269147],
        [58.643631, 29.274047],
        [58.648789, 29.292534999999997],
        [58.658328, 29.267104],
        [58.662945, 29.265212],
        [58.669636, 29.265409],
        [58.672858, 29.275972999999997],
        [58.674671, 29.269081],
        [58.678701999999994, 29.268082],
        [58.682542999999995, 29.273391999999998],
        [58.684850999999995, 29.268808],
        [58.696138999999995, 29.284986],
        [58.700969, 29.280143],
        [58.701077, 29.274029],
        [58.698567999999995, 29.271929],
        [58.700531, 29.258689999999998],
        [58.705628999999995, 29.251756],
        [58.704539999999994, 29.244922],
        [58.709968999999994, 29.225966],
        [58.697527, 29.218045],
        [58.701265, 29.204660999999998],
        [58.708138999999996, 29.20218],
        [58.711459999999995, 29.184113],
        [58.703196999999996, 29.175919999999998],
        [58.706858, 29.160732],
        [58.70138, 29.156488],
        [58.701107, 29.141675],
        [58.706114, 29.140278],
        [58.707808, 29.127938999999998],
        [58.717687, 29.124848],
        [58.720852, 29.097572],
        [58.732783, 29.086036999999997],
        [58.732828999999995, 29.075616],
        [58.725204999999995, 29.059116999999997],
        [58.725555, 29.036696],
        [58.727824, 29.035559],
        [58.72886, 29.054676999999998],
        [58.735907, 29.073680999999997],
        [58.740489, 29.070235],
        [58.739981, 29.057512],
        [58.767328, 29.05238],
        [58.770357999999995, 29.045295],
        [58.777333, 29.050922],
        [58.826440999999996, 29.039257],
        [58.825393, 29.020266],
        [58.814586999999996, 29.022299],
        [58.813548, 29.008542],
        [58.809481, 29.003605999999998],
        [58.812945, 28.998476],
        [58.812867999999995, 28.986445],
        [58.797846, 28.966879],
        [58.801952, 28.906439],
        [58.816868, 28.888215],
        [58.825694, 28.840535],
        [58.826404, 28.797525],
        [58.830321999999995, 28.782657999999998],
        [58.828565, 28.779861999999998],
        [58.838516999999996, 28.764777],
        [58.843216, 28.767933],
        [58.845639, 28.757932999999998],
        [58.845197, 28.750411999999997],
        [58.842546, 28.75459],
        [58.842572999999994, 28.746052],
        [58.851368, 28.735426999999998],
        [58.851406999999995, 28.731285],
        [58.85427, 28.732093],
        [58.852712999999994, 28.722471],
        [58.857931, 28.707697999999997],
        [58.863059, 28.704096],
        [58.870376, 28.687507999999998]
      ]
    ]
  ]
}, {
  name: `Подпорожский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [61.154219999999995, 33.449086],
        [61.160408, 33.45883],
        [61.165468, 33.483461999999996],
        [61.184884, 33.501652],
        [61.186617999999996, 33.512951],
        [61.213376999999994, 33.549825],
        [61.218959999999996, 33.574231999999995],
        [61.214783, 33.603954],
        [61.220470999999996, 33.609272],
        [61.215422999999994, 33.6688],
        [61.233242, 33.668441],
        [61.235223999999995, 34.189267],
        [61.243857999999996, 34.188604],
        [61.243815, 34.238777999999996],
        [61.237407999999995, 34.244152],
        [61.238099999999996, 34.255334],
        [61.230098999999996, 34.270405],
        [61.228896, 34.282827],
        [61.224168, 34.294193],
        [61.220118, 34.336825],
        [61.194593, 34.360814999999995],
        [61.194109999999995, 34.375530999999995],
        [61.161243, 34.406861],
        [61.148737999999994, 34.405998],
        [61.143136999999996, 34.426269999999995],
        [61.143735, 34.442039],
        [61.157802, 34.452491],
        [61.161950999999995, 34.469476],
        [61.184411, 34.473126],
        [61.19985, 34.491659],
        [61.207606, 34.482863],
        [61.208208, 34.465216],
        [61.218726999999994, 34.479292],
        [61.223889, 34.473053],
        [61.234162999999995, 34.47471],
        [61.238107, 34.483111],
        [61.237970999999995, 34.491963999999996],
        [61.244279, 34.502607],
        [61.244465, 34.512059],
        [61.26079, 34.529095999999996],
        [61.261303, 34.541844999999995],
        [61.275611, 34.545549],
        [61.274286, 34.657561],
        [61.295522999999996, 34.730115],
        [61.297191, 34.749962],
        [61.304919999999996, 34.738353],
        [61.312630999999996, 34.738349],
        [61.312424, 34.770412],
        [61.299122, 34.780977],
        [61.296792999999994, 34.790527],
        [61.289339, 34.794864],
        [61.288177, 34.875261],
        [61.282081999999996, 34.905527],
        [61.266344999999994, 34.942512],
        [61.262946, 35.051547],
        [61.259209999999996, 35.053148],
        [61.256296, 35.06697],
        [61.247659, 35.082561999999996],
        [61.241726, 35.087313],
        [61.247741, 35.10131],
        [61.253446, 35.100083999999995],
        [61.255539999999996, 35.107490999999996],
        [61.25029, 35.143471],
        [61.243528999999995, 35.150847],
        [61.238741, 35.171819],
        [61.246503999999995, 35.186695],
        [61.259389, 35.200086],
        [61.255146999999994, 35.216702],
        [61.247437, 35.227587],
        [61.251673999999994, 35.299597999999996],
        [61.270731999999995, 35.332783],
        [61.277575, 35.330686],
        [61.285486, 35.353474999999996],
        [61.277649999999994, 35.367832],
        [61.282089, 35.392987],
        [61.277406, 35.395595],
        [61.269535, 35.382723],
        [61.253299999999996, 35.378429],
        [61.24135, 35.36749],
        [61.232152, 35.371093],
        [61.225483, 35.359775],
        [61.175827, 35.352762999999996],
        [61.174991999999996, 35.405781],
        [61.170666, 35.409683],
        [61.16547, 35.394078],
        [61.165822999999996, 35.398863],
        [61.140215, 35.396676],
        [61.138904, 35.402429999999995],
        [61.141794999999995, 35.422103],
        [61.160453999999994, 35.454032999999995],
        [61.156268, 35.465288],
        [61.166734999999996, 35.501149],
        [61.169318, 35.500668],
        [61.181157999999996, 35.526357],
        [61.177029999999995, 35.564972999999995],
        [61.182095999999994, 35.575558],
        [61.184431, 35.590495],
        [61.182424, 35.589695999999996],
        [61.178605, 35.602866999999996],
        [61.190787, 35.66091],
        [61.207449999999994, 35.721419],
        [61.000305, 35.702047],
        [60.914338, 35.526340999999995],
        [60.892852, 35.523196],
        [60.886547, 35.511227999999996],
        [60.883679, 35.493035],
        [60.875851, 35.481958999999996],
        [60.870515999999995, 35.486699],
        [60.86416, 35.480328],
        [60.864118999999995, 35.472378],
        [60.85946, 35.472837],
        [60.855554, 35.462826],
        [60.856095999999994, 35.422131],
        [60.853159, 35.417738],
        [60.853359999999995, 35.397121999999996],
        [60.858537999999996, 35.391625999999995],
        [60.860533, 35.363153],
        [60.856317, 35.331182999999996],
        [60.843934, 35.284147],
        [60.845827, 35.264702],
        [60.842003999999996, 35.256381999999995],
        [60.833878999999996, 35.261251],
        [60.834137999999996, 35.244715],
        [60.808361999999995, 35.231657],
        [60.808043, 35.238352],
        [60.801735, 35.237517],
        [60.798634, 35.202238],
        [60.78297, 35.19347],
        [60.781425999999996, 35.187484999999995],
        [60.768080999999995, 35.17711],
        [60.750329, 35.185767999999996],
        [60.734072, 35.217374],
        [60.731027999999995, 35.239385],
        [60.713735, 35.238692],
        [60.703312, 35.209835999999996],
        [60.698164999999996, 35.210249999999995],
        [60.694258999999995, 35.227377],
        [60.696495, 35.22857],
        [60.69612, 35.234617],
        [60.694312999999994, 35.234656],
        [60.690048, 35.261002],
        [60.684780999999994, 35.258607999999995],
        [60.677873, 35.269782],
        [60.668901, 35.263736],
        [60.635258, 35.283927],
        [60.618655999999994, 35.283054],
        [60.608788, 35.27324],
        [60.602537, 35.298485],
        [60.603736999999995, 35.301477],
        [60.458512999999996, 35.288055],
        [60.35803, 35.27324],
        [60.358033999999996, 35.281019],
        [60.336726, 35.280744999999996],
        [60.332969, 35.258621],
        [60.320012, 35.267264],
        [60.290189, 35.244659999999996],
        [60.272799, 35.198885],
        [60.253896999999995, 35.179134999999995],
        [60.248359, 35.194601999999996],
        [60.245326, 35.191637],
        [60.249416999999994, 35.181324],
        [60.248497, 35.172927],
        [60.253149, 35.16146],
        [60.25487, 35.145707],
        [60.25908, 35.139792],
        [60.259397, 35.092545],
        [60.251332, 35.068408999999996],
        [60.250496, 35.04103],
        [60.241091, 35.038689999999995],
        [60.245273, 35.03375],
        [60.244336999999994, 34.995394999999995],
        [60.236214999999994, 34.955666],
        [60.235783999999995, 34.888563],
        [60.269633999999996, 34.873784],
        [60.257692999999996, 34.739017],
        [60.25866, 34.732273],
        [60.29606, 34.719452],
        [60.290710999999995, 34.647963],
        [60.31597, 34.638666],
        [60.314034, 34.617090999999995],
        [60.324241, 34.589504999999996],
        [60.338936999999994, 34.585958],
        [60.339721999999995, 34.593525],
        [60.341528, 34.592037],
        [60.338339, 34.558529],
        [60.411248, 34.531928],
        [60.404838999999996, 34.459036999999995],
        [60.433467, 34.452993],
        [60.432748, 34.437464999999996],
        [60.440642999999994, 34.407585999999995],
        [60.46543, 34.398787],
        [60.464791999999996, 34.380479],
        [60.469784999999995, 34.388920999999996],
        [60.475322, 34.386945],
        [60.486182, 34.368358],
        [60.482811, 34.353936],
        [60.496291, 34.347532],
        [60.517115, 34.346951],
        [60.515918, 34.342082999999995],
        [60.521027, 34.320295],
        [60.536407999999994, 34.387824],
        [60.557143999999994, 34.396784],
        [60.557935, 34.361331],
        [60.561640999999995, 34.347349],
        [60.55379, 34.319264],
        [60.55493, 34.292241],
        [60.552924999999995, 34.27572],
        [60.555606999999995, 34.234919],
        [60.574186999999995, 34.237715],
        [60.573422, 34.272262],
        [60.587039, 34.276449],
        [60.598212, 34.27411],
        [60.598873, 34.287788],
        [60.608523, 34.297691],
        [60.612379999999995, 34.295655],
        [60.609784, 34.268616],
        [60.610837999999994, 34.249958],
        [60.608795, 34.232813],
        [60.605776999999996, 34.228924],
        [60.606514999999995, 34.214698],
        [60.603958999999996, 34.203706],
        [60.611388, 34.195445],
        [60.611248999999994, 34.171704999999996],
        [60.613068999999996, 34.188046],
        [60.6188, 34.200860999999996],
        [60.626275, 34.20518],
        [60.628769, 34.216175],
        [60.632106, 34.21561],
        [60.632189, 34.221661],
        [60.639221, 34.223276999999996],
        [60.640071999999996, 34.227235],
        [60.642877, 34.228079],
        [60.650835, 34.198449],
        [60.666242, 34.222957],
        [60.670857, 34.215852],
        [60.681529, 34.222969],
        [60.693756, 34.221193],
        [60.714459, 34.182314999999996],
        [60.719355, 34.166],
        [60.718351999999996, 34.126824],
        [60.714113999999995, 34.127052],
        [60.705827, 34.107749],
        [60.713502, 34.10853],
        [60.714166, 34.071601],
        [60.749157999999994, 34.076952999999996],
        [60.757344999999994, 33.906264],
        [60.77813, 33.904685],
        [60.782413999999996, 33.909196],
        [60.784315, 33.918977999999996],
        [60.789732, 33.922824],
        [60.7919, 33.932638],
        [60.794002, 33.930538999999996],
        [60.793026999999995, 33.91876],
        [60.810770999999995, 33.927293999999996],
        [60.811598999999994, 33.957536],
        [60.814322999999995, 33.957817],
        [60.814201, 33.928612],
        [60.816503999999995, 33.929083999999996],
        [60.814102999999996, 33.925865],
        [60.813945999999994, 33.889236],
        [60.805158, 33.878240999999996],
        [60.835266999999995, 33.749092],
        [60.85594, 33.777046],
        [60.871891999999995, 33.764680999999996],
        [60.881888, 33.780350999999996],
        [60.890792, 33.80846],
        [60.902403, 33.814547999999995],
        [60.926261999999994, 33.840406],
        [60.927634, 33.832001999999996],
        [60.939007999999994, 33.812537],
        [60.937574999999995, 33.787400999999996],
        [60.947221, 33.786808],
        [60.947976, 33.81451],
        [60.972269999999995, 33.821233],
        [60.978711999999994, 33.832751],
        [60.990688, 33.841843],
        [60.99626, 33.857738],
        [61.002638, 33.862562],
        [61.006567, 33.859203],
        [61.012617999999996, 33.866029],
        [61.015997, 33.864041],
        [61.041554999999995, 33.878579],
        [61.047793, 33.8855],
        [61.055530999999995, 33.905659],
        [61.063421999999996, 33.90812],
        [61.072585999999994, 33.919151],
        [61.088473, 33.919883],
        [61.091592, 33.915664],
        [61.09478, 33.910796999999995],
        [61.096131, 33.88743],
        [61.102087, 33.880142],
        [61.095943999999996, 33.843821],
        [61.105366999999994, 33.834838],
        [61.131133999999996, 33.73789],
        [61.124517, 33.737696],
        [61.1264, 33.728307],
        [61.113293999999996, 33.7243],
        [61.113353999999994, 33.684418],
        [61.096863, 33.665793],
        [61.096331, 33.659949],
        [61.074003999999995, 33.662265],
        [61.071076999999995, 33.642534],
        [61.066893, 33.634527],
        [61.066222999999994, 33.601136],
        [61.063354, 33.590202],
        [61.079429999999995, 33.546458],
        [61.095313, 33.544143999999996],
        [61.110209999999995, 33.522532],
        [61.110935999999995, 33.507628],
        [61.115376, 33.49]
      ]
    ]
  ]
}, {
  name: `Приозерский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [60.75483, 29.50187],
        [60.766385, 29.497683],
        [60.769484999999996, 29.498652999999997],
        [60.770892999999994, 29.504723],
        [60.777677999999995, 29.507234999999998],
        [60.787369999999996, 29.499955],
        [60.794784, 29.523477999999997],
        [60.800405, 29.511488999999997],
        [60.80553, 29.515454],
        [60.815875999999996, 29.513472],
        [60.817294999999994, 29.521527],
        [60.827755999999994, 29.51916],
        [60.829170999999995, 29.509877],
        [60.836541999999994, 29.499682],
        [60.837033, 29.527673],
        [60.823631, 29.532612],
        [60.818922, 29.539123],
        [60.822343999999994, 29.543747],
        [60.833079, 29.536775],
        [60.833216, 29.580661],
        [60.834782999999995, 29.583425],
        [60.833416, 29.604848999999998],
        [60.826674999999994, 29.619329999999998],
        [60.825492, 29.636999],
        [60.826138, 29.643065999999997],
        [60.838316, 29.661692],
        [60.842436, 29.702014],
        [60.909368, 29.624795],
        [60.92135, 29.636231],
        [60.946313999999994, 29.587474999999998],
        [60.957583, 29.585046],
        [60.969286, 29.570342999999998],
        [60.976856999999995, 29.583592999999997],
        [60.980450999999995, 29.583128],
        [60.983726999999995, 29.575834999999998],
        [60.988755999999995, 29.579365],
        [61.002731, 29.6116],
        [61.009812999999994, 29.603037999999998],
        [61.011691, 29.607808],
        [61.014834, 29.604432],
        [61.014226, 29.600009],
        [61.029796, 29.581552],
        [61.030657, 29.585836999999998],
        [61.049184999999994, 29.584512],
        [61.055839, 29.564556999999997],
        [61.056227, 29.551613999999997],
        [61.13019, 29.553275],
        [61.141107999999996, 29.568993],
        [61.142103, 29.576711],
        [61.155038999999995, 29.566312],
        [61.167203, 29.569547999999998],
        [61.181082999999994, 29.568967999999998],
        [61.181419, 29.565834],
        [61.181478, 29.572571],
        [61.189744, 29.576552],
        [61.183606, 29.617286],
        [61.179679, 29.627343999999997],
        [61.182213, 29.645630999999998],
        [61.178242, 29.656398],
        [61.178248999999994, 29.672981],
        [61.172422, 29.691060999999998],
        [61.175289, 29.692235],
        [61.170655, 29.793460999999997],
        [61.170147, 29.858843999999998],
        [61.147200999999995, 29.997813999999998],
        [61.142647, 30.080123],
        [60.846326, 31.899016],
        [60.523047999999996, 30.718518],
        [60.515, 30.698608999999998],
        [60.486346, 30.686080999999998],
        [60.486154, 30.673351],
        [60.478271, 30.628363],
        [60.447505, 30.550209],
        [60.449262, 30.534532],
        [60.453756999999996, 30.528088],
        [60.464957999999996, 30.527967999999998],
        [60.467304999999996, 30.520180999999997],
        [60.461307, 30.516831],
        [60.462768, 30.511505999999997],
        [60.449712, 30.495286],
        [60.450320999999995, 30.491108999999998],
        [60.443954, 30.490209999999998],
        [60.436656, 30.476609999999997],
        [60.430858, 30.481278999999997],
        [60.430735999999996, 30.474643],
        [60.427817, 30.472348999999998],
        [60.428830999999995, 30.464976],
        [60.424369999999996, 30.452526],
        [60.433330999999995, 30.438354],
        [60.44237, 30.436223],
        [60.444967, 30.427456],
        [60.445980999999996, 30.412955999999998],
        [60.441154999999995, 30.398702],
        [60.444720999999994, 30.375355],
        [60.441924, 30.355446999999998],
        [60.448494, 30.347254],
        [60.452709999999996, 30.332753999999998],
        [60.452794, 30.310335],
        [60.450773999999996, 30.305123],
        [60.458383999999995, 30.290892],
        [60.464054, 30.286633],
        [60.464527, 30.278554],
        [60.469406, 30.278145],
        [60.467923, 30.273297],
        [60.471368, 30.270108999999998],
        [60.472122999999996, 30.264270999999997],
        [60.471221, 30.258566],
        [60.467760999999996, 30.259597999999997],
        [60.462976999999995, 30.270771999999997],
        [60.462865, 30.267128999999997],
        [60.458771, 30.266997],
        [60.459526, 30.261587],
        [60.468664, 30.254519],
        [60.472615, 30.256289],
        [60.477112, 30.24356],
        [60.468275999999996, 30.227634],
        [60.457242, 30.215801],
        [60.457232, 30.191988],
        [60.459115, 30.188774],
        [60.461448999999995, 30.192242],
        [60.467926, 30.162653],
        [60.480740999999995, 30.151638],
        [60.483624, 30.144834999999997],
        [60.485913, 30.115087],
        [60.490291, 30.114677999999998],
        [60.490576, 30.091412],
        [60.488302999999995, 30.079041999999998],
        [60.496128, 30.062493999999997],
        [60.495844999999996, 30.056593999999997],
        [60.481372, 30.043653],
        [60.477964, 30.043898],
        [60.47618, 30.051353],
        [60.477542, 30.016755999999997],
        [60.458634999999994, 29.991327],
        [60.465174999999995, 29.980907],
        [60.460235999999995, 29.970865],
        [60.43662, 29.944172],
        [60.437053999999996, 29.932800999999998],
        [60.434306, 29.924972999999998],
        [60.433153999999995, 29.906323999999998],
        [60.425275, 29.891672999999997],
        [60.425670999999994, 29.875114999999997],
        [60.417832999999995, 29.858952],
        [60.420468, 29.845363],
        [60.421532, 29.816053999999998],
        [60.428895, 29.797739999999997],
        [60.426936, 29.767397],
        [60.422360999999995, 29.758069],
        [60.424501, 29.752916],
        [60.441184, 29.753626999999998],
        [60.443236999999996, 29.735245],
        [60.447396999999995, 29.730062999999998],
        [60.445848999999995, 29.720005999999998],
        [60.458861, 29.715049999999998],
        [60.459714, 29.705710999999997],
        [60.464661, 29.701293999999997],
        [60.468188999999995, 29.691464999999997],
        [60.472867, 29.693447],
        [60.473476, 29.706794],
        [60.48976, 29.742649],
        [60.503332, 29.756954999999998],
        [60.519852, 29.752616999999997],
        [60.524483, 29.747785],
        [60.526661, 29.739213999999997],
        [60.528737, 29.742967999999998],
        [60.528036, 29.739535999999998],
        [60.534177, 29.739031999999998],
        [60.536908, 29.717079],
        [60.544297, 29.727408999999998],
        [60.542668, 29.732046],
        [60.547861, 29.730701999999997],
        [60.546279999999996, 29.713721],
        [60.549284, 29.701717],
        [60.540253, 29.713765],
        [60.540231999999996, 29.718045999999998],
        [60.536933999999995, 29.716769],
        [60.540623, 29.688530999999998],
        [60.530707, 29.682802],
        [60.529993, 29.671578999999998],
        [60.533128, 29.667029999999997],
        [60.53169, 29.657739],
        [60.524674999999995, 29.667925],
        [60.521307, 29.660853999999997],
        [60.517911999999995, 29.664706],
        [60.515319999999996, 29.65711],
        [60.519921, 29.640114999999998],
        [60.524341, 29.633163999999997],
        [60.533057, 29.623929],
        [60.536252999999995, 29.625303],
        [60.541897999999996, 29.612025],
        [60.546189, 29.609959999999997],
        [60.547846, 29.602348],
        [60.549595999999994, 29.606647],
        [60.550636, 29.600315],
        [60.554373, 29.600244999999997],
        [60.554024999999996, 29.594669],
        [60.573761999999995, 29.55569],
        [60.574721, 29.548315],
        [60.587274, 29.540423999999998],
        [60.593526999999995, 29.544843999999998],
        [60.607434, 29.526383],
        [60.613260999999994, 29.523308999999998],
        [60.63887, 29.523719],
        [60.633404, 29.580489],
        [60.635901, 29.614303],
        [60.637864, 29.617767999999998],
        [60.643727, 29.604602999999997],
        [60.645049, 29.631359999999997],
        [60.647883, 29.640859],
        [60.657444999999996, 29.640386999999997],
        [60.659150999999994, 29.647337999999998],
        [60.671459999999996, 29.649285],
        [60.682904, 29.633485],
        [60.688748999999994, 29.605801999999997],
        [60.708977, 29.575906999999997],
        [60.714586, 29.574731],
        [60.722652999999994, 29.55097],
        [60.737705999999996, 29.520152]
      ]
    ]
  ]
}, {
  name: `Сланцевский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [58.976381999999994, 27.731785],
        [58.978634, 27.737782],
        [58.984657999999996, 27.744297999999997],
        [58.994406999999995, 27.747011999999998],
        [58.999921, 27.742524999999997],
        [59.00881, 27.73998],
        [59.018809999999995, 27.74467],
        [59.02805, 27.74515],
        [59.03122, 27.75135],
        [59.032439999999994, 27.7666],
        [59.033719999999995, 27.76853],
        [59.044999999999995, 27.77488],
        [59.051899999999996, 27.770909999999997],
        [59.05892, 27.773439999999997],
        [59.065459999999995, 27.78069],
        [59.06979, 27.79204],
        [59.077659999999995, 27.792769999999997],
        [59.08201, 27.788729999999997],
        [59.09162, 27.78805],
        [59.102799999999995, 27.80004],
        [59.10749, 27.8098],
        [59.113459999999996, 27.80342],
        [59.12847, 27.808139999999998],
        [59.13453, 27.819589999999998],
        [59.144169999999995, 27.829729999999998],
        [59.149339999999995, 27.84341],
        [59.1613, 27.86536],
        [59.172, 27.8722],
        [59.184169999999995, 27.88798],
        [59.19479, 27.89137],
        [59.207449999999994, 27.90056],
        [59.21798, 27.89573],
        [59.22571, 27.89935],
        [59.23366, 27.89885],
        [59.24155, 27.9053],
        [59.255081999999994, 27.924666],
        [59.27191, 27.9576],
        [59.27366, 27.96604],
        [59.27363, 27.98217],
        [59.27509, 27.9911],
        [59.288869999999996, 28.033279999999998],
        [59.291019999999996, 28.047719999999998],
        [59.292449999999995, 28.08],
        [59.292249999999996, 28.10972],
        [59.29044, 28.11988],
        [59.29109, 28.128519999999998],
        [59.29504, 28.13628],
        [59.318979999999996, 28.158275],
        [59.295888999999995, 28.292455999999998],
        [59.2876, 28.319239],
        [59.288579, 28.330215],
        [59.301660999999996, 28.372863],
        [59.304266999999996, 28.389825],
        [59.298066999999996, 28.434562999999997],
        [59.298413999999994, 28.476665999999998],
        [59.299827, 28.488204],
        [59.294912, 28.492133],
        [59.293318, 28.497639],
        [59.286742, 28.498229],
        [59.284060999999994, 28.506987],
        [59.283016999999994, 28.51539],
        [59.283925999999994, 28.517967],
        [59.282519, 28.523321],
        [59.277615999999995, 28.522834999999997],
        [59.276059999999994, 28.52749],
        [59.267227, 28.532787],
        [59.245338, 28.533189],
        [59.235685, 28.546191999999998],
        [59.233838, 28.556462999999997],
        [59.220313999999995, 28.547945],
        [59.213794, 28.551841],
        [59.202673, 28.549483],
        [59.193095, 28.557374],
        [59.167722, 28.56292],
        [59.162577, 28.557288],
        [59.156907, 28.556058],
        [59.156454999999994, 28.559058999999998],
        [59.153271999999994, 28.55812],
        [59.155564999999996, 28.569356],
        [59.155054, 28.573732999999997],
        [59.152342999999995, 28.576105],
        [59.152975, 28.581864999999997],
        [59.151362999999996, 28.583419],
        [59.152077, 28.585722999999998],
        [59.150566, 28.585660999999998],
        [59.151030999999996, 28.592126],
        [59.14769, 28.598408],
        [59.149463999999995, 28.612028],
        [59.147019, 28.621629],
        [59.149277, 28.629261999999997],
        [59.151323, 28.646787999999997],
        [59.139834, 28.656879999999997],
        [59.139315999999994, 28.661375],
        [59.140494999999994, 28.661913],
        [59.137847, 28.666711],
        [59.127312999999994, 28.669715999999998],
        [59.113903, 28.686362],
        [59.115801, 28.688187],
        [59.115080999999996, 28.689915],
        [59.111872, 28.687914],
        [59.107527, 28.693136],
        [59.108453, 28.697522],
        [59.114213, 28.702446],
        [59.114020999999994, 28.712498999999998],
        [59.118542, 28.726836],
        [59.124007999999996, 28.730186],
        [59.146046999999996, 28.703819],
        [59.144852, 28.686787],
        [59.140854999999995, 28.665027],
        [59.142851, 28.663505999999998],
        [59.146958, 28.681780999999997],
        [59.151886, 28.679754],
        [59.15692, 28.687044999999998],
        [59.158820999999996, 28.696659999999998],
        [59.167035, 28.699365],
        [59.175354999999996, 28.712388999999998],
        [59.180972, 28.716022],
        [59.174853999999996, 28.736676],
        [59.171532, 28.795844],
        [59.175101999999995, 28.801256],
        [59.176046, 28.799473],
        [59.176877999999995, 28.801005],
        [59.173337, 28.803662],
        [59.17044, 28.797466999999997],
        [59.169149999999995, 28.783858],
        [59.167677, 28.780818999999997],
        [59.173154, 28.76548],
        [59.159563, 28.760545],
        [59.159009999999995, 28.765366],
        [59.156144999999995, 28.770267999999998],
        [59.154275, 28.767782999999998],
        [59.136205999999994, 28.769869],
        [59.136637, 28.773418],
        [59.128961999999994, 28.773176],
        [59.126951999999996, 28.778181],
        [59.113211, 28.793146999999998],
        [59.116094, 28.800331],
        [59.122515, 28.832635],
        [59.125966999999996, 28.841013999999998],
        [59.125899999999994, 28.854248],
        [59.142075999999996, 28.873268999999997],
        [59.144890999999994, 28.882623],
        [59.158136, 28.894192],
        [59.161812, 28.909501],
        [59.165923, 28.915668999999998],
        [59.166194, 28.920779999999997],
        [59.169275, 28.921011],
        [59.174338999999996, 28.910985],
        [59.181329999999996, 28.907135],
        [59.182362, 28.911285999999997],
        [59.184694, 28.908915],
        [59.183411, 28.905331],
        [59.188309, 28.903454999999997],
        [59.185944, 28.912889999999997],
        [59.176002, 28.918015999999998],
        [59.171124999999996, 28.931027],
        [59.167100999999995, 28.949309],
        [59.162692, 28.95807],
        [59.160349, 28.969393999999998],
        [59.154683, 28.973831999999998],
        [59.15372, 28.980179],
        [59.142066, 28.986677999999998],
        [59.141023999999994, 28.994228],
        [59.137138, 28.999215],
        [59.135459, 28.996257],
        [59.131062, 29.004441],
        [59.115221999999996, 29.007565],
        [59.113654999999994, 29.013022],
        [59.111219999999996, 29.014069],
        [59.106635, 29.024046],
        [59.104659999999996, 29.023629],
        [59.100297999999995, 29.031371999999998],
        [59.097429999999996, 29.029227],
        [59.098518999999996, 29.024123999999997],
        [59.09346, 29.013506999999997],
        [59.092199, 29.016833],
        [59.087695, 29.013296],
        [59.084405999999994, 28.994137],
        [59.083495, 28.996439],
        [59.076499, 28.98557],
        [59.073057, 28.968387999999997],
        [59.069787, 28.965888999999997],
        [59.063050999999994, 28.968501],
        [59.056307999999994, 28.978727],
        [59.052597, 28.976951999999997],
        [59.047509, 28.98311],
        [59.044365, 28.975187],
        [59.04296, 28.988225999999997],
        [59.027003, 29.001980999999997],
        [59.021215, 29.01041],
        [59.018443999999995, 29.001987],
        [59.022943, 28.995077],
        [59.022476, 28.982979],
        [59.024685, 28.979397],
        [59.022873999999995, 28.965377],
        [59.02428, 28.960853999999998],
        [59.029726, 28.954468],
        [59.028714, 28.942353999999998],
        [59.029455999999996, 28.933714],
        [59.02735, 28.933408],
        [59.027742999999994, 28.927654999999998],
        [59.020346999999994, 28.918924999999998],
        [59.015753, 28.901249],
        [59.006504, 28.907705],
        [59.001695, 28.901753],
        [58.987959, 28.913577999999998],
        [58.985071999999995, 28.918376],
        [58.982245, 28.914165999999998],
        [58.981539999999995, 28.917578],
        [58.973565, 28.915194],
        [58.971914, 28.911251],
        [58.977523, 28.900263],
        [58.979813, 28.898522999999997],
        [58.981972, 28.901308],
        [58.984334, 28.897305],
        [58.986459999999994, 28.887988],
        [58.986199, 28.881937999999998],
        [58.982679999999995, 28.872597],
        [58.982983, 28.860595],
        [58.978671999999996, 28.85773],
        [58.977318999999994, 28.851858999999997],
        [58.980737, 28.837294],
        [58.980132, 28.832171],
        [58.98121, 28.831286],
        [58.95793, 28.707231999999998],
        [58.961459999999995, 28.683678999999998],
        [58.959874, 28.677608],
        [58.956939, 28.678485],
        [58.954268, 28.685063],
        [58.951885999999995, 28.684516],
        [58.947449, 28.688736],
        [58.949172999999995, 28.682527],
        [58.921164999999995, 28.695757],
        [58.922191999999995, 28.696989],
        [58.921822, 28.702779],
        [58.918645, 28.703922],
        [58.91287, 28.69627],
        [58.909959, 28.696064],
        [58.908573999999994, 28.702593999999998],
        [58.904433, 28.702287],
        [58.903802999999996, 28.696488],
        [58.895498999999994, 28.686232],
        [58.893527, 28.689567],
        [58.890404999999994, 28.690181],
        [58.888313, 28.697350999999998],
        [58.884102, 28.702313999999998],
        [58.882979999999996, 28.701031],
        [58.883226, 28.710379999999997],
        [58.879374, 28.706507],
        [58.876318999999995, 28.69496],
        [58.870376, 28.687507999999998],
        [58.869595, 28.692173999999998],
        [58.868536999999996, 28.691205999999998],
        [58.863059, 28.704096],
        [58.857931, 28.707697999999997],
        [58.852712999999994, 28.722471],
        [58.85427, 28.732093],
        [58.851406999999995, 28.731285],
        [58.851368, 28.735426999999998],
        [58.848853, 28.736268],
        [58.847759999999994, 28.74108],
        [58.842572999999994, 28.746052],
        [58.842546, 28.75459],
        [58.843129999999995, 28.755982],
        [58.843565, 28.750131],
        [58.845197, 28.750411999999997],
        [58.845639, 28.757932999999998],
        [58.843216, 28.767933],
        [58.838516999999996, 28.764777],
        [58.832572, 28.772088],
        [58.830946, 28.765914],
        [58.827301999999996, 28.769733],
        [58.824898999999995, 28.768960999999997],
        [58.829282, 28.745915],
        [58.832840999999995, 28.742089],
        [58.849526999999995, 28.677951999999998],
        [58.852357999999995, 28.666501],
        [58.851448, 28.663099],
        [58.852197999999994, 28.66151],
        [58.856331999999995, 28.668325],
        [58.863907, 28.671713999999998],
        [58.866797, 28.667984999999998],
        [58.866467, 28.660353999999998],
        [58.855559, 28.655845],
        [58.852581, 28.644741999999997],
        [58.855318999999994, 28.641189999999998],
        [58.855317, 28.636077999999998],
        [58.860806999999994, 28.633218],
        [58.863115, 28.621261999999998],
        [58.86474, 28.621848999999997],
        [58.868542999999995, 28.598042999999997],
        [58.867098, 28.596892999999998],
        [58.864982, 28.600154],
        [58.860358999999995, 28.589216],
        [58.862677, 28.586159],
        [58.864059999999995, 28.588410999999997],
        [58.86672, 28.586582999999997],
        [58.867194, 28.575445],
        [58.865877999999995, 28.568234],
        [58.868513, 28.563803],
        [58.866346, 28.557312999999997],
        [58.867373, 28.555951],
        [58.866352, 28.552222],
        [58.868126999999994, 28.539841],
        [58.866226999999995, 28.539215],
        [58.864281999999996, 28.542271],
        [58.863063999999994, 28.536258],
        [58.860679999999995, 28.534423],
        [58.861853999999994, 28.529434],
        [58.867756, 28.523844],
        [58.865002999999994, 28.517006],
        [58.867639, 28.512354],
        [58.862088, 28.502254999999998],
        [58.862565999999994, 28.493311],
        [58.868888, 28.487154],
        [58.86947, 28.478493],
        [58.871503999999995, 28.478157],
        [58.877568999999994, 28.459937999999998],
        [58.87882, 28.452628999999998],
        [58.877236999999994, 28.447879999999998],
        [58.880399999999995, 28.436671],
        [58.874029, 28.434290999999998],
        [58.870807, 28.424294999999997],
        [58.868218999999996, 28.424498999999997],
        [58.865106, 28.416757999999998],
        [58.86056, 28.411723],
        [58.857927999999994, 28.40936],
        [58.855689999999996, 28.415153],
        [58.854633, 28.411669999999997],
        [58.867445, 28.388561],
        [58.871246, 28.390912999999998],
        [58.874269999999996, 28.378650999999998],
        [58.878321, 28.372664],
        [58.869909, 28.358652],
        [58.867214999999995, 28.363453999999997],
        [58.861478999999996, 28.358886],
        [58.862462, 28.348746],
        [58.860147999999995, 28.345188],
        [58.854127, 28.344051999999998],
        [58.851416, 28.325146999999998],
        [58.844972, 28.321068],
        [58.843734999999995, 28.326318999999998],
        [58.831644, 28.340166999999997],
        [58.827009999999994, 28.348945999999998],
        [58.824667999999996, 28.345222],
        [58.840356, 28.311086999999997],
        [58.830935999999994, 28.311069],
        [58.823947, 28.300214999999998],
        [58.825917999999994, 28.294458],
        [58.840078999999996, 28.287829],
        [58.839994, 28.284425],
        [58.859128999999996, 28.274186999999998],
        [58.874669999999995, 28.26074],
        [58.874658, 28.243958],
        [58.87996, 28.239352],
        [58.881541999999996, 28.255328],
        [58.885287999999996, 28.266607999999998],
        [58.889013999999996, 28.266406],
        [58.892129, 28.259973],
        [58.897785, 28.257588],
        [58.894684, 28.229868999999997],
        [58.897169999999996, 28.223381],
        [58.905302, 28.211606],
        [58.910987, 28.214318],
        [58.911832, 28.217651999999998],
        [58.910888, 28.220131],
        [58.913317, 28.222758],
        [58.913674, 28.218930999999998],
        [58.920263, 28.215633],
        [58.920790999999994, 28.211845],
        [58.923749, 28.21692],
        [58.9343, 28.203073999999997],
        [58.936347999999995, 28.196475999999997],
        [58.940144, 28.204462999999997],
        [58.941938, 28.201615],
        [58.941187, 28.188024],
        [58.962534, 28.15056],
        [58.970853999999996, 28.177042],
        [58.976957, 28.176475999999997],
        [58.972925, 28.160577],
        [58.972702999999996, 28.149454],
        [58.978412999999996, 28.144579999999998],
        [58.974373, 28.122241],
        [58.992391, 28.121214],
        [58.993291, 28.101347999999998],
        [59.000549, 28.098509],
        [58.99999, 28.092883],
        [58.998343999999996, 28.092558],
        [58.99173, 28.080123999999998],
        [58.990829, 28.074251999999998],
        [58.992399, 28.066347999999998],
        [58.991986, 28.054250999999997],
        [58.991198999999995, 28.047715999999998],
        [58.986886, 28.045880999999998],
        [58.986906, 28.038057],
        [58.995413, 28.039793999999997],
        [58.999005, 28.035736999999997],
        [59.002288, 28.028938999999998],
        [59.004715999999995, 27.997248],
        [59.012561, 27.99953],
        [59.015961999999995, 27.991],
        [59.015091, 27.93244],
        [59.019081, 27.928487999999998],
        [59.021015, 27.919580999999997],
        [59.015389, 27.886719]
      ]
    ]
  ]
}, {
  name: `Тихвинский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.62592, 32.662861],
        [59.672621, 32.689771],
        [59.750077, 32.762971],
        [59.766059999999996, 32.850783],
        [59.762502999999995, 32.861115999999996],
        [59.766724999999994, 32.851456999999996],
        [59.775889, 32.850636],
        [59.777319999999996, 32.886808],
        [59.780536, 32.888905],
        [59.779587, 32.908069999999995],
        [59.784369999999996, 32.917994],
        [59.776149999999994, 32.950253],
        [59.777868999999995, 32.955278],
        [59.796239, 32.944564],
        [59.798080999999996, 32.957101],
        [59.794138, 32.961076],
        [59.811381, 32.996727],
        [59.845907, 32.991037999999996],
        [59.846892, 33.006028],
        [59.841563, 33.018775],
        [59.838646999999995, 33.016264],
        [59.827754999999996, 33.03402],
        [59.823014, 33.033831],
        [59.796927, 33.061526],
        [59.799696999999995, 33.06681],
        [59.810272, 33.062571999999996],
        [59.819593, 33.048032],
        [59.833355999999995, 33.041588],
        [59.840452, 33.051927],
        [59.848216, 33.051989],
        [59.849880999999996, 33.048922],
        [59.840576999999996, 33.037738],
        [59.841885999999995, 33.023767],
        [59.858514, 33.010768],
        [59.856173, 33.099523],
        [59.880165999999996, 33.100206],
        [59.893347999999996, 33.113715],
        [59.895409, 33.117582999999996],
        [59.892443, 33.142888],
        [59.9344, 33.147622],
        [59.945218999999994, 33.176074],
        [59.98037, 33.129872999999996],
        [59.997625, 33.125037],
        [59.999215, 33.165124],
        [60.003865999999995, 33.170277999999996],
        [60.006485, 33.180651],
        [60.013711, 33.171896],
        [60.019332, 33.175636],
        [60.023077, 33.171454],
        [60.027943, 33.155867],
        [60.039142999999996, 33.139023],
        [60.047779999999996, 33.136305],
        [60.050427, 33.139776],
        [60.053695, 33.132054],
        [60.065054999999994, 33.133359999999996],
        [60.087714, 33.098659999999995],
        [60.126909999999995, 33.07244],
        [60.145984999999996, 33.052011],
        [60.179232, 33.061883],
        [60.195505, 33.079293],
        [60.195183, 33.098866],
        [60.202315, 33.124840999999996],
        [60.205850999999996, 33.170691999999995],
        [60.202293, 33.193464999999996],
        [60.210891, 33.205767],
        [60.210896, 33.212285],
        [60.18091, 33.239125],
        [60.180906, 33.261719],
        [60.17818, 33.266287],
        [60.154348, 33.287859],
        [60.144262999999995, 33.284793],
        [60.147495, 33.304818],
        [60.145903999999994, 33.330141],
        [60.141248, 33.329412],
        [60.130148999999996, 33.355727],
        [60.135093999999995, 33.367205999999996],
        [60.123867, 33.408167999999996],
        [60.122366, 33.429885999999996],
        [60.113268999999995, 33.441097],
        [60.11627, 33.448654],
        [60.110265, 33.458698999999996],
        [60.114197, 33.464124999999996],
        [60.12081, 33.462331999999996],
        [60.121441999999995, 33.476954],
        [60.130052, 33.479019],
        [60.132619999999996, 33.487997],
        [60.130441, 33.497119],
        [60.133744, 33.515561],
        [60.113887999999996, 33.568159],
        [60.113910999999995, 33.593598],
        [60.111081999999996, 33.594195],
        [60.110831999999995, 33.607023],
        [60.117067999999996, 33.636914],
        [60.12668, 33.638413],
        [60.133548, 33.632821],
        [60.142740999999994, 33.650707],
        [60.178290999999994, 33.678459],
        [60.174544999999995, 33.68588],
        [60.166031999999994, 33.683419],
        [60.168347, 33.699774],
        [60.166866, 33.746732],
        [60.162079999999996, 33.745599999999996],
        [60.162946999999996, 33.787251999999995],
        [60.155094, 33.785053],
        [60.150534, 33.794748999999996],
        [60.151022999999995, 33.804249999999996],
        [60.158376999999994, 33.797816],
        [60.159017999999996, 33.816643],
        [60.165941999999994, 33.819885],
        [60.169933, 33.829384999999995],
        [60.167868, 33.835509],
        [60.16316, 33.835457],
        [60.163506, 33.864849],
        [60.15548, 33.895528],
        [60.158300999999994, 33.903624],
        [60.147495, 33.910517999999996],
        [60.15658, 33.937025999999996],
        [60.152235999999995, 33.952788999999996],
        [60.159417999999995, 33.976189],
        [60.167817, 33.970791],
        [60.164769, 34.053129],
        [60.161313, 34.057629999999996],
        [60.15777, 34.086175],
        [60.159524, 34.09512],
        [60.164784999999995, 34.098479],
        [60.165796, 34.116005],
        [60.158679, 34.124635999999995],
        [60.152333, 34.111667],
        [60.139858, 34.108328],
        [60.145835999999996, 34.164618],
        [60.152335, 34.163548],
        [60.155727999999996, 34.170097],
        [60.157304999999994, 34.162262999999996],
        [60.161159999999995, 34.180893999999995],
        [60.157973999999996, 34.423645],
        [60.167865, 34.437095],
        [60.168029, 34.452249],
        [60.173218, 34.467074],
        [60.173916, 34.486155],
        [60.177633, 34.499587999999996],
        [60.183028, 34.506302],
        [60.188872999999994, 34.530626999999996],
        [60.232122999999994, 34.495939],
        [60.239652, 34.479256],
        [60.241899, 34.48117],
        [60.242121, 34.495225],
        [60.254304999999995, 34.499463999999996],
        [60.253322999999995, 34.50501],
        [60.267542, 34.512980999999996],
        [60.281285, 34.54072],
        [60.290710999999995, 34.647963],
        [60.29606, 34.719452],
        [60.25866, 34.732273],
        [60.257692999999996, 34.739017],
        [60.269633999999996, 34.873784],
        [60.235783999999995, 34.888563],
        [60.236214999999994, 34.955666],
        [60.244336999999994, 34.995394999999995],
        [60.245273, 35.03375],
        [60.241091, 35.038689999999995],
        [60.250496, 35.04103],
        [60.251332, 35.068408999999996],
        [60.259397, 35.092545],
        [60.25908, 35.139792],
        [60.25487, 35.145707],
        [60.24659, 35.189375999999996],
        [60.225666999999994, 35.188944],
        [60.219584999999995, 35.181315],
        [60.21304, 35.190245],
        [60.2026, 35.190899],
        [60.187219, 35.177613],
        [60.168918, 35.173089],
        [60.167185999999994, 35.178953],
        [60.158088, 35.167304],
        [60.130795, 35.179299],
        [60.126908, 35.192626],
        [60.116129, 35.204875],
        [60.06946, 35.215722],
        [60.063074, 35.192741999999996],
        [60.047002, 35.161155],
        [60.045083, 35.145106999999996],
        [60.046988, 35.112542],
        [60.04884, 35.112466],
        [60.047014999999995, 35.078587],
        [60.04866, 35.064254999999996],
        [60.061144999999996, 35.061085999999996],
        [60.062287999999995, 35.047743],
        [60.092211, 35.023857],
        [60.091083, 35.033474999999996],
        [60.095813, 35.028957999999996],
        [60.100615999999995, 35.031946],
        [60.098068999999995, 35.038503999999996],
        [60.106327, 35.039088],
        [60.107236, 35.046229],
        [60.112291, 35.050529],
        [60.109719, 35.059830999999996],
        [60.089411999999996, 35.046215],
        [60.085212999999996, 35.04971],
        [60.084775, 35.058284],
        [60.091795, 35.080075],
        [60.097319999999996, 35.074183],
        [60.100035, 35.081299],
        [60.120042, 35.081581],
        [60.119963999999996, 35.090748],
        [60.138476, 35.088462],
        [60.139162999999996, 35.080237],
        [60.134977, 35.079436],
        [60.138920999999996, 35.068301999999996],
        [60.141127999999995, 35.071985],
        [60.140074999999996, 35.064561999999995],
        [60.143049999999995, 35.056596],
        [60.140459, 35.056253],
        [60.138935999999994, 35.042891],
        [60.137795999999994, 35.047801],
        [60.134949, 35.046174],
        [60.136415, 35.056509999999996],
        [60.134665999999996, 35.06152],
        [60.130154999999995, 35.057606],
        [60.135385, 35.065413],
        [60.128265, 35.061910999999995],
        [60.126332999999995, 35.056678],
        [60.131004, 35.046800999999995],
        [60.130691, 35.040890999999995],
        [60.134507, 35.042547],
        [60.137226999999996, 35.040892],
        [60.132968999999996, 35.033668],
        [60.121347, 35.031613],
        [60.12166, 35.015602],
        [60.125111, 35.01535],
        [60.125564999999995, 35.00358],
        [60.130556999999996, 34.99913],
        [60.132222, 35.007926],
        [60.137665999999996, 35.009533],
        [60.140088999999996, 35.026959],
        [60.148188999999995, 35.021992],
        [60.151078, 35.011457],
        [60.15031, 35.031586],
        [60.154377999999994, 35.037803],
        [60.1603, 35.037955],
        [60.164319, 35.024279],
        [60.168445, 35.020454],
        [60.170351, 35.002638],
        [60.173024999999996, 35.001639],
        [60.172869, 35.013945],
        [60.188362999999995, 35.010937999999996],
        [60.189153999999995, 35.003457],
        [60.179480999999996, 35.002752],
        [60.180788, 34.931686],
        [60.140699, 34.928636],
        [60.133877999999996, 34.957665],
        [60.118638999999995, 34.96414],
        [60.1132, 34.984513],
        [60.108473, 34.986115],
        [60.108799999999995, 34.981232999999996],
        [60.107163, 34.980505],
        [60.101561999999994, 34.997408],
        [60.096723, 35.001051],
        [60.097972999999996, 34.959587],
        [60.093101, 34.958326],
        [60.090900999999995, 34.962903],
        [60.088494999999995, 34.95836],
        [60.089893999999994, 34.949582],
        [60.087728, 34.955763999999995],
        [60.086824, 34.947054],
        [60.083292, 34.942477],
        [60.083138999999996, 34.932297999999996],
        [60.086689, 34.928038],
        [60.087616999999995, 34.919003],
        [60.093379999999996, 34.913603],
        [60.092659, 34.890668],
        [60.081328, 34.889085],
        [60.079538, 34.914451],
        [60.075255999999996, 34.920933],
        [60.073935999999996, 34.93145],
        [60.062307999999994, 34.94181],
        [60.066410999999995, 34.948215999999995],
        [60.067626999999995, 34.96704],
        [60.067459, 34.977779999999996],
        [60.065152999999995, 34.989135999999995],
        [60.062846, 34.987569],
        [60.062174999999996, 34.996798999999996],
        [60.049943999999996, 34.993995],
        [60.050287, 35.001252],
        [59.994142999999994, 34.99535],
        [59.985927, 34.985327999999996],
        [59.973541999999995, 34.92751],
        [59.960583, 34.910708],
        [59.963514999999994, 34.905246],
        [59.963207999999995, 34.890786999999996],
        [59.960471999999996, 34.907032],
        [59.958783, 34.909406],
        [59.956446, 34.899755],
        [59.954887, 34.90866],
        [59.95285, 34.907807999999996],
        [59.948231, 34.880199],
        [59.929412, 34.889108],
        [59.923522, 34.878735999999996],
        [59.921791999999996, 34.872761],
        [59.924440999999995, 34.863703],
        [59.924628, 34.83659],
        [59.915402, 34.834336],
        [59.915274, 34.814049],
        [59.906048, 34.812796],
        [59.902017, 34.818362],
        [59.898824999999995, 34.812449],
        [59.882864999999995, 34.813331],
        [59.87558, 34.83086],
        [59.870920999999996, 34.829525],
        [59.866626, 34.813189],
        [59.861633, 34.807409],
        [59.85322, 34.766000999999996],
        [59.856609, 34.74254],
        [59.853798, 34.72873],
        [59.848569, 34.731567999999996],
        [59.848364999999994, 34.739629],
        [59.826128999999995, 34.748177999999996],
        [59.826946, 34.577712999999996],
        [59.689102, 34.569555],
        [59.677178, 34.539457999999996],
        [59.677367, 34.52501],
        [59.693666, 34.494872],
        [59.690621, 34.41897],
        [59.719646, 34.417091],
        [59.715855, 34.306726],
        [59.725209, 34.301080999999996],
        [59.732414999999996, 34.240489],
        [59.731275, 34.22748],
        [59.73808, 34.210068],
        [59.733737, 34.147878],
        [59.737449999999995, 34.127106],
        [59.745117, 34.109750999999996],
        [59.740888, 34.095406],
        [59.736566999999994, 34.092399],
        [59.737643999999996, 34.055541999999996],
        [59.73399, 34.047452],
        [59.793389999999995, 34.039511],
        [59.79322, 34.045556],
        [59.821296, 34.035219999999995],
        [59.823435999999994, 33.855467],
        [59.820674, 33.852204],
        [59.819219999999994, 33.855987],
        [59.813927, 33.844718],
        [59.816458, 33.837804],
        [59.804887, 33.806863],
        [59.785880999999996, 33.780702999999995],
        [59.709467999999994, 33.779230999999996],
        [59.672518999999994, 33.771405],
        [59.669871, 33.762597],
        [59.663131, 33.756266],
        [59.659772999999994, 33.740843999999996],
        [59.654447, 33.749269999999996],
        [59.656053, 33.760172],
        [59.644399, 33.763051],
        [59.642503, 33.699569],
        [59.628279, 33.703955],
        [59.632906999999996, 33.685660999999996],
        [59.633013, 33.669748],
        [59.639762, 33.65427],
        [59.62976, 33.65558],
        [59.631992, 33.646663],
        [59.626295999999996, 33.651849],
        [59.624638999999995, 33.647846],
        [59.634586999999996, 33.643003],
        [59.63379, 33.633635999999996],
        [59.632380999999995, 33.631384],
        [59.624900999999994, 33.642224999999996],
        [59.619431, 33.63946],
        [59.613895, 33.629650999999996],
        [59.615147, 33.623675999999996],
        [59.572277, 33.617215],
        [59.572621, 33.635887],
        [59.561465999999996, 33.623339],
        [59.559628, 33.632017999999995],
        [59.551649, 33.642136],
        [59.548105, 33.698833],
        [59.529771, 33.700575],
        [59.526759, 33.71562],
        [59.510723999999996, 33.69383],
        [59.510748, 33.686764],
        [59.502348999999995, 33.685390999999996],
        [59.502979999999994, 33.646274999999996],
        [59.488876999999995, 33.64407],
        [59.484241, 33.620982],
        [59.48929, 33.60825],
        [59.480169999999994, 33.597408],
        [59.385453, 33.572016],
        [59.386888, 33.541947],
        [59.377899, 33.528687],
        [59.379548, 33.487043],
        [59.377587999999996, 33.452939],
        [59.384588, 33.351825],
        [59.31532, 33.341421],
        [59.320546, 33.260442999999995],
        [59.337146, 33.261162999999996],
        [59.347573, 33.245166999999995],
        [59.361579, 33.236857],
        [59.362868999999996, 33.214835],
        [59.382146, 33.159105],
        [59.375299, 33.149052999999995],
        [59.373461999999996, 33.137603],
        [59.388346, 33.116707999999996],
        [59.379169, 33.068712999999995],
        [59.382442, 33.066127],
        [59.384553, 33.074495],
        [59.388358, 33.065424],
        [59.418774, 33.062548],
        [59.423100999999996, 33.055713],
        [59.425436, 32.997518],
        [59.418515, 32.967847],
        [59.424212, 32.966153],
        [59.42776, 32.952388],
        [59.438969, 32.948595],
        [59.438798, 32.880224999999996],
        [59.428047, 32.829482],
        [59.436690999999996, 32.787461],
        [59.451257999999996, 32.777661],
        [59.47425, 32.799811999999996],
        [59.489915999999994, 32.801384999999996],
        [59.495064, 32.84694],
        [59.519014, 32.847094999999996],
        [59.530051, 32.836283],
        [59.536460999999996, 32.844497],
        [59.556920999999996, 32.844089],
        [59.542061, 32.803185],
        [59.541559, 32.771139],
        [59.619992999999994, 32.778245999999996]
      ]
    ]
  ]
}, {
  name: `Тосненский район`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.638329999999996, 30.465829],
        [59.639976999999995, 30.472186999999998],
        [59.637653, 30.481458],
        [59.643012, 30.489069999999998],
        [59.645990999999995, 30.505457],
        [59.668892, 30.501887999999997],
        [59.670038999999996, 30.489859],
        [59.677884, 30.488884],
        [59.677676999999996, 30.478882],
        [59.680786999999995, 30.477162999999997],
        [59.684875999999996, 30.486093999999998],
        [59.688466, 30.48798],
        [59.690918999999994, 30.520546999999997],
        [59.693681, 30.523259999999997],
        [59.696555, 30.519707],
        [59.700387, 30.524418999999998],
        [59.704299999999996, 30.524472],
        [59.704487, 30.530136],
        [59.707269999999994, 30.530777999999998],
        [59.704978, 30.564254],
        [59.729977999999996, 30.576503],
        [59.729079999999996, 30.593041],
        [59.730061, 30.59244],
        [59.730869, 30.617316],
        [59.735338999999996, 30.616584999999997],
        [59.73594, 30.630149999999997],
        [59.721188, 30.64855],
        [59.724774999999994, 30.684175],
        [59.731597, 30.679077999999997],
        [59.744616, 30.695427],
        [59.746876, 30.703443999999998],
        [59.746086, 30.725817],
        [59.743854999999996, 30.756898],
        [59.738029, 30.760628999999998],
        [59.7313, 30.757158999999998],
        [59.729006, 30.763230999999998],
        [59.726130999999995, 30.764360999999997],
        [59.726884999999996, 30.776708],
        [59.732769, 30.777131999999998],
        [59.732752999999995, 30.786959],
        [59.727470999999994, 30.78953],
        [59.728141, 30.785997],
        [59.726186, 30.784024],
        [59.721, 30.794822999999997],
        [59.715894999999996, 30.795060999999997],
        [59.718947, 30.806348999999997],
        [59.731961999999996, 30.802912],
        [59.737024, 30.840377],
        [59.736698999999994, 30.849527],
        [59.731597, 30.856973],
        [59.727944, 30.856438999999998],
        [59.726237999999995, 30.852688999999998],
        [59.716581999999995, 30.852037],
        [59.718135999999994, 30.891053],
        [59.722578999999996, 30.941879999999998],
        [59.699441, 30.916141],
        [59.726259999999996, 30.963113999999997],
        [59.715081999999995, 30.96868],
        [59.691381, 30.973923],
        [59.690099999999994, 30.970717999999998],
        [59.681382, 30.967475999999998],
        [59.672779999999996, 30.957829999999998],
        [59.674614, 30.948007999999998],
        [59.667437, 30.922062999999998],
        [59.655446, 30.961789999999997],
        [59.645443, 30.958717999999998],
        [59.634512, 30.966883],
        [59.631764999999994, 30.984249],
        [59.635524999999994, 31.004109],
        [59.632259, 31.016517999999998],
        [59.632104999999996, 31.023688],
        [59.634187999999995, 31.032380999999997],
        [59.633210999999996, 31.035989999999998],
        [59.634409, 31.043633999999997],
        [59.636882, 31.047855],
        [59.636655999999995, 31.082304999999998],
        [59.635498, 31.091506],
        [59.633607999999995, 31.093839],
        [59.632042, 31.092003],
        [59.629825999999994, 31.121131],
        [59.630078999999995, 31.149745],
        [59.625097999999994, 31.150143999999997],
        [59.625049, 31.158582],
        [59.634243999999995, 31.159439],
        [59.632473, 31.170219],
        [59.636193, 31.174947],
        [59.636033, 31.183017],
        [59.633112999999994, 31.183232999999998],
        [59.631606, 31.17787],
        [59.6275, 31.181942999999997],
        [59.629625999999995, 31.188105],
        [59.628347, 31.192781],
        [59.628875, 31.20129],
        [59.632726999999996, 31.206764999999997],
        [59.634654, 31.203650999999997],
        [59.632799, 31.194012999999998],
        [59.637747999999995, 31.189981999999997],
        [59.640736, 31.196612],
        [59.640722999999994, 31.200014999999997],
        [59.636841999999994, 31.20111],
        [59.63594, 31.208517999999998],
        [59.649054, 31.234862999999997],
        [59.64922, 31.252163],
        [59.645267999999994, 31.252919],
        [59.643134999999994, 31.246958],
        [59.638352999999995, 31.243579999999998],
        [59.634358999999996, 31.253280999999998],
        [59.636444999999995, 31.255619999999997],
        [59.635467, 31.259075],
        [59.631479, 31.255941999999997],
        [59.626179, 31.26192],
        [59.628563, 31.272471999999997],
        [59.626906999999996, 31.273408],
        [59.62578, 31.280503999999997],
        [59.621424, 31.279374999999998],
        [59.619167999999995, 31.269192],
        [59.616884, 31.270951999999998],
        [59.616116999999996, 31.26839],
        [59.615916999999996, 31.271490999999997],
        [59.61241, 31.272945999999997],
        [59.608852, 31.273297],
        [59.607327, 31.268952],
        [59.607367999999994, 31.272676999999998],
        [59.605577, 31.273660999999997],
        [59.594955, 31.274192],
        [59.590185999999996, 31.27829],
        [59.585192, 31.274867999999998],
        [59.579944999999995, 31.277883],
        [59.575955, 31.287253999999997],
        [59.567353, 31.287028],
        [59.562903, 31.290191],
        [59.561783999999996, 31.297416],
        [59.559785, 31.298479999999998],
        [59.559025, 31.306016999999997],
        [59.552066999999994, 31.312697999999997],
        [59.550703999999996, 31.310309999999998],
        [59.551364, 31.305415999999997],
        [59.547491, 31.300531999999997],
        [59.532272, 31.332582],
        [59.531788, 31.343885999999998],
        [59.53514, 31.350906],
        [59.537602, 31.350821],
        [59.542843, 31.384921],
        [59.541494, 31.386392999999998],
        [59.541365, 31.418646],
        [59.536879, 31.454442],
        [59.538047, 31.467416999999998],
        [59.537068999999995, 31.474304999999998],
        [59.541323999999996, 31.486053],
        [59.539296, 31.497632],
        [59.532945, 31.505799999999997],
        [59.526906999999994, 31.504132],
        [59.518587999999994, 31.518293999999997],
        [59.514793, 31.516185999999998],
        [59.508525, 31.520519],
        [59.502047, 31.525543],
        [59.500136, 31.532147],
        [59.497896999999995, 31.530732999999998],
        [59.49619, 31.534426999999997],
        [59.507172, 31.551094],
        [59.508947, 31.549245],
        [59.514395, 31.552191999999998],
        [59.516059999999996, 31.56128],
        [59.536834, 31.602746999999997],
        [59.475995, 31.608912999999998],
        [59.464074, 31.584021],
        [59.45428, 31.581072],
        [59.444967, 31.583855999999997],
        [59.442656, 31.572765999999998],
        [59.437461, 31.567425],
        [59.437405, 31.572408],
        [59.428394, 31.583858],
        [59.425819, 31.584184999999998],
        [59.421316, 31.578685],
        [59.417918, 31.565880999999997],
        [59.397242, 31.574506],
        [59.396077999999996, 31.579258],
        [59.391434, 31.578329],
        [59.38926, 31.584820999999998],
        [59.385523, 31.587387999999997],
        [59.384071999999996, 31.593498],
        [59.378626999999994, 31.594663999999998],
        [59.376524999999994, 31.598595],
        [59.374593999999995, 31.597033],
        [59.37155, 31.579273999999998],
        [59.369014, 31.552719999999997],
        [59.356538, 31.542645999999998],
        [59.352714999999996, 31.5479],
        [59.339642999999995, 31.549526999999998],
        [59.332425, 31.549445],
        [59.32723, 31.542021],
        [59.315360999999996, 31.542529],
        [59.311468, 31.554951],
        [59.305536, 31.553403],
        [59.29812, 31.570909],
        [59.293882999999994, 31.560178999999998],
        [59.294214999999994, 31.542748999999997],
        [59.283542, 31.537867],
        [59.25242, 31.557651],
        [59.231111999999996, 31.583060999999997],
        [59.221951, 31.570876],
        [59.211445999999995, 31.569177999999997],
        [59.205805, 31.529784],
        [59.20243, 31.530431999999998],
        [59.212204, 31.512262999999997],
        [59.206621999999996, 31.499736],
        [59.200522, 31.510976999999997],
        [59.164763, 31.472535999999998],
        [59.165676999999995, 31.493073],
        [59.16366, 31.486932999999997],
        [59.159974, 31.490876999999998],
        [59.138825, 31.487301],
        [59.124548, 31.467772999999998],
        [59.115198, 31.474300999999997],
        [59.11016, 31.472234],
        [59.102320999999996, 31.4816],
        [59.092952, 31.469036],
        [59.094167999999996, 31.462729],
        [59.089341999999995, 31.458074999999997],
        [59.087309999999995, 31.461764],
        [59.072385999999995, 31.429153999999997],
        [59.074811999999994, 31.421014],
        [59.065338, 31.405030999999997],
        [59.065284, 31.384038999999998],
        [59.056867999999994, 31.379603999999997],
        [59.057676, 31.373901],
        [59.055389999999996, 31.372121],
        [59.056678, 31.36309],
        [59.05374, 31.355532],
        [59.052091, 31.358297],
        [59.054016, 31.364088],
        [59.047315, 31.375588999999998],
        [59.042387, 31.37201],
        [59.041140999999996, 31.376818],
        [59.026775, 31.371042],
        [59.025931, 31.358964999999998],
        [59.027077999999996, 31.354266],
        [59.025037, 31.340175],
        [59.02997, 31.331049],
        [59.025816999999996, 31.323812],
        [59.027677, 31.32013],
        [59.023331999999996, 31.311017999999997],
        [59.018251, 31.32019],
        [59.012442, 31.300743999999998],
        [59.008010999999996, 31.303827],
        [59.006449999999994, 31.223619999999997],
        [59.046943, 31.190707],
        [59.04683, 31.166752],
        [59.050267, 31.175680999999997],
        [59.054505999999996, 31.165834],
        [59.053678999999995, 31.160286],
        [59.054956999999995, 31.156768],
        [59.062089, 31.167645999999998],
        [59.069266, 31.164502],
        [59.075807999999995, 31.166840999999998],
        [59.078925, 31.153084],
        [59.089932, 31.131482],
        [59.090872999999995, 31.083081999999997],
        [59.074380999999995, 31.071066],
        [59.069281, 31.062706],
        [59.072339, 31.053268],
        [59.075191, 31.057612],
        [59.077155, 31.050124999999998],
        [59.072917999999994, 31.042008],
        [59.068166, 31.022333999999997],
        [59.057955, 31.015185],
        [59.056255, 31.010453],
        [59.040687999999996, 30.991329],
        [59.044089, 30.984561999999997],
        [59.04405, 30.972704999999998],
        [59.031844, 30.971863],
        [59.034796, 30.967475],
        [59.032554999999995, 30.956761999999998],
        [59.015701, 30.956722],
        [59.015741, 30.972284],
        [58.999765999999994, 31.006331999999997],
        [58.992630999999996, 30.989656999999998],
        [58.992951, 30.943786],
        [58.99981, 30.956559],
        [59.011303999999996, 30.961917],
        [59.013127, 30.954469],
        [59.009124, 30.946724999999997],
        [59.008893, 30.940666999999998],
        [59.005019, 30.936954],
        [59.002494, 30.924239999999998],
        [59.007557999999996, 30.917109],
        [59.009024, 30.921834999999998],
        [59.011244999999995, 30.919991999999997],
        [59.009679999999996, 30.910852],
        [59.006719999999994, 30.913567999999998],
        [59.011429, 30.898415],
        [59.014382, 30.900308],
        [59.014585999999994, 30.910480999999997],
        [59.041084999999995, 30.915739],
        [59.041563, 30.926042],
        [59.045431, 30.911921999999997],
        [59.053717, 30.911158],
        [59.052906, 30.921464],
        [59.058823999999994, 30.928175],
        [59.060258, 30.935496999999998],
        [59.068780999999994, 30.92906],
        [59.071562, 30.932655999999998],
        [59.07787, 30.930593],
        [59.080973, 30.924146],
        [59.074566, 30.926503999999998],
        [59.07742, 30.923043999999997],
        [59.075047999999995, 30.918342],
        [59.073769999999996, 30.89293],
        [59.089357, 30.898625],
        [59.089386, 30.885022],
        [59.096336, 30.876393],
        [59.097947, 30.868876],
        [59.100319999999996, 30.866723],
        [59.095009999999995, 30.855103999999997],
        [59.097994, 30.844724],
        [59.080686, 30.841331999999998],
        [59.0789, 30.826656999999997],
        [59.076429999999995, 30.826324999999997],
        [59.072317, 30.769526],
        [59.069562999999995, 30.758388],
        [59.070336, 30.744483],
        [59.058206999999996, 30.727145],
        [59.049408, 30.732837],
        [59.058282, 30.703799999999998],
        [59.058325999999994, 30.670001],
        [59.065104999999996, 30.657898],
        [59.063494, 30.656523999999997],
        [59.063674, 30.608615999999998],
        [59.074284999999996, 30.59618],
        [59.141014, 30.659722],
        [59.162561999999994, 30.695107999999998],
        [59.166619999999995, 30.698102],
        [59.168924, 30.690264],
        [59.194331, 30.663579],
        [59.217876, 30.633139],
        [59.233579999999996, 30.632323999999997],
        [59.250772, 30.611399],
        [59.250194, 30.617196],
        [59.25282, 30.628881],
        [59.251774, 30.631876],
        [59.265105, 30.649611999999998],
        [59.267238, 30.642781999999997],
        [59.269448999999994, 30.614546999999998],
        [59.27222, 30.602308999999998],
        [59.309774999999995, 30.60439],
        [59.308645, 30.581894],
        [59.316018, 30.581364999999998],
        [59.325634, 30.587968],
        [59.340681, 30.570166999999998],
        [59.355002, 30.573005],
        [59.362933, 30.562236],
        [59.397242, 30.570487999999997],
        [59.403074999999994, 30.567187999999998],
        [59.416554999999995, 30.577422],
        [59.423086, 30.574610999999997],
        [59.438036999999994, 30.544186],
        [59.475317, 30.528972999999997],
        [59.480458999999996, 30.519143999999997],
        [59.488758, 30.430774],
        [59.49552, 30.418991],
        [59.499196, 30.424000999999997],
        [59.508933, 30.458226],
        [59.558831, 30.501254],
        [59.565728, 30.515863999999997],
        [59.574227, 30.516817],
        [59.573474999999995, 30.499520999999998],
        [59.604383999999996, 30.488031999999997],
        [59.605036999999996, 30.507372],
        [59.622569, 30.488518],
        [59.637149, 30.469882]
      ]
    ]
  ]
}, {
  name: `Сосновый бор`,
  color: `#3BCEAC`,
  coords: [
    [
      [
        [59.815276, 28.975758],
        [59.914272, 28.983945],
        [59.938703, 29.041565],
        [59.938618, 29.094558],
        [59.92291, 29.094761],
        [59.925055, 29.111548],
        [59.917269, 29.113159],
        [59.918072, 29.153091],
        [59.900349, 29.152388],
        [59.900466, 29.173113],
        [59.899685, 29.179569],
        [59.898848, 29.178369],
        [59.898856, 29.180327],
        [59.89852, 29.180698],
        [59.899007, 29.181514],
        [59.898217, 29.186074],
        [59.897307, 29.189371],
        [59.892592, 29.186606],
        [59.891762, 29.188322],
        [59.861523, 29.192649],
        [59.851046, 29.193463],
        [59.848102, 29.103439],
        [59.848123, 29.075112],
        [59.80047, 29.054465],
        [59.800359, 29.052727],
        [59.80107, 29.051376],
        [59.800124, 29.047302],
        [59.801686, 29.047364],
        [59.799132, 29.038067],
        [59.805951, 29.034614],
        [59.808046, 29.020922],
        [59.811011, 29.011335],
        [59.809644, 29.004079],
        [59.809196, 28.99306],
        [59.811455, 28.988018],
        [59.814563, 28.984441]
      ]
    ]
  ]
}];
