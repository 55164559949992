export const THRESHOLD_APPEARANCE_ELEMENT_SELECTOR = `.header`;

export const defaultStickyHeaderModelData = {
  isTopSectionRendered: false,
  isBottomSectionRendered: false
};

export const ActionType = {
  RENDER_TOP_SECTION: `RENDER_TOP_SECTION`,
  DESTROY_TOP_SECTION: `DESTROY_TOP_SECTION`,
  RENDER_BOTTOM_SECTION: `RENDER_BOTTOM_SECTION`,
  DESTROY_BOTTOM_SECTION: `DESTROY_BOTTOM_SECTION`
};

export const MOBILE_MEDIA_QUERY = `(max-width: 500px)`;
export const THRESHOLD_WINDOW_HEIGHT = 300;
