export const defaultFileAttachModelData = {
  isUploadedListRendered: false,
  isDragEnter: false,
  isDragOver: false
};

export const ActionType = {
  RENDER_UPLOADED_LIST: `RENDER_UPLOADED_LIST`,
  DESTROY_UPLOADED_LIST: `DESTROY_UPLOADED_LIST`,
  ADD_UPLOADED_ITEM: `ADD_UPLOADED_ITEM`,
  REMOVE_UPLOADED_ITEM: `REMOVE_UPLOADED_ITEM`,
  DRAG_ENTER: `DRAG_ENTER`,
  DRAG_LEAVE: `DRAG_LEAVE`,
  DRAG_OVER: `DRAG_OVER`,
  DROP: `DROP`,
  RESET: `RESET`
};

export const IMAGE_FILE_TYPES = [
  `image/jpeg`,
  `image/png`,
  `image/gif`,
  `image/webp`
];
