import MiniListPresenter from "../../mini-list/presenter/mini-list.js";
import TriggerPresenter from "../../mini-comparison/presenter/trigger.js";

import MiniComparisonSectionView from "../view/section.js";
import MiniComparisonItemView from "../view/item.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  CONNECTOR_URL,
  RequestAction
} from "../../comparison/comparison-data/const.js";

import {
  ActionType as ComparisonActionType
} from "../../comparison/comparison-data/const.js";

export default class MiniComparison extends MiniListPresenter {
  constructor(triggerElement) {
    super(triggerElement);

    this._TriggerPresenter = TriggerPresenter;
    this._connectorUrl = CONNECTOR_URL;
    this._requestAction = RequestAction.REMOVE_PRODUCT;

    this._SectionView = MiniComparisonSectionView;
    this._ItemView = MiniComparisonItemView;
  }

  processRemoveItem(elementToRemove, removedProductId) {
    super.processRemoveItem(elementToRemove);

    const requestArgs = {
      body: [
        `action=${this._requestAction}`,
        `product_id=${removedProductId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);
        this._processChangeComparisonData(data, removedProductId);
      },
    };

    sendXhr(requestArgs);
  }

  _processChangeComparisonData(data, removedProductId) {
    if (window.ComparisonData) {
      window.ComparisonData.changeData(Object.assign(
          {},
          window.ComparisonData.getData(),
          data
      ), ComparisonActionType.REMOVE_FROM_LIST, {
        removedProductId
      });
    }
  }
}
