import {
  TimingFunction
} from "./../animation/timing-functions.js";

import {
  animate
} from "./../animation/utils/animate.js";

import {
  DEFAULT_DURATION,
  GLOBAL_OFFSET_ELEMENTS_SELECTORS
} from "./const.js";

export default class ScrollToElement {
  constructor() {
    this._timingFunction = TimingFunction.EASE_OUT_QUART;
    this._duration = DEFAULT_DURATION;
    this._relativeElement = null;

    this._doGlobal = true;
  }

  scroll({element, duration, timing, relativeContainer, afterEnd}) {
    if (duration) {
      this._duration = duration;
    }

    if (timing) {
      this._timingFunction = TimingFunction[timing];
    }

    let drawFunction;

    if (relativeContainer !== undefined) {
      const elementTopOffset = element.getBoundingClientRect().top;
      const relativeContainerScrollTop = relativeContainer.scrollTop;

      drawFunction = (progress) => {
        relativeContainer.scrollTo(0, relativeContainerScrollTop + elementTopOffset * progress);
      };
    } else {
      const windowPageYOffset = window.pageYOffset;
      const elementTopOffset = this._getElementTopOffset(element);


      drawFunction = (progress) => {
        window.scrollTo(0, windowPageYOffset - (windowPageYOffset - elementTopOffset) * progress);
      };
    }

    // Set the global after end function.
    const globalAfterEnd = () => {
      if (this._getGlobalOffset() > 0) {
        const options = {
          element,
          duration,
          timing,
          relativeContainer,
          afterEnd: () => {
            this._doGlobal = true;
          }
        };
        this.scroll(options);
      }
    };

    const options = {
      timing: this._timingFunction,
      draw: drawFunction,
      duration: this._duration,
      afterEnd
    };

    if (this._doGlobal === true) {
      this._doGlobal = false;
      options.globalAfterEnd = globalAfterEnd;
    }

    animate(options);
  }

  _getGlobalOffset() {
    return (GLOBAL_OFFSET_ELEMENTS_SELECTORS.length > 0) ?
      GLOBAL_OFFSET_ELEMENTS_SELECTORS
        .map((selector) => {
          const globalOffsetElement = document.querySelector(selector);

          return (globalOffsetElement) ? globalOffsetElement.clientHeight : 0;
        })
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0) :
      0;
  }

  _getElementTopOffset(element) {
    const box = element.getBoundingClientRect();
    const globalOffset = this._getGlobalOffset();

    return box.top + window.pageYOffset - globalOffset;
  }
}
