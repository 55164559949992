export default class ChangeUrl {
  constructor() {
    this._history = window.history;
  }

  change(params, url, replace = false) {
    if (replace === true) {
      this._history.replaceState(params, ``, url);
      return;
    }

    this._history.pushState(params, ``, url);
  }
}
