import TriggerView from "../../mini-list/view/trigger.js";

import {
  formatNumber
} from "../../../utils/format-number.js";

import {
  declinationNumber
} from "../../../utils/declination-number.js";

import {
  QUANTITY_UNIT_MEASURE,
  CURRENCY,
  EMPTY_TEXT
} from "../lexicon.js";

export default class Trigger extends TriggerView {
  constructor(element) {
    super(element);

    this._textElement = null;
  }

  getTextElement() {
    if (!this._textElement) {
      this._textElement = this._element.querySelector(`.header-cart-text`);
    }

    return this._textElement;
  }

  update({
    totalPositions,
    totalCost
  }) {
    if (totalPositions > 0) {
      const textElementText = (totalCost > 0) ?
        `${totalPositions} ${declinationNumber(totalPositions, QUANTITY_UNIT_MEASURE)} – ${formatNumber(totalCost, 2, `,`, `\u00A0`)} ${CURRENCY}` :
        `${totalPositions} ${declinationNumber(totalPositions, QUANTITY_UNIT_MEASURE)}`;
      this.getTextElement().textContent = textElementText;
    } else {
      this.setEmpty();
    }
  }

  setEmpty() {
    this.getTextElement().textContent = EMPTY_TEXT;
  }
}
