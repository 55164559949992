import Abstract from "../../abstract-view/abstract.js";

export default class ReviewItem extends Abstract {
  constructor(element, index) {
    super();

    this._element = element;
    this._index = index;

    this._mouseEnterHandler = this._mouseEnterHandler.bind(this);
    this._clickHandler = this._clickHandler.bind(this);
  }

  getIndex() {
    return this._index;
  }

  activate() {
    this._element.classList.add(`${this._element.classList[0]}_active`);
  }

  deactivate() {
    this._element.classList.remove(`${this._element.classList[0]}_active`);
  }

  setMouseEnterHandler(callback) {
    this._callback.mouseEnter = callback;
    this._element.addEventListener(`mouseenter`, this._mouseEnterHandler);
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    this._element.addEventListener(`click`, this._clickHandler);
  }

  _mouseEnterHandler() {
    this._callback.mouseEnter(this.getIndex());
  }

  _clickHandler() {
    this._callback.click(this.getIndex());
  }
}
