import Abstract from "../../abstract-view/abstract.js";

export default class Item extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  hide() {
    this._element.style.display = `none`;
  }

  show() {
    if (this._element.style.display === `none`) {
      this._element.style.display = null;
    }
  }
}
