import Abstract from "../../abstract-view/abstract.js";

export default class ReviewsContainer extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._listElement = null;
    this._inputElement = null;

    this._listMouseLeaveHandler = this._listMouseLeaveHandler.bind(this);
  }

  getItems() {
    return this._element.querySelector(`.reviews-rating-items`).querySelectorAll(`li`);
  }

  getListElement() {
    if (!this._listElement) {
      this._listElement = this._element.querySelector(`.reviews-rating-items`);
    }

    return this._listElement;
  }

  getInputElement() {
    if (!this._inputElement) {
      this._inputElement = this._element.querySelector(`input`);
    }

    return this._inputElement;
  }

  setInputValue(value) {
    this.getInputElement().value = value;
  }

  setListMouseLeaveHandler(callback) {
    this._callback.listMouseLeave = callback;
    this.getListElement().addEventListener(`mouseleave`, this._listMouseLeaveHandler);
  }

  _listMouseLeaveHandler() {
    this._callback.listMouseLeave();
  }
}
