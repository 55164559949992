import ProductsListPresenter from "../../products-list/presenter/products-list.js";
import ProductCardPresenter from "./product-card.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  TABLET_MEDIA_QUERY
} from "../const.js";

export default class ProductCards extends ProductsListPresenter {
  constructor(listElement) {
    super(listElement);

    this._ProductItemPresenter = ProductCardPresenter;

    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._handleWindowResizeDebounced = debounce(this._handleWindowResize);
  }

  init() {
    super.init();

    window.addEventListener(`resize`, this._handleWindowResizeDebounced);
  }

  destroy(destroyItems = true) {
    super.destroy(destroyItems);

    window.removeEventListener(`resize`, this._handleWindowResizeDebounced);
  }

  setProductItemPresenter(presenter) {
    this._ProductItemPresenter = presenter;
  }

  _handleWindowResize() {
    if (window.matchMedia(TABLET_MEDIA_QUERY).matches) {
      Object.values(this._itemsPresenter)
      .forEach((presenter) => {
        presenter.forceReset();
      });
    }
  }
}
