import Abstract from "../../abstract-view/abstract.js";

import {
  CLOSE_BUTTON_TEXT
} from "../lexicon.js";

const createMapContainerTemplate = () => {
  return (
    `<div class="office-map-wrapper">
      <button class="office-map-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
      <div class="office-map"></div>
    </div>`
  );
};

export default class MapContainer extends Abstract {
  constructor() {
    super();

    this._closeButton = null;
    this._mapElement = null;

    this._closeButtonClickHandler = this._closeButtonClickHandler.bind(this);
  }

  setElement(element) {
    this._element = element;
  }

  getCloseButton() {
    if (!this._closeButton) {
      this._closeButton = this._element.querySelector(`.office-map-close-button`);
    }

    return this._closeButton;
  }

  getMapElement() {
    if (!this._mapElement) {
      this._mapElement = this._element.querySelector(`.office-map`);
    }

    return this._mapElement;
  }

  getTemplate() {
    return createMapContainerTemplate();
  }

  reset() {
    this._element = null;
    this._closeButton = null;
    this._mapElement = null;
  }

  setCloseButtonClickHandler(callback) {
    this._callback.closeButtonClick = callback;
    this.getCloseButton().addEventListener(`click`, this._closeButtonClickHandler);
  }

  _closeButtonClickHandler() {
    this._callback.closeButtonClick();
  }
}
