// Fixed the bug when there was an empty gap appeared after the window had been resized. The last element had been activated in the previous state.

export const ResizeGap = (Glide, Components, Events) => {
  const Component = {
    mount() {
      Events.emit(`ResizeGap.mount`);
    }
  };

  Events.on(`resize`, () => {
    if (Components.Sizes.length < Glide.settings.perView) {
      return;
    }

    if (Components.Sizes.length - Glide.index < Glide.settings.perView) {
      Glide.go(`>>`);
    }
  });

  return Component;
};
