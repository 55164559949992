import Abstract from "../../abstract-view/abstract.js";

import {
  DISCOUNT_TEXT
} from "../lexicon.js";

import {
  createElement
} from "../../render/render.js";

const createDiscountTemplate = (discount) => {
  return (
    `<span class="add-to-cart-modal-discount">${DISCOUNT_TEXT} ${discount}%</span>`
  );
};

export default class Discount extends Abstract {
  constructor(discount) {
    super();

    this._discount = discount;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  setDiscount(value) {
    this._discount = value;
    this._element.textContent = `${DISCOUNT_TEXT} ${this._discount}%`;
  }

  _getTemplate() {
    return createDiscountTemplate(this._discount);
  }
}
