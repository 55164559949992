import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

const createPropertyItemTemplate = ({
  name,
  value,
  uri
}) => {
  const titleMarkup = createPropertyItemTitleTemplate(value, uri);
  return (
    `<li class="product-property">
      <p class="product-property__name">${name}:</p>
      ${titleMarkup}
    </li>`
  );
};

const createPropertyItemTitleTemplate = (value, uri) => {
  return (uri) ?
    `<p class="product-property__value">
      <a class="product-property__link" href="${uri}">${value}</a>
    </p>` :
    `<p class="product-property__value">${value}</p>`;
};

export default class PropertyItem extends Abstract {
  constructor(data) {
    super();

    this._data = data;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  _getTemplate() {
    return createPropertyItemTemplate(this._data);
  }
}
