import Abstract from "./../../abstract-view/abstract.js";

import {createElement} from "./../../render/render.js";

const createNotificationTemplate = (notification) => {
  return (
    `<div class="form-element-notification">
      <p class="form-element-notification__text">${notification}</p>
    </div>`
  );
};

export default class Notification extends Abstract {
  constructor(notification) {
    super();

    this._notification = notification;
  }

  _getTemplate() {
    return createNotificationTemplate(this._notification);
  }

  getElement() {
    if (this._element === null) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }
}
