import Abstract from "./../../abstract-view/abstract.js";

import {createElement} from "./../../render/render.js";

export default class PaginationList extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  getTemplate(template) {
    return createElement(template);
  }

  setElement(element) {
    this._element = element;
  }

  getItems() {
    if (!this._element) {
      return null;
    }

    return this._element.querySelectorAll(`li`);
  }
}
