export const defaultModalModelData = {
  isActive: false,
  isInit: false,
  propertiesData: null
};

export const defaultModalProductPropertiesTriggerModelData = {
  isClicking: false,
  isModalShown: false
};

export const ActionType = {
  CLICKING: `CLICKING`,
  SHOW_MODAL: `SHOW_MODAL`,
  RENDER_PROPERTIES: `RENDER_PROPERTIES`
};

export const MODAL_MAIN_CLASS = `product-properties-modal-wrapper`;

export const CONNECTOR_URL = `/assets/components/ecommerce/connector.php`;

export const RequestAction = {
  GET_PROPERTIES: `get_properties`
};
