import Abstract from "../../abstract-view/abstract.js";

import {
  ADD_TO_CART_BUTTON_TEXT_DEFAULT,
  ADD_TO_CART_BUTTON_TEXT_ADDED
} from "../lexicon.js";

export default class ProductItem extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._titleElement = null;
    this._imageElement = null;
    this._priceElement = null;
    this._currencyElement = null;
    this._addToCartButton = null;
    this._oneClickBuyButton = null;
    this._favoriteItemElement = null;
    this._comparisonElement = null;
    this._showPropertiesElement = null;
    this._closestSectionElement = null;
    this._sectionHeadingElement = null;
    this._formElement = null;
    this._actionsWrapperElement = null;
    this._pictureElement = null;
    this._quantityElement = null;

    this._pictureClickHandler = this._pictureClickHandler.bind(this);
  }

  getOneClickBuyData() {
    const data = {
      title: this._element.dataset.productTitle || this.getTitleElement().textContent,
      src: (this.getImageElement()) ? this.getImageElement().src : null,
      price: +this._element.dataset.price || ((this.getPriceElement()) ? +this.getPriceElement().textContent.replace(/\D/gi, ``) : 0),
      currencyMeasure: this._element.dataset.currencyMeasure || ((this.getCurrencyElement()) ? this.getCurrencyElement().textContent.replace(/\d/gi, ``) : 0)
    };

    const heading = this.getSectionHeadingElement();

    if (heading) {
      data.section = heading.textContent;
    }

    return data;
  }

  getPictureElement() {
    if (!this._pictureElement) {
      this._pictureElement = this._element.querySelector(`picture`);
    }

    return this._pictureElement;
  }

  getSectionHeadingElement() {
    const sectionElement = this.getClosestSectionElement();

    return (sectionElement) ? sectionElement.querySelector(`h2`) : null;
  }

  getClosestSectionElement() {
    return this.getElement().closest(`section`);
  }

  getTitleElement() {
    if (!this._titleElement) {
      this._titleElement = this._element.querySelector(`.product-item__title`);
    }

    return this._titleElement;
  }

  getImageElement() {
    if (!this._imageElement) {
      this._imageElement = this._element.querySelector(`img`);
    }

    return this._imageElement;
  }

  getPriceElement() {
    if (!this._priceElement) {
      this._priceElement = this._element.querySelector(`.product-item__price`);
    }

    return this._priceElement;
  }

  getCurrencyElement() {
    if (!this._currencyElement) {
      this._currencyElement = this._element.querySelector(`.product-item__currency`);
    }

    return this._currencyElement;
  }

  getAddToCartButton() {
    if (!this._addToCartButton) {
      this._addToCartButton = this._element.querySelector(`button[type="submit"]`);
    }

    return this._addToCartButton;
  }

  getOneClickBuyButton() {
    if (!this._oneClickBuyButton) {
      this._oneClickBuyButton = this._element.querySelector(`.product-item-form__one-click-buy`);
    }

    return this._oneClickBuyButton;
  }

  getFavoriteTriggerElement() {
    if (!this._favoriteItemElement) {
      this._favoriteItemElement = this._element.querySelector(`.product-item-action__button_favorite`);
    }

    return this._favoriteItemElement;
  }

  getComparisonTriggerElement() {
    if (!this._comparisonElement) {
      this._comparisonElement = this._element.querySelector(`.product-item-action__button_compare`);
    }

    return this._comparisonElement;
  }

  getShowPropertiesElement() {
    if (!this._showPropertiesElement) {
      this._showPropertiesElement = this._element.querySelector(`.product-to-properties-list`);
    }

    return this._showPropertiesElement;
  }

  getFormElement() {
    if (!this._formElement) {
      this._formElement = this._element.querySelector(`.product-item-form`);
    }

    return this._formElement;
  }

  getActionsWrapperElement() {
    if (!this._actionsWrapperElement) {
      this._actionsWrapperElement = this._element.querySelector(`.product-item-actions`);
    }

    return this._actionsWrapperElement;
  }

  setMobile() {
    const addToCartButton = this.getAddToCartButton();

    if (addToCartButton) {
      addToCartButton.classList.add(`${addToCartButton.classList[1]}_mobile`);
    }
  }

  setDefault() {
    const addToCartButton = this.getAddToCartButton();

    if (addToCartButton) {
      addToCartButton.classList.remove(`${addToCartButton.classList[1]}_mobile`);
    }
  }

  checkAddedToCart() {
    return (this.getAddToCartButton()) ?
      this.getAddToCartButton().classList.contains(`button_added-to-cart`) :
      false;
  }

  setAddedToCart() {
    this.getAddToCartButton().classList.add(`button_added-to-cart`);
    this.getAddToCartButton().textContent = ADD_TO_CART_BUTTON_TEXT_ADDED;
  }

  unsetAddedToCart() {
    this.getAddToCartButton().classList.remove(`button_added-to-cart`);
    this.getAddToCartButton().textContent = ADD_TO_CART_BUTTON_TEXT_DEFAULT;
  }

  getProductId() {
    return +this._element.dataset.productId;
  }

  getQuantityElement() {
    if (!this._quantityElement) {
      this._quantityElement = this._element.querySelector(`.product-quantity`);
    }

    return this._quantityElement;
  }

  getPictureSourceUrl() {
    if (!this.getPictureElement()) {
      return null;
    }

    return this.getPictureElement().dataset.sourceUrl;
  }

  resetView() {
    this._element.style.zIndex = null;
  }

  forceReset() {
    this._element.style.zIndex = null;
  }

  setPictureClickHandler(callback) {
    if (!this.getPictureElement()) {
      return;
    }

    this._callback.pictureClick = callback;
    this.getPictureElement().addEventListener(`click`, this._pictureClickHandler);
  }

  _pictureClickHandler(evt) {
    this._callback.pictureClick(evt);
  }
}
