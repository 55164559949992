import {
  Fancybox
} from "@fancyapps/ui";

import ru from "@fancyapps/ui/src/Fancybox/l10n/ru";

import TriggerView from "../view/trigger.js";
import MapContainerView from "../view/map-container.js";

import ModalOfficeMapModel from "../model/modal-office-map.js";

import OfficeMap from "../../office-map/presenter/office-map.js";

import {
  defaultOfficeMapModelData,
  ActionType
} from "../const.js";

import {
  OrganizationCoord
} from "../../../const.js";

export default class ModalOfficeMap {
  constructor(trigger) {
    this._trigger = trigger;

    this._triggerView = null;
    this._mapContainerView = null;
    this._model = null;
    this._modalInstance = null;
    this._officeMap = null;

    this._handleTriggerClick = this._handleTriggerClick.bind(this);
    this._handleModalReveal = this._handleModalReveal.bind(this);
    this._handleModalDone = this._handleModalDone.bind(this);
    this._handleCloseButtonClick = this._handleCloseButtonClick.bind(this);
    this._handleModalDestroy = this._handleModalDestroy.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._mapContainerView = new MapContainerView();
    this._triggerView = new TriggerView(this._trigger);
    this._triggerView.setClickHandler(this._handleTriggerClick);
    this._model = new ModalOfficeMapModel(Object.assign(
        {},
        defaultOfficeMapModelData
    ));
    this._model.addObserver(this._handleChangeData);
  }

  closeMap() {
    const modelData = this._model.getData();

    if (!modelData.isActive) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isActive: false
        }
    ), ActionType.CLOSE);
  }

  _buildMap() {
    Fancybox.defaults.l10n = ru;
    Fancybox.show([
      {
        src: this._mapContainerView.getTemplate(),
        type: `html`
      }
    ], {
      mainClass: `office-map`,
      Carousel: {
        Panzoom: {
          touch: false
        }
      },
      on: {
        reveal: this._handleModalReveal,
        done: this._handleModalDone,
        destroy: this._handleModalDestroy
      }
    });
  }

  _destroyMap() {
    this._modalInstance.close();
  }

  _handleModalReveal(fancybox, slide) {
    this._mapContainerView.setElement(slide.$content);
    this._officeMap = new OfficeMap(this._mapContainerView.getMapElement());
    this._officeMap.init(OrganizationCoord);
    this._modalInstance = fancybox;
  }

  _handleModalDone() {
    this._mapContainerView.setCloseButtonClickHandler(this._handleCloseButtonClick);
  }

  _handleModalDestroy() {
    this._modalInstance = null;
    this._mapContainerView.reset();
  }

  _handleCloseButtonClick() {
    const modelData = this._model.getData();

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isActive: false
        }
    ), ActionType.CLOSE);
  }

  _handleTriggerClick() {
    const modelData = this._model.getData();

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isActive: true
        }
    ), ActionType.OPEN);
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.OPEN:
        this._buildMap();
        break;
      case ActionType.CLOSE:
        this._destroyMap();
        break;
    }
  }
}
