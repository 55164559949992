import {TimingFunction} from "./../animation/timing-functions.js";
import {animate} from "./../animation/utils/animate.js";
import {DEFAULT_DURATION} from "./const.js";

export default class ScrollContainer {
  constructor() {
    this._timingFunction = TimingFunction.EASE_OUT_QUART;
    this._duration = DEFAULT_DURATION;
    this._relativeElement = null;
  }

  scroll({element, offsetLeft, duration, timing, beforeBegin, afterEnd}) {
    if (duration) {
      this._duration = duration;
    }

    if (timing) {
      this._timingFunction = TimingFunction[timing];
    }

    const startContainerOffset = element.scrollLeft;

    const drawFunction = (progress) => {
      // console.log(offsetLeft * progress);
      // element.scrollLeft = offsetLeft * progress;
      element.scrollLeft = startContainerOffset - (startContainerOffset - offsetLeft) * progress;
    };

    animate({
      timing: this._timingFunction,
      draw: drawFunction,
      duration: this._duration,
      beforeBegin,
      afterEnd
    });
  }
}
