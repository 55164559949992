import {getSizes} from "./get-sizes.js";

export const getElementSizesBeforeRender = (element) => {
  const parent = element.parentElement;
  const clonedParent = parent.cloneNode(true);

  if (parent.clientWidth > 0) {
    clonedParent.style.width = parent.clientWidth + `px`;
  }

  const childrenElement = clonedParent.children;
  const clonedElement = Array.from(childrenElement).find((item) => item.classList[0] === element.classList[0]);

  const dummyWrapper = document.createElement(`div`);
  dummyWrapper.append(clonedParent);

  dummyWrapper.style.visibility = `hidden`;
  dummyWrapper.style.listStyle = `none`;
  dummyWrapper.style.position = `absolute`;
  dummyWrapper.zIndex = `-1`;

  document.body.append(dummyWrapper);

  const sizes = getSizes(clonedElement);

  dummyWrapper.remove();

  return sizes;
};

