import {
  OrganizationCoord,
  USER_COORDS_STORAGE_KEY
} from "../const.js";

export const userCoords = {
  getCoords(callback) {
    if (localStorage.getItem(USER_COORDS_STORAGE_KEY)) {
      this._coords = localStorage.getItem(USER_COORDS_STORAGE_KEY).split(`,`);
      callback();
    } else {
      this._ymaps = window.ymaps;

      this._ymaps.ready(() => {
        this._ymaps.geolocation.get(
            {
              provider: `auto`,
              mapStateAutoApply: true
            }
        ).then((result) => {
          this._coords = (result.geoObjects.position) ?
            result.geoObjects.position :
            Object.values(OrganizationCoord);
          localStorage.setItem(USER_COORDS_STORAGE_KEY, this._coords.join(`,`));
          callback();
        });
      });
    }
  }
};

userCoords._ymaps = userCoords._ymaps;
userCoords._coords = userCoords._coords;
