import Abstract from "../../../abstract-view/abstract.js";

import {
  PREV,
  NEXT
} from "../lexicon.js";

const createControlsLayout = (options) => {
  const postfixClass = (options && options.postfix) ?
    ` slider-navigation_${options.postfix}` :
    ``;

  return (
    `<div class="slider-navigation${postfixClass}" data-glide-el="controls">
      <button class="slider-navigation__item slider-navigation__item_prev" data-glide-dir="<">
        <span class="visually-hidden">${PREV}</span>
      </button>
      <button class="slider-navigation__item slider-navigation__item_next"  data-glide-dir=">">
        <span class="visually-hidden">${NEXT}</span>
      </button>
    </div>`
  );
};

import {
  createElement
} from "../../../render/render.js";

export default class Controls extends Abstract {
  constructor(options) {
    super();

    this._options = options;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  setTopOffset(offsetTop) {
    this._element.style.top = `${offsetTop}px`;
  }

  _getTemplate() {
    return createControlsLayout(this._options);
  }
}
