import TopStickySectionView from "./top-section.js";

const createStickySectionTemplate = () => {
  return (
    `<section class="sticky-header sticky-header_bottom" style="display: none;">
      <div class="sticky-header-wrapper sticky-header-wrapper_bottom">
        <div class="container">
          <ul class="header-list header-list_sticky-bottom">
            <li class="header-contacts-list__item header-contacts-list__item_address header-contacts-list__item_address-sticky">
              <p class="header-address-text">
                <span class="header-address-text-title">Адрес:</span>
                <button class="header-address-button header-address-button_sticky">
                  <span class="header-address-button-sticky-text">Адрес офиса</span>
                </button>
              </p>
            </li>
            <li class="header-list__item header-list__item_middle header-list__item_phone header-list__item_phone-sticky-bottom">
              <a class="sticky-header-phone" href="tel:+78126043323">+7 (812) 604-33-23</a>
            </li>
            <li class="header-list__item header-list__item_middle header-list__item_compare header-list__item_compare-sticky">
              <a class="header-compare" href="/comparison">
                <span class="visually-hidden">Список сравнения</span>
              </a>
            </li>
            <li class="header-list__item header-list__item_middle header-list__item_favorite header-list__item_favorite-sticky">
              <a class="header-favorite" href="/favorites">
                <span class="visually-hidden">Избранное</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>`
  );
};

export default class BottomStickySection extends TopStickySectionView {
  constructor() {
    super();

    this._modalOfficeMapTrigger = null;
    this._favoriteTrigger = null;
    this._comparisonTrigger = null;
  }

  getModalOfficeMapTrigger() {
    if (!this._modalOfficeMapTrigger) {
      this._modalOfficeMapTrigger = this.getElement().querySelector(`.header-address-button`);
    }

    return this._modalOfficeMapTrigger;
  }

  getFavoriteTrigger() {
    if (!this._favoriteTrigger) {
      this._favoriteTrigger = this.getElement().querySelector(`.header-favorite`);
    }

    return this._favoriteTrigger;
  }

  getComparisonTrigger() {
    if (!this._comparisonTrigger) {
      this._comparisonTrigger = this.getElement().querySelector(`.header-compare`);
    }

    return this._comparisonTrigger;
  }

  getHeight() {
    return this._element.clientHeight;
  }

  _getTemplate() {
    return createStickySectionTemplate();
  }
}
