import {
  yandexMaps
} from "../../../mixins/yandex-maps.js";

import {
  MapParam
} from "../const.js";
export default class OfficeMap {
  constructor(container) {
    this._zoom = MapParam.ZOOM;
    this._iconUrl = MapParam.ICON.URL;
    this._iconSize = MapParam.ICON.SIZE;
    this._iconRealSize = MapParam.ICON.REAL_SIZE;

    this._coords = {
      latitude: null,
      longitude: null
    };

    this._placemark = null;

    this._container = container;

    this._renderMap = this._renderMap.bind(this);
  }

  init(coords) {
    this._coords = coords;
    this.loadScript(this._renderMap);
  }

  _renderMap() {
    this._ymaps = window.ymaps;

    this._ymaps.ready(() => {
      this._map = new this._ymaps.Map(
          this._container,
          {
            center: [this._coords.latitude, this._coords.longitude],
            zoom: this._zoom,
            controls: {}
          },
          {
            suppressMapOpenBlock: true
          }
      );

      this._map.behaviors.disable([`scrollZoom`, `dblClickZoom`]);

      this._placemark = this.createPlacemark(Object.values(this._coords));
      this.setCopyrightsStyle();
    });
  }
}

Object.assign(OfficeMap.prototype, yandexMaps);
