import SearchInputView from "./../view/search-input.js";
import SectionView from "./../view/section.js";
import SuggestionContainerView from "./../view/suggestions-container.js";
import SectionModel from "./../model/section.js";
import SearchInputModel from "./../model/search-input.js";
import Animation from "./../../animation/animation.js";
import ElementNotification from "./../../element-notification/presenter/element-notification.js";

import {
  createElement,
  render,
  remove,
  removeElement,
  RenderPosition
} from "./../../render/render.js";

import {AnimationClass} from "./../../animation/const.js";

import {debounce} from "./../../../utils/debounce.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  REQUEST_URL,
  ACTION_TYPE,
  MIN_QUERY_LENGTH,
  Action,
  Status,
  ANIMATION_DURATION,
  RESPONSIVE_MEDIA_QUERY,
  defaultSearchInputModelData
} from "./../const.js";
export default class SearchSuggestions {
  constructor(searchInputElement) {
    this._searchInputView = new SearchInputView(searchInputElement);
    this._connectorUrl = REQUEST_URL;
    this._action = ACTION_TYPE;
    this._animationDuration = ANIMATION_DURATION;
    this._animation = new Animation();
    this._elementNotification = null;

    this._sectionView = null;
    this._suggestionsContainerView = null;

    this._sectionModel = new SectionModel({});
    this._searchInputModel = new SearchInputModel(Object.assign(
        {},
        defaultSearchInputModelData
    ));

    this._handleSearchInputInput = this._handleSearchInputInput.bind(this);
    this._handleSearchInputClear = this._handleSearchInputClear.bind(this);
    this._handleSearchToogleButtonClick = this._handleSearchToogleButtonClick.bind(this);
    this._handleChangeModel = this._handleChangeModel.bind(this);
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._handleWindowScroll = this._handleWindowScroll.bind(this);
    this._handleWindowScrollDebounced = debounce(this._handleWindowScroll);
    this._handleEscKeyDown = this._handleEscKeyDown.bind(this);
    this._handleClearTriggerClick = this._handleClearTriggerClick.bind(this);
    this._handleSearchInputFocus = this._handleSearchInputFocus.bind(this);
    this._handleSearchInputBlur = this._handleSearchInputBlur.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
    this._handleSearchInputKeyDown = this._handleSearchInputKeyDown.bind(this);
    this._handlerClearTriggerBlur = this._handlerClearTriggerBlur.bind(this);
    this._handleFirstFocusableChildKeyDown = this._handleFirstFocusableChildKeyDown.bind(this);
    this._handleFormSubmit = this._handleFormSubmit.bind(this);
  }

  init() {
    this._searchInputView.setInputHandler(this._handleSearchInputInput);
    this._searchInputView.setInputClearHandler(this._handleSearchInputClear);
    this._searchInputView.setSearchToogleButtonClickHandler(this._handleSearchToogleButtonClick);
    this._searchInputView.setInputFocusHandler(this._handleSearchInputFocus);
    this._searchInputView.setInputBlurHandler(this._handleSearchInputBlur);
    this._searchInputView.setFormSubmitHandler(this._handleFormSubmit);

    this._sectionModel.addObserver(this._handleChangeModel);
    this._searchInputModel.addObserver(this._handleChangeModel);
    this._setWindowResizeHandler();
    this._setWindowOrientationChangeHandler();
    this._setEscKeyDownHandler();

    if (this._searchInputView.getValue() !== ``) {
      this._searchInputModel.changeData(Object.assign(
          {},
          this._searchInputModel.getData(),
          {
            clearTrigger: true
          }
      ), Action.SHOW_CLEAR_TRIGGER);
    }
  }

  resetSearch() {
    if (this._elementNotification && this._elementNotification.checkIfRendered()) {
      this._elementNotification.destroy();
    }

    this._sectionModel.changeData(Object.assign(
        {},
        this._sectionModel.getData(),
        {
          status: Status.DESTROY
        }
    ), Action.DESTROY);

    this._searchInputModel.changeData(Object.assign(
        {},
        this._searchInputModel.getData(),
        {
          responsiveHide: true
        }
    ), Action.INPUT_HIDE);

    this._searchInputModel.changeData(Object.assign(
        {},
        this._searchInputModel.getData(),
        {
          clearTrigger: false
        }
    ), Action.HIDE_CLEAR_TRIGGER);

    if (document.activeElement !== this._searchInputView.getElement()) {
      this._searchInputModel.changeData(Object.assign(
          {},
          this._searchInputModel.getData(),
          {
            focused: false
          }
      ), Action.INPUT_BLUR);
    }
  }

  setWindowScrollHandler() {
    window.addEventListener(`scroll`, this._handleWindowScrollDebounced, {
      passive: true
    });
  }

  removeWindowScrollHandler() {
    window.removeEventListener(`scroll`, this._handleWindowScrollDebounced);
  }

  _handleSearchInputInput() {
    const query = this._searchInputView.getValue();

    if (query.length >= MIN_QUERY_LENGTH) {
      this._getSuggestions(query);
    }
  }

  _handleSearchInputClear() {
    if (this._elementNotification && this._elementNotification.checkIfRendered()) {
      this._elementNotification.destroy();
    }

    const query = this._searchInputView.getValue();

    if (query.length > 0) {
      const currentData = this._searchInputModel.getData();

      if (currentData.clearTrigger === false) {
        this._searchInputModel.changeData(Object.assign(
            {},
            currentData,
            {
              clearTrigger: true
            }
        ), Action.SHOW_CLEAR_TRIGGER);
      }
    } else {
      const currentData = this._searchInputModel.getData();

      if (currentData.clearTrigger === true) {
        this._searchInputModel.changeData(Object.assign(
            {},
            currentData,
            {
              clearTrigger: false
            }
        ), Action.HIDE_CLEAR_TRIGGER);
      }
    }

    if (query.length < MIN_QUERY_LENGTH) {
      this._sectionModel.changeData(Object.assign(
          {},
          this._sectionModel.getData(),
          {
            status: Status.DESTROY
          }
      ), Action.DESTROY);
      return;
    }
  }

  _destroySection() {
    if (!this._sectionView) {
      return;
    }

    const sectionElement = this._sectionView.getElement();
    this._sectionView = null;

    this._animation.fadeOut(
        sectionElement,
        this._animationDuration,
        () => {
          removeElement(sectionElement);
          this._sectionView = null;
        }
    );
  }

  _getSuggestions(query) {
    const requestParams = {
      action: this._action,
      query
    };

    const body = [];

    Object
      .entries(requestParams)
      .forEach(([property, value]) => body.push(`${property}=${value}`));

    const requestArgs = {
      body,
      connectorUrl: this._connectorUrl,
      loadstartCallback: () => {
        if (!this._sectionView) {
          this._sectionModel.changeData(Object.assign(
              {},
              this._sectionModel.getData(),
              {
                status: Status.LOADING
              }
          ), Action.RENDER);
        } else {
          this._sectionModel.changeData(Object.assign(
              {},
              this._sectionModel.getData(),
              {
                status: Status.LOADING
              }
          ), Action.LOADING);
        }
      },
      loadCallback: (response) => {
        const data = JSON.parse(response);

        if (!data.markup) {
          this._sectionModel.changeData(Object.assign(
              {},
              this._sectionModel.getData(),
              {
                status: Status.DESTROY
              }
          ), Action.DESTROY);
          return;
        }

        this._suggestionsContainerView = new SuggestionContainerView(createElement(data.markup));

        this._sectionModel.changeData(Object.assign(
            {},
            this._sectionModel.getData(),
            {
              status: Status.IDLE
            }
        ), Action.LOADED);

      },
    };
    sendXhr(requestArgs);
  }

  _handleChangeModel(action) {
    switch (action) {
      case Action.RENDER:
        this._sectionView = new SectionView();
        render(document.body, this._sectionView.getElement());
        this._setSectionPosition();

        this._animation.appear(
            this._sectionView.getElement(),
            this._animationDuration
        );

        this._sectionView.setStatus(Status.LOADING);
        render(this._sectionView.getElement(), this._sectionView.getPreloader(), RenderPosition.AFTERBEGIN);

        this._setDocumentClickHandler();
        break;
      case Action.LOADING:
        this._sectionView.setHeight();
        remove(this._suggestionsContainerView);
        this._suggestionsContainerView = null;
        this._sectionView.setStatus(Status.LOADING);
        break;
      case Action.LOADED:
        this._sectionView.setStatus(Status.IDLE);
        render(this._sectionView.getElement(), this._suggestionsContainerView.getElement());
        this._sectionView.removeHeight();
        this._searchInputView.setInputKeyDownHandler(this._handleSearchInputKeyDown);
        this._suggestionsContainerView.setFirstFocusableChildKeyDownHandler(this._handleFirstFocusableChildKeyDown);
        break;
      case Action.DESTROY:
        if (this._suggestionsContainerView) {
          this._suggestionsContainerView.removeFirstFocusableChildKeyDownHandler();
        }

        this._destroySection();
        this._removeDocumentClickHandler();
        this._searchInputView.removeInputKeyDownHandler();
        break;
      case Action.INPUT_SHOW:
        this._searchInputView.prepareBeforeFadeIn();

        this._animation.fadeIn(
            this._searchInputView.getForm(),
            this._animationDuration,
            () => {
              this._searchInputView.prepareAfterFadeIn();

              if (!this._sectionView) {
                return;
              }

              this._setSectionPosition();
            }
        );
        break;
      case Action.INPUT_HIDE:
        if (window.matchMedia(RESPONSIVE_MEDIA_QUERY).matches === true) {
          this._animation.fadeOut(
              this._searchInputView.getForm(),
              this._animationDuration,
              () => {
                this._searchInputView.prepareAfterFadeOut();
              }
          );
        }

        this._searchInputView.clear();
        break;
      case Action.INPUT_RESET:
        this._searchInputView.prepareAfterFadeOut();

        if (!this._sectionView) {
          return;
        }

        this._setSectionPosition();
        break;
      case Action.SHOW_CLEAR_TRIGGER:
        const clearTriggerElement = this._searchInputView.getClearTrigger();
        this._searchInputView.setClearTriggerClickHandler(this._handleClearTriggerClick);
        this._searchInputView.setClearTriggerBlurHandler(this._handlerClearTriggerBlur);
        render(this._searchInputView.getParent(), clearTriggerElement);

        this._animation.fadeIn(
            this._searchInputView.getClearTrigger(),
            null,
            () => {
              this._searchInputView.getClearTrigger().classList.remove(`clear-search-input-button_hidden`);
            },
            AnimationClass.SHOW_BLOCK_HALF_TRANSPARENT
        );
        break;
      case Action.HIDE_CLEAR_TRIGGER:
        this._animation.fadeOut(
            this._searchInputView.getClearTrigger(),
            null,
            () => {
              this._searchInputView.removeClearTrigger();
            },
            AnimationClass.HIDE_HALF_TRANSPARENT
        );
        break;
      case Action.INPUT_FOCUS:
        this._searchInputView.setFocused();
        break;
      case Action.INPUT_BLUR:
        this._searchInputView.removeFocused();
        break;
    }
  }

  _setWindowResizeHandler() {
    window.addEventListener(`resize`, debounce(this._handleWindowResize), {
      passive: true
    });
  }

  _setWindowOrientationChangeHandler() {
    window.addEventListener(`orientationchange`, debounce(this._handleWindowResize));
  }

  _handleWindowResize() {
    const currentData = this._searchInputModel.getData();

    if (window.matchMedia(RESPONSIVE_MEDIA_QUERY).matches === false && currentData.responsiveHide === false) {
      this._searchInputModel.changeData(Object.assign(
          {},
          currentData,
          {
            responsiveHide: !currentData.responsiveHide
          }
      ), Action.INPUT_RESET);
      return;
    } else if (window.matchMedia(RESPONSIVE_MEDIA_QUERY).matches === true && currentData.responsiveHide === true) {
      if (!this._sectionView) {
        return;
      }

      this._searchInputModel.changeData(Object.assign(
          {},
          currentData,
          {
            responsiveHide: !currentData.responsiveHide
          }
      ), Action.INPUT_SHOW);
    } else {
      this._setSectionPosition();
    }
  }

  _setSectionPosition() {
    if (!this._sectionView) {
      return;
    }

    const relativeElement = (window.matchMedia(RESPONSIVE_MEDIA_QUERY).matches === true) ?
      this._searchInputView.getForm() :
      this._searchInputView.getMenuElement();

    this._sectionView.setPosition(relativeElement);
  }

  _handleSearchToogleButtonClick() {
    if (window.matchMedia(RESPONSIVE_MEDIA_QUERY).matches === true) {
      const currentData = this._searchInputModel.getData();
      const action = currentData.responsiveHide ? Action.INPUT_SHOW : Action.INPUT_HIDE;

      this._searchInputModel.changeData(Object.assign(
          {},
          currentData,
          {
            responsiveHide: !currentData.responsiveHide
          }
      ), action);

      if (action === Action.INPUT_HIDE) {
        this._sectionModel.changeData(Object.assign(
            {},
            this._sectionModel.getData(),
            {
              status: Status.DESTROY
            }
        ), Action.DESTROY);
      }
    }
  }

  _setEscKeyDownHandler() {
    document.addEventListener(`keydown`, this._handleEscKeyDown);
  }

  _handleWindowScroll() {
    if (!this._sectionView) {
      return;
    }

    if (
      this._sectionView.getSectionTopOffset() - this._searchInputView.getMenuElementHeight() > 0 ||
      this._sectionView.getSectionTopOffset() + this._sectionView.getSectionHeight() - this._searchInputView.getMenuElementHeight() < 0
    ) {
      this._setSectionPosition();
    }
  }

  _handleEscKeyDown(evt) {
    if (evt.key === `Escape` || evt.key === `Esc`) {
      this.resetSearch();
    }
  }

  _handleClearTriggerClick() {
    this.resetSearch();
  }

  _handleSearchInputFocus() {
    this._searchInputModel.changeData(Object.assign(
        {},
        this._searchInputModel.getData(),
        {
          focused: true
        }
    ), Action.INPUT_FOCUS);
  }

  _handleSearchInputBlur(evt) {
    if (evt.target.value !== ``) {
      return;
    }

    if (evt.relatedTarget === this._searchInputView.getClearTrigger()) {
      return;
    }

    this._searchInputModel.changeData(Object.assign(
        {},
        this._searchInputModel.getData(),
        {
          focused: false
        }
    ), Action.INPUT_BLUR);
  }

  _setDocumentClickHandler() {
    document.addEventListener(`click`, this._handleDocumentClick);
  }

  _removeDocumentClickHandler() {
    document.removeEventListener(`click`, this._handleDocumentClick);
  }

  _handleDocumentClick(evt) {
    const target = evt.target;

    if (this._searchInputView.getWrapper().contains(target) || this._sectionView.getElement().contains(target)) {
      return;
    }

    this.resetSearch();
  }

  _handleSearchInputKeyDown(evt) {
    if (evt.key === `ArrowDown` && this._suggestionsContainerView !== null) {
      this._focusOnFirstActiveChild();
    }
  }

  _focusOnFirstActiveChild() {
    const childToFocus = this._suggestionsContainerView.getFirstFocusableChild();

    if (childToFocus === null) {
      return;
    }

    childToFocus.focus();
  }

  _handlerClearTriggerBlur() {
    if (this._suggestionsContainerView !== null) {
      this._focusOnFirstActiveChild();
    }
  }

  _handleFirstFocusableChildKeyDown(evt) {
    if (evt.key === `ArrowUp` || (evt.shiftKey === true && evt.key === `Tab`)) {
      evt.preventDefault();
      this._searchInputView.getElement().focus();
    }
  }

  _handleFormSubmit() {
    if (this._searchInputView.getElement().value.length < MIN_QUERY_LENGTH) {
      if (!this._elementNotification) {
        this._elementNotification = new ElementNotification(this._searchInputView.getParent());
        this._elementNotification.init();
      }

      if (this._elementNotification.checkIfRendered() === true) {
        return;
      }

      const notification = `Введите запрос из ${MIN_QUERY_LENGTH} или более символов.`;
      this._elementNotification.render(notification);
      return;
    }

    window.location.href = this._searchInputView.getAction() + `?query=${this._searchInputView.getElement().value}`;
  }
}
