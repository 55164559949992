export const RequestSubject = {
  HEADER: `Заказ обратного звонка («шапка» сайта)`,
  FOOTER: `Заказ обратного звонка («подвал» сайта)`,
  INSERTION: `Нашли дешевле – вставка в листинг`,
  SECOND_BANNER: `Кнопка во втором баннере на главной странице`
};

export const Classname = {
  HEADER: `phone-button`,
  FOOTER: `button_footer`,
  SECOND_BANNER: `banners-list__item_button_cta`,
  INSERTION: `button_category-insertion`
};
