import Glide from "@glidejs/glide";

import {
  VisibleClass
} from "../../glide/components/visible-class";

import {
  NavigationDisabler
} from "../../glide/components/navigation-disabler.js";

import {
  ResizeGap
} from "../../glide/components/resize-gap.js";

import {
  ControlsExtended
} from "../../glide/components/controls-extended.js";

import ProductCardsPresenter from "../../product-cards/presenter/product-cards.js";

import ControlsView from "../../slider/controls/view/controls";
import WrapperView from "../view/wrapper.js";

import {
  render,
  removeElement
} from "../../render/render.js";

import {
  navigationPosition
} from "../../../mixins/slider-navigation-position.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  defaultSliderOptions
} from "../const.js";

export default class ProductsSlider {
  constructor(wrapperElement, options = {}) {
    this._wrapperElement = wrapperElement;

    this._ProductCardsPresenter = ProductCardsPresenter;

    this._productCardsComponent = null;
    this._controlsView = null;
    this._wrapperView = null;

    this._slider = null;

    this._sliderOptions = Object.assign(
        {},
        defaultSliderOptions,
        options
    );

    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._handleWindowResizeDebounced = debounce(this._handleWindowResize);
    this._handleLazyLoadedImages = this._handleLazyLoadedImages.bind(this);
  }

  init() {
    this._wrapperView = new WrapperView(this._wrapperElement);

    this._slider = new Glide(this._wrapperElement, Object.assign(
        {},
        this._sliderOptions
    ));

    const options = {
      postfix: `product`
    };

    this._controlsView = new ControlsView(options);
    render(this._wrapperView.getElement(), this._controlsView.getElement());

    this._setSliderEvents();

    this._slider.mount({
      'ControlsExtended': ControlsExtended,
      'VisibleClass': VisibleClass,
      'NavigationDisabler': NavigationDisabler,
      'ResizeGap': ResizeGap,
    });

    this._productCardsComponent = new this._ProductCardsPresenter(this._wrapperView.getListElement());
    this._productCardsComponent.init();

    document.addEventListener(`lazyloaded`, this._handleLazyLoadedImages);
    window.addEventListener(`resize`, this._handleWindowResizeDebounced);
  }

  destroy() {
    this._productCardsComponent.destroy();

    if (this._controlsView && this._controlsView.getElement()) {
      removeElement(this._controlsView.getElement());
    }

    document.removeEventListener(`lazyloaded`, this._handleLazyLoadedImages);
    window.removeEventListener(`resize`, this._handleWindowResizeDebounced);

    if (this._slider) {
      this._slider.destroy();
    }
  }

  _setSliderEvents() {}

  _handleLazyLoadedImages(evt) {
    const slideImage = this._wrapperView.getSlideImage();

    if (evt.target === slideImage) {
      this.adjustNavigationPosition();
      document.removeEventListener(`lazyloaded`, this._handleLazyLoadedImages);
    }
  }

  _handleWindowResize() {
    this.adjustNavigationPosition();
  }
}

Object.assign(ProductsSlider.prototype, navigationPosition);
