import {
  siblings
} from "@glidejs/glide/src/utils/dom";

export const CustomActiveClass = (Glide, Components, Events) => {
  const Component = {
    mount() {
      this.changeActiveSlide();
    },

    changeActiveSlide() {
      const slide = Components.Html.slides[Glide.index];

      slide.classList.remove(`is-next`, `is-prev`);
      slide.classList.add(`is-active`);

      siblings(slide).forEach((sibling) => {
        sibling.classList.remove(`is-active`, `is-next`, `is-prev`);
      });

      if (slide.nextElementSibling) {
        slide.nextElementSibling.classList.add(`is-next`);
      }

      if (slide.previousElementSibling) {
        slide.previousElementSibling.classList.add(`is-prev`);
      }

      let bullets;

      Components.Controls.items
        .forEach((item) => {
          if (!bullets && item.dataset.glideEl === `controls[nav]`) {
            bullets = item;
          }
        });

      if (bullets) {
        const bullet = [...bullets.children].find(
            // eslint-disable-next-line no-shadow
            (bullet) => bullet.getAttribute(`data-glide-dir`) === `=${Glide.index}`
        );

        if (!bullet) {
          return;
        }

        bullet.classList.remove(`is-next`, `is-prev`);
        bullet.classList.add(`is-active`);

        siblings(bullet).forEach((sibling) => {
          sibling.classList.remove(`is-active`, `is-next`, `is-prev`);
        });

        if (bullet.nextElementSibling) {
          bullet.nextElementSibling.classList.add(`is-next`);
        }

        if (bullet.previousElementSibling) {
          bullet.previousElementSibling.classList.add(`is-prev`);
        }
      }
    },
  };

  Events.on(`run`, () => {
    Component.changeActiveSlide();
  });

  return Component;
};
