import Abstract from "../../abstract-view/abstract.js";

import {
  ANIMATION_DURATION
} from "../const.js";

export default class FaqItem extends Abstract {
  constructor(element, index, animation) {
    super();

    this._element = element;
    this._index = index;
    this._animation = animation;

    this._titleElement = null;
    this._descriptionElement = null;

    this._titleClickHandler = this._titleClickHandler.bind(this);
  }

  getTitleElement() {
    if (!this._titleElement) {
      this._titleElement = this._element.querySelector(`.faq-item__title`);
    }

    return this._titleElement;
  }

  getDescriptionElement() {
    if (!this._descriptionElement) {
      this._descriptionElement = this._element.querySelector(`.faq-item__description`);
    }

    return this._descriptionElement;
  }

  expand() {
    const titleElement = this.getTitleElement();
    titleElement.classList.add(`${titleElement.classList[0]}_active`);

    const descriptionElement = this.getDescriptionElement();
    this._animation.fadeIn(
        descriptionElement,
        ANIMATION_DURATION,
        () => {
          descriptionElement.style.display = `block`;
        }
    );
  }

  shrink() {
    const titleElement = this.getTitleElement();
    titleElement.classList.remove(`${titleElement.classList[0]}_active`);

    const descriptionElement = this.getDescriptionElement();
    this._animation.fadeOut(
        descriptionElement,
        ANIMATION_DURATION,
        () => {
          descriptionElement.style.display = null;
        }
    );
  }

  setTitleClickHandler(callback) {
    this._titleClick = callback;
    this.getTitleElement().addEventListener(`click`, this._titleClickHandler);
  }

  _titleClickHandler() {
    this._titleClick(this._index);
  }
}
