import ProductItemPresenter from "./product-item.js";
import ProductsListView from "../view/products-list.js";

import Animation from "../../animation/animation.js";

import {
  generateId
} from "../../../utils/generate-id.js";

export default class ProductsList {
  constructor(listElement) {
    this._listElement = listElement;

    this._animation = null;

    this._ProductItemPresenter = ProductItemPresenter;
    this._ProductsListView = ProductsListView;

    this._productsListView = null;
    this._itemPresenterArgs = [];

    this._itemsPresenter = {};
  }

  init() {
    this._animation = new Animation();

    this._productsListView = new ProductsListView(this._listElement);
    Array.from(this._productsListView.getItems())
      .forEach((item) => {
        this._initItem(item);
      });
  }

  destroy(destroyItems = true) {
    if (destroyItems) {
      Object.values(this._itemsPresenter)
        .forEach((presenter) => {
          presenter.destroy();
        });
      this._itemsPresenter = {};
    }
  }

  _initItem(item) {
    const id = generateId();
    const itemPresenter = new this._ProductItemPresenter(item, id, this._animation, ...this._itemPresenterArgs);
    itemPresenter.init();

    this._itemsPresenter[id] = itemPresenter;
  }
}
