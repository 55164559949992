export const MODAL_MAIN_CLASS = `modal-feedback-form-wrapper modal-feedback-form-wrapper_list-request`;

export const REQUEST_SUBJECT = `Запросить товары по списку`;
export const DEFAULT_YM_GOAL_ID = `napspisok`;

import {
  RuleName
} from "../submit-form/const";

export const EXTRA_VALIDATION_RULES = [
  {
    name: `email`,
    rules: [
      RuleName.EMAIL
    ]
  },
  {
    name: `text`,
    rules: [
      RuleName.NOT_EMPTY
    ]
  }
];
