import CatalogButtonView from "../view/catalog-button.js";
import CatalogNavigationView from "../view/catalog-navigation.js";
import CategoryLink from "../view/category-link.js";
import SubcategoriesItemView from "../view/subcategories-item.js";
import MobileMenuView from "../view/mobile-menu.js";
import SubcategoriesModalView from "../view/subcategories-modal.js";

import HeaderCatalogModel from "../model/header-catalog.js";
import CategoryLinkModel from "../model/category-link.js";
import SubcategoriesItemModel from "../model/subcategories-item.js";

import Animation from "../../animation/animation.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  getMaxZIndex
} from "../../../utils/get-max-z-index.js";

import {
  render,
  removeElement,
  RenderPosition
} from "../../render/render.js";

import {
  DefaultCatalogModel,
  DefaultCategoryLinkModel,
  DefaultSubcategoriesItemModel,
  ActionType,
  KeyboardKey,
  AnimationDuration,
  MOBILE_MEDIA_QUERY
} from "../const.js";

import {
  AnimationClass
} from "../../animation/const.js";

export default class HeaderCatalog {
  constructor(catalogButton, catalogNavigation) {
    this._catalogButton = catalogButton;
    this._catalogNavigation = catalogNavigation;

    this._catalogModel = null;
    this._categoriesModel = {};
    this._subcategoriesModel = {};

    this._catalogButtonView = null;
    this._catalogNavigationView = null;
    this._mobileMenuView = null;

    this._mobileSubcategriesActiveList = null;
    this._subcategoriesModalView = null;

    this._categoriesView = {};
    this._subcategoriesView = {};

    this._callbacks = {};

    this._animation = new Animation();

    this._handleCatalogButtonClick = this._handleCatalogButtonClick.bind(this);
    this._handleChangeModel = this._handleChangeModel.bind(this);
    this._handleCategoryLinkMouseEnter = this._handleCategoryLinkMouseEnter.bind(this);
    this._handleCategoryButtonClick = this._handleCategoryButtonClick.bind(this);
    this._handleSubcategoriesModalCloseButtonClick = this._handleSubcategoriesModalCloseButtonClick.bind(this);

    this._handleEscKeyDown = this._handleEscKeyDown.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
    this._handleWindowChange = this._handleWindowChange.bind(this);

    this._debouncedHandleWindowChange = debounce(this._handleWindowChange);
  }

  init() {
    this._catalogButtonView = new CatalogButtonView(this._catalogButton);
    this._catalogButtonView.setClickHandler(this._handleCatalogButtonClick);

    this._catalogNavigationView = new CatalogNavigationView(this._catalogNavigation);

    Array.from(this._catalogNavigationView.getCategoriesLinks()).forEach((item) => {
      const categoryId = +item.dataset.categoryId;

      const categoryLinkView = new CategoryLink(item, categoryId);
      categoryLinkView.setMouseEnterHandler(this._handleCategoryLinkMouseEnter);
      categoryLinkView.setButtonClickHandler(this._handleCategoryButtonClick);

      const categoryLinkModel = new CategoryLinkModel(Object.assign(
          {},
          DefaultCategoryLinkModel
      ));
      categoryLinkModel.addObserver(this._handleChangeModel);

      this._categoriesView[categoryId] = categoryLinkView;
      this._categoriesModel[categoryId] = categoryLinkModel;
    });

    Array.from(this._catalogNavigationView.getSubcategoriesItems()).forEach((item) => {
      const categoryId = +item.dataset.categoryId;

      const subcategoriesItemView = new SubcategoriesItemView(item);
      const subcategoriesItemModel = new SubcategoriesItemModel(Object.assign(
          {},
          DefaultSubcategoriesItemModel
      ));
      subcategoriesItemModel.addObserver(this._handleChangeModel);

      this._subcategoriesView[categoryId] = subcategoriesItemView;
      this._subcategoriesModel[categoryId] = subcategoriesItemModel;
    });

    this._catalogModel = new HeaderCatalogModel(Object.assign(
        {},
        DefaultCatalogModel
    ));
    this._catalogModel.addObserver(this._handleChangeModel);

    this._renderMobileMenu();
  }

  addAfterOpenCallback(callback) {
    if (!this._callbacks.afterOpen) {
      this._callbacks.afterOpen = [];
    }

    this._callbacks.afterOpen.push(callback);
  }

  addBeforeCloseCallback(callback) {
    if (!this._callbacks.beforeClose) {
      this._callbacks.beforeClose = [];
    }

    this._callbacks.beforeClose.push(callback);
  }

  _renderMobileMenu() {
    this._mobileMenuView = new MobileMenuView();
    this._catalogModel.changeData(Object.assign(
        {},
        this._catalogModel.getData(),
        {
          isMobileMenuRendered: true
        }
    ), ActionType.RENDER_MOBILE_MENU);
  }

  _closeCatalog() {
    const modelData = this._catalogModel.getData();

    if (modelData.isOpen === false || modelData.animation) {
      return;
    }

    this._catalogModel.changeData(Object.assign(
        {},
        modelData,
        {
          isOpen: false,
          animation: true
        }
    ), ActionType.CLOSE_CATALOG);
  }

  _showSubcategoryItem(categoryId) {
    const model = this._subcategoriesModel[categoryId];
    const modelData = model.getData();

    if (modelData.isOpen) {
      return;
    }

    model.changeData(Object.assign(
        {},
        modelData,
        {
          isOpen: true
        }
    ), ActionType.SUBCATEGORIES_ITEM_SHOW, {
      categoryId
    });
  }

  _hideSubcategoryItem(categoryId) {
    const model = this._subcategoriesModel[categoryId];
    const modelData = model.getData();

    if (!modelData.isOpen) {
      return;
    }

    model.changeData(Object.assign(
        {},
        modelData,
        {
          isOpen: false
        }
    ), ActionType.SUBCATEGORIES_ITEM_HIDE, {
      categoryId
    });
  }

  _showSubcategoriesWrapper() {
    const modelData = this._catalogModel.getData();

    if (modelData.isWrapperOpen) {
      return;
    }

    this._catalogModel.changeData(Object.assign(
        {},
        modelData,
        {
          isWrapperOpen: true
        }
    ), ActionType.OPEN_SUBCATEGORIES_WRAPPER);
  }

  _setActiveCategory(categoryId) {
    this._deactivateCategories(categoryId);

    const model = this._categoriesModel[categoryId];
    const modelData = model.getData();

    if (modelData.isActive) {
      return;
    }

    model.changeData(Object.assign(
        {},
        modelData,
        {
          isActive: true
        }
    ), ActionType.CATEGORY_LINK_ACTIVATE, {
      categoryId
    });
  }

  _deactivateCategories(currentCategoryId) {
    Object.entries(this._categoriesModel).forEach(([id, model]) => {
      if (+id === currentCategoryId) {
        return;
      }

      model.changeData(Object.assign(
          {},
          model.getData(),
          {
            isActive: false
          }
      ), ActionType.CATEGORY_LINK_DEACTIVATE, {
        categoryId: id
      });
    });
  }

  _deactivateMobileCategories() {
    Object.entries(this._subcategoriesModel).forEach((item) => {
      const model = item[1];
      const modelData = model.getData();

      model.changeData(Object.assign(
          {},
          modelData,
          {
            isOpen: false
          }
      ));
    });
  }

  _showMobileSubcategoriesModal(categoryId) {
    if (this._catalogModel.getData().isMobileModalRendered) {
      return;
    }

    this._catalogModel.changeData(Object.assign(
        {},
        this._catalogModel.getData(),
        {
          isMobileModalRendered: true
        }
    ));

    const subcategoryItemListElement = this._subcategoriesView[categoryId].getClonedListElement();
    const categoryText = this._categoriesView[categoryId].getText();
    const categoryHref = this._categoriesView[categoryId].getHref();

    this._subcategoriesModalView = new SubcategoriesModalView(categoryText, categoryHref, categoryId);
    this._subcategoriesModalView.setZIndex(getMaxZIndex(true) + 1);
    this._subcategoriesModalView.setCloseButtonClickHandler(this._handleSubcategoriesModalCloseButtonClick);

    const subcategoriesModalElement = this._subcategoriesModalView.getElement();

    render(document.body, subcategoriesModalElement);
    render(this._subcategoriesModalView.getListWrapperElement(), subcategoryItemListElement);

    this._animation.fadeIn(
        subcategoriesModalElement,
        null,
        () => {
          this._subcategoriesModalView.setVisible();
        }
    );
  }

  _hideMobileSubcategoriesModal() {
    if (!this._catalogModel.getData().isMobileModalRendered) {
      return;
    }

    this._catalogModel.changeData(Object.assign(
        {},
        this._catalogModel.getData(),
        {
          isMobileModalRendered: false
        }
    ));

    const subcategoriesModalElement = this._subcategoriesModalView.getElement();

    this._animation.fadeOut(
        subcategoriesModalElement,
        null,
        () => {
          removeElement(subcategoriesModalElement);
          this._subcategoriesModalView = null;
        }
    );
  }

  _handleCatalogButtonClick() {
    const modelData = this._catalogModel.getData();
    const action = (modelData.isOpen) ?
      ActionType.CLOSE_CATALOG :
      ActionType.OPEN_CATALOG;
    const isOpen = (modelData.isOpen) ?
      false :
      true;

    this._catalogModel.changeData(Object.assign(
        {},
        modelData,
        {
          isOpen,
          animation: true
        }
    ), action);
  }

  _handleCategoryLinkMouseEnter(categoryId) {
    this._setActiveCategory(categoryId);
  }

  _handleCategoryButtonClick(categoryId) {
    this._setActiveCategory(categoryId);

    if (window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
      const model = this._subcategoriesModel[categoryId];
      const modelData = model.getData();

      this._deactivateMobileCategories();

      model.changeData(Object.assign(
          {},
          modelData
      ), ActionType.MOBILE_SUBCATEGORIES_MODAL_SHOW, {
        categoryId
      });
    }
  }

  _handleEscKeyDown(evt) {
    if (KeyboardKey.ESC.indexOf(evt.key) !== -1) {
      this._closeCatalog();
    }
  }

  _handleDocumentClick(evt) {
    if (
      this._catalogNavigationView.getElement().contains(evt.target) ||
      this._catalogButtonView.getElement().contains(evt.target)
    ) {
      return;
    }

    if (
      this._subcategoriesModalView &&
      this._subcategoriesModalView.getElement().contains(evt.target)
    ) {
      return;
    }

    this._closeCatalog();
  }

  _handleSubcategoriesModalCloseButtonClick(categoryId) {
    const model = this._subcategoriesModel[categoryId];
    const modelData = model.getData();

    model.changeData(Object.assign(
        {},
        modelData,
        {
          isOpen: false
        }
    ), ActionType.MOBILE_SUBCATEGORIES_MODAL_HIDE);
  }

  _handleWindowChange() {
    const activeCategoriesIds = Object
    .entries(this._categoriesModel)
    // eslint-disable-next-line no-unused-vars
    .filter(([id, model]) => {
      return model.getData().isActive;
    })
    .map((item) => item[0])
    .flat();

    if (window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
      const modelData = this._catalogModel.getData();

      if (modelData.isWrapperOpen) {
        this._catalogModel.changeData(
            Object.assign(
                {},
                modelData,
                {
                  isWrapperOpen: false
                }
            ), ActionType.CLOSE_SUBCATEGORIES_WRAPPER
        );
      }

      if (activeCategoriesIds.length > 0) {
        const activeCategoryId = activeCategoriesIds[0];
        const activeCategoryModel = this._subcategoriesModel[activeCategoryId];

        activeCategoryModel.changeData(Object.assign(
            {},
            activeCategoryModel.getData(),
            {
              isOpen: true
            }
        ), ActionType.MOBILE_SUBCATEGORIES_MODAL_SHOW, {
          categoryId: activeCategoryId
        });
      }
    } else {
      if (activeCategoriesIds.length > 0 && !this._catalogModel.getData().isWrapperOpen) {
        const activeCategoryId = activeCategoriesIds[0];
        this._setActiveCategory(activeCategoryId);
      }

      if (this._mobileSubcategriesActiveList) {
        removeElement(this._mobileSubcategriesActiveList);
        this._mobileSubcategriesActiveList = null;
      }
    }
  }

  _handleChangeModel(action, payload) {
    const modelData = this._catalogModel.getData();

    switch (action) {
      case ActionType.RENDER_MOBILE_MENU:
        render(
            this._catalogNavigationView,
            this._mobileMenuView.getElement(),
            RenderPosition.AFTERBEGIN
        );
        break;
      case ActionType.MOBILE_SUBCATEGORIES_MODAL_HIDE:
        this._hideMobileSubcategoriesModal();
        break;
      case ActionType.MOBILE_SUBCATEGORIES_MODAL_SHOW:
        this._showMobileSubcategoriesModal(payload.categoryId);
        break;
      case ActionType.OPEN_SUBCATEGORIES_WRAPPER:
        this._animation.fadeIn(
            this._catalogNavigationView.getSubcategoriesWrapperElement(),
            AnimationDuration.SUBCATEGORIES_WRAPPER,
            null,
            AnimationClass.SHOW_FLEX
        );
        break;
      case ActionType.CLOSE_SUBCATEGORIES_WRAPPER:
        this._animation.fadeOut(
            this._catalogNavigationView.getSubcategoriesWrapperElement()
        );
        break;
      case ActionType.SUBCATEGORIES_ITEM_SHOW:
        if (!window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
          this._showSubcategoriesWrapper();
          this._animation.fadeIn(
              this._subcategoriesView[payload.categoryId].getElement()
          );
        }
        break;
      case ActionType.SUBCATEGORIES_ITEM_HIDE:
        this._subcategoriesView[payload.categoryId].resetView();
        break;
      case ActionType.CATEGORY_LINK_DEACTIVATE:
        this._hideSubcategoryItem(payload.categoryId);
        this._categoriesView[payload.categoryId].resetView();
        break;
      case ActionType.CATEGORY_LINK_ACTIVATE:
        this._showSubcategoryItem(payload.categoryId);
        this._categoriesView[payload.categoryId].setActive();
        break;
      case ActionType.ANIMATION_END:
        if (modelData.isOpen) {
          document.addEventListener(`click`, this._handleDocumentClick);

          if (this._callbacks.afterOpen) {
            this._callbacks.afterOpen.forEach((callback) => callback());
          }
        } else {
          document.removeEventListener(`click`, this._handleDocumentClick);
        }
        break;
      case ActionType.OPEN_CATALOG:
        this._catalogButtonView.setOpened();
        this._animation.fadeIn(
            this._catalogNavigationView.getElement(),
            AnimationDuration.CATALOG,
            () => {
              this._catalogModel.changeData(Object.assign(
                  {},
                  modelData,
                  {
                    animation: false
                  }
              ), ActionType.ANIMATION_END);

              document.addEventListener(`keydown`, this._handleEscKeyDown);
              window.addEventListener(`resize`, this._debouncedHandleWindowChange, {
                passive: true
              });
            }
        );

        break;
      case ActionType.CLOSE_CATALOG:
        this._catalogButtonView.setClosed();

        if (this._callbacks.beforeClose) {
          this._callbacks.beforeClose.forEach((callback) => callback());
        }

        this._animation.fadeOut(
            this._catalogNavigationView.getElement(),
            AnimationDuration.CATALOG,
            () => {
              this._catalogModel.changeData(Object.assign(
                  {},
                  modelData,
                  {
                    animation: false
                  }
              ), ActionType.ANIMATION_END);
            }
        );

        document.removeEventListener(`keydown`, this._handleEscKeyDown);
        window.removeEventListener(`resize`, this._debouncedHandleWindowChange, {
          passive: true
        });
        break;
    }
  }
}
