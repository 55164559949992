import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

import {
  REMOVE_ITEM_LEXICON
} from "../lexicon.js";

const createMiniListItemTemplate = ({
  id
}) => {
  return (
    `<li class="mini-list__item" data-item-id="${id}">
      <button class="mini-list__remove-button" type="button">
        <span class="visually-hidden">${REMOVE_ITEM_LEXICON}</span>
      </button>
    </li>`
  );
};

export default class MiniListItem extends Abstract {
  constructor(data) {
    super();

    this._data = data;

    this._removeButton = null;

    this._handleRemoveButtonClick = this._handleRemoveButtonClick.bind(this);
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getData() {
    return this._data;
  }

  getRemoveButton() {
    if (!this._removeButton) {
      this._removeButton = this.getElement().querySelector(`button`);
    }

    return this._removeButton;
  }

  setRemoveButtonClickHandler(callback) {
    this._callback.removeButtonClick = callback;
    this.getRemoveButton().addEventListener(`click`, this._handleRemoveButtonClick);
  }

  _getTemplate() {
    return createMiniListItemTemplate(this._data);
  }

  _handleRemoveButtonClick() {
    this._callback.removeButtonClick(this._element, this._data.id);
  }
}
