export const REQUEST_URL = `/assets/components/customsearch/connector.php`;
export const ACTION_TYPE = `suggestions`;
export const MIN_QUERY_LENGTH = 3;

export const Action = {
  RENDER: `RENDER`,
  LOADING: `LOADING`,
  LOADED: `LOADED`,
  DESTROY: `DESTROY`,
  INPUT_SHOW: `INPUT_SHOW`,
  INPUT_HIDE: `INPUT_HIDE`,
  INPUT_RESET: `INPUT_RESET`,
  INPUT_FOCUS: `INPUT_FOCUS`,
  INPUT_BLUR: `INPUT_BLUR`,
  SHOW_CLEAR_TRIGGER: `SHOW_CLEAR_TRIGGER`,
  HIDE_CLEAR_TRIGGER: `HIDE_CLEAR_TRIGGER`
};

export const Status = {
  LOADING: `LOADING`,
  IDLE: `IDLE`,
  DESTROY: `DESTROY`
};

export const LOADING_POSTFIX = `loading`;
export const IDLE_POSTFIX = `idle`;
export const ANIMATION_DURATION = 300;
export const DEBOUNCE_TIME = 500;

export const RESPONSIVE_MEDIA_QUERY = `(max-width: 800px)`;

export const defaultSearchInputModelData = {
  responsiveHide: true,
  clearTrigger: false,
  focused: false
};
