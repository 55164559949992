import ReviewsContainerView from "../view/container.js";
import ReviewItemView from "../view/item.js";

import ReviewsRatingModel from "../model/reviews-rating";

import {
  defaultReviewsRatingModelData,
  ActionType
} from "../const.js";

export default class ReviewsRating {
  constructor(container) {
    this._container = container;

    this._containerView = null;
    this._itemsViews = [];
    this._model = [];

    this._handleListMouseLeave = this._handleListMouseLeave.bind(this);
    this._handleItemMouseEnter = this._handleItemMouseEnter.bind(this);
    this._handleItemClick = this._handleItemClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._containerView = new ReviewsContainerView(this._container);
    this._containerView.setListMouseLeaveHandler(this._handleListMouseLeave);

    Array.from(this._containerView.getItems())
      .forEach((item, index) => {
        const itemView = new ReviewItemView(item, index);

        itemView.setMouseEnterHandler(this._handleItemMouseEnter);
        itemView.setClickHandler(this._handleItemClick);

        this._itemsViews.push(itemView);
      });

    this._model = new ReviewsRatingModel(Object.assign(
        {},
        defaultReviewsRatingModelData
    ));
    this._model.addObserver(this._handleChangeData);
  }

  _processListLeave() {
    this._itemsViews.forEach((item, index) => {
      if (index >= this._model.getData().rating) {
        this._model.changeData(Object.assign(
            {},
            this._model.getData()
        ), ActionType.ITEM_DEACTIVATE, {
          itemId: index
        });
      } else {
        this._model.changeData(Object.assign(
            {},
            this._model.getData()
        ), ActionType.ITEM_ACTIVATE, {
          itemId: index
        });
      }
    });
  }

  _processItemEnter(currentItemId) {
    this._itemsViews.forEach((item, index) => {
      if (index > currentItemId) {
        this._model.changeData(Object.assign(
            {},
            this._model.getData()
        ), ActionType.ITEM_DEACTIVATE, {
          itemId: index
        });
      } else {
        this._model.changeData(Object.assign(
            {},
            this._model.getData()
        ), ActionType.ITEM_ACTIVATE, {
          itemId: index
        });
      }
    });
  }

  _processItemActivate(itemId) {
    this._itemsViews[itemId].activate();
  }

  _processItemDeactivate(itemId) {
    this._itemsViews[itemId].deactivate();
  }

  _processItemSelect() {
    this._containerView.setInputValue(this._model.getData().rating);
  }

  _handleListMouseLeave() {
    this._model.changeData(Object.assign(
        {},
        this._model.getData()
    ), ActionType.LIST_LEAVE);
  }

  _handleItemMouseEnter(currentItemId) {
    const modelData = this._model.getData();

    this._model.changeData(Object.assign(
        {},
        modelData
    ), ActionType.ITEM_ENTER, {
      currentItemId
    });
  }

  _handleItemClick(selectedItemId) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          rating: selectedItemId + 1
        }
    ), ActionType.ITEM_SELECT);
  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.LIST_LEAVE:
        this._processListLeave();
        break;
      case ActionType.ITEM_ENTER:
        this._processItemEnter(payload.currentItemId);
        break;
      case ActionType.ITEM_ACTIVATE:
        this._processItemActivate(payload.itemId);
        break;
      case ActionType.ITEM_DEACTIVATE:
        this._processItemDeactivate(payload.itemId);
        break;
      case ActionType.ITEM_SELECT:
        this._processItemSelect();
        break;
    }
  }
}
