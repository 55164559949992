const EXCLUDED_SELECTORS = [
  `.fancybox__container`,
  `[class^=comagic]`,
  `.responsive-filter`,
  `.promo-video-wrapper`,
  `.header-catalog-subcategories-modal`,
  `.categories-modal`
];

export const getMaxZIndex = (preventUsingExcluded = false) => {
  const selector = (preventUsingExcluded === false && EXCLUDED_SELECTORS.length > 0) ?
    `body *:not(${EXCLUDED_SELECTORS.join(`,`)})` :
    `body *`;

  return Array.from(document.querySelectorAll(selector))
    .map((element) => parseFloat(window.getComputedStyle(element).zIndex))
    .filter((item) => !isNaN(item))
    .sort((a, b) => {
      return a - b;
    })
    .pop();
};
