import ModalFeedbackFormPresenter from "../../modal-feedback-form/presenter/modal-feedback-form.js";
import FileAttachPresenter from "../../file-attach/presenter/file-attach.js";
import UISPresenter from "../../uis/presenter/uis.js";

import ModalSectionView from "../view/section.js";

import {
  MODAL_MAIN_CLASS,
  EXTRA_VALIDATION_RULES,
  DEFAULT_YM_GOAL_ID
} from "../const.js";

import {
  YM_COUNTER_ID
} from "../../modal-feedback-form/const.js";

export default class ModalExtendedFeedbackForm extends ModalFeedbackFormPresenter {
  constructor(addExtraValidationRules = true, properties = {}) {
    super();

    this._modalMainClass = MODAL_MAIN_CLASS;

    if (addExtraValidationRules) {
      this._defaultValidationRules = this._defaultValidationRules.concat(EXTRA_VALIDATION_RULES);
    }

    if (properties.submitYmGoal) {
      this._submitYmGoal = properties.submitYmGoal;
    }

    this._modalSectionView = new ModalSectionView();

    this._fileAttachPresenter = null;

    this._submitCallbacks = {
      loadSuccess: (response, formData) => {
        this._fileAttachPresenter.reset();

        const goalId = (this._submitYmGoal) ?
          this._submitYmGoal :
          DEFAULT_YM_GOAL_ID;

        // Send the Yandex Metrika goal.
        if (window.ym) {
          window.ym(YM_COUNTER_ID, `reachGoal`, goalId);
        }

        const uisComponent = new UISPresenter();
        uisComponent.addRequest(formData);
      }
    };
  }

  _processInit() {
    super._processInit();

    const fileInputElement = this._modalSectionView.getFileInputElement();
    this._fileAttachPresenter = new FileAttachPresenter(fileInputElement);
    this._fileAttachPresenter.init();
  }
}
