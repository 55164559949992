export const defaultTriggerModelData = {
  isActive: false,
  productId: null
};

export const ActionType = {
  ADD: `ADD`,
  REMOVE: `REMOVE`
};

export const RequestAction = {
  INIT: `init`,
  ADD_TO_LIST: `add_to_list`,
  REMOVE_FROM_LIST: `remove_from_list`,
  UPDATE_DATA: `update_data`,
  GET_DATA: `get_data`
};
