import Abstract from "../../abstract-view/abstract.js";

import {
  ITEMS_ANIMATION_DURATION,
  MOBILE_MEDIA_QUERY
} from "../const.js";

import {
  BUTTON_HIDE_TEXT
} from "../lexicon.js";

export default class CatalogItem extends Abstract {
  constructor(element, index, animationComponent, scrollToElementComponent) {
    super();

    this._element = element;
    this._index = index;
    this._animation = animationComponent;
    this._scrollToElement = scrollToElementComponent;

    this._itemsButton = null;
    this._titleButton = null;
    this._hiddenItems = null;
    this._linksList = null;

    this._itemsButtonText = null;

    this._itemsButtonClickHandler = this._itemsButtonClickHandler.bind(this);
    this._titleButtonClckHandler = this._titleButtonClckHandler.bind(this);
  }

  setActive() {
    if (this.getItemsButton()) {
      this.getItemsButton().classList.add(`${this.getItemsButton().classList[0]}_active`);
      this._itemsButtonText = this.getItemsButton().textContent;
      this.getItemsButton().textContent = BUTTON_HIDE_TEXT;
    }

    this.getTitleButton().classList.add(`${this.getTitleButton().classList[0]}_active`);
  }

  setDefault() {
    if (this.getItemsButton()) {
      this.getItemsButton().classList.remove(`${this.getItemsButton().classList[0]}_active`);
      this.getItemsButton().textContent = this._itemsButtonText;
    }

    this.getTitleButton().classList.remove(`${this.getTitleButton().classList[0]}_active`);
  }

  showItems() {
    Array.from(this.getHiddenItems())
      .forEach((item) => {
        this._animation.slideDown(
            item,
            ITEMS_ANIMATION_DURATION,
            null,
            () => {
              item.style.display = `block`;
            }
        );
      });

    if (window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
      this._animation.fadeIn(
          this.getListLinks(),
          null,
          () => {
            if (window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
              const scrollOptions = {
                element: this.getElement()
              };

              this._scrollToElement.scroll(scrollOptions);
            }
          }
      );
    } else {
      this.getListLinks().style.display = `flex`;
    }
  }

  hideItems() {
    Array.from(this.getHiddenItems())
      .forEach((item) => {
        this._animation.slideUp(
            item,
            ITEMS_ANIMATION_DURATION,
            null,
            () => {
              item.style.display = null;
            }
        );
      });

    if (window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
      this._animation.fadeOut(
          this.getListLinks(),
          ITEMS_ANIMATION_DURATION,
          () => {
            this._resetListLinks();
          }
      );
    } else {
      this._resetListLinks();
    }
  }

  getListLinks() {
    if (!this._linksList) {
      this._linksList = this._element.querySelector(`.catalog-js-list__links`);
    }

    return this._linksList;
  }

  getHiddenItems() {
    if (!this._hiddenItems) {
      this._hiddenItems = this._element.querySelectorAll(`.catalog-js-list__item_hidden`);
    }

    return this._hiddenItems;
  }

  getItemsButton() {
    if (!this._itemsButton) {
      this._itemsButton = this._element.querySelector(`.catalog-js-list__items-button`);
    }

    return this._itemsButton;
  }

  getTitleButton() {
    if (!this._titleButton) {
      this._titleButton = this._element.querySelector(`.catalog-js-list__title-button`);
    }

    return this._titleButton;
  }

  setItemsButtonClickHandler(callback) {
    const itemsButton = this.getItemsButton();

    if (!itemsButton) {
      return;
    }

    this._callback.itemsButtonClick = callback;
    this.getItemsButton().addEventListener(`click`, this._itemsButtonClickHandler);
  }

  setTitleButtonClickHandler(callback) {
    this._callback.titleButtonClick = callback;
    this.getTitleButton().addEventListener(`click`, this._titleButtonClckHandler);
  }

  _resetListLinks() {
    this.getListLinks().style.display = null;
  }

  _itemsButtonClickHandler() {
    this._callback.itemsButtonClick();
  }

  _titleButtonClckHandler() {
    this._callback.titleButtonClick();
  }
}
