import MiniListPresenter from "../../mini-list/presenter/mini-list.js";
import TriggerPresenter from "../../mini-favorite/presenter/trigger.js";

import MiniFavoriteSectionView from "../view/section.js";
import MiniFavoriteItemView from "../view/item.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  CONNECTOR_URL,
  RequestAction
} from "../../favorite/favorite-data/const.js";

import {
  ActionType as FavoriteActionType
} from "../../favorite/favorite-data/const.js";

export default class MiniFavorite extends MiniListPresenter {
  constructor(triggerElement) {
    super(triggerElement);

    this._TriggerPresenter = TriggerPresenter;
    this._connectorUrl = CONNECTOR_URL;
    this._requestAction = RequestAction.REMOVE_PRODUCT;

    this._SectionView = MiniFavoriteSectionView;
    this._ItemView = MiniFavoriteItemView;
  }

  processRemoveItem(elementToRemove, removedProductId) {
    super.processRemoveItem(elementToRemove);

    const requestArgs = {
      body: [
        `action=${this._requestAction}`,
        `product_id=${removedProductId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);
        this._processChangeFavoriteData(data, removedProductId);
      },
    };
    sendXhr(requestArgs);
  }

  _processChangeFavoriteData(data, removedProductId) {
    if (window.FavoriteData) {
      window.FavoriteData.changeData(Object.assign(
          {},
          window.FavoriteData.getData(),
          data
      ), FavoriteActionType.UPDATE_DATA, {
        removedProductId
      });
    }
  }
}
