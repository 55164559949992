export const defaultMiniListTriggerModelData = {
  isActive: false,
  itemsCount: null,
  isCounterRendered: false,
};

export const SECTION_RENDER_TIMEOUT = 300;
export const SECTION_DESTROY_TIMEOUT = 400;

export const ActionType = {
  RENDER_SECTION: `RENDER_SECTION`,
  DESTROY_SECTION: `DESTROY_SECTION`,
  REMOVE_ITEM: `REMOVE_ITEM`,
  UPDATE_TRIGGER: `UPDATE_TRIGGER`,
  RENDER_COUNTER: `RENDER_COUNTER`,
  DESTROY_COUNTER: `DESTROY_COUNTER`,
  CHANGE_COUNT: `CHANGE_COUNT`
};

export const MOBILE_MEDIA_QUERY = `(max-width: 500px)`;
