import AddToCartPresenter from "../../add-to-cart/presenter/add-to-cart.js";
import OneClickBuyPresenter from "../../one-click-buy/presenter/one-click-buy.js";
import FavoriteTriggerPresenter from "../../favorite/trigger/presenter/favorite-trigger.js";
import ComparisonTriggerPresenter from "../../comparison/trigger/presenter/comparison-trigger.js";
import ModalProductPropertiesTrigger from "../../modal-product-properties/presenter/modal-product-properties-trigger.js";
import ProductQuantityPresenter from "../../product-quantity/presenter/product-quantity.js";

import ProductItemView from "../view/product-item.js";
import ProductItemModel from "../model/product-item.js";

import Animation from "../../animation/animation.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  Fancybox
} from "@fancyapps/ui";

import ru from "@fancyapps/ui/src/Fancybox/l10n/ru";

import {
  FancyboxOptions
} from "../../../const.js";

import {
  defaultProductItemModelData,
  ActionType
} from "../const.js";

import {
  ActionType as CartDataActionType
} from "../../cart-data/const.js";
export default class ProductItem {
  constructor(element, id, animationComponent) {
    this._element = element;
    this._id = id;

    this._animation = animationComponent || new Animation();

    this._ItemView = ProductItemView;

    this._addToCartPresenter = null;
    this._oneClickBuyPresenter = null;
    this._favoriteTriggerPresenter = null;
    this._comparisonTriggerPresenter = null;
    this._modalProductPropertiesPresenter = null;
    this._productQuantityPresenter = null;

    this._itemView = null;
    this._itemModel = null;

    this._itemArgs = [];

    this._itemModelData = Object.assign(
        {},
        defaultProductItemModelData
    );

    this._handlePictureClick = this._handlePictureClick.bind(this);
    this.handleChangeData = this.handleChangeData.bind(this);
    this._handleCartDataChange = this._handleCartDataChange.bind(this);
  }

  init() {
    this._itemView = new this._ItemView(this._element, ...this._itemArgs);
    this._itemModel = new ProductItemModel(Object.assign(
        {},
        this._itemModelData
    ));
    this._itemModel.addObserver(this.handleChangeData);

    if (this._itemModel.getData().initAddToCartTrigger) {
      this._addToCartPresenter = new AddToCartPresenter(
          this._itemView.getAddToCartButton(),
          this._itemView.getProductId(),
          this._animation
      );
      this._addToCartPresenter.init();

      this._productQuantityPresenter = new ProductQuantityPresenter(
          this._itemView.getQuantityElement(),
          null,
          this._itemView.getProductId(),
          {
            preventWidthUpdate: true
          }
      );

      this._productQuantityPresenter.addObserver(this._addToCartPresenter.getProductQuantityObservationHandler());
      this._productQuantityPresenter.init();
    }

    if (this._itemModel.getData().initOneClickBuyTrigger) {
      this._oneClickBuyPresenter = new OneClickBuyPresenter(
          this._itemView.getOneClickBuyButton(),
          this._itemView.getOneClickBuyData()
      );
      this._oneClickBuyPresenter.init();
    }

    this._favoriteTriggerPresenter = new FavoriteTriggerPresenter(
        this._itemView.getFavoriteTriggerElement(),
        this._itemView.getProductId(),
        this._id,
        this._animation
    );
    this._favoriteTriggerPresenter.init();

    if (this._itemModel.getData().initComparisonTrigger) {
      this._comparisonTriggerPresenter = new ComparisonTriggerPresenter(
          this._itemView.getComparisonTriggerElement(),
          this._itemView.getProductId(),
          this._id,
          this._animation
      );
      this._comparisonTriggerPresenter.init();
    }

    if (
      this._itemModel.getData().initProductPropertiesTrigger &&
      this._itemView.getShowPropertiesElement()
    ) {
      this._modalProductPropertiesPresenter = new ModalProductPropertiesTrigger(
          this._itemView.getShowPropertiesElement(),
          this._itemView.getProductId()
      );
      this._modalProductPropertiesPresenter.init();
    }

    this._checkAddedToCart();

    if (window.CartData) {
      window.CartData.addObserver(this._handleCartDataChange);
    }
  }

  destroy() {
    this._favoriteTriggerPresenter.destroy();

    if (this._itemModel.getData().initComparisonTrigger) {
      this._comparisonTriggerPresenter.destroy();
    }

    this.forceReset();
  }

  getElement() {
    return this._element;
  }

  forceReset() {
    const modelData = this._itemModel.getData();

    this._itemModel.changeData(Object.assign(
        {},
        modelData,
        {
          isHovered: false
        }
    ), ActionType.FORCE_RESET);
  }

  resetView() {
    const modelData = this._itemModel.getData();

    if (!modelData.isHovered) {
      return;
    }

    this._itemModel.changeData(Object.assign(
        {},
        modelData,
        {
          isHovered: false
        }
    ), ActionType.ITEM_LEAVE);
  }

  initZoomTrigger() {
    this._itemView.setPictureClickHandler(this._handlePictureClick);
  }

  handleChangeData(action) {
    switch (action) {
      case ActionType.FORCE_RESET:
        this._forceResetProductItem();
        break;
      case ActionType.SET_ADDED_TO_CART:
        this._processSetAddedToCart();
        break;
      case ActionType.UNSET_ADDED_TO_CART:
        this._processUnsetAddedToCart();
        break;
    }
  }

  _processAddProductToCart(productId) {
    if (this._itemView.getProductId() !== productId) {
      return;
    }

    if (this._itemModel.getData().isAddedToCart) {
      return;
    }

    this._itemModel.changeData(Object.assign(
        {},
        this._itemModel.getData(),
        {
          isAddedToCart: true
        }
    ), ActionType.SET_ADDED_TO_CART);
  }

  _processRemoveProductFromCart(productId) {
    if (this._itemView.getProductId() !== productId) {
      return;
    }

    if (!this._itemModel.getData().isAddedToCart) {
      return;
    }

    this._itemModel.changeData(Object.assign(
        {},
        this._itemModel.getData(),
        {
          isAddedToCart: false
        }
    ), ActionType.UNSET_ADDED_TO_CART);
  }

  _processSetAddedToCart() {
    this._itemView.setAddedToCart();
  }

  _processUnsetAddedToCart() {
    this._itemView.unsetAddedToCart();
  }

  _checkAddedToCart() {
    if (this._itemView.checkAddedToCart()) {
      this._itemModel.changeData(Object.assign(
          {},
          this._itemModel.getData(),
          {
            isAddedToCart: true
          }
      ));
    }
  }

  _forceResetProductItem() {
    this._itemView.forceReset();
  }

  _handlePictureClick(evt) {
    const sourceUrl = this._itemView.getPictureSourceUrl();

    if (!sourceUrl) {
      return;
    }

    evt.preventDefault();

    Fancybox.defaults.l10n = ru;
    Fancybox.show([
      {
        src: sourceUrl,
        type: `image`
      }
    ], Object.assign(
        {},
        FancyboxOptions
    ));
  }

  _handleCartDataChange(action, payload) {
    switch (action) {
      case CartDataActionType.ADD_PRODUCT:
        this._processAddProductToCart(payload.addedProductId);
        break;
      case CartDataActionType.REMOVE_PRODUCT:
        this._processRemoveProductFromCart(payload.removedProductId);
        break;
    }
  }
}
