import Notification from "./../view/notification.js";

import {
  OverlayTemplate,
  SubmitClass
} from "./../const.js";
import {SubmitMessage} from "./../lexicon.js";

const createSubmittingTemplate = () => {
  return (
    `<div class="form-submit">
      <div class="form-submit-wrapper">
        <div class="form-submit__process-loader">
          <div class="form-submit__process-loader-circle "></div>
          <div class="form-submit__process-loader-circle form-submit__process-loader-circle_second"></div>
          <div class="form-submit__process-loader-circle form-submit__process-loader-circle_third"></div>
          <div class="form-submit__process-loader-circle form-submit__process-loader-circle_fourth"></div>
        </div>
        <p class="form-submit__text">${SubmitMessage.SUBMITING}</p>
      </div>
    </div>`
  );
};

const createSuccessTemplate = (postfix, submitSuccessText) => {
  const checkIconExtraClass = postfix ? SubmitClass.CHECK_ICON + `_` + postfix : SubmitClass.CHECK_ICON;
  const fixExtraClass = postfix ? SubmitClass.FIX + `_` + postfix : SubmitClass.FIX;

  return (
    `<div class="form-submit">
      <div class="form-submit-wrapper">
        <div class="form-submit__success-checkmark">
          <div class="form-submit__success-check-icon ${checkIconExtraClass}">
            <span class="form-submit__success-icon-line form-submit__success-icon-line_tip"></span>
            <span class="form-submit__success-icon-line form-submit__success-icon-line_long"></span>
            <div class="form-submit__success-icon-circle"></div>
            <div class="form-submit__success-icon-fix ${fixExtraClass}"></div>
          </div>
        </div>
        <p class="form-submit__text">${(submitSuccessText) ? submitSuccessText : SubmitMessage.SUCCESS}</p>
      </div>
    </div>`
  );
};

const createErrorTemplate = () => {
  return (
    `<div class="form-submit">
      <div class="form-submit-wrapper">
        <div class="form-submit__error-mark"></div>
        <p class="form-submit__text">${SubmitMessage.ERROR}</p>
      </div>
    </div>`
  );
};

export default class SubmitOverlay extends Notification {
  constructor(submitStyle, submitSuccessText) {
    super();

    this._template = OverlayTemplate.SUBMITTING;
    this._submitStyle = submitStyle;
    this._submitSuccessText = submitSuccessText;
  }

  switchTemplate(template) {
    this._template = template;
  }

  _getTemplate() {
    switch (this._template) {
      case OverlayTemplate.SUBMITTING:
        return createSubmittingTemplate();
      case OverlayTemplate.ERROR:
        return createErrorTemplate();
      default:
        return createSuccessTemplate(this._submitStyle, this._submitSuccessText);
    }
  }
}
