import TriggerView from "../view/trigger.js";
import ActionLinkView from "../../action-link/view/link.js";

import TriggerModel from "../model/trigger.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  removeElement,
  render,
  RenderPosition
} from "../../render/render.js";

import {
  defaultTriggerModelData,
  ActionType,
  RequestAction
} from "../const.js";
export default class ActionTrigger {
  constructor(
      element,
      productId,
      generatedId,
      animationComponent
  ) {
    this._element = element;
    this._productId = productId;
    this._generatedId = generatedId;
    this._animation = animationComponent;

    this._activeText = null;

    this._triggerView = null;
    this._linkView = null;
    this._model = null;

    this._linkTitle = null;
    this._linkHref = null;
    this._connectorUrl = null;

    this._handleTriggerClick = this._handleTriggerClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._triggerView = new TriggerView(this._element);
    this._triggerView.setClickHandler(this._handleTriggerClick);

    this._initModel();
  }

  destroy() {
    this._triggerView.removeClickHandler();
  }

  _initModel() {
    const requestArgs = {
      body: [
        `action=${RequestAction.INIT}`,
        `product_id=${this._productId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);
        this._processInitModel(data);
      },
    };
    sendXhr(requestArgs);
  }

  _renderLink() {
    this._linkView = new ActionLinkView(this._linkTitle, this._linkHref);
    render(this._triggerView.getElement(), this._linkView.getElement(), RenderPosition.AFTEREND);
  }

  _removeLink() {
    if (!this._linkView) {
      return;
    }

    const linkElement = this._linkView.getElement();

    this._linkView = null;

    this._animation.fadeOut(
        linkElement,
        null,
        () => {
          removeElement(linkElement);
        }
    );
  }

  _activate() {
    this._triggerView.setActive(this._activeText);
    this._renderLink();
  }

  _deactivate() {
    this._triggerView.setInactive();
    this._removeLink();
  }

  _processInitModel(data) {
    this._model = new TriggerModel(Object.assign(
        {},
        defaultTriggerModelData,
        data,
        {
          productId: this._productId
        }
    ));
    this._model.addObserver(this._handleChangeData);

    if (this._model.getData().isActive) {
      this._activate();
    } else {
      this._deactivate();
    }
  }

  _processTriggerClick(newStatus) {
    const action = (newStatus) ?
      ActionType.ADD :
      ActionType.REMOVE;

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isActive: newStatus
        }
    ), action);
  }

  _handleTriggerClick(evt) {
    evt.preventDefault();

    if (!this._model) {
      console.error(`The item's action model has not been initialized!`);
      return;
    }

    const newStatus = !this._model.getData().isActive;

    const action = (this._model.getData().isActive) ?
      RequestAction.REMOVE_FROM_LIST :
      RequestAction.ADD_TO_LIST;

    const requestArgs = {
      body: [
        `action=${action}`,
        `product_id=${this._productId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: () => {
        this._processTriggerClick(newStatus);
      }
    };
    sendXhr(requestArgs);
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.ADD:
        this._activate();
        break;
      case ActionType.REMOVE:
        this._deactivate();
        break;
    }
  }
}
