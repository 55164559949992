import SummaryView from "../view/summary.js";

import {
  ActionType
} from "../const.js";

export default class Summary {
  constructor(element) {
    this._element = element;

    this._view = null;

    this._handleCartDataChange = this._handleCartDataChange.bind(this);
  }

  init() {
    this._view = new SummaryView(this._element);

    if (window.CartData) {
      window.CartData.addObserver(this._handleCartDataChange);
    }
  }

  _processCartUpdate() {
    const {totalCost, totalPositions} = window.CartData.getData();

    this._view.setTotalPositionsValue(totalPositions);
    this._view.setTotalCostValue(totalCost);
  }

  _handleCartDataChange(action) {
    switch (action) {
      case ActionType.BLUR:
      case ActionType.SUBMIT:
      case ActionType.INVALID_CHECK:
      case ActionType.ADD_QUANTITY:
      case ActionType.REMOVE_QUANTITY:
      case ActionType.CHANGE:
      case ActionType.UPDATE_DATA:
        this._processCartUpdate();
        break;
    }
  }
}
