import ModalFeedbackFormSection from "../../modal-feedback-form/view/modal-feedback-form-section.js";

import {
  feedbackFormSubject
} from "../../../mixins/feedback-form-subject.js";

import {
  REQUEST_SUBJECT
} from "../const.js";

import {
  CLOSE_BUTTON_TEXT
} from "../../modal/lexicon.js";

import {
  formatNumber
} from "../../../utils/format-number.js";

const createModalSectionTemplate = ({
  title,
  src,
  price,
  currencyMeasure,
  section
}) => {
  const requestSubject = (section) ? `${REQUEST_SUBJECT} (${title}, секция «${section}»)` : `${REQUEST_SUBJECT} (${title})`;

  return (
    `<section class="modal-feedback-form-section">
      <button class="modal-feedback-form-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
      <div class="modal-feedback-form-container">
        <form class="modal-feedback-form" method="post">
          ${feedbackFormSubject.getTemplate(requestSubject)}
          <div class="modal-feedback-product">
            <picture class="modal-feedback-product__picture">
              <img
                class="modal-feedback-product__image lazyload"

                alt=""
                width="80"

                src="${src}"
                srcset="/assets/templates/main/images/lazy-load-placeholder.gif"

                data-src="${src}"
                data-srcset="${src}"

                data-aspectratio="80/80"
              >
            </picture>
            <div class="modal-feedback-product-text">
              <p class="modal-feedback-product__title">${title}</p>
              <p class="product-item__price product-item__price_modal-feedback">
                ${formatNumber(price, 2, `,`, `\u00A0`)}
                <span class="product-item__currency">${currencyMeasure}</span>
              </p>
            </div>
          </div>
          <div class="form-item-container">
            <label class="form-label" for="modal-phone">Введите ваш телефон</label>
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_consult">
              <input
              id="modal-phone"
              class="form-input"
              name="phone"
              type="tel"
              placeholder="+7 ("
              value>
            </div>
            <p class="form-item-descriptor">По этому номеру мы свяжемся с вами, чтобы уточнить детали.</p>
          </div>
          <div class="form-item-container">
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_consult">
              <button class="button button_width_full" type="submit">Отправить</button>
              <p class="form-item-descriptor">Мы свяжемся с вами в ближайшее время.</p>
            </div>
          </div>
          <div class="form-item-container form-item-container_last">
            <div class="form-item-wrapper  form-item-wrapper_no-margins form-item-wrapper_consult">
              <label class="form-label form-label_checkbox">
                <input class="form-checkbox visually-hidden" type="checkbox" name="policy" checked>
                <span class="form-checkbox-icon"></span>
                <span class="form-checkbox-text form-checkbox-text_policy">Согласен с <a class="form-link" href="/policy/">политикой конфиденциальности</a></span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </section>`
  );
};

export default class ModalSection extends ModalFeedbackFormSection {
  constructor(productData) {
    super();

    this._createModalSectionTemplate = createModalSectionTemplate.bind(this, productData);
  }
}
