import SubmitFormModel from "./../model/submit-form.js";
import SubmitFormView from "./../view/submit-form.js";
import FormElementView from "./../view/form-element.js";
import NotificationView from "./../view/notification.js";
import SubmitOverlay from "./../view/submit-overlay.js";

import Animation from "./../../animation/animation.js";
import ScrollToElement from "./../../scroll-to-element/scroll-to-element.js";

import {
  render,
  remove,
  removeElement,
  RenderPosition
} from "./../../render/render.js";

import {
  RuleName,
  ValidateRule,
  ValidateConverter,
  TagName,
  Type,
  ValidationStatus,
  RequestMethod,
  OverlayTemplate,
  Timeout,
  REQUEST_URI,
  ResponseStatus
} from "./../const.js";

import {
  Notification
} from "./../lexicon.js";

import {
  AnimationClass
} from "../../animation/const.js";

import {
  currentServerType,
  ServerType
} from "../../../const.js";

export default class SubmitForm {
  constructor({formElement, requestUrl, requestMethod, submitStyle, callbacks, action, submitSuccessText, afterSubmit}) {
    this._requestUrl = requestUrl || window.location.origin + REQUEST_URI;
    this._requestMethod = requestMethod || RequestMethod.POST;
    this._callbacks = callbacks || {};
    this._action = action || null;
    this._relativeScrollContainer = null;
    this._afterSubmit = afterSubmit || null;

    this._model = new SubmitFormModel();
    this._formView = new SubmitFormView(formElement);
    this._overlayView = new SubmitOverlay(submitStyle, submitSuccessText);

    this._animationComponent = new Animation();
    this._scrollComponent = new ScrollToElement();

    this._rules = [];
    this._elements = [];
    this._elementsViews = {};
    this._notificationsViews = {};

    this._handleChangeForm = this._handleChangeForm.bind(this);
    this._handleFocusOut = this._handleFocusOut.bind(this);
    this._handleInput = this._handleInput.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleChangeModel = this._handleChangeModel.bind(this);
  }

  init(rules) {
    this._rules = rules || [];

    this._setElements(this._formView.getElement());
    this._removeRequiredInfo();
    this._initModel();

    this._formView.setChangeFormHandler(this._handleChangeForm);
    this._formView.setFocusOutHandler(this._handleFocusOut);
    this._formView.setInputHandler(this._handleInput);
    this._formView.setSubmit(this._handleSubmit);

    this._model.addObserver(this._handleChangeModel);
  }

  clearNotifications() {
    Object.keys(this._notificationsViews).forEach((notificationName) => {
      this._makeElementDefault(notificationName);
    });
  }

  setRelativeScrollContainer(container) {
    this._relativeScrollContainer = container;
  }

  _initModel() {
    const elementsValidation = {};

    this._elements.forEach((element) => {
      elementsValidation[element.name] = null;
    });
    this._model.setValidationData(
        Object.assign(
            {},
            elementsValidation
        )
    );
  }

  _setElements(form) {
    this._rules.forEach((item) => {
      const element = form.querySelector(`[name="${item.name}"]`);

      this._elements.push(element);
      this._elementsViews[item.name] = new FormElementView(element);
    });
  }

  _removeRequiredInfo() {
    const infoIds = this._formView.getRequiredInfoIds();

    infoIds.forEach((id) => {
      const foundRule = this._rules.find((rule) => {
        const regExp = new RegExp(`${rule.name}`, `i`);
        return regExp.test(id);
      });

      if (!foundRule) {
        const infoElement = this._formView.getRequiredInfoElement(id);

        if (infoElement) {
          removeElement(infoElement);
        }
      }
    });
  }

  _validate(element) {
    if (this._elements.indexOf(element) === -1) {
      return;
    }

    const value = element.value;
    const name = element.name;

    const rulesList = this._rules.find((item) => item.name === name).rules;

    const rulesToCheck = {};

    if (rulesList !== undefined && rulesList.length > 0) {
      rulesList.forEach((rule) => {
        rulesToCheck[rule] = ValidateRule[ValidateConverter[rule]];
      });
    }

    rulesToCheck.NOT_EMPTY = ValidateRule[ValidateConverter.NOT_EMPTY];

    const notifications = [];

    Object.entries(rulesToCheck).forEach(([ruleName, func]) => {
      let funcValue = value;

      if (ruleName === RuleName.IS_CHECKED) {
        funcValue = element.checked;
      }

      if (func(funcValue) === false) {
        notifications.push(Notification[RuleName[ruleName]]);
      }
    });

    if (!this._notificationsViews[name] && notifications.length > 0) {
      this._model.changeElementValidation(element, ValidationStatus.INVALID, notifications);
      return;
    }

    if (notifications.length === 0) {
      this._model.changeElementValidation(element, ValidationStatus.VALID);
    }
  }

  _renderNotification(name, notifications) {
    this._notificationsViews[name] = new NotificationView(notifications.join(` `));

    const element = this._elementsViews[name].getElement();
    const notificationElement = this._notificationsViews[name].getElement();
    render(this._elementsViews[name], notificationElement, RenderPosition.AFTEREND);

    notificationElement.style.transform = `translateY(${element.offsetTop + element.offsetHeight}px)`;
    this._animationComponent.appear(notificationElement);
  }

  _removeNotification(name) {
    if (this._notificationsViews[name]) {
      this._animationComponent.disappear(
          this._notificationsViews[name].getElement(),
          null,
          () => {
            if (this._notificationsViews[name]) {
              remove(this._notificationsViews[name]);
              this._notificationsViews[name] = null;
            }
          }
      );
    }
  }

  _submit() {
    const xhr = new XMLHttpRequest();

    const formData = new FormData(this._formView.getElement());

    if (this._action) {
      formData.append(`action`, this._action);
    }

    formData.append(`uri`, document.location.origin + document.location.pathname);
    formData.append(`resource_id`, document.body.dataset.resourceId);

    let isSubmit = false;
    const CHECK_TIMEOUT = Timeout.SUBMIT.CHECK;
    const REMOVE_TIMEOUT = Timeout.SUBMIT.REMOVE;

    xhr.addEventListener(`loadstart`, () => {
      if (this._callbacks.loadStart) {
        this._callbacks.loadStart();
      }

      this._formView.hide();

      render(this._formView, this._overlayView.getElement(), RenderPosition.BEFOREBEGIN);
      this._animationComponent.fadeIn(
          this._overlayView.getElement(),
          null,
          () => {
            isSubmit = true;
          },
          AnimationClass.SHOW_FLEX);
    });

    xhr.addEventListener(`load`, () => {
      if (xhr.readyState === 4) {
        let overlayTemplate;

        switch (xhr.status) {
          case ResponseStatus.SUCCESS:
            const response = JSON.parse(xhr.response);

            if (response.success === false) {
              if (this._callbacks.loadError) {
                this._callbacks.loadError();
              }

              overlayTemplate = OverlayTemplate.ERROR;
              break;
            }

            if (this._callbacks.loadSuccess) {
              this._callbacks.loadSuccess(response, formData);
            }

            overlayTemplate = OverlayTemplate.SUCCESS;
            break;
          default:
            if (this._callbacks.loadError) {
              this._callbacks.loadError();
            }

            overlayTemplate = OverlayTemplate.ERROR;
            break;
        }

        if (currentServerType === ServerType.LAYOUT) {
          if (this._callbacks.loadSuccess) {
            this._callbacks.loadSuccess();
          }

          overlayTemplate = OverlayTemplate.SUCCESS;
        }

        if (this._callbacks.loadEnd) {
          this._callbacks.loadEnd();
        }

        const submitFormInterval = setInterval(() => {
          if (isSubmit === true) {
            clearInterval(submitFormInterval);
            this._animationComponent.fadeOut(
                this._overlayView.getElement(),
                null,
                () => {
                  remove(this._overlayView);
                  this._overlayView.removeElement();
                  this._overlayView.switchTemplate(overlayTemplate);
                  render(this._formView, this._overlayView.getElement(), RenderPosition.BEFOREBEGIN);

                  setTimeout(this._refresh(), REMOVE_TIMEOUT);
                }
            );
          }
        }, CHECK_TIMEOUT);

        if (this._afterSubmit) {
          this._afterSubmit();
        }
      }
    });

    xhr.open(this._requestMethod, this._requestUrl, true);
    xhr.setRequestHeader(`X-Requested-With`, `XMLHttpRequest`);
    xhr.send(formData);
  }

  _makeElementDefault(name) {
    if (!this._elementsViews[name]) {
      return;
    }

    this._elementsViews[name].makeDefault();
    this._removeNotification(name);
  }

  _refresh() {
    return () => {
      this._animationComponent.fadeOut(
          this._overlayView.getElement(),
          null,
          () => {
            remove(this._overlayView);
            this._formView.show();
            this._overlayView.switchTemplate(OverlayTemplate.SUBMITTING);
            this._elements.forEach((element) => {

              if (element.type === Type.CHECKBOX) {
                return;
              }

              this._model.changeElementValidation(element, ValidationStatus.NULL);
              element.value = ``;
            });
          }
      );
    };
  }

  _handleChangeModel(event, [element, status, notifications]) {
    switch (status) {
      case ValidationStatus.NULL:
        this._makeElementDefault(element.name);
        break;
      case ValidationStatus.INVALID:
        this._elementsViews[element.name].makeInvalid();
        this._renderNotification(element.name, notifications);
        break;
      case ValidationStatus.VALID:

        switch (element.tagName) {
          case TagName.SELECT:
            this._elementsViews[element.name].makeNotEmpty();
            break;
          case TagName.INPUT:
            this._elementsViews[element.name].makeValid();
            break;
        }

        break;
    }
  }

  _handleChangeForm(evt) {
    const element = evt.target;
    this._validate(element);
  }

  _handleFocusOut(evt) {
    const element = evt.target;
    this._validate(element);
  }

  _handleInput(evt) {
    const element = evt.target;

    if (this._elements.indexOf(element) === -1) {
      return;
    }

    this._model.changeElementValidation(element, ValidationStatus.NULL);
  }

  _handleSubmit() {
    this._elements.forEach((element) => this._validate(element));

    const validationData = this._model.getValidationData();
    const firstInvalidData = Object
      .entries(validationData)
      .find((item) => item[1] === false);

    if (firstInvalidData !== undefined) {
      const firstInvalidElement = this._elementsViews[firstInvalidData[0]].getElement();

      const scrollOptions = {
        element: firstInvalidElement,
        duration: null,
        timing: null,
        afterEnd: () => {
          firstInvalidElement.focus();
        }
      };


      if (this._relativeScrollContainer) {
        scrollOptions.relativeContainer = this._relativeScrollContainer;
      }

      this._scrollComponent.scroll(scrollOptions);
      return;
    }

    this._submit();
  }
}
