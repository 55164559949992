
export const managersItems = [
  {
    imageSrc: `/assets/templates/main/images/consult/consult-manager-category@2x.jpg`,
    width: 270,
    aspectRatio: `540/540`,
    name: `Алексей Андреев`,
    position: `Коммерческий директор «АРТЭКО»`
  },
  {
    imageSrc: `/assets/templates/main/images/consult/consult-manager-category-birukova@2x.jpg`,
    width: 270,
    aspectRatio: `540/540`,
    name: `Ярослава Бирюкова`,
    position: `Специалист отдела продаж`
  },
  {
    imageSrc: `/assets/templates/main/images/consult/consult-manager-category-kazanskiy@2x.jpg`,
    width: 270,
    aspectRatio: `540/540`,
    name: `Павел Казанский`,
    position: `Специалист отдела продаж`
  },
  {
    imageSrc: `/assets/templates/main/images/consult/consult-manager-category-kononenko@2x.jpg`,
    width: 270,
    aspectRatio: `540/540`,
    name: `Ольга Кононенко`,
    position: `Специалист отдела продаж`
  },
  {
    imageSrc: `/assets/templates/main/images/consult/consult-manager-category-koptsev@2x.jpg`,
    width: 270,
    aspectRatio: `540/540`,
    name: `Виталий Копцев`,
    position: `Специалист отдела продаж`
  },
  {
    imageSrc: `/assets/templates/main/images/consult/consult-manager-category-pervuhina@2x.jpg`,
    width: 270,
    aspectRatio: `540/540`,
    name: `Елена Первухина`,
    position: `Специалист отдела продаж`
  }
];
