export const AnimationClass = {
  SHOW_BLOCK: `animate_show_block`,
  SHOW_BLOCK_HALF_TRANSPARENT: `animate_show_block_half-transparent`,
  SHOW_FLEX: `animate_show_flex`,
  HIDE: `animate_hide`,
  HIDE_HALF_TRANSPARENT: `animate_hide_half-transparent`,
  APPEAR: `animate_appear`,
  DISAPPEAR: `animate_disappear`
};

export const DEFAULT_DURATION = 200;
export const DEFAULT_ANIMATION_TIME = 500;
