import Abstract from "../../abstract-view/abstract.js";

export default class CatalogButton extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._clickHandler = this._clickHandler.bind(this);
  }

  setOpened() {
    this._element.classList.add(`button_catalog-opened`);
  }

  setClosed() {
    this._element.classList.remove(`button_catalog-opened`);
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    this._element.addEventListener(`click`, this._clickHandler);
  }

  _clickHandler() {
    this._callback.click();
  }
}
