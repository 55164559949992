import TriggerPresenter from "../../mini-list/presenter/trigger.js";

import TriggerView from "../view/trigger.js";

import {
  ActionType as CartDataActionType
} from "../../cart-data/const.js";

import {
  RequestAction,
  CONNECTOR_URL
} from "../const.js";

import {
  ActionType
} from "../../mini-list/const.js";
export default class Trigger extends TriggerPresenter {
  constructor(element, listPresenter) {
    super(element, listPresenter);

    this._TriggerView = TriggerView;
    this._connectorUrl = CONNECTOR_URL;
    this._requestAction = RequestAction.GET_LIST_DATA;

    this._handleCartDataChange = this._handleCartDataChange.bind(this);
  }

  init() {
    super.init();

    if (window.CartData) {
      if (window.CartData.getData().isInit) {
        this.updateTrigger();
      }

      window.CartData.addObserver(this._handleCartDataChange);
    }
  }

  destroy() {
    super.destroy();
    window.CartData.removeObserver(this._handleCartDataChange);
  }

  updateTrigger() {
    super.updateTrigger();

    const cartData = window.CartData.getData();

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          itemsCount: cartData.totalPositions
        }
    ), ActionType.UPDATE_TRIGGER);
  }

  _processUpdateTrigger() {
    this.updateCounter();

    this._triggerView.update({
      totalPositions: window.CartData.getData().totalPositions,
      totalCost: window.CartData.getData().totalCost
    });
  }

  _processUpdateCartData() {
    const cartData = window.CartData.getData();

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          itemsCount: cartData.totalPositions
        }
    ), ActionType.UPDATE_TRIGGER);
  }

  _handleCartDataChange(action) {
    switch (action) {
      case CartDataActionType.REMOVE_QUANTITY:
      case CartDataActionType.ADD_QUANTITY:
      case CartDataActionType.GET_DATA:
        this._processUpdateCartData();
        break;
    }
  }
}
