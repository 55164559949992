import Abstract from "../../abstract-view/abstract.js";

export default class SubcategoriesItem extends Abstract {
  constructor(element, categoryId) {
    super();

    this._element = element;
    this._categoryId = categoryId;

    this._clonedListElement = null;
    this._listElement = null;
  }

  getListElement() {
    if (!this._listElement) {
      this._listElement = this._element.querySelector(`.header-subcategories__subcategory`);
    }

    return this._listElement;
  }

  getClonedListElement() {
    if (!this._clonedListElement) {
      const clonedListElement = this.getListElement().cloneNode(true);
      clonedListElement.style.display = `none`;

      this._clonedListElement = clonedListElement;
    }


    return this._clonedListElement;
  }

  resetView() {
    this._element.style.display = null;
  }
}
