import ListView from "../view/list.js";
import ItemView from "../view/item.js";
import ButtonView from "../view/button.js";

import ListModel from "../model/links-list.js";

import Animation from "../../animation/animation.js";

import {
  render
} from "../../render/render.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  defaultListItemsModelData,
  ActionType
} from "../const.js";

import {
  getElementSizesBeforeRender
} from "../../../utils/get-element-sizes-before-render.js";

export default class LinksList {
  constructor(listElement, visibleLimit) {
    this._listElement = listElement;

    if (visibleLimit) {
      this._visibleLimit = visibleLimit;
    }

    this._animation = new Animation();

    this._listView = null;
    this._itemsViews = [];
    this._buttonView = null;

    this._model = null;

    this._windowWidth = window.innerWidth;

    this._handleWindowLoad = this._handleWindowLoad.bind(this);
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._handleWindowResizeDebounced = debounce(this._handleWindowResize);
    this._handleButtonClick = this._handleButtonClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    window.addEventListener(`load`, this._handleWindowLoad);
  }

  getRelatedIds() {
    return this._listView.getRelatedIds();
  }

  _getVisibleLimit() {
    const listWidth = this._listView.getElement().offsetWidth;
    const items = (this._itemsViews.length > 0) ?
      this._itemsViews
        .slice()
        .map((view) => view.getElement()) :
      this._listView.getItems();

    let limitWidth = 0;
    let visibleLimit = 0;

    for (let i = 0; i < items.length; i++) {
      const styles = window.getComputedStyle(items[i]);
      const elementWidth = items[i].offsetWidth + parseFloat(styles.marginLeft) + parseFloat(styles.marginRight) + parseFloat(styles.borderLeftWidth) + parseFloat(styles.borderRightWidth);

      limitWidth += elementWidth;


      const buttonView = new ButtonView(items.length - visibleLimit);

      const buttonSizes = getElementSizesBeforeRender(buttonView.getElement());
      const buttonWidth = parseFloat(buttonSizes.width);

      if (limitWidth <= (listWidth - buttonWidth)) {
        visibleLimit++;
      } else {
        break;
      }
    }

    return (visibleLimit > 0) ? visibleLimit : 1;
  }

  _initButton() {
    this._buttonView = new ButtonView(this._listView.getItems().length - this._visibleLimit);

    render(this._listView.getElement(), this._buttonView.getElement());
    this._buttonView.setClickHandler(this._handleButtonClick);
  }

  _processExpand() {
    if (this._buttonView) {
      this._buttonView.setActive();
    }

    this._itemsViews.forEach((view, index) => {
      if (index > this._visibleLimit - 1) {
        this._animation.fadeIn(
            view.getElement(),
            null,
            () => {
              const modelData = this._model.getData();

              if (modelData.isResized) {
                this._model.changeData(Object.assign(
                    {},
                    modelData,
                    {
                      isResized: false,
                    }
                ), ActionType.RESIZE);
              }
            }
        );
      }
    });
  }

  _processShrink() {
    this._buttonView.reset();
    this._itemsViews.forEach((view, index) => {
      if (index > this._visibleLimit - 1) {
        this._animation.fadeOut(
            view.getElement()
        );
      }
    });
  }

  _processResize() {
    this._visibleLimit = this._getVisibleLimit();
    this._buttonView.setRemaingCount(this._itemsViews.length - this._visibleLimit);
    this._buttonView.reset();
    this._itemsViews.forEach((view, index) => {
      if (index > this._visibleLimit - 1) {
        this._animation.fadeOut(
            view.getElement()
        );
      }
    });
  }

  _handleWindowLoad() {
    this._listView = new ListView(this._listElement);
    this._listView.setLoaded();

    if (this._listView.getVisible()) {
      this._visibleLimit = this._listView.getVisible();
    }

    if (!this._visibleLimit) {
      this._visibleLimit = this._getVisibleLimit();
    };

    const items = this._listView.getItems();

    Array.from(items)
      .forEach((item, index) => {
        const view = new ItemView(item);

        if (index > this._visibleLimit - 1) {
          view.hide();
        }

        this._itemsViews.push(view);
      });

    this._model = new ListModel(Object.assign(
        {},
        defaultListItemsModelData
    ));
    this._model.addObserver(this._handleChangeData);

    if (this._listView.getItems().length > this._visibleLimit) {
      this._initButton();

      const activeItem = this._listView.getActiveItem();

      if (activeItem) {
        this._handleButtonClick();
      }
    }

    this._windowWidth = window.innerWidth;
    window.addEventListener(`resize`, this._handleWindowResizeDebounced);
  }

  _handleWindowResize() {
    if (this._windowWidth === window.innerWidth) {
      return;
    }

    this._windowWidth = window.innerWidth;
    const modelData = this._model.getData();

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isExpanded: false,
          isResized: true
        }
    ), ActionType.EXPAND);
  }

  _handleButtonClick() {
    const modelData = this._model.getData();
    const action = (modelData.isExpanded) ?
      ActionType.SHRINK :
      ActionType.EXPAND;

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isExpanded: !modelData.isExpanded
        }
    ), action);
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.EXPAND:
        this._processExpand();
        break;
      case ActionType.SHRINK:
        this._processShrink();
        break;
      case ActionType.RESIZE:
        this._processResize();
        break;
    }
  }
}
