import ActionTriggerPresenter from "../../../action-item/presenter/action-trigger.js";

import {
  CONNECTOR_URL,
  LINK_HREF
} from "../const.js";

import {
  LINK_TITLE,
  ACTIVE_TEXT
} from "../lexicon.js";

import {
  LIST_ID_KEY
} from "../../list/const.js";

import {
  ActionType as ComparisonDataActionType
} from "../../../comparison/comparison-data/const.js";

import {
  ActionType
} from "../const.js";

import {
  RequestAction
} from "../../../action-item/const.js";

import {
  sendXhr
} from "../../../../utils/send-xhr/send-xhr.js";

export default class ComparisonTrigger extends ActionTriggerPresenter {
  constructor(element, productId, generatedId, animationComponent) {
    super(element, productId, generatedId, animationComponent);

    this._linkTitle = LINK_TITLE;
    this._linkHref = LINK_HREF;
    this._connectorUrl = CONNECTOR_URL;
    this._activeText = ACTIVE_TEXT;

    this._handleComparisonDataChange = this._handleComparisonDataChange.bind(this);
  }

  init() {
    super.init();

    window.ComparisonData.addObserver(this._handleComparisonDataChange);
  }

  destroy() {
    super.destroy();
    window.ComparisonData.removeObserver(this._handleComparisonDataChange);
  }

  _activate() {
    this._triggerView.setActive(this._activeText);

    if (this._model.getData().isComparable) {
      this._renderLink();
    }
  }

  _renderLink() {
    if (this._model.getData().listId) {
      this._linkHref = `${LINK_HREF}?${LIST_ID_KEY}=${this._model.getData().listId}`;
    }

    super._renderLink();
  }

  _updateTriggerModel(data) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        data
    ), ActionType.UPDATE_DATA);
  }

  _processTriggerClick(newStatus, isComparable, listId) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isComparable,
          listId: (listId) ? +listId : null
        }
    ));

    super._processTriggerClick(newStatus);

    const args = {
      body: [
        `action=${RequestAction.GET_DATA}`,
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);
        const comparisonDataAction = (newStatus) ?
          ActionType.ADD_PRODUCT :
          ActionType.REMOVE_PRODUCT;

        if (window.ComparisonData) {
          window.ComparisonData.changeData(Object.assign(
              {},
              window.ComparisonData.getData(),
              data
          ), comparisonDataAction,
          {
            productId: this._productId,
            generatedId: this._generatedId
          });
        }
      }
    };

    sendXhr(args);


    if (window.ComparisonData) {
      window.ComparisonData.changeData(Object.assign(
          {},
          window.ComparisonData.getData()
      ), ComparisonDataActionType.UPDATE_DATA);
    }
  }

  _processRemoveFromListComparisonData(removedProductId) {
    const modelData = this._model.getData();

    if (!modelData.isActive || removedProductId !== this._productId) {
      return;
    }

    const requestArgs = {
      body: [
        `action=${RequestAction.REMOVE_FROM_LIST}`,
        `product_id=${this._productId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);

        this._processTriggerClick(false, data.isComparable, data.listId);
      }
    };
    sendXhr(requestArgs);
  }

  _processUpdateComparisonData() {
    const requestArgs = {
      body: [
        `action=${RequestAction.UPDATE_DATA}`,
        `product_id=${this._productId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);
        this._updateTriggerModel(data);
      },
    };
    sendXhr(requestArgs);
  }

  _processUpdateTriggerModel() {
    if (this._linkView) {
      this._removeLink();
    }

    if (this._model.getData().isComparable) {
      this._renderLink();
    }
  }

  _handleTriggerClick(evt) {
    evt.preventDefault();

    if (!this._model) {
      console.error(`The item's action model has not been initialized!`);
      return;
    }

    const newStatus = !this._model.getData().isActive;

    const action = (this._model.getData().isActive) ?
      RequestAction.REMOVE_FROM_LIST :
      RequestAction.ADD_TO_LIST;

    const requestArgs = {
      body: [
        `action=${action}`,
        `product_id=${this._productId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);

        this._processTriggerClick(newStatus, data.isComparable, data.listId);
      }
    };
    sendXhr(requestArgs);
  }

  _handleComparisonDataChange(action, payload) {
    switch (action) {
      case ComparisonDataActionType.REMOVE_FROM_LIST:
        this._processRemoveFromListComparisonData(payload.removedProductId);
        break;
      case ComparisonDataActionType.UPDATE_DATA:
        this._processUpdateComparisonData();
        break;
    }
  }

  _handleChangeData(action) {
    super._handleChangeData(action);

    switch (action) {
      case ActionType.UPDATE_DATA:
        this._processUpdateTriggerModel();
        break;
    }
  }
}
