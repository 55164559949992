export const RequestState = {
  READY: 4
};

export const RequestStatusCode = {
  SUCCESS: 200
};

export const RequestMethod = {
  POST: `POST`
};
