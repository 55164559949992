import ContainerView from "../view/container.js";
import FeedbackFormView from "../view/feedback-form.js";

import SubmitFormPresenter from "../../submit-form/presenter/submit-form.js";

import {
  render
} from "../../render/render.js";

export default class FeedbackForm {
  constructor({
    container,
    elementRules,
    submitStyle,
    callbacks
  }) {
    this._container = container;
    this._elementRules = elementRules;
    this._submitStyle = submitStyle || null;
    this._callbacks = callbacks || null;

    this._containerView = new ContainerView(this._container);
    this._formView = new FeedbackFormView();

    this._submitFormPresenter = null;
  }

  init() {
    render(this._containerView.getElement(), this._formView.getElement());
    this._initFormSubmit();
  }

  _initFormSubmit() {
    const submitOptions = {
      formElement: this._formView.getFormElement()
    };

    if (this._submitStyle) {
      submitOptions.submitStyle = this._submitStyle;
    }

    if (this._callbacks) {
      submitOptions.callbacks = this._callbacks;
    }

    this._submitFormPresenter = new SubmitFormPresenter(submitOptions);
    this._submitFormPresenter.init(this._elementRules);
    this._submitFormPresenter.setRelativeScrollContainer(this._formView.getElement());
  }
}
