import Abstract from "../../abstract-view/abstract.js";

import {
  formatNumber
} from "../../../utils/format-number.js";

import {
  PRICE_CURRENCY
} from "../lexicon.js";

export default class Prices extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._totalCostElement = null;
    this._oldTotalCostElement = null;
  }

  getTotalCostElement() {
    if (!this._totalCostElement) {
      this._totalCostElement = this._element.querySelector(`.product-item__price_add-to-cart`);
    }

    return this._totalCostElement;
  }

  setTotalCostElement(value) {
    if (this.getTotalCostElement()) {
      this.getTotalCostElement().textContent = this._formatPrice(value);
    }
  }

  getOldTotalCostElement() {
    if (!this._oldTotalCostElement) {
      this._oldTotalCostElement = this._element.querySelector(`.product-total__old_add-to-cart`);
    }

    return this._oldTotalCostElement;
  }

  setOldTotalCostElement(value) {
    if (!this.getOldTotalCostElement()) {
      return;
    }

    this.getOldTotalCostElement().textContent = this._formatPrice(value);
  }

  _formatPrice(value) {
    return `${formatNumber(value, 2, `,`, `\u00A0`)} ${PRICE_CURRENCY}`;
  }
}
