import Abstract from "../../abstract-view/abstract.js";

import {
  mobileMenuData
} from "../const.js";

const createMobileMenuTemplate = () => {
  const items = mobileMenuData
    .slice()
    .map((data) => createMobileMenuItemLayout(data))
    .join(`\n`);

  return (
    `<ul class="header-mobile-menu">
      ${items}
    </ul>`
  );
};

const createMobileMenuItemLayout = (data) => {
  return (
    `<li class="header-mobile-menu__item">
      <a class="header-mobile-menu__link" href="${data.link}">${data.title}</a>
    </li>`
  );
};

import {
  createElement
} from "../../render/render.js";

export default class MobileMenu extends Abstract {
  constructor() {
    super();

    this._element = null;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  _getTemplate() {
    return createMobileMenuTemplate();
  }
}
