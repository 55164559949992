export const defaultProductQuantityModelData = {
  quantity: 0,
  step: 0,
  shippingPrice: 0,
  abstractQuantity: 1,
  defaultStep: 0
};

export const ActionType = {
  INIT: `INIT`,
  ADD_QUANTITY: `ADD_QUANTITY`,
  REMOVE_QUANTITY: `REMOVE_QUANTITY`,
  INVALID_CHECK: `INVALID_CHECK`,
  SUBMIT: `SUBMIT`,
  BLUR: `BLUR`,
  INPUT_CHANGE: `INPUT_CHANGE`,
  UPDATE: `UPDATE`,
  CHANGE_UNIT: `CHANGE_UNIT`
};

export const CONNECTOR_URL = `/assets/components/ecommerce/connector.php`;

export const RequestAction = {
  CHANGE_ITEM_QUANTITY: `change_item_quantity`
};

export const DEFAULT_ABSTRACT_QUANTITY = 1;
