import SectionView from "../../mini-list/view/section.js";

import {
  formatNumber
} from "../../../utils/format-number.js";

import {
  SECTION_HEADING,
  SUMMARY_TEXT_LEXICON,
  BUTTON_TEXT,
  CURRENCY
} from "../lexicon.js";

const createTotalOldCostTemplate = (totalOldCost) => {
  return (totalOldCost) ?
    `<span class="mini-list-summary__product-total-old">${formatNumber(totalOldCost, 2, `,`, `\u00A0`)} ${CURRENCY}</span>` :
    ``;
};

const createMiniListSectionTemplate = ({
  cart,
  cartPageUri
}) => {
  const totalOldCostMarkup = createTotalOldCostTemplate(cart.totalOldCost);

  return (
    `<section class="mini-list-section">
      <span class="mini-list-section__decor"></span>
      <h2 class="visually-hidden">${SECTION_HEADING}</h2>
      <div class="mini-list-container">
        <div class="mini-list-wrapper">
          <ul class="mini-list">
          </ul>
        </div>
      </div>
      <div class="mini-list-summary">
        <span class="mini-list-summary__text">
          ${SUMMARY_TEXT_LEXICON}:
        </span>
        <p class="mini-list-summary__product-total">
          <span class="mini-list-summary__product-total-current">${formatNumber(cart.totalCost, 2, `,`, `\u00A0`)} ${CURRENCY}</span>
          ${totalOldCostMarkup}
        </p>
        <a class="button button_mini-list-summary" href="${cartPageUri}">${BUTTON_TEXT}</a>
      </div>
    </section>`
  );
};

export default class MiniCartSection extends SectionView {
  constructor(data) {
    super(data);
  }

  _getTemplate() {
    return createMiniListSectionTemplate(this._data);
  }
}
