import ModalFeedbackFormPresenter from "../../modal-feedback-form/presenter/modal-feedback-form.js";
import FileAttachPresenter from "../../file-attach/presenter/file-attach.js";
import UISPresenter from "../../uis/presenter/uis.js";

import ModalSectionView from "../view/section.js";

import {
  EXTRA_VALIDATION_RULES,
  REQUEST_SUBJECT,
  DEFAULT_YM_GOAL_ID
} from "../const.js";

import {
  YM_COUNTER_ID
} from "../../modal-feedback-form/const.js";

export default class ModalExtendedFeedbackForm extends ModalFeedbackFormPresenter {
  constructor(properties = {}) {
    super();

    if (properties.submitYmGoal) {
      this._submitYmGoal = properties.submitYmGoal;
    }

    this._defaultValidationRules = this._defaultValidationRules.concat(EXTRA_VALIDATION_RULES);
    this._modalSectionView = new ModalSectionView(REQUEST_SUBJECT);

    this._submitCallbacks = {
      loadSuccess: (response, formData) => {
        // Send the Yandex Metrika goal.

        const goalId = (this._submitYmGoal) ?
          this._submitYmGoal :
          DEFAULT_YM_GOAL_ID;

        if (window.ym) {
          window.ym(YM_COUNTER_ID, `reachGoal`, goalId);
        }

        const uisComponent = new UISPresenter();
        uisComponent.addRequest(formData);
      }
    };
  }

  _processInit() {
    super._processInit();

    const fileInputElement = this._modalSectionView.getFileInputElement();

    this._fileAttachPresenter = new FileAttachPresenter(fileInputElement);
    this._fileAttachPresenter.init();
  }
}
