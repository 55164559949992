// Modify these according to your controls
const Classes = {
  controls: `slider-navigation`,
  hidden: `slider-navigation_hidden`,
  item: `slider-navigation__item`,
  prev: `slider-navigation__item_prev`,
  next: `slider-navigation__item_next`,
};

export const NavigationDisabler = (Glide, Components) => {
  const getNavigationElement = () => {
    return (Components.Controls.items.length > 0) ?
      Components.Controls.items[0] :
      null;
  };

  const changeStatus = () => {
    const navigationElement = getNavigationElement();

    if (!navigationElement) {
      return;
    }

    if (Components.Sizes.length <= Glide.settings.perView) {
      navigationElement.classList.add(Classes.hidden);
    } else {
      navigationElement.classList.remove(Classes.hidden);
    }
  };

  return {
    mount() {
      // Only in effect when rewinding is disabled
      if (Glide.settings.rewind) {
        return;
      }

      const navigationElement = getNavigationElement();
      const navigationItems = navigationElement.querySelectorAll(`.${Classes.item}`);
      Array.from(navigationItems)
        .forEach((item) => {
          item.addEventListener(`click`, (evt) => {
            evt.stopPropagation();
          });
        });

      Glide.on([`mount.after`, `run`, `resize`], () => {
        changeStatus();

        // Filter out arrows_control
        for (let controlItem of Components.Controls.items) {
          if (!controlItem.classList.contains(Classes.controls)) {
            continue;
          }

          // Set left arrow state
          const left = controlItem.querySelector(`.${Classes.prev}`);

          if (left) {
            if (Glide.index === 0) {
              left.setAttribute(`disabled`, ``); // Disable on first slide
            } else {
              left.removeAttribute(`disabled`); // Enable on other slides
            }
          }

          // Set right arrow state
          const right = controlItem.querySelector(`.${Classes.next}`);

          if (right) {
            if (
              Glide.index === Components.Sizes.length - Glide.settings.perView ||
              Glide.settings.perView > Components.Sizes.length - Glide.index
            ) {
              right.setAttribute(`disabled`, ``); // Disable on last slide
            } else {
              right.removeAttribute(`disabled`); // Disable on other slides
            }
          }
        }
      });
    }
  };
};
