import ModalFeedbackFormSection from "../../modal-feedback-form/view/modal-feedback-form-section.js";

import {
  feedbackFormSubject
} from "../../../mixins/feedback-form-subject.js";

import {
  CLOSE_BUTTON_TEXT
} from "../../modal/lexicon.js";

const createModalSectionTemplate = (requestSubject) => {

  return (
    `<section class="modal-feedback-form-section">
      <button class="modal-feedback-form-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
      <div class="modal-feedback-form-container">
        <form class="modal-feedback-form" method="post">
          ${feedbackFormSubject.getTemplate(requestSubject)}
          <div class="form-item-container">
            <label class="form-label" for="modal-phone">Введите ваш телефон</label>
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_consult">
              <input
              id="modal-phone"
              class="form-input"
              name="phone"
              type="tel"
              placeholder="+7 ("
              value>
            </div>
            <p class="form-item-descriptor">По этому номеру мы свяжемся с вами, чтобы уточнить детали.</p>
          </div>
          <div class="form-item-container">
            <label class="form-label" for="modal-cheaper-link">Ссылка на товар с более низкой ценой</label>
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_consult">
              <input
              id="modal-cheaper-link"
              class="form-input"
              name="cheaper_link"
              type="text"
              placeholder="https://"
              value>
            </div>
          </div>
          <div class="form-item-container">
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_no-decoration">
              <label class="form-label" for="modal-cheaper-file">или  предложение другой компании</label>
              <label class="form-label form-label_file">
                <span class="form-label__text form-label__text_file">Прикрепите документ</span>
                <input
                  class="form-input visually-hidden"
                  name="file[]"
                  type="file"
                  id="modal-cheaper-file"
                  multiple>
              </label>
            </div>
            <p class="form-item-descriptor">Вес документа – не более 20 Мб.</p>
          </div>
          <div class="form-item-container">
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_consult">
              <button class="button button_width_full" type="submit">Отправить</button>
              <p class="form-item-descriptor">Мы свяжемся с вами в ближайшее время.</p>
            </div>
          </div>
          <div class="form-item-container form-item-container_last">
            <div class="form-item-wrapper  form-item-wrapper_no-margins form-item-wrapper_consult">
              <label class="form-label form-label_checkbox">
                <input class="form-checkbox visually-hidden" type="checkbox" name="policy" checked>
                <span class="form-checkbox-icon"></span>
                <span class="form-checkbox-text form-checkbox-text_policy">Согласен с <a class="form-link" href="/policy/">политикой конфиденциальности</a></span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </section>`
  );
};

export default class ModalSection extends ModalFeedbackFormSection {
  constructor(firstClassname) {
    super();

    this._createModalSectionTemplate = createModalSectionTemplate.bind(this, firstClassname);
  }

  getFileInputElement() {
    if (!this._fileInput) {
      this._fileInput = this._element.querySelector(`input[type="file"]`);
    }

    return this._fileInput;
  }
}
