import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

const createFeedbackFormTemplate = () => {};

export default class FeedbackForm extends Abstract {
  constructor() {
    super();

    this._formElement = null;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getFormElement() {
    if (!this._formElement) {
      this._formElement = this._element.querySelector(`form`);
    }

    return this._formElement;
  }

  _getTemplate() {
    return createFeedbackFormTemplate();
  }
}
