import ModalSectionView from "../../modal/view/section.js";

import {
  CLOSE_BUTTON_TEXT,
  MODAL_HEADING_TEXT
} from "../lexicon.js";

const createModalSectionTemplate = () => {
  return (
    `<section class="product-properties-modal-section">
      <button class="product-properties-modal-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
      <div class="product-properties-modal">
        <h3 class="heading heading_h3 product-properties-modal-heading">
          ${MODAL_HEADING_TEXT}: <a class="product-properties-modal-link"></a>
        </h3>
        <ul class="product-properties-list product-properties-list_product product-properties-list_columns_two">
        </ul>
      </div>
    </section>`
  );
};


export default class ModalProductPropertiesSection extends ModalSectionView {
  constructor() {
    super();

    this._closeButtonClass = `product-properties-modal-close-button`;
    this._createModalSectionTemplate = createModalSectionTemplate;

    this._slideElement = null;
    this._propertiesListElement = null;
    this._headingLinkElement = null;
  }

  getSlideElement() {
    if (!this._slideElement) {
      this._slideElement = this._element.closest(`.product-properties-modal-wrapper .fancybox__slide`);
    }

    return this._slideElement;
  }

  getProperitesListElement() {
    if (!this._propertiesListElement) {
      this._propertiesListElement = this._element.querySelector(`.product-properties-list`);
    }

    return this._propertiesListElement;
  }

  getLinkElement() {
    if (!this._linkElement) {
      this._headingLinkElement = this._element.querySelector(`.product-properties-modal-link`);
    }

    return this._headingLinkElement;
  }

  setHeadingLink(title, uri) {
    const element = this.getLinkElement();

    element.href = uri;
    element.textContent = title;
  }
}
