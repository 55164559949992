import DiscountView from "../view/discount.js";

import {
  render
} from "../../render/render.js";

import {
  ActionType
} from "../const.js";

export default class Discount {
  constructor(container, totalCost, oldTotalCost, itemId) {
    this._container = container;

    this._totalCost = totalCost;
    this._oldTotalCost = oldTotalCost;
    this._itemId = itemId;

    this._view = null;

    this._handleCartDataChange = this._handleCartDataChange.bind(this);
  }

  init() {
    if (window.CartData) {
      window.CartData.addObserver(this._handleCartDataChange);
    }

    if (this._oldTotalCost <= this._totalCost) {
      return;
    }

    const discount = this._calculateDiscount();
    this._view = new DiscountView(discount);

    render(this._container, this._view.getElement());
  }

  _calculateDiscount() {
    return Math.ceil((this._oldTotalCost - this._totalCost) / this._oldTotalCost * 100);
  }

  _processUpdateDiscount({
    currentItemPrice,
    currentItemOldPrice,
    currentItemQuantity
  }) {
    this._totalCost = currentItemQuantity * currentItemPrice;
    this._oldTotalCost = currentItemQuantity * currentItemOldPrice;

    this._view.setDiscount(this._calculateDiscount());
  }

  _handleCartDataChange(action, payload) {
    switch (action) {
      case ActionType.BLUR:
      case ActionType.SUBMIT:
      case ActionType.INVALID_CHECK:
      case ActionType.ADD_QUANTITY:
      case ActionType.REMOVE_QUANTITY:
      case ActionType.CHANGE:
        if (this._itemId !== payload.currentItemId) {
          return;
        }

        this._processUpdateDiscount({
          currentItemOldPrice: payload.currentItemOldPrice,
          currentItemPrice: payload.currentItemPrice,
          currentItemQuantity: payload.currentItemQuantity
        });
        break;
    }
  }
}
