require(`intersection-observer`);
IntersectionObserver.prototype.POLL_INTERVAL = 100;

import OfficeMapPresenter from "../components/office-map/presenter/office-map.js";

import {
  OrganizationCoord
} from "../const.js";

const container = document.querySelector(`.contacts-map-container`);
const officeMapComponent = new OfficeMapPresenter(container);

officeMapComponent.init(OrganizationCoord);
