export const getSizes = (element) => {
  const elementStyles = window.getComputedStyle(element);

  const sizes = {};

  [`height`, `width`, `marginTop`, `marginBottom`, `marginLeft`, `marginRight`, `left`].forEach((property) => {
    if (elementStyles[property] !== undefined) {
      sizes[property] = elementStyles[property];
    }
  });

  return sizes;
};
