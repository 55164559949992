export const defaultProductReviewsModelData = {
  sortName: null,
  sortDir: null,
  isLoading: false,
  isMoreButtonRendered: false
};

export const SortDir = {
  DESC: `desc`,
  ASC: `asc`
};

export const ActionType = {
  LOAD_MORE: `LOAD_MORE`,
  DESTROY_MORE_BUTTON: `DESTROY_MORE_BUTTON`,
  ACTIVATE_SORTING_BUTTON: `ACTIVATE_SORTING_BUTTON`,
  CHANGE_SORTING_DIRECTION: `CHANGE_SORTING_DIRECTION`,
  SORT: `SORT`,
  SORT_START: `SORT_START`,
  SORT_END: `SORT_END`
};

export const RequestAction = {
  LOAD_MORE: `load_more`,
  REQUEST_END: `request_end`,
  GET_ITEMS: `get_items`
};

export const CONNECTOR_URL = `/assets/components/reviews/frontend-connector.php`;

export const REQUEST_DELAY = 200;
