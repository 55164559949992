import FeedbackFormView from "../../feedback-form/view/feedback-form.js";

import {
  feedbackFormSubject
} from "../../../mixins/feedback-form-subject.js";

import {
  REQUEST_SUBJECT
} from "../const.js";

const createFeedbackFormTemplate = () => {
  return (
    `<div class="contacts-form-wrapper">
      <form class="request-form" method="post">
        ${feedbackFormSubject.getTemplate(REQUEST_SUBJECT)}
        <div class="contacts-form-text-wrapper">
          <p class="contacts-text">Подберем строительные материалы, проконсультируем по необходимому количеству и стоимости:</p>
          <label class="form-label" for="phone">Введите ваш телефон</label>
        </div>
        <div class="contacts-form-outer">
          <div class="form-item-wrapper form-item-wrapper_contacts form-item-wrapper_width_half">
            <div class="form-item-inner">
              <input
              id="phone"
              class="form-input"
              name="phone"
              type="tel"
              placeholder="+7 ("
              value>
            </div>
            <p class="form-item-descriptor">По этому номеру мы свяжемся с вами, чтобы уточнить детали.</p>
          </div>
          <div class="form-item-wrapper form-item-wrapper_contacts form-item-wrapper_width_half">
            <button class="button button_width_full" type="submit">Отправить</button>
            <p class="form-item-descriptor">Мы свяжемся с вами в ближайшее время.</p>
          </div>
          <div class="form-item-wrapper form-item-wrapper_policy">
            <label class="form-label form-label_checkbox">
              <input class="form-checkbox visually-hidden" type="checkbox" name="policy" checked>
              <span class="form-checkbox-icon"></span>
              <span class="form-checkbox-text form-checkbox-text_policy">Согласен с <a class="form-link" href="/policy/">политикой конфиденциальности</a></span>
            </label>
          </div>
        </div>
      </form>
    </div>`
  );
};

export default class ContactsForm extends FeedbackFormView {
  constructor() {
    super();
  }

  _getTemplate() {
    return createFeedbackFormTemplate();
  }
}
