import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

import {
  SECTION_HEADING
} from "../lexicon.js";

const createMiniListSectionTemplate = () => {
  return (
    `<section class="mini-list-section">
      <span class="mini-list-section__decor"></span>
      <h2 class="visually-hidden">${SECTION_HEADING}</h2>
      <div class="mini-list-container">
        <div class="mini-list-wrapper">
          <ul class="mini-list">
          </ul>
        </div>
      </div>
    </section>`
  );
};

export default class MiniListSection extends Abstract {
  constructor(data) {
    super();

    this._data = data;

    this._decorElement = null;
    this._listElement = null;

    this._elementMouseEnterHandler = this._elementMouseEnterHandler.bind(this);
    this._elementMouseLeaveHandler = this._elementMouseLeaveHandler.bind(this);
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getDecorElement() {
    if (!this._decorElement) {
      this._decorElement = this._element.querySelector(`.mini-list-section__decor`);
    }

    return this._decorElement;
  }

  getListElement() {
    if (!this._listElement) {
      this._listElement = this._element.querySelector(`ul`);
    }

    return this._listElement;
  }

  setDecorOffset(offset) {
    this.getDecorElement().style.right = `${offset}px`;
  }

  setElementMouseEnterHandler(callback) {
    this._callback.elementMouseEnter = callback;
    this.getElement().addEventListener(`mouseenter`, this._elementMouseEnterHandler);
  }

  setElementMouseLeaveHandler(callback) {
    this._callback.elementMouseLeave = callback;
    this.getElement().addEventListener(`mouseleave`, this._elementMouseLeaveHandler);
  }

  _getTemplate() {
    return createMiniListSectionTemplate();
  }

  _elementMouseEnterHandler() {
    this._callback.elementMouseEnter();
  }

  _elementMouseLeaveHandler() {
    this._callback.elementMouseLeave();
  }
}
