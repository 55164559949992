export const SECTION_HEADING = `Избранное`;
export const REMOVE_ITEM_LEXICON = `Удалить`;
export const BUTTON_TEXT = `Перейти в избранное`;

export const QUANTITY_UNIT_MEASURE = [
  `товар`,
  `товара`,
  `товаров`
];
export const ON_REQUEST = `По запросу`;
