import ModalProductPropertiesPresenter from "./modal-product-properties.js";

import TriggerView from "../view/trigger.js";

import ModalProductPropertiesTriggerModel from "../model/modal-product-properties-trigger.js";

import {
  ActionType,
  defaultModalProductPropertiesTriggerModelData
} from "../const.js";

export default class ModalProductPropertiesTrigger {
  constructor(triggerElement, productId) {
    this._triggerElement = triggerElement;
    this._productId = productId;

    this._modalProductPropertiesPresenter = null;

    this._triggerView = new TriggerView(this._triggerElement);
    this._model = new ModalProductPropertiesTriggerModel(Object.assign(
        {},
        defaultModalProductPropertiesTriggerModelData
    ));

    this._handleTriggerClick = this._handleTriggerClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._triggerView.setClickHandler(this._handleTriggerClick);
    this._model.addObserver(this._handleChangeData);
  }

  reset() {
    if (!this._modalProductPropertiesPresenter) {
      return;
    }

    this._modalProductPropertiesPresenter.reset();
  }

  _processShowModal() {
    this._modalProductPropertiesPresenter = new ModalProductPropertiesPresenter(this._productId);
    this._modalProductPropertiesPresenter.build();
  }

  _processClicking() {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isClicking: false,
          isModalShown: true
        }
    ), ActionType.SHOW_MODAL);
  }

  _handleTriggerClick() {
    if (this._model.getData().isClicking) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isClicking: true
        }
    ), ActionType.CLICKING);
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.CLICKING:
        this._processClicking();
        break;
      case ActionType.SHOW_MODAL:
        this._processShowModal();
        break;
    }
  }
}
