import ModalFeedbackTriggerPresenter from "../../modal-feedback-form/presenter/modal-feedback-trigger.js";
import CallbackFindCheaperFormModalPresenter from "./modal.js";

export default class ModalExtendedFeedbackForm extends ModalFeedbackTriggerPresenter {
  constructor(triggerElement) {
    super(triggerElement);
  }

  init() {
    super.init();
  }

  _processShowModal() {
    const properties = {};
    const submitYmGoal = this._triggerView.getSubmitYmGoal();

    if (submitYmGoal) {
      properties.submitYmGoal = submitYmGoal;
    }

    this._modalFeedbackFormPresenter = new CallbackFindCheaperFormModalPresenter(properties);
    this._modalFeedbackFormPresenter.build();
  }
}
