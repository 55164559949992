
import ProductItemBasePresenter from "../../products-list/presenter/product-item.js";

import ProductCardView from "../view/product-card.js";
export default class ProductCard extends ProductItemBasePresenter {
  constructor(element, id, animationComponent) {
    super(element, id, animationComponent);

    this._ItemView = ProductCardView;

    this._itemArgs = [
      this._animation
    ];
  }
}
