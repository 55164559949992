export const ControlsExtended = (Glide, Components, Events) => {
  Components.Controls.click = (event) => {
    Components.Run.make(Components.Direction.resolve(event.currentTarget.getAttribute(`data-glide-dir`)));
  };

  return {
    mount() {
      Events.emit(`ControlsEctended.mount`);
    }
  };
};
