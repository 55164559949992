import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

import {
  BUTTON_TEXT
} from "../lexicon.js";

const createToTopTemplate = () => {
  return (
    `<button class="to-top-button" style="display: none;">
      <span class="visually-hidden">${BUTTON_TEXT}</span>
    </button>`
  );
};

export default class ToTop extends Abstract {
  constructor() {
    super();

    this._clickHandler = this._clickHandler.bind(this);
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  setTransition() {
    this._element.style.transition = `0.2s ease`;
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    this._element.addEventListener(`click`, this._clickHandler);
  }

  _getTemplate() {
    return createToTopTemplate();
  }

  _clickHandler() {
    this._callback.click();
  }
}
