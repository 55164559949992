export const defaultCartData = {
  totalCost: null,
  totalQuantity: null,
  currentItemQuantity: null,
  currentItemPrice: null,
  currentItemOldPrice: null,
  totalPositions: null,
  isInit: false
};

export const ActionType = {
  GET_DATA: `GET_DATA`,
  UPDATE_DATA: `UPDATE_DATA`,
  ADD_QUANTITY: `ADD_QUANTITY`,
  REMOVE_QUANTITY: `REMOVE_QUANTITY`,
  ADD_PRODUCT: `ADD_PRODUCT`,
  REMOVE_PRODUCT: `REMOVE_PRODUCT`,
};

export const CONNECTOR_URL = `/assets/components/ecommerce/connector.php`;

export const RequestAction = {
  GET_CART_DATA: `get_cart_data`
};
