import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

const createCounterElementTemplate = () => {

  return (
    `<span class="mini-list-counter"></span>`
  );
};

export default class MiniListTrigger extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._counterElement = null;

    this._mouseEnterHandler = this._mouseEnterHandler.bind(this);
    this._mouseLeaveHandler = this._mouseLeaveHandler.bind(this);
  }

  getCounterElement() {
    if (!this._counterElement) {
      this._counterElement = createElement(this._getCounterElementTemplate());
    }

    return this._counterElement;
  }

  removeCounterElement() {
    this._counterElement = null;
  }

  setCount(value) {
    this.getCounterElement().textContent = value;
  }

  getElementWidth() {
    return this._element.clientWidth;
  }

  update() {}

  setMouseEnterHandler(callback) {
    this._callback.mouseEnter = callback;
    this._element.addEventListener(`mouseenter`, this._mouseEnterHandler);
  }

  setMouseLeaveHandler(callback) {
    this._callback.mouseLeave = callback;
    this._element.addEventListener(`mouseleave`, this._mouseLeaveHandler);
  }

  _mouseEnterHandler() {
    this._callback.mouseEnter();
  }

  _mouseLeaveHandler() {
    this._callback.mouseLeave();
  }

  _getCounterElementTemplate() {
    return createCounterElementTemplate();
  }
}
