import PricesView from "../view/prices.js";

import {
  ActionType
} from "../const.js";

export default class Prices {
  constructor(element, itemId) {
    this._element = element;
    this._itemId = itemId;

    this._view = null;

    this._handleCartDataChange = this._handleCartDataChange.bind(this);
  }

  init() {
    this._view = new PricesView(this._element);

    if (window.CartData) {
      window.CartData.addObserver(this._handleCartDataChange);
    }
  }

  _processUpdatePrices({
    currentItemPrice,
    currentItemOldPrice,
    currentItemQuantity
  }) {
    const totalCost = currentItemQuantity * currentItemPrice;
    this._view.setTotalCostElement(totalCost);

    if (currentItemOldPrice) {
      const oldTotalCost = currentItemQuantity * currentItemOldPrice;
      this._view.setOldTotalCostElement(oldTotalCost);
    }
  }

  _handleCartDataChange(action, payload) {
    switch (action) {
      case ActionType.BLUR:
      case ActionType.SUBMIT:
      case ActionType.INVALID_CHECK:
      case ActionType.ADD_QUANTITY:
      case ActionType.REMOVE_QUANTITY:
      case ActionType.CHANGE:
        if (this._itemId !== payload.currentItemId) {
          return;
        }

        this._processUpdatePrices({
          currentItemOldPrice: payload.currentItemOldPrice,
          currentItemPrice: payload.currentItemPrice,
          currentItemQuantity: payload.currentItemQuantity
        });
        break;
    }
  }
}
