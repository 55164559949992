import MiniListPresenter from "../../mini-list/presenter/mini-list.js";
import TriggerPresenter from "../../mini-cart/presenter/trigger.js";

import MiniCartSectionView from "../view/section.js";
import MiniCartItemView from "../view/item.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  CONNECTOR_URL,
  RequestAction
} from "../const.js";

import {
  ActionType
} from "../../mini-list/const.js";

import {
  ActionType as CartDataActionType
} from "../../cart-data/const.js";

export default class MiniCart extends MiniListPresenter {
  constructor(triggerElement) {
    super(triggerElement);

    this._TriggerPresenter = TriggerPresenter;
    this._connectorUrl = CONNECTOR_URL;
    this._requestAction = RequestAction.REMOVE_PRODUCT;

    this._SectionView = MiniCartSectionView;
    this._ItemView = MiniCartItemView;
  }

  _handleItemRemoveButton(elementToRemove, productId) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData()
    ), ActionType.REMOVE_ITEM, {
      elementToRemove,
      productId
    });
  }

  processRemoveItem(elementToRemove) {
    super.processRemoveItem(elementToRemove);

    const elementData = this._viewToRemove.getData();

    const requestArgs = {
      body: [
        `action=${RequestAction.REMOVE_PRODUCT}`,
        `session_element_id=${elementData.sessionElementId}`
      ],
      connectorUrl: CONNECTOR_URL,
      loadCallback: (response) => {
        const data = JSON.parse(response);
        this._processChangeCartData(data);
      },
    };
    sendXhr(requestArgs);
  }

  _processChangeCartData(data) {
    if (window.CartData) {
      window.CartData.changeData(Object.assign(
          {},
          window.CartData.getData(),
          data
      ), CartDataActionType.UPDATE_DATA);

      window.CartData.changeData(Object.assign(
          {},
          window.CartData.getData(),
          data
      ), CartDataActionType.REMOVE_PRODUCT, {
        removedProductId: data.removedProductId
      });
    }
  }
}
