import ModalPresenter from "../../modal/presenter/modal.js";
import ProductQuantityPresenter from "../../product-quantity/presenter/product-quantity.js";
import PricesPresenter from "./prices.js";
import DiscountPresenter from "./discount.js";
import SummaryPresenter from "./summary.js";

import ModalSectionView from "../view/modal-section.js";

import {
  defaultModalModelData,
  ActionType,
  MODAL_MAIN_CLASS,
  defaultSliderOptions
} from "../const.js";

export default class AddToCartModal extends ModalPresenter {
  constructor(data) {
    super();

    this._addedData = data;
    this._modalMainClass = MODAL_MAIN_CLASS;
    this._modalSectionView = new ModalSectionView(this._addedData);
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        defaultModalModelData
    ));

    this._productQuantityPresenter = null;
    this._pricesPresenter = null;
    this._discountPresenter = null;
    this._relatedProductsSlider = null;
    this._summaryPresenter = null;

    this._handleContinueShoppingButtonClick = this._handleContinueShoppingButtonClick.bind(this);
    this._handleCartDataChange = this._handleCartDataChange.bind(this);
  }

  _processInit(modalInstanceId) {
    if (window.ModalRelatedProducts && !window.ModalRelatedProducts.getData().isSliderInitialized) {
      window.ModalRelatedProducts.changeData(Object.assign(
          {},
          window.ModalRelatedProducts.getData(),
          {
            isSliderInitialized: true,
            modalInstanceId
          }
      ), ActionType.INIT_RELATED, {
        wrapperElement: this._modalSectionView.getRelatedProductsWrapperElement(),
        sliderOptions: defaultSliderOptions
      });
    }

    this._productQuantityPresenter = new ProductQuantityPresenter(
        this._modalSectionView.getQuantityElement(),
        this._modalSectionView.getSessionElementId(),
        this._modalSectionView.getItemId()
    );
    this._productQuantityPresenter.init();

    this._pricesPresenter = new PricesPresenter(
        this._modalSectionView.getPricesElement(),
        this._modalSectionView.getItemId()
    );
    this._pricesPresenter.init();

    if (this._modalSectionView.getOldTotalCostWrapperElement()) {
      this._discountPresenter = new DiscountPresenter(
          this._modalSectionView.getOldTotalCostWrapperElement(),
          this._modalSectionView.getAddedProductTotalCost(),
          this._modalSectionView.getAddedProductTotalOldCost(),
          this._modalSectionView.getItemId()
      );
      this._discountPresenter.init();
    }

    this._summaryPresenter = new SummaryPresenter(
        this._modalSectionView.getSummaryElement()
    );
    this._summaryPresenter.init();

    this._modalSectionView.setContinueShoppingButtonClickHandler(this._handleContinueShoppingButtonClick);

    if (window.CartData) {
      window.CartData.addObserver(this._handleCartDataChange);
    }
  }

  _processDestroy(modalInstanceId) {
    super._processDestroy();

    if (window.CartData) {
      window.CartData.removeObserver(this._handleCartDataChange);
    }

    if (window.ModalRelatedProducts && window.ModalRelatedProducts.getData().isSliderInitialized) {
      if (modalInstanceId !== window.ModalRelatedProducts.getData().modalInstanceId) {
        return;
      }

      window.ModalRelatedProducts.changeData(Object.assign(
          {},
          window.ModalRelatedProducts.getData(),
          {
            isSliderInitialized: false
          }
      ), ActionType.DESTROY_RELATED);
    }
  }

  _processUpdateQuantity({
    currentItemQuantity,
    currentItemId
  }) {
    if (this._modalSectionView.getItemId() !== currentItemId) {
      return;
    }

    this._productQuantityPresenter.changeQuantity(currentItemQuantity);
  }

  _handleContinueShoppingButtonClick() {
    this.closeModal(this._modalInstance.id);
  }

  _handleCartDataChange(action, payload) {
    switch (action) {
      case ActionType.BLUR:
      case ActionType.SUBMIT:
      case ActionType.INVALID_CHECK:
      case ActionType.ADD_QUANTITY:
      case ActionType.REMOVE_QUANTITY:
      case ActionType.CHANGE:
        this._processUpdateQuantity({
          currentItemQuantity: payload.currentItemQuantity,
          currentItemId: payload.currentItemId
        });
        break;
    }
  }
}
