import Abstract from "./../../abstract-view/abstract.js";

import {debounce} from "./../../../utils/debounce.js";

import {
  DEBOUNCE_TIME
} from "./../const.js";
import {
  createElement,
  removeElement
} from "../../render/render.js";

const createClearTriggerTemplate = () => {
  return (
    `<button class="clear-search-input-button clear-search-input-button_hidden" type="button">
      <span class="visually-hidden">Очистить поиск</span>
     </button>`
  );
};

export default class SearchInput extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._parent = this._element.parentElement;
    this._form = null;
    this._wrapper = null;
    this._menuElement = null;
    this._searchToogleButton = null;
    this._clearTrigger = null;
    this._defaultName = this._element.name;

    this._setInputClasses();
    this._setMenuElement();
    this._setFormElement();
    this._setSearchToogleButton();
    this._setWrapper();

    this._inputInputHandler = this._inputInputHandler.bind(this);
    this._inputClearHandler = this._inputClearHandler.bind(this);
    this._searchToogleButtonClickHandler = this._searchToogleButtonClickHandler.bind(this);
    this._clearTriggerClickHandler = this._clearTriggerClickHandler.bind(this);
    this._inputFocusHandler = this._inputFocusHandler.bind(this);
    this._inputBlurHandler = this._inputBlurHandler.bind(this);
    this._inputKeyDownHandler = this._inputKeyDownHandler.bind(this);
    this._clearTriggerBlurHandler = this._clearTriggerBlurHandler.bind(this);
    this._formSubmitHandler = this._formSubmitHandler.bind(this);
  }

  getAction() {
    return this._form.action;
  }

  setFocused() {
    this._wrapper.classList.add(`${this._wrapper.classList[0]}_active`);
    this._setRandomName();
  }

  removeFocused() {
    this._wrapper.classList.remove(`${this._wrapper.classList[0]}_active`);
    this._resetName();
  }

  getParent() {
    return this._parent;
  }

  getForm() {
    return this._form;
  }

  getClearTrigger() {
    if (!this._clearTrigger) {
      this._clearTrigger = createElement(createClearTriggerTemplate());
    }

    return this._clearTrigger;
  }

  removeClearTrigger() {
    removeElement(this._clearTrigger);
    this._clearTrigger = null;
  }

  clear() {
    this._element.value = ``;
  }

  prepareBeforeFadeIn() {
    this._form.classList.add(`${this._form.classList[0]}_visible`);
  }

  prepareAfterFadeIn() {
    this._form.style.opacity = 1;
  }

  prepareAfterFadeOut() {
    this._form.classList.remove(`${this._form.classList[0]}_visible`);
    this._form.style.opacity = null;
    this._form.style.display = null;
  }

  getWrapper() {
    return this._wrapper;
  }

  getMenuElement() {
    return this._menuElement;
  }

  getMenuElementHeight() {
    return this.getMenuElement().clientHeight;
  }

  getValue() {
    return this._element.value;
  }

  setInputHandler(callback) {
    this._callback.inputInput = callback;
    this._element.addEventListener(`input`, debounce(this._inputInputHandler, DEBOUNCE_TIME));
  }

  setInputClearHandler(callback) {
    this._callback.inputClear = callback;
    this._element.addEventListener(`input`, this._inputClearHandler);
  }

  setSearchToogleButtonClickHandler(callback) {
    this._callback.searchToogleButtonClick = callback;
    this._searchToogleButton.addEventListener(`click`, this._searchToogleButtonClickHandler);
  }

  setClearTriggerClickHandler(callback) {
    this._callback.clearTriggerClick = callback;
    this._clearTrigger.addEventListener(`click`, this._clearTriggerClickHandler);
  }

  setInputFocusHandler(callback) {
    this._callback.inputFocus = callback;
    this._element.addEventListener(`focus`, this._inputFocusHandler);
  }

  setInputBlurHandler(callback) {
    this._callback.inputBlur = callback;
    this._element.addEventListener(`blur`, this._inputBlurHandler);
  }

  setInputKeyDownHandler(callback) {
    this._callback.inputKeyDown = callback;
    this._element.addEventListener(`keydown`, this._inputKeyDownHandler);
  }

  removeInputKeyDownHandler() {
    delete this._callback.inputKeyDown;
    this._element.removeEventListener(`keydown`, this._inputKeyDownHandler);
  }

  setClearTriggerBlurHandler(callback) {
    this._callback.clearTriggerBlur = callback;
    this._clearTrigger.addEventListener(`blur`, this._clearTriggerBlurHandler);
  }

  setFormSubmitHandler(callback) {
    this._callback.formSubmit = callback;
    this._form.addEventListener(`submit`, this._formSubmitHandler);
  }

  _setRandomName() {
    this._element.name = `${this._defaultName}-${Math.random()}`;
  }

  _resetName() {
    this._element.name = this._defaultName;
  }

  _setFormElement() {
    if (!this._form) {
      this._form = this._element.closest(`form`);
    }
  }

  _setInputClasses() {
    this._element.classList.add(`search-block-wrapper__input`);
  }

  _setSearchToogleButton() {
    if (!this._searchToogleButton) {
      this._searchToogleButton = this._menuElement.querySelector(`.header-list__item_search-form`);
    }
  }

  _setWrapper() {
    if (!this._wrapper) {
      this._wrapper = this._element.closest(`.header-list__item_search-form`);
    }
  }

  _setMenuElement() {
    if (!this._menuElement) {
      this._menuElement = this._element.closest(`.header-list`);
    }
  }

  _inputInputHandler() {
    this._callback.inputInput();
  }

  _inputClearHandler() {
    this._callback.inputClear();
  }

  _searchToogleButtonClickHandler(evt) {
    evt.stopImmediatePropagation();

    if (evt.target !== this._searchToogleButton) {
      return;
    }
    this._callback.searchToogleButtonClick();
  }

  _clearTriggerClickHandler() {
    this._callback.clearTriggerClick();
  }

  _inputFocusHandler() {
    this._callback.inputFocus();
  }

  _inputBlurHandler(evt) {
    this._callback.inputBlur(evt);
  }

  _inputKeyDownHandler(evt) {
    this._callback.inputKeyDown(evt);
  }

  _clearTriggerBlurHandler() {
    this._callback.clearTriggerBlur();
  }

  _formSubmitHandler(evt) {
    evt.preventDefault();
    this._callback.formSubmit();
  }
}
