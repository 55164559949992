export const RequestFieldConverter = {
  "text": `Сообщение`,
  "name": `Имя`,
  "uri": `Страница, с которой отправили заявку`,
  "cheaper_link": `Ссылка на страницу с более низкой ценой`,
  "product-title": `Название товара / страницы`,
  "product-article": `Артикул товара`,
  "product-quantity": `Выбранное количество товара`,
  "request-subject": `Название формы`,
  "order_num": `Номер заказа`
};
