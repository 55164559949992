export const defaultToTopModelData = {
  isRendered: false,
  isScrolling: false
};

export const ActionType = {
  RENDER: `RENDER`,
  DESTROY: `DESTROY`,
  SCROLL: `SCROLL`
};
