export const fonts = [
  {
    family: `Proxima Nova`,
    weight: 400
  },
  {
    family: `Proxima Nova`,
    weight: 700
  }
];

export const OrganizationCoord = {
  latitude: 59.963647,
  longitude: 30.382110
};

export const FancyboxOptions = {
  groupAll: true,
  placeFocusBack: false,
  infinite: false,
  Thumbs: {
    autoStart: false
  },
  Toolbar: {
    display: [
      `counter`,
      `close`,
    ],
  }
};

export const YANDEX_MAPS_API_KEY = `7b6656aa-0654-4ae7-8d03-ad75b2f5fd4b`;
export const USER_COORDS_STORAGE_KEY = `userCoords`;

export const ServerType = {
  LAYOUT: `LAYOUT`,
  DEVELOPMENT: `DEVELOPMENT`,
  PRODUCTION: `PRODUCTION`
};

export const currentServerType = ServerType.DEVELOPMENT;

export const COMPANY_NAME = `«АРТЭКО»`;
