export const VisibleClass = (Glide, Components, Events) => {
  const Component = {
    mount() {
      this.setVisibleClasses();
    },

    setVisibleClasses() {
      if (Glide.settings.perView > 1) {
        const glideEl = Components.Html.root;

        const index = (Glide.index < 0) ? 0 :
          Glide.index;
        const active = Components.Html.slides[index];

        // Calculate how many on each side we need to add visible classes to
        let numBefore;
        let numAfter;

        if (Glide.settings.focusAt === `center`) {
          numBefore = Math.ceil((Glide.settings.perView - 1) / 2);
          numAfter = numBefore;
        } else {
          numBefore = Glide.settings.focusAt;
          numAfter = Glide.settings.perView - Glide.settings.focusAt - 1;
        }

        // Remove visible classes
        glideEl.querySelectorAll(`.glide__slide--visible`).forEach((slide) => {
          slide.classList.remove(`glide__slide--visible`);
        });

        // Add visible class to active slide

        if (!active) {
          return;
        }

        active.classList.add(`glide__slide--visible`);

        // Add visible classes to next siblings
        let next = active.nextElementSibling;

        if (next) {
          next.classList.add(`glide__slide--visible`);

          for (let i = 0; i < numAfter - 1; i++) {
            if (next) {
              next = next.nextElementSibling;
            }

            if (next) {
              next.classList.add(`glide__slide--visible`);
            }
          }
        }
      } else {
        Components.Html.root.querySelectorAll(`.glide__slide--visible`).forEach((slide) => {
          slide.classList.remove(`glide__slide--visible`);
        });
        Components.Html.slides[Glide.index].classList.add(`glide__slide--visible`);
      }
    }
  };

  Events.on([`run`, `resize`], () => {
    Component.setVisibleClasses();
  });

  return Component;
};
