import ItemView from "../view/item.js";

import {
  YM_COUNTER_ID
} from "../const.js";

export default class FeaturesList {
  constructor(items) {
    this._items = items;

    this._views = [];

    this._handleItemMouseEnter = this._handleItemMouseEnter.bind(this);
  }

  init() {
    Array.from(this._items).forEach((element, index) => {
      const view = new ItemView(element, index);
      view.setMouseEnterHandler(this._handleItemMouseEnter);

      this._views.push(view);
    });
  }

  _sendYmGoal(goalId) {
    ym(YM_COUNTER_ID,'reachGoal',goalId);
  }

  _handleItemMouseEnter(currentIndex) {
    const currentView = this._views.find((item) => item.getIndex() === currentIndex);

    if (currentView) {
      const ymGoalId = currentView.getYmGoalId();

      if (ymGoalId) {
        this._sendYmGoal(ymGoalId);
      }
    }
  }
}
