import Abstract from "../../abstract-view/abstract.js";

export default class ProductQuantity extends Abstract {
  constructor(element) {
    super();

    const empty = document.createElement(`form`)
    this._element = element ?? empty;

    this._formElement = null;
    this._quantityInput = null;
    this._removeButton = null;
    this._addButton = null;

    this._defaultInputWidth = null;

    this._removeButtonClickHandler = this._removeButtonClickHandler.bind(this);
    this._addButtonClickHandler = this._addButtonClickHandler.bind(this);
    this._formSubmitHandler = this._formSubmitHandler.bind(this);
    this._quantityInputInvalidHandler = this._quantityInputInvalidHandler.bind(this);
    this._quantityInputBlurHandler = this._quantityInputBlurHandler.bind(this);
    this._quantityInputChangeHandler = this._quantityInputChangeHandler.bind(this);

    this.updateQuantityInputWidth();
  }

  getFormElement() {
    if (!this._formElement) {
      this._formElement = this._element.closest(`form`);
    }

    return this._formElement;
  }

  getProductId() {
    return +this._element?.dataset.productId;
  }

  getQuantityInput() {
    if (!this._quantityInput) {
      const empty = document.createElement(`input`)
      this._quantityInput = this._element.querySelector(`.form-input_product-quantity`) ?? empty;
    }

    return this._quantityInput;
  }

  getRemoveButton() {
    if (!this._removeButton) {
      console.log(this._element);
      const empty = document.createElement(`button`)
      this._removeButton = this._element.querySelector(`.product-quantity__button_remove`) ?? empty;
    }

    return this._removeButton;
  }

  getAddButton() {
    if (!this._addButton) {
      const empty = document.createElement(`button`)
      this._addButton = this._element.querySelector(`.product-quantity__button_add`) ?? empty;
    }

    return this._addButton;
  }

  getQuantity() {
    return +this.getQuantityInput().value;
  }

  getStep() {
    return +this.getQuantityInput().step;
  }

  setStep(step) {
    this.getQuantityInput().step = step;
    this.getQuantityInput().min = step;
  }

  getShippingPrice() {
    return +this.getElement().dataset.shippingPrice;
  }

  setQuantity(value) {
    this.getQuantityInput().value = value;
    this.getQuantityInput().setAttribute(`value`, value);

    if (this._updateWidth) {
      this.updateQuantityInputWidth();
    }
  }

  updateQuantityInputWidth() {
    const inputElement = this.getQuantityInput();
    if (!inputElement) {
      return
    }

    if (!this._defaultInputWidth) {
      this._defaultInputWidth = inputElement.clientWidth;
    }

    // inputElement.style.width = 0;
    // const newWidth = Math.max((inputElement.value.length + 1) * 12, this._defaultInputWidth);
    // inputElement.style.width = newWidth + `px`;
  }

  setRemoveButtonClickHandler(callback) {
    this._callback.removeButtonClick = callback;
    this.getRemoveButton()?.addEventListener(`click`, this._removeButtonClickHandler);
  }

  setAddButtonClickHandler(callback) {
    this._callback.addButtonClick = callback;
    this.getAddButton()?.addEventListener(`click`, this._addButtonClickHandler);
  }

  setFormSubmitHandler(callback) {
    this._callback.formSubmit = callback;
    this.getFormElement()?.addEventListener(`submit`, this._formSubmitHandler);
  }

  setQuantityInputInvalidHandler(callback) {
    this._callback.quantityInputInvalid = callback;
    this.getQuantityInput()?.addEventListener(`invalid`, this._quantityInputInvalidHandler);
  }

  setQuantityInputBlurHandler(callback) {
    this._callback.quantityInputBlur = callback;
    this.getQuantityInput()?.addEventListener(`blur`, this._quantityInputBlurHandler);
  }

  setQuantityInputChangeHandler(callback) {
    this._callback.quantityInputChange = callback;
    this.getQuantityInput()?.addEventListener(`change`, this._quantityInputChangeHandler);
  }

  _removeButtonClickHandler() {
    this._callback.removeButtonClick();
  }

  _addButtonClickHandler() {
    this._callback.addButtonClick();
  }

  _formSubmitHandler(evt) {
    this._callback.formSubmit(evt);
  }

  _quantityInputInvalidHandler(evt) {
    this._callback.quantityInputInvalid(evt);
  }

  _quantityInputBlurHandler() {
    this._callback.quantityInputBlur();
  }

  _quantityInputChangeHandler(evt) {
    evt.stopImmediatePropagation();
    this._callback.quantityInputChange();
  }
}
