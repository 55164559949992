import Observer from "../../observer/observer.js";
import {
  modelData
} from "../../../mixins/model-data.js";

export default class FaqItem extends Observer {
  constructor(data) {
    super();

    this._data = data;
  }
}

Object.assign(FaqItem.prototype, modelData);
