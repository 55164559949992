import CatalogItemPresenter from "./catalog-item.js";

import CatalogListView from "../view/catalog-list.js";

import Animation from "../../animation/animation.js";
import ScrollToElement from "../../scroll-to-element/scroll-to-element.js";

import {
  ActionType,
  KeyboardKey
} from "../const.js";

export default class CatalogList {
  constructor(element) {
    this._element = element;
    this._animation = new Animation();
    this._scrollToElement = new ScrollToElement();

    this._catalogListView = null;
    this._catalogItemPresenters = [];

    this._resetExceptActive = this._resetExceptActive.bind(this);

    this._handleEscKeyDown = this._handleEscKeyDown.bind(this);
  }

  init() {
    this._catalogListView = new CatalogListView(this._element);

    const items = this._catalogListView.getItems();

    Array.from(items)
      .forEach((item, index) => {
        const presenter = new CatalogItemPresenter(
            item,
            index,
            this._animation,
            this._scrollToElement
        );
        presenter.init();
        presenter.addObserver(this._resetExceptActive);

        this._catalogItemPresenters.push(presenter);
      });

    document.addEventListener(`keydown`, this._handleEscKeyDown);
  }

  _resetExceptActive(action, payload) {
    switch (action) {
      case ActionType.SHOW_ITEMS:
        this._catalogItemPresenters.forEach((presenter, index) => {
          if (index === payload.index) {
            return;
          }

          presenter.reset();
        });
        break;
    }
  }

  _handleEscKeyDown(evt) {
    if (KeyboardKey.ESC.indexOf(evt.key) !== -1) {
      this._catalogItemPresenters.forEach((presenter) => {
        presenter.reset();
      });
    }
  }
}
