import Abstract from "../../abstract-view/abstract.js";

export default class Item extends Abstract {
  constructor(element, index)  {
    super();

    this._element = element;
    this._index = index;
    this._mouseEnterHandler = this._mouseEnterHandler.bind(this);
  }

  getIndex() {
    return this._index;
  }

  getYmGoalId() {
    return this.getElement().dataset.ymGoalId;
  }

  setMouseEnterHandler(callback) {
    this._callback.mouseEnter = callback;
    this._element.addEventListener('mouseenter', this._mouseEnterHandler);
  }

  _mouseEnterHandler() {
    this._callback.mouseEnter(this._index);
  }
}
