export const defaultSliderOptions = {
  perView: 4,
  gap: 20,
  type: `slider`,
  bound: true,
  animationDuration: 500,
  keyboard: false,
  rewind: false,
  breakpoints: {
    1180: {
      perView: 2
    },
    768: {
      perView: 2,
      gap: 10
    },
    360: {
      perView: 1,
      gap: 0
    }
  }
};
