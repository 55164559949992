import TriggerPresenter from "../../mini-list/presenter/trigger.js";

import {
  CONNECTOR_URL,
  RequestAction,
  ActionType as ComparisonDataActionType
} from "../../comparison/comparison-data/const.js";

import {
  ActionType
} from "../../mini-list/const.js";

export default class Trigger extends TriggerPresenter {
  constructor(element, listPresenter) {
    super(element, listPresenter);

    this._connectorUrl = CONNECTOR_URL;
    this._requestAction = RequestAction.GET_PRODUCTS;

    this._handleComparisonDataChange = this._handleComparisonDataChange.bind(this);
  }

  init() {
    super.init();

    if (window.ComparisonData) {
      if (window.ComparisonData.getData().isInit) {
        this.updateTrigger();
      }

      window.ComparisonData.addObserver(this._handleComparisonDataChange);
    }
  }

  destroy() {
    super.destroy();
    window.ComparisonData.removeObserver(this._handleComparisonDataChange);
  }

  updateTrigger() {
    super.updateTrigger();

    const comparisonData = window.ComparisonData.getData();

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          itemsCount: comparisonData.quantity
        }
    ), ActionType.UPDATE_TRIGGER);
  }

  _processUpdateTrigger() {
    this.updateCounter();
  }

  _processGetComparisonData() {
    if (!window.ComparisonData) {
      return;
    }

    const comparisonData = window.ComparisonData.getData();

    if (comparisonData.quantity === 0) {
      return;
    }

    this.updateTrigger();
  }

  _processUpdateComparisonData() {
    const comparisonData = window.ComparisonData.getData();

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          itemsCount: comparisonData.quantity
        }
    ), ActionType.UPDATE_TRIGGER);
  }

  _handleComparisonDataChange(action) {
    switch (action) {
      case ComparisonDataActionType.GET_DATA:
        this._processGetComparisonData();
        break;
      case ComparisonDataActionType.AFTER_CLEAR_LIST:
      case ComparisonDataActionType.REMOVE_FROM_LIST:
      case ComparisonDataActionType.ADD_PRODUCT:
      case ComparisonDataActionType.REMOVE_PRODUCT:
      case ComparisonDataActionType.UPDATE_DATA:
        this._processUpdateComparisonData();
        break;
    }
  }
}
