import ToTopView from "../view/to-top.js";
import ToTopModel from "../model/to-top.js";

import Animation from "../../animation/animation.js";
import ScrollToElement from "../../scroll-to-element/scroll-to-element.js";

import {
  render,
  removeElement
} from "../../render/render.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  isInViewport
} from "../../../utils/is-in-viewport.js";

import {
  defaultToTopModelData,
  ActionType
} from "../const.js";

export default class ToTop {
  constructor(thresholdElement) {
    this._thresholdElement = thresholdElement;

    this._animation = new Animation();
    this._scrollToElement = new ScrollToElement();

    this._view = null;
    this._model = null;

    this._handleWindowScroll = this._handleWindowScroll.bind(this);
    this._handleToTopButtonClick = this._handleToTopButtonClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._model = new ToTopModel(Object.assign(
        {},
        defaultToTopModelData
    ));
    this._model.addObserver(this._handleChangeData);

    window.addEventListener(`scroll`, debounce(this._handleWindowScroll), {
      passive: true
    });
  }

  _processRender() {
    this._view = new ToTopView();

    render(document.body, this._view.getElement());

    this._view.setClickHandler(this._handleToTopButtonClick);

    this._animation.fadeIn(
        this._view.getElement(),
        null,
        () => {
          this._view.setTransition();
        }
    );
  }

  _processDestroy() {
    const toTopElement = this._view.getElement();
    this._view = null;

    this._animation.fadeOut(
        toTopElement,
        null,
        () => {
          removeElement(toTopElement);
        }
    );

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isScrolling: false
        }
    ));
  }

  _processScroll() {
    this._scrollToElement.scroll({
      element: document.body,
      afterEnd: () => {
        this._processDestroy();
      }
    });

  }

  _handleToTopButtonClick() {
    const modelData = this._model.getData();

    if (modelData.isScrolling) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isScrolling: true,
          isRendered: false
        }
    ), ActionType.SCROLL);
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.RENDER:
        this._processRender();
        break;
      case ActionType.DESTROY:
        this._processDestroy();
        break;
      case ActionType.SCROLL:
        this._processScroll();
        break;
    }
  }

  _handleWindowScroll() {
    const inViewport = isInViewport(this._thresholdElement);
    const modelData = this._model.getData();

    if (inViewport && !modelData.isRendered) {
      return;
    }

    if (!inViewport && modelData.isRendered) {
      return;
    }

    const action = (inViewport) ?
      ActionType.DESTROY :
      ActionType.RENDER;
    const isRendered = (inViewport) ?
      false :
      true;

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isRendered
        }
    ), action);
  }
}
