import Abstract from "../../abstract-view/abstract.js";

export default class CategoryLink extends Abstract {
  constructor(element, categoryId) {
    super();

    this._element = element;
    this._categoryId = categoryId;

    this._buttonElement = null;
    this._parentElement = null;

    this._mouseEnterHandler = this._mouseEnterHandler.bind(this);
    this._buttonClickHandler = this._buttonClickHandler.bind(this);
  }

  getParentElement() {
    if (!this._parentElement) {
      this._parentElement = this._element.parentElement;
    }

    return this._parentElement;
  }

  getText() {
    return this._element.textContent.trim();
  }

  getHref() {
    return this._element.href;
  }

  setActive() {
    this._element.classList.add(`header-category__link_active`);
  }

  resetView() {
    this._element.classList.remove(`header-category__link_active`);
  }

  setMouseEnterHandler(callback) {
    this._callback.mouseEnter = callback;
    this._element.addEventListener(`mouseenter`, this._mouseEnterHandler);
  }

  setButtonClickHandler(callback) {
    this._callback.buttonClick = callback;

    this._getButton().addEventListener(`click`, this._buttonClickHandler);
  }

  _getButton() {
    if (!this._buttonElement) {
      this._buttonElement = this._element.querySelector(`.header-category__button`);
    }

    return this._buttonElement;
  }

  _mouseEnterHandler() {
    this._callback.mouseEnter(this._categoryId);
  }

  _buttonClickHandler(evt) {
    evt.preventDefault();
    this._callback.buttonClick(this._categoryId);
  }
}
