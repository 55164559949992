import {animate} from "./utils/animate.js";

import {getElementSizesBeforeRender} from "../../utils/get-element-sizes-before-render.js";
import {getSizes} from "../../utils/get-sizes.js";

import {TimingFunction} from "./timing-functions.js";

import {
  AnimationClass,
  DEFAULT_DURATION,
  DEFAULT_ANIMATION_TIME
} from "./const.js";

export default class Animation {
  constructor() {
    this._duration = DEFAULT_DURATION;
    this._animationTime = DEFAULT_ANIMATION_TIME;
  }

  fadeIn(element, duration, callback, showClass = AnimationClass.SHOW_BLOCK, ...args) {
    this._setDuration(duration);

    const onAnimationEnd = () => {
      element.style.opacity = null;
      element.classList.remove(AnimationClass.HIDE);
      element.classList.remove(showClass);

      element.removeEventListener(`animationend`, onAnimationEnd);

      if (callback) {
        callback.call(this, element, args);
      }

      element.style.animationDuration = null;
    };

    element.style.opacity = 0;

    switch (showClass) {
      case AnimationClass.SHOW_BLOCK:
      case AnimationClass.SHOW_BLOCK_HALF_TRANSPARENT:
        element.style.display = `block`;
        break;
      case AnimationClass.SHOW_FLEX:
        element.style.display = `flex`;
        break;
    }

    element.addEventListener(`animationend`, onAnimationEnd);
    element.style.animationDuration = `${this._duration / 1000}s`;
    element.classList.add(showClass);
  }

  fadeOut(element, duration, callback, hideClass = AnimationClass.HIDE, ...args) {
    this._setDuration(duration);

    const onAnimationEnd = () => {
      element.style.display = `none`;
      element.classList.remove(hideClass);
      element.style.animationDuration = null;
      element.removeEventListener(`animationend`, onAnimationEnd);

      if (callback) {
        callback.call(this, element, args);
      }
    };

    element.addEventListener(`animationend`, onAnimationEnd);
    element.style.animationDuration = `${this._duration / 1000}s`;
    element.classList.add(hideClass);
  }

  slideDown(element, duration, beforeBegin, afterEnd) {
    if (!duration) {
      duration = this._animationTime;
    }

    element.style = null;

    const sizes = getElementSizesBeforeRender(element, afterEnd);

    const drawFunction = (progress) => {
      Object.entries(sizes).forEach(([property, value]) => {
        element.style[property] = +value.replace(/\D/g, ``) * progress + value.replace(/\d/g, ``);
      });
      element.style.opacity = progress;
    };

    animate({
      timing: TimingFunction.LINEAR,
      draw: drawFunction,
      duration,
      beforeBegin: () => {
        Object.entries(sizes).forEach(([property]) => {
          element.style[property] = 0;
        });

        element.style.opacity = 0;
        element.style.display = `block`;

        if (beforeBegin) {
          beforeBegin.call(null, element);
        }
      },
      afterEnd: () => {
        element.style = null;

        if (afterEnd) {
          afterEnd.call(null, element);
        }
      }
    });
  }

  slideUp(element, duration, beforeBegin, afterEnd) {
    if (!duration) {
      duration = this._animationTime;
    }

    element.style.transition = `none`;

    const sizes = getSizes(element);

    const drawFunction = (progress) => {
      Object.entries(sizes).forEach(([property, value]) => {
        element.style[property] = +value.replace(/\D/g, ``) * (1 - progress) + value.replace(/\d/g, ``);
      });
      element.style.opacity = 1 - progress;
    };

    animate({
      timing: TimingFunction.LINEAR,
      draw: drawFunction,
      duration,
      beforeBegin: () => {
        if (beforeBegin) {
          beforeBegin.call(null, element);
        }
      },
      afterEnd: () => {
        if (afterEnd) {
          afterEnd.call(null, element);
        }
      }
    });
  }

  appear(element, duration, callback, ...args) {
    this._setDuration(duration);

    const onAnimationEnd = () => {
      element.classList.remove(AnimationClass.APPEAR);
      element.style.animationDuration = null;

      element.removeEventListener(`animationend`, onAnimationEnd);

      if (callback) {
        callback.call(this, element, args);
      }

    };

    element.addEventListener(`animationend`, onAnimationEnd);
    element.style.animationDuration = `${this._duration / 1000}s`;
    element.classList.add(AnimationClass.APPEAR);
  }

  disappear(element, duration, callback, ...args) {
    this._setDuration(duration);

    const onAnimationEnd = () => {

      element.style.opacity = 0;
      element.classList.remove(AnimationClass.DISAPPEAR);
      element.style.animationDuration = null;

      element.removeEventListener(`animationend`, onAnimationEnd);

      if (callback) {
        callback.call(this, element, args);
      }

    };

    element.addEventListener(`animationend`, onAnimationEnd);
    element.style.animationDuration = `${this._duration / 1000}s`;
    element.classList.add(AnimationClass.DISAPPEAR);
  }

  _setDuration(duration) {
    if (duration) {
      this._duration = duration;
    }
  }
}
