import ModalFeedbackFormPresenter from "./modal-feedback-form.js";

import TriggerView from "../view/trigger.js";

import ModalFeedbackTriggerModel from "../model/modal-feedback-trigger.js";

import {
  ActionType,
  defaultModalFeedbackTriggerModelData
} from "../const.js";

export default class ModalFeedbackTrigger {
  constructor(triggerElement) {

    if (triggerElement) {
      this._triggerElement = triggerElement;
      this._triggerView = new TriggerView(this._triggerElement);
    }

    this._modalFeedbackFormPresenter = null;

    this._model = new ModalFeedbackTriggerModel(Object.assign(
        {},
        defaultModalFeedbackTriggerModelData
    ));

    this._handleTriggerClick = this._handleTriggerClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    if (this._triggerView) {
      this._triggerView.setClickHandler(this._handleTriggerClick);
    }

    this._model.addObserver(this._handleChangeData);
  }

  reset() {
    if (!this._modalFeedbackFormPresenter) {
      return;
    }

    this._modalFeedbackFormPresenter.reset();
  }

  _processShowModal() {
    this._modalFeedbackFormPresenter = new ModalFeedbackFormPresenter();
    this._modalFeedbackFormPresenter.build();
  }

  _processClicking() {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isClicking: false,
          isModalShown: true
        }
    ), ActionType.SHOW_MODAL);
  }

  _handleTriggerClick() {
    if (this._model.getData().isClicking) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isClicking: true
        }
    ), ActionType.CLICKING);
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.CLICKING:
        this._processClicking();
        break;
      case ActionType.SHOW_MODAL:
        this._processShowModal();
        break;
    }
  }
}
