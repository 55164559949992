import ModalFeedbackFormPresenter from "../../modal-feedback-form/presenter/modal-feedback-form.js";
import UISPresenter from "../../uis/presenter/uis.js";

import ModalSectionView from "../view/section.js";

export default class ModalCallbackFeedbackForm extends ModalFeedbackFormPresenter {
  constructor(firstClassname) {
    super();

    this._modalSectionView = new ModalSectionView(firstClassname);
    this._submitCallbacks = {
      loadSuccess: (response, formData) => {
        // Send the Yandex Metrika goal.
        if (window.ym) {
          window.ym(29276100, `reachGoal`, `zvonokzakaz`);
        }

        const uisComponent = new UISPresenter();
        uisComponent.addRequest(formData);
      }
    };
  }

  reset() {
    const modalId = (this._modalInstance) ? this._modalInstance.id : null;
    this.closeModal(modalId);
  }
}
