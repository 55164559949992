import HeaderCatalogPresenter from "../../header-catalog/presenter/header-catalog.js";
import SearchSuggestionsPresenter from "../../search-suggestions/presenter/search-suggestions.js";
import ModalCallbackFeedbackFormPresenter from "../../modal-callback-feedback-form/presenter/modal-callback-feedback-form.js";
import ModalOfficeMapPresenter from "../../modal-office-map/presenter/modal-office-map.js";
import MiniCartPresenter from "../../mini-cart/presenter/mini-cart.js";
import MiniFavoritePresenter from "../../mini-favorite/presenter/mini-favorite.js";
import MiniComparisonPresenter from "../../mini-comparison/presenter/mini-comparison.js";

import ScrollAppearanceView from "../view/scroll-appearance-view.js";
import TopStickySectionView from "../view/top-section.js";
import BottomStickySectionView from "../view/bottom-section.js";
import FooterView from "../view/footer.js";

import StickyHeaderModel from "../model/sticky-header.js";

import Animation from "../../animation/animation.js";

import {
  removeElement,
  render
} from "../../render/render.js";

import {
  getMaxZIndex
} from "../../../utils/get-max-z-index.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  THRESHOLD_APPEARANCE_ELEMENT_SELECTOR,
  ActionType,
  defaultStickyHeaderModelData,
  MOBILE_MEDIA_QUERY,
  THRESHOLD_WINDOW_HEIGHT
} from "../const.js";

export default class StikyHeader {
  constructor({
    catalogNavigationElement,
    footerElement
  }) {
    if (catalogNavigationElement) {
      this._catalogNavigationElement = catalogNavigationElement.cloneNode(true);
    }

    this._footerElement = footerElement;
    this._defaultModelData = defaultStickyHeaderModelData;

    this._animation = new Animation();

    this._headerCatalogPresenter = null;
    this._searchSuggestionsPresenter = null;
    this._modalCallbackFeedbackFormPresenter = null;
    this._modalOfficeMapPresenter = null;
    this._miniCartPresenter = null;
    this._miniFavoriteTopPresenter = null;
    this._miniComparisonTopPresenter = null;
    this._miniFavoriteBottomPresenter = null;
    this._miniComparisonBottomPresenter = null;

    this._scrollAppearanceView = null;
    this._topSectionView = null;
    this._bottomSectionView = null;
    this._footerView = null;
    this._model = null;

    this._handleWindowLoad = this._handleWindowLoad.bind(this);
    this._handleWindowScroll = this._handleWindowScroll.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
    this._handleWindowResizeBottomSection = this._handleWindowResizeBottomSection.bind(this);
    this._handleWindowResizeBottomSectionDebounced = debounce(this._handleWindowResizeBottomSection);
    this._handleHeaderCatalogAfterOpen = this._handleHeaderCatalogAfterOpen.bind(this);
    this._handleHeaderCatalogBeforeClose = this._handleHeaderCatalogBeforeClose.bind(this);
  }

  init() {
    this._model = new StickyHeaderModel(Object.assign(
        {},
        this._defaultModelData
    ));
    this._model.addObserver(this._handleChangeData);

    window.addEventListener(`scroll`, this._handleWindowScroll, {
      passive: true
    });
    window.addEventListener(`load`, this._handleWindowLoad);
    window.addEventListener(`resize`, this._handleWindowResizeBottomSectionDebounced, {
      passive: true
    });
  }

  _initScrollAppearance() {
    if (!this._scrollAppearanceView) {
      this._scrollAppearanceView = new ScrollAppearanceView(document.querySelector(THRESHOLD_APPEARANCE_ELEMENT_SELECTOR));
    }
  }

  _processRenderTopSection() {
    this._topSectionView = new TopStickySectionView();
    render(document.body, this._topSectionView.getElement());
    this._topSectionView.setSectionZIndex(getMaxZIndex() + 1);
    render(this._topSectionView, this._catalogNavigationElement.cloneNode(true));

    this._animation.fadeIn(
        this._topSectionView.getElement()
    );

    this._headerCatalogPresenter = new HeaderCatalogPresenter(
        this._topSectionView.getCatalogButton(),
        this._topSectionView.getCatalogNavigationElement()
    );
    this._headerCatalogPresenter.init();
    this._headerCatalogPresenter.addAfterOpenCallback(this._handleHeaderCatalogAfterOpen);
    this._headerCatalogPresenter.addBeforeCloseCallback(this._handleHeaderCatalogBeforeClose);

    this._searchSuggestionsPresenter = new SearchSuggestionsPresenter(
        this._topSectionView.getSearchInputElement()
    );
    this._searchSuggestionsPresenter.init();
    this._searchSuggestionsPresenter.setWindowScrollHandler();

    this._modalCallbackFeedbackFormPresenter = new ModalCallbackFeedbackFormPresenter(
        this._topSectionView.getPhoneButtonElement()
    );
    this._modalCallbackFeedbackFormPresenter.init();

    this._miniCartPresenter = new MiniCartPresenter(
        this._topSectionView.getCartTrigger()
    );
    this._miniCartPresenter.init();


    this._miniFavoriteTopPresenter = new MiniFavoritePresenter(
        this._topSectionView.getFavoriteTrigger()
    );
    this._miniFavoriteTopPresenter.init();

    this._miniComparisonTopPresenter = new MiniComparisonPresenter(
        this._topSectionView.getComparisonTrigger()
    );
    this._miniComparisonTopPresenter.init();
  }

  _processDestroyTopSection() {
    const sectionElement = this._topSectionView.getElement();
    this._topSectionView = null;

    this._animation.fadeOut(
        sectionElement,
        null,
        () => {
          removeElement(sectionElement);
        }
    );

    this._headerCatalogPresenter = null;

    this._searchSuggestionsPresenter.resetSearch();
    this._searchSuggestionsPresenter.removeWindowScrollHandler();
    this._searchSuggestionsPresenter = null;

    this._modalCallbackFeedbackFormPresenter.reset();
    this._modalCallbackFeedbackFormPresenter = null;

    this._miniCartPresenter.destroy();
    this._miniCartPresenter = null;
  }

  _processRenderBottomSection() {
    this._bottomSectionView = new BottomStickySectionView();
    render(document.body, this._bottomSectionView.getElement());
    this._bottomSectionView.setSectionZIndex(getMaxZIndex() + 1);

    this._animation.fadeIn(
        this._bottomSectionView.getElement()
    );

    this._modalOfficeMapPresenter = new ModalOfficeMapPresenter(
        this._bottomSectionView.getModalOfficeMapTrigger()
    );
    this._modalOfficeMapPresenter.init();

    this._miniFavoriteBottomPresenter = new MiniFavoritePresenter(
        this._bottomSectionView.getFavoriteTrigger()
    );
    this._miniFavoriteBottomPresenter.init();

    this._miniComparisonBottomPresenter = new MiniComparisonPresenter(
        this._bottomSectionView.getComparisonTrigger()
    );
    this._miniComparisonBottomPresenter.init();

    this._footerView = new FooterView(this._footerElement);
    this._footerView.setStickyRendered(this._bottomSectionView.getHeight());
  }

  _processDestroyBottomSection() {
    const sectionElement = this._bottomSectionView.getElement();
    this._bottomSectionView = null;

    this._animation.fadeOut(
        sectionElement,
        null,
        () => {
          removeElement(sectionElement);
        }
    );

    this._modalOfficeMapPresenter.closeMap();
    this._modalOfficeMapPresenter = null;

    this._footerView.reset();
    this._footerView = null;
  }

  _renderBottomSection() {
    this._initScrollAppearance();

    const scrollThreshold = this._scrollAppearanceView.getHeight() + this._scrollAppearanceView.getTopOffset();
    const modelData = this._model.getData();

    if (scrollThreshold < 0) {
      if (!window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
        return;
      }

      if (modelData.isBottomSectionRendered) {
        return;
      }

      this._model.changeData(Object.assign(
          {},
          modelData,
          {
            isBottomSectionRendered: true
          }
      ), ActionType.RENDER_BOTTOM_SECTION);
    } else {
      if (!modelData.isBottomSectionRendered) {
        return;
      }

      this._model.changeData(Object.assign(
          {},
          modelData,
          {
            isBottomSectionRendered: false
          }
      ), ActionType.DESTROY_BOTTOM_SECTION);
    }
  }

  _renderTopSection() {
    this._initScrollAppearance();

    const scrollThreshold = this._scrollAppearanceView.getHeight() + this._scrollAppearanceView.getTopOffset();
    const modelData = this._model.getData();

    if (scrollThreshold < 0) {
      if (modelData.isTopSectionRendered) {
        return;
      }

      this._model.changeData(Object.assign(
          {},
          modelData,
          {
            isTopSectionRendered: true
          }
      ), ActionType.RENDER_TOP_SECTION);
    } else {
      if (!modelData.isTopSectionRendered) {
        return;
      }

      this._model.changeData(Object.assign(
          {},
          modelData,
          {
            isTopSectionRendered: false
          }
      ), ActionType.DESTROY_TOP_SECTION);
    }
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.RENDER_TOP_SECTION:
        this._processRenderTopSection();
        break;
      case ActionType.DESTROY_TOP_SECTION:
        this._processDestroyTopSection();
        break;
      case ActionType.RENDER_BOTTOM_SECTION:
        this._processRenderBottomSection();
        break;
      case ActionType.DESTROY_BOTTOM_SECTION:
        this._processDestroyBottomSection();
        break;
    }
  }

  _handleWindowLoad() {
    this._renderBottomSection();
    this._renderTopSection();
  }

  _handleWindowScroll() {
    this._renderBottomSection();
    this._renderTopSection();
  }

  _handleWindowResizeBottomSection() {
    if (
      (!window.matchMedia(MOBILE_MEDIA_QUERY).matches ||
        window.innerHeight < THRESHOLD_WINDOW_HEIGHT) && this._model.getData().isBottomSectionRendered
    ) {
      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            isBottomSectionRendered: false
          }
      ), ActionType.DESTROY_BOTTOM_SECTION);
    }

    if (window.matchMedia(MOBILE_MEDIA_QUERY).matches &&
        window.innerHeight >= THRESHOLD_WINDOW_HEIGHT &&
        !this._model.getData().isBottomSectionRendered
    ) {
      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            isBottomSectionRendered: true
          }
      ), ActionType.RENDER_BOTTOM_SECTION);
    }
  }

  _handleHeaderCatalogAfterOpen() {
    this._topSectionView.setScrollable();
  }

  _handleHeaderCatalogBeforeClose() {
    this._topSectionView.unsetScrollable();
  }
}
