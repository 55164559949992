export const navigationPosition = {
  adjustNavigationPosition() {
    const slideImageWrapper = this._wrapperView.getSlideImageWrapper();

    if (!slideImageWrapper) {
      return;
    }

    const offsetTop = slideImageWrapper.offsetTop;
    const offsetHeight = slideImageWrapper.offsetHeight;
    const navigationElement = this._controlsView.getElement();
    const navigationHeight = navigationElement.offsetHeight;
    const top = offsetTop + offsetHeight / 2 - navigationHeight / 2;

    this._controlsView.setTopOffset(top);
  }
};

navigationPosition._controlsView = navigationPosition._controlsView;
navigationPosition._wrapperView = navigationPosition._wrapperView;
