import MiniListItemView from "../../mini-list/view/item.js";

import {
  REMOVE_ITEM_LEXICON,
  ON_REQUEST
} from "../lexicon.js";

import {
  formatNumber
} from "../../../utils/format-number.js";

const createPriceMarkup = (price, currency, unitMeasure) => {
  return (price > 0) ?
    `${formatNumber(price, 2, `,`, `\u00A0`)}
    <span class="product-item__currency">${currency} / ${unitMeasure}</span>` :
    `${ON_REQUEST}`;
};


const createMiniListItemTemplate = ({
  id,
  imageSrc,
  uri,
  title,
  price,
  currency,
  unitMeasure
}) => {
  const priceMarkup = createPriceMarkup(price, currency, unitMeasure);

  return (
    `<li class="mini-list__item" data-item-id="${id}">
      <button class="mini-list__remove-button" type="button">
        <span class="visually-hidden">${REMOVE_ITEM_LEXICON}</span>
      </button>
      <div class="mini-list-product">
        <picture class="mini-list-product__picture">
          <img
              class="mini-list-product__image lazyload"

              alt=""
              width="240"

              src="${imageSrc.jpg[`@2x`]}"
              srcset="/assets/templates/main/images/lazy-load-placeholder.gif"

              data-src="${imageSrc.jpg[`@2x`]}"
              data-srcset="${imageSrc.jpg[`@2x`]}"

              data-aspectratio="240/240">
        </picture>
        <div class="mini-list-product__text-wrapper">
          <a class="mini-list-product__link" href="${uri}">${title}</a>
          <p class="product-item__price product-item__price_mini-list-product">
            ${priceMarkup}
          </p>
        </div>
      </div>
    </li>`
  );
};

export default class MiniFavoriteItem extends MiniListItemView {
  constructor(data) {
    super(data);
  }

  _getTemplate() {
    return createMiniListItemTemplate(this._data);
  }
}
