export const RuleName = {
  MORE_THAN_ZERO: `MORE_THAN_ZERO`,
  EMAIL: `EMAIL`,
  PHONE: `PHONE`,
  NOT_EMPTY: `NOT_EMPTY`,
  IS_CHECKED: `IS_CHECKED`
};

export const ValidateRule = {
  isNotEmpty: (value) => !value ? false : true,
  isMoreThanZero: (value) => value > 0 ? true : false,
  isEmail: (value) => /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(value) ? true : false,
  isPhone: (value) => value.replace(/\D/g, ``).length === 11 ? true : false,
  isChecked: (checked) => checked
};

export const ValidateConverter = {
  NOT_EMPTY: `isNotEmpty`,
  MORE_THAN_ZERO: `isMoreThanZero`,
  EMAIL: `isEmail`,
  PHONE: `isPhone`,
  IS_CHECKED: `isChecked`
};

export const FormClass = {
  INPUT: {
    INVALID: `form-input_invalid`
  },
  TEXTAREA: {
    INVALID: `form-textarea_invalid`
  },
  WRAPPER: {
    VALID: `form-item-wrapper_valid`
  },
  SELECT: {
    INVALID: `select_invalid`,
    NOT_EMPTY: `select_not-empty`
  },
  LABEL: {
    SELECT_NOT_EMPTY: `label_select_not_empty`
  }
};

export const TagName = {
  INPUT: `INPUT`,
  SELECT: `SELECT`,
  TEXTAREA: `TEXTAREA`
};

export const Type = {
  CHECKBOX: `checkbox`
};

export const ValidationStatus = {
  NULL: null,
  VALID: true,
  INVALID: false
};

export const RequestMethod = {
  POST: `POST`,
  GET: `GET`
};

export const OverlayTemplate = {
  SUBMITTING: `SUBMITING`,
  SUCCESS: `SUCCESS`,
  ERROR: `ERROR`
};

export const Timeout = {
  SUBMIT: {
    CHECK: 2000,
    REMOVE: 4000
  }
};

export const SubmitClass = {
  CHECK_ICON: `form-submit__success-check-icon`,
  FIX: `form-submit__success-icon-fix`
};

export const REQUEST_URI = `/assets/components/callbackforms/connector.php`;

export const ResponseStatus = {
  SUCCESS: 200
};
