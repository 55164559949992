import Abstract from "../../../abstract-view/abstract.js";

import {
  SLIDE_NAME
} from "../lexicon.js";

const createNavigationLayout = (slidesCount, postfix) => {
  const buttonsLayout = [...Array(slidesCount).keys()]
    .map((index) => createButtonLayout(index))
    .join(`\n`);
  const postfixClass = (postfix) ?
    ` dots-controls_${postfix}` :
    ``;

  return (
    `<div class="dots-controls${postfixClass}" data-glide-el="controls[nav]">
      ${buttonsLayout}
    </div>`
  );
};

const createButtonLayout = (index) => {
  return (
    `<button class="dots-controls__button" data-glide-dir="=${index}" aria-label="${SLIDE_NAME}${(index + 1)}"></button>`
  );
};

import {
  createElement
} from "../../../render/render.js";

export default class DotsNavigation extends Abstract {
  constructor(slidesCount, postfix = null) {
    super();

    this._slidesCount = slidesCount;
    this._postfix = postfix;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  _getTemplate() {
    return createNavigationLayout(this._slidesCount, this._postfix);
  }
}
