import FeedbackFormView from "../../feedback-form/view/feedback-form.js";

import {
  feedbackFormSubject
} from "../../../mixins/feedback-form-subject.js";

import {
  REQUEST_SUBJECT
} from "../const.js";

import {
  managersItems
} from "../data.js";

const createFeedbackFormTemplate = () => {
  const randomItem = managersItems[Math.floor(Math.random() * managersItems.length)];

  return (
    `<div class="form-wrapper">
      <form class="request-form request-form_consult" method="post">
        ${feedbackFormSubject.getTemplate(REQUEST_SUBJECT)}
        <div class="request-form-consult-wrapper">
          <div class="heading heading_h3">Нужна профессиональная консультация? Поможем!</div>
          <div class="form-item-container">
            <label class="form-label" for="phone">Введите ваш телефон</label>
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_consult">
              <input
              id="phone"
              class="form-input"
              name="phone"
              type="tel"
              placeholder="+7 ("
              value>
            </div>
            <p class="form-item-descriptor">По этому номеру мы свяжемся с вами, чтобы уточнить детали.</p>
          </div>
          <div class="form-item-container">
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_consult">
              <button class="button button_width_full" type="submit">Получить расчет</button>
            </div>
          </div>
          <div class="form-item-container">
            <div class="form-item-wrapper  form-item-wrapper_no-margins form-item-wrapper_consult">
              <label class="form-label form-label_checkbox">
                <input class="form-checkbox visually-hidden" type="checkbox" name="policy" checked>
                <span class="form-checkbox-icon"></span>
                <span class="form-checkbox-text form-checkbox-text_policy">Согласен с <a class="form-link" href="/policy/">политикой конфиденциальности</a></span>
              </label>
            </div>
          </div>
        </div>
        <div class="manager">
          <picture class="manager__picture">
            <img
              class="manager__image lazyload"

              alt=""
              width="${randomItem.width}"

              src="${randomItem.imageSrc}"
              srcset="/assets/templates/main/images/lazy-load-placeholder.gif"

              data-src="${randomItem.imageSrc}"
              data-srcset="${randomItem.imageSrc}"

              data-aspectratio="${randomItem.aspectRatio}"
            >
          </picture>
          <div class="manager__info">
            <p class="manager__name">${randomItem.name}</p>
            <p class="manager__position">${randomItem.position}</p>
          </div>
        </div>
      </form>
    </div>`
  );
};

export default class RequestFormConsult extends FeedbackFormView {
  constructor() {
    super();
  }

  _getTemplate() {
    return createFeedbackFormTemplate();
  }
}
