export const defaultComparisonData = {
  quantity: null,
  isInit: false
};

export const ActionType = {
  ADD_PRODUCT: `ADD_PRODUCT`,
  REMOVE_PRODUCT_FROM_LIST: `REMOVE_PRODUCT_FROM_LIST`,
  REMOVE_FROM_LIST: `REMOVE_FROM_LIST`,
  REMOVE_PRODUCT: `REMOVE_PRODUCT`,
  AFTER_CLEAR_LIST: `AFTER_CLEAR_LIST`,
  CLEAR_LIST: `CLEAR_LIST`,
  GET_DATA: `GET_DATA`,
  CHANGE_LIST: `CHANGE_LIST`,
  CHANGE_TYPES_FORM: `CHANGE_TYPES_FORM`,
  UPDATE_DATA: `UPDATE_DATA`,
  UPDATE_VIEW: `UPDATE_VIEW`
};

export const CONNECTOR_URL = `/assets/components/comparison/connector.php`;

export const RequestAction = {
  REMOVE_PRODUCT: `remove_product`,
  CLEAR: `clear`,
  GET_DATA: `get_data`,
  GET_PRODUCTS: `get_products`,
  GET_LIST_CATEGORY_DATA: `get_list_category_data`,
  GET_CATEGORIES_LIST_DATA: `get_categories_list_data`,
  RENDER_COMPARISON: `render_comparison`,
  GET_LIST_DATA: `get_list_data`
};
