export const defaultModalModelData = {
  isActive: false,
  isInit: false
};

export const defaultModalFeedbackTriggerModelData = {
  isClicking: false,
  isModalShown: false
};

export const ActionType = {
  CLICKING: `CLICKING`,
  SHOW_MODAL: `SHOW_MODAL`
};

export const MODAL_MAIN_CLASS = `modal-feedback-form-wrapper`;
export const MODAL_CLOSE_AFTER_SUBMIT_TIMEOUT = 4000;

export const YM_COUNTER_ID = 29276100;
