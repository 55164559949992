import ModalFeedbackTriggerPresenter from "../../modal-feedback-form/presenter/modal-feedback-trigger.js";
import ExtendedFeedbackFormModalPresenter from "./modal.js";
export default class ModalExtendedFeedbackForm extends ModalFeedbackTriggerPresenter {
  constructor(triggerElement, addExtraValidationRules = true) {
    super(triggerElement);

    this._addExtraValidationRules = addExtraValidationRules;
  }

  init() {
    super.init();
  }

  _processShowModal() {
    const properties = {};

    if (this._triggerView) {
      const submitYmGoal = this._triggerView.getSubmitYmGoal();

      if (submitYmGoal) {
        properties.submitYmGoal = submitYmGoal;
      }
    }

    this._modalFeedbackFormPresenter = new ExtendedFeedbackFormModalPresenter(this._addExtraValidationRules, properties);
    this._modalFeedbackFormPresenter.build();
  }
}
