import StickySidebar from "sticky-sidebar-v2";

import SidebarView from "../view/sidebar.js";

import {
  render,
  replace
} from "../../render/render.js";

import {
  defaultOptions
} from "../const.js";

export default class StickySidebarPresenter {
  constructor(contentElement, options = {}) {
    this._contentElement = contentElement;
    this._view = null;
    this._sidebar = null;
    this._options = Object.assign(
        {},
        defaultOptions,
        options
    );
  }

  init() {
    this._prepareMarkup();
    this._sidebar = new StickySidebar(this._view.getElement(), this._options);
  }

  _prepareMarkup() {
    this._view = new SidebarView(this._contentElement);
    replace(this._view.getElement(), this._contentElement);
    render(this._view.getInnerElement(), this._contentElement);
  }
}
