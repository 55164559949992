import Abstract from "../abstract-view/abstract.js";

export const RenderPosition = {
  AFTEREND: `afterend`,
  BEFOREEND: `beforeend`,
  AFTERBEGIN: `afterbegin`,
  BEFOREBEGIN: `beforebegin`
};


export const createElement = (template) => {
  const newElement = document.createElement(`div`);
  newElement.innerHTML = template;

  return newElement.firstElementChild;
};

export const render = (container, child, place = RenderPosition.BEFOREEND) => {
  if (container instanceof Abstract) {
    container = container.getElement();
  }

  if (child instanceof Abstract) {
    child = child.getElement();
  }

  switch (place) {
    case RenderPosition.BEFOREBEGIN:
      container.parentElement.insertBefore(child, container);
      break;
    case RenderPosition.AFTERBEGIN:
      container.prepend(child);
      break;
    case RenderPosition.BEFOREEND:
      container.append(child);
      break;
    case RenderPosition.AFTEREND:
      const nextSibling = container.nextElementSibling;

      if (nextSibling !== null) {
        container.parentElement.insertBefore(child, nextSibling);
        return;
      }

      container.parentElement.append(child);
      break;
  }
};

export const remove = (component) => {
  if (!component) {
    return;
  }

  if (!(component instanceof Abstract)) {
    throw new Error(`Can remove only components.`);
  }

  component.getElement().remove();
  component.removeElement();
};

export const replace = (newChild, oldChild) => {
  if (oldChild instanceof Abstract) {
    oldChild = oldChild.getElement();
  }

  if (newChild instanceof Abstract) {
    newChild = newChild.getElement();
  }

  const parent = oldChild.parentElement;

  if (parent === null || oldChild === null || newChild === null) {
    throw new Error(`Can't replace unexisting elements.`);
  }

  parent.replaceChild(newChild, oldChild);
};

export const removeElement = (element) => {
  if (!element || !element.parentElement) {
    return;
  }

  element.parentElement.removeChild(element);
};
