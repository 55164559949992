import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

import {
  ItemType
} from "../const.js";

import {
  PREV_BUTTON_TEXT,
  NEXT_BUTTON_TEXT
} from "../lexicon.js";

const createScrollContainerNavigationTemplate = () => {
  return (
    `<ul class="scroll-container-nav">
      <li class="scroll-container-nav__item scroll-container-nav__item_prev scroll-container-nav__item_disabled">
        <button class="scroll-container-nav__button scroll-container-nav__button_prev" type="button">
          <span class="visually-hidden">${PREV_BUTTON_TEXT}</span>
        </button>
      </li>
      <li class="scroll-container-nav__item scroll-container-nav__item_next">
        <button class="scroll-container-nav__button scroll-container-nav__button_next" type="button">
          <span class="visually-hidden">${NEXT_BUTTON_TEXT}</span>
        </button>
      </li>
    </ul>`
  );
};

export default class ScrollContainerNavigation extends Abstract {
  constructor() {
    super();
    this._prevButton = null;
    this._nextButton = null;

    this._prevButtonClickHandler = this._prevButtonClickHandler.bind(this);
    this._nextButtonClickHandler = this._nextButtonClickHandler.bind(this);
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getPrevElement() {
    if (!this._prevElement) {
      this._prevElement = this._element.querySelector(`.scroll-container-nav__item_prev`);
    }

    return this._prevElement;
  }

  getNextElement() {
    if (!this._nextElement) {
      this._nextElement = this._element.querySelector(`.scroll-container-nav__item_next`);
    }

    return this._nextElement;
  }

  getPrevButton() {
    if (!this._prevButton) {
      this._prevButton = this._element.querySelector(`.scroll-container-nav__button_prev`);
    }

    return this._prevButton;
  }

  getNextButton() {
    if (!this._nextButton) {
      this._nextButton = this._element.querySelector(`.scroll-container-nav__button_next`);
    }

    return this._nextButton;
  }

  disableItem(type) {
    if (type === ItemType.PREV) {
      this.getPrevElement().classList.add(`scroll-container-nav__item_disabled`);
    } else {
      this.getNextElement().classList.add(`scroll-container-nav__item_disabled`);
    }
  }

  enableItem(type) {
    if (type === ItemType.PREV) {
      this.getPrevElement().classList.remove(`scroll-container-nav__item_disabled`);
    } else {
      this.getNextElement().classList.remove(`scroll-container-nav__item_disabled`);
    }
  }

  setPrevButtonClickHandler(callback) {
    this._callback.prevButtonClick = callback;
    this.getPrevButton().addEventListener(`click`, this._prevButtonClickHandler);
  }

  setNextButtonClickHandler(callback) {
    this._callback.nextButtonClick = callback;
    this.getNextButton().addEventListener(`click`, this._nextButtonClickHandler);
  }

  _getTemplate() {
    return createScrollContainerNavigationTemplate();
  }

  _prevButtonClickHandler(evt) {
    this._callback.prevButtonClick(evt);
  }

  _nextButtonClickHandler(evt) {
    this._callback.nextButtonClick(evt);
  }
}
