export const animate = ({timing, draw, duration, beforeBegin, afterEnd, globalAfterEnd}) => {
  if (beforeBegin) {
    beforeBegin();
  }

  const start = performance.now();
  const animateProgress = (time) => {
    let timeFraction = (time - start) / duration;

    if (timeFraction > 1) {
      timeFraction = 1;
    }

    const progress = timing(timeFraction);

    draw(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(animateProgress);
    } else {
      if (afterEnd) {
        afterEnd();
      }

      if (globalAfterEnd) {
        globalAfterEnd();
      }
    }
  };

  requestAnimationFrame(animateProgress);
};
