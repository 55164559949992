export const REQUEST_SUBJECT = `Нашел дешевле`;

import {
  RuleName
} from "../submit-form/const.js";

export const DEFAULT_YM_GOAL_ID = `find_cheaper`;

export const EXTRA_VALIDATION_RULES = [
  {
    name: `cheaper_link`,
    rules: [
      RuleName.NOT_EMPTY
    ]
  },
  {
    name: `phone`,
    rules: [
      RuleName.PHONE
    ]
  }
];
