export const CONNECTOR_URL = `/assets/components/comparison/connector.php`;
export const LINK_HREF = `/comparison/`;

export const ActionType = {
  SET_COMPARABLE: `SET_COMPARABLE`,
  UNSET_COMPARABLE: `UNSET_COMPARABLE`,
  UPDATE_DATA: `UPDATE_DATA`,
  ADD_PRODUCT: `ADD_PRODUCT`,
  REMOVE_PRODUCT: `REMOVE_PRODUCT`
};
