import Abstract from "../../abstract-view/abstract.js";

export default class Wrapper extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._instanceClass = null;
  }

  getSlides() {
    return this._element.querySelectorAll(`.glide__slide`);
  }

  setActiveLink(index) {
    this._element.querySelectorAll(`a`)[index].focus();
  }

  setInstanceClass(index) {
    this._instanceClass = `${this._element.classList[0]}_${index}`;
    this._element.classList.add(this._instanceClass);
  }

  getInstanceClass() {
    return this._instanceClass;
  }
}
