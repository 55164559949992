import ProductQuantityView from "../view/product-quantity";

import ProductQuantityModel from "../model/product-quantity.js";

import {
  defaultProductQuantityModelData,
  ActionType,
  CONNECTOR_URL,
  RequestAction,
  DEFAULT_ABSTRACT_QUANTITY
} from "../const.js";

import {
  sendXhrQueue,
} from "../../../utils/send-xhr/send-xhr.js";
export default class ProductQuantity {
  constructor(element, sessionElementId, itemId, options = {}) {
    this._element = element;
    this._sessionElementId = sessionElementId;
    this._itemId = itemId;
    this._connectorUrl = CONNECTOR_URL;
    this._options = options;
    this._updateWidth = (this._options.preventWidthUpdate) ?
      false : true;

    this._view = null;

    this._model = new ProductQuantityModel(Object.assign(
        {},
        defaultProductQuantityModelData
    ));

    this._requestQueue = {};

    this._handleRemoveButtonClick = this._handleRemoveButtonClick.bind(this);
    this._handleAddButtonClick = this._handleAddButtonClick.bind(this);
    this._handleFormSubmit = this._handleFormSubmit.bind(this);
    this._handleQuantityInputInvalid = this._handleQuantityInputInvalid.bind(this);
    this._handleQuantityInputBlur = this._handleQuantityInputBlur.bind(this);
    this._handleQuantityInputChange = this._handleQuantityInputChange.bind(this);

    this._handleChangeProductShippingUnitData = this._handleChangeProductShippingUnitData.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._view = new ProductQuantityView(this._element, {
      updateWidth: this._updateWidth
    });

    this._model.addObserver(this._handleChangeData);
    this._addProductShippingUnitObserver();

    this._view.setRemoveButtonClickHandler(this._handleRemoveButtonClick);
    this._view.setAddButtonClickHandler(this._handleAddButtonClick);
    this._view.setFormSubmitHandler(this._handleFormSubmit);
    this._view.setQuantityInputInvalidHandler(this._handleQuantityInputInvalid);
    this._view.setQuantityInputBlurHandler(this._handleQuantityInputBlur);
    this._view.setQuantityInputChangeHandler(this._handleQuantityInputChange);

    const quantity = this._view.getQuantity();
    const step = this._view.getStep();
    const shippingPrice = this._view.getShippingPrice();
    const defaultStep = step;

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          quantity,
          step,
          shippingPrice,
          abstractQuantity: DEFAULT_ABSTRACT_QUANTITY,
          defaultStep
        }
    ), ActionType.INIT, {
      defaultStepQuantity: defaultStep
    });
  }

  addObserver(observer) {
    this._model.addObserver(observer);
  }

  changeQuantity(quantity) {
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          quantity
        }
    ), ActionType.UPDATE, {
      quantity
    });
  }

  _addProductShippingUnitObserver() {
    if (!window.ProductShippingUnitData) {
      return;
    }

    const productId = this._view.getProductId();

    if (!window.ProductShippingUnitData[productId]) {
      return;
    }

    window.ProductShippingUnitData[productId].addObserver(this._handleChangeProductShippingUnitData);
  }

  _getFormatedQuantity() {
    const step = this._model.getData().step;

    let quantity = this._view.getQuantity();

    if (quantity <= step) {
      quantity = step;
    }

    const remnant = quantity % step;

    quantity = (remnant > step / 2) ?
      quantity - remnant + step :
      quantity - remnant;

    return parseFloat(quantity.toFixed(2));
  }

  _processQuantityUpdate(quantity) {
    this._view.setQuantity(quantity);
  }

  _processChangeUnit() {
    const abstractQuantity = this._model.getData().abstractQuantity;
    const step = this._model.getData().step;
    const quantity = Math.round(step * abstractQuantity * 100) / 100;

    this._view.setStep(step);
    this._view.setQuantity(quantity);
  }

  _calcAbstractQuantity(quantity) {
    const modelData = this._model.getData();
    const step = modelData.step;
    return Math.round(quantity / step);
  }

  _changeCartData(action, data) {
    this._view.setQuantity(this._model.getData().quantity);

    const payload = (this._itemId) ?
      Object.assign(
          {},
          data,
          {
            itemId: this._itemId,
            currentItemQuantity: this._model.getData().quantity
          }
      ) :
      Object.assign(
          {},
          data,
          {
            sessionElementId: this._sessionElementId,
            currentItemQuantity: this._model.getData().quantity
          }
      );

    if (window.CartData) {
      window.CartData.changeData(Object.assign(
          {},
          window.CartData.getData(),
          payload
      ), action, payload);
    }
  }

  _handleRemoveButtonClick() {
    const modelData = this._model.getData();

    if (modelData.quantity <= modelData.step) {
      return;
    }

    const step = modelData.step;
    const quantity = parseFloat((modelData.quantity - step).toFixed(2));
    const abstractQuantity = modelData.abstractQuantity - 1;
    const shippingPrice = modelData.shippingPrice;
    const defaultStep = modelData.defaultStep;

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          quantity,
          abstractQuantity
        }
    ), ActionType.REMOVE_QUANTITY, {
      quantity,
      step,
      abstractQuantity,
      shippingPrice,
      defaultStepQuantity: defaultStep * abstractQuantity
    });
  }

  _handleAddButtonClick() {
    const modelData = this._model.getData();

    const step = modelData.step;
    const quantity = parseFloat((modelData.quantity + step).toFixed(2));
    const abstractQuantity = modelData.abstractQuantity + 1;
    const shippingPrice = modelData.shippingPrice;
    const defaultStep = modelData.defaultStep;

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          quantity,
          step,
          abstractQuantity
        }
    ), ActionType.ADD_QUANTITY, {
      quantity,
      step,
      abstractQuantity,
      shippingPrice,
      defaultStepQuantity: defaultStep * abstractQuantity
    });
  }

  _handleFormSubmit(evt) {
    evt.preventDefault();

    const quantity = this._getFormatedQuantity();
    const modelData = this._model.getData();

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          quantity
        }
    ), ActionType.SUBMIT, {
      quantity,
      step: modelData.step
    });
  }

  _handleQuantityInputInvalid(evt) {
    evt.preventDefault();
  }

  _handleQuantityInputBlur() {

  }

  _handleQuantityInputChange() {

  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.UPDATE:
        this._processQuantityUpdate(payload.quantity);
        break;
      case ActionType.BLUR:
      case ActionType.SUBMIT:
      case ActionType.INVALID_CHECK:
      case ActionType.ADD_QUANTITY:
      case ActionType.REMOVE_QUANTITY:
      case ActionType.INPUT_CHANGE:
        if (!this._sessionElementId) {
          this._changeCartData(action, {});
          return;
        }

        const requestArgs = {
          body: [
            `action=${RequestAction.CHANGE_ITEM_QUANTITY}`,
            `current_item_quantity=${this._model.getData().quantity}`,
            `session_element_id=${this._sessionElementId}`
          ],
          connectorUrl: CONNECTOR_URL,
          loadCallback: (response) => {
            const data = JSON.parse(response);

            if (data.error) {
              console.error(data.error);
              return;
            }

            this._changeCartData(action, data);
          },
          requestQueue: this._requestQueue
        };
        sendXhrQueue(requestArgs);
        break;
      case ActionType.CHANGE_UNIT:
        this._processChangeUnit();
        break;
    }
  }

  _handleChangeProductShippingUnitData(action, payload) {
    const step = payload.quantityPerShippingUnit;
    const shippingPrice = payload.shippingUnitPrice;
    const unitPrice = payload.unitPrice;
    const abstractQuantity = this._model.getData().abstractQuantity;

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          quantity: step * abstractQuantity,
          shippingPrice,
          unitPrice,
          step
        }
    ), ActionType.CHANGE_UNIT);
  }
}
