import ElementNotificatonView from "./../view/element-notification.js";
import ElementNotificatonModel from "./../model/element-notification.js";
import Animation from "./../../animation/animation.js";

import {
  render,
  removeElement
} from "./../../render/render.js";

import {
  getMaxZIndex
} from "../../../utils/get-max-z-index.js";

import {
  Action,
  Style
} from "./../const.js";

export default class ElementNotificaton {
  constructor(element) {
    this._relativeElement = element;
    this._elementNotificationModel = new ElementNotificatonModel({
      notification: false
    });
    this._animation = new Animation();

    this._handleChangeModel = this._handleChangeModel.bind(this);
  }

  init() {
    this._elementNotificationModel.addObserver(this._handleChangeModel);
  }

  checkIfRendered() {
    return this._elementNotificationModel.getData().notification;
  }

  render(notification, style) {
    if (this.checkIfRendered()) {
      this.destroy();
    }

    this._elementNotificatonView = new ElementNotificatonView(notification);
    this._elementNotificationModel.changeData(Object.assign(
        {},
        this._elementNotificationModel.getData(), {
          notification: true
        }
    ), Action.RENDER, {
      style: style || Style.ERROR
    });
  }

  destroy() {
    if (!this._elementNotificationModel.getData().notification) {
      return;
    }

    this._elementNotificationModel.changeData(Object.assign(
        {},
        this._elementNotificationModel.getData(), {
          notification: false
        }
    ), Action.DESTROY);
  }

  _handleChangeModel(action, payload) {
    switch (action) {
      case Action.RENDER:
        const notificationElement = this._elementNotificatonView.getElement();
        this._elementNotificatonView.setStyle(payload.style);

        render(this._relativeElement, notificationElement);

        this._elementNotificatonView.setZIndex(getMaxZIndex() + 1);

        this._animation.appear(
            notificationElement
        );
        break;
      case Action.DESTROY:
        const oldNotificationElement = this._elementNotificatonView.getElement();
        this._elementNotificatonView = null;

        this._animation.disappear(
            oldNotificationElement,
            null,
            () => {
              removeElement(oldNotificationElement);
            }
        );
        break;
    }
  }
}
