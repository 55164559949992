import ModalFeedbackTriggerPresenter from "../../modal-feedback-form/presenter/modal-feedback-trigger.js";
import OneClickBuyModalPresenter from "./modal.js";
export default class OneClickBuy extends ModalFeedbackTriggerPresenter {
  constructor(triggerElement, productData) {
    super(triggerElement);

    this._productData = productData;
  }

  _processShowModal() {
    // Send the Yandex Metrika goal.
    if (window.ym) {
      window.ym(29276100,'reachGoal','one_click_purchase_button_click');
    }

    this._modalFeedbackFormPresenter = new OneClickBuyModalPresenter(this._productData);
    this._modalFeedbackFormPresenter.build();
  }
}
