export const defaultCatalogItemModelData = {
  isItemsButtonActive: false,
  isTitleButtonActive: false
};

export const ActionType = {
  SHOW_ITEMS: `SHOW_ITEMS`,
  HIDE_ITEMS: `HIDE_ITEMS`
};

export const ITEMS_ANIMATION_DURATION = 100;

export const MOBILE_MEDIA_QUERY = `(max-width: 500px)`;

export const KeyboardKey = {
  ESC: [
    `Escape`,
    `Esc`
  ]
};
