export const modelData = {
  getData() {
    return this._data;
  },

  changeData(data, action, payload) {
    this._data = data;
    this._notify(action, payload);
  }
};
