export const formatNumber = (number, decimals, decPoint, thousandsSep) => {
  let i;
  let j;
  let kw;
  let kd;
  let km;

  if (isNaN(decimals = Math.abs(decimals))) {
    decimals = 2;
  }

  if (decPoint === undefined) {
    decPoint = `,`;
  }

  if (thousandsSep === undefined) {
    thousandsSep = `.`;
  }

  i = parseInt(number = (+number || 0).toFixed(decimals), 10) + ``;

  if ((j = i.length) > 3) {
    j = j % 3;
  } else {
    j = 0;
  }

  km = (j ? i.substr(0, j) + thousandsSep : ``);
  kw = i.substr(j).replace(/(\d{3})(?=\d)/g, `$1` + thousandsSep);
  kd = (decimals ? decPoint + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : ``);

  // Remove the ending `,00`
  kd = kd.replace(`,00`, ``);

  return km + kw + kd;
};
