import {
  Fancybox
} from "@fancyapps/ui";

import ru from "@fancyapps/ui/src/Fancybox/l10n/ru";

import SectionView from "../view/section.js";
import SortingButtonView from "../../sorting/view/sorting-button.js";

import ProductReviewsModel from "../model/product-reviews.js";

import Animation from "../../animation/animation.js";

import {
  sendXhr,
} from "../../../utils/send-xhr/send-xhr.js";

import {
  createElement,
  removeElement,
  replace,
  render
} from "../../render/render.js";

import {
  defaultProductReviewsModelData,
  SortDir,
  ActionType,
  RequestAction,
  CONNECTOR_URL,
  REQUEST_DELAY
} from "../const.js";

import {
  AnimationClass
} from "../../animation/const.js";

import {
  FancyboxOptions
} from "../../../const.js";

export default class ProductReviews {
  constructor(section) {
    this._section = section;

    this._animation = new Animation();

    this._sectionView = null;
    this._model = null;
    this._sortingButtonsViews = [];

    this._handleMoreButtonClick = this._handleMoreButtonClick.bind(this);
    this._handleSortingButtonClick = this._handleSortingButtonClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._sectionView = new SectionView(this._section);

    const isMoreButtonRendered = (this._sectionView.getMoreButtonElement()) ? true : false;

    if (isMoreButtonRendered) {
      this._sectionView.setMoreButtonClickHandler(this._handleMoreButtonClick);
    }

    const sortingButtons = this._sectionView.getSortingButtons();
    Array.from(sortingButtons)
      .forEach((button, index) => {
        const view = new SortingButtonView(button, index);
        view.setClickHandler(this._handleSortingButtonClick);
        this._sortingButtonsViews.push(view);
      });

    this._model = new ProductReviewsModel(Object.assign(
        {},
        defaultProductReviewsModelData,
        {
          sortName: this._sectionView.getActiveSortingName(),
          sortDir: this._sectionView.getActiveSortingDir(),
          isMoreButtonRendered
        }
    ));

    this._model.addObserver(this._handleChangeData);

    this._initModal();
  }

  _loadItems() {
    const body = [
      `action=${RequestAction.LOAD_MORE}`,
      `offset=${this._sectionView.getListItems().length}`,
      `resource_id=${document.body.dataset.resourceId}`,
      `sort_dir=${this._model.getData().sortDir}`,
      `sort_name=${this._model.getData().sortName}`
    ];

    const itemTemplate = this._sectionView.getItemTemplate();

    if (itemTemplate) {
      body.push(`item_template=${itemTemplate}`);
    }

    const requestArgs = {
      body,
      connectorUrl: CONNECTOR_URL,
      loadCallback: (response) => {
        const {items, isMoreButtonDestroy} = JSON.parse(response);
        this._processLoadItems(items, isMoreButtonDestroy);
      },
      loadendCallback: () => {
        setTimeout(() => {
          this._model.changeData(Object.assign(
              {},
              this._model.getData(),
              {
                isLoading: false
              }
          ), ActionType.REQUEST_END);
        }, REQUEST_DELAY);
      }
    };
    sendXhr(requestArgs);
  }

  _destroyMoreButton() {
    const moreButton = this._sectionView.getMoreButtonElement();
    this._sectionView.setMoreButtonElement(null);
    removeElement(moreButton);
  }

  _activateSortingButton(currentIndex) {
    this._sortingButtonsViews.forEach((view, index) => {
      if (currentIndex === index) {
        view.activate();
      } else {
        view.deactivate();
      }
    });

    this._sortReviews();
  }

  _changeSortingDirection(currentIndex, newSortDir, oldSortDir) {
    this._sortingButtonsViews[currentIndex].setSortDir(newSortDir, oldSortDir);
    this._sortReviews();
  }

  _sortReviews() {
    const requestArgs = {
      body: [
        `action=${RequestAction.GET_ITEMS}`,
        `sort_dir=${this._model.getData().sortDir}`,
        `sort_name=${this._model.getData().sortName}`,
        `resource_id=${document.body.dataset.resourceId}`,
      ],
      connectorUrl: CONNECTOR_URL,
      loadstartCallback: () => {
        this._model.changeData(Object.assign(
            {},
            this._model.getData(),
            {
              isLoading: true
            }
        ), ActionType.SORT_START);
      },
      loadCallback: (response) => {
        const {list, button} = JSON.parse(response);
        this._processSortReviews(list, button);
      },
      loadendCallback: () => {
        setTimeout(() => {
          this._model.changeData(Object.assign(
              {},
              this._model.getData(),
              {
                isLoading: false
              }
          ), ActionType.SORT_END);
        }, REQUEST_DELAY);
      }
    };
    sendXhr(requestArgs);
  }

  _initModal() {
    const selector = `.${this._sectionView.getSectionClass()} a`;

    Fancybox.defaults.l10n = ru;
    Fancybox.bind(selector, Object.assign(
        {},
        FancyboxOptions
    ));
  }

  _processSortReviews(list, button) {
    const newListElement = createElement(list);
    replace(newListElement, this._sectionView.getListElement());
    this._sectionView.setListElement(newListElement);

    this._animation.fadeIn(
        newListElement,
        null,
        null,
        AnimationClass.SHOW_FLEX
    );

    const oldButtonElement = this._sectionView.getMoreButtonElement();

    if (oldButtonElement) {
      if (button) {
        removeElement(oldButtonElement);
      } else {
        this._animation.fadeOut(
            oldButtonElement,
            null,
            () => {
              removeElement(oldButtonElement);
            }
        );
      }
    }

    if (button) {
      const newButtonElement = createElement(button);
      render(this._sectionView, newButtonElement);
      this._sectionView.setMoreButtonElement(newButtonElement);

      this._animation.fadeIn(newButtonElement);
      this._sectionView.setMoreButtonClickHandler(this._handleMoreButtonClick);
    }
  }

  _processLoadItems(data, destroyMoreButton = false) {
    const dummyElement = document.createElement(`div`);
    dummyElement.innerHTML = data;
    const items = dummyElement.children;

    Array.from(items)
      .forEach((item) => {
        item.style.display = `none`;
        render(this._sectionView.getListElement(), item);
        this._animation.fadeIn(item);
      });

    if (destroyMoreButton) {
      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            isMoreButtonRendered: false
          }
      ), ActionType.DESTROY_MORE_BUTTON);
    }
  }

  _handleSortingButtonClick({
    sortName,
    sortDir,
    index
  }) {
    const modelData = this._model.getData();

    if (modelData.isLoading) {
      return;
    }

    if (sortName !== modelData.sortName) {
      this._model.changeData(Object.assign(
          {},
          modelData,
          {
            sortName,
            sortDir
          }
      ), ActionType.ACTIVATE_SORTING_BUTTON, {
        index
      });

      return;
    }

    const newSortDir = (sortDir === SortDir.DESC) ? SortDir.ASC : SortDir.DESC;

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          sortDir: newSortDir
        }
    ), ActionType.CHANGE_SORTING_DIRECTION, {
      index,
      newSortDir,
      oldSortDir: modelData.sortDir
    });
  }

  _handleMoreButtonClick() {
    const modelData = this._model.getData();

    if (modelData.isLoading) {
      return;
    }

    this._model.changeData(Object.assign(Object.assign(
        {},
        modelData,
        {
          isLoading: true
        }
    )), ActionType.LOAD_MORE);
  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.LOAD_MORE:
        this._sectionView.setLoading();
        this._sectionView.setMoreButtonLoading();
        this._loadItems();
        break;
      case ActionType.REQUEST_END:
        this._sectionView.resetView();
        this._sectionView.resetMoreButtonView();
        break;
      case ActionType.DESTROY_MORE_BUTTON:
        this._destroyMoreButton();
        break;
      case ActionType.ACTIVATE_SORTING_BUTTON:
        this._activateSortingButton(payload.index);
        break;
      case ActionType.CHANGE_SORTING_DIRECTION:
        this._changeSortingDirection(payload.index, payload.newSortDir, payload.oldSortDir);
        break;
      case ActionType.SORT:
        this._processSortReviews();
        break;
    }
  }
}
