import Abstract from "./../../abstract-view/abstract.js";

export default class PaginationItem extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._itemClickHandler = this._itemClickHandler.bind(this);
  }

  getText() {
    return this._element.textContent.trim();
  }

  setItemClickHandler(callback) {
    this._callback.itemClick = callback;

    this._element.addEventListener(`click`, this._itemClickHandler);
  }

  _itemClickHandler(evt) {
    evt.preventDefault();

    if (this._element.closest(`.pagination-list__item_current`)) {
      return;
    }

    this._callback.itemClick(this.getText());
  }
}
