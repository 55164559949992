import MiniListTriggerView from "../view/trigger.js";

import MiniListTriggerModel from "../model/trigger.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  removeElement,
  render
} from "../../render/render.js";

import {
  defaultMiniListTriggerModelData,
  SECTION_RENDER_TIMEOUT,
  SECTION_DESTROY_TIMEOUT,
  ActionType,
  MOBILE_MEDIA_QUERY
} from "../const.js";

import {
  AnimationClass
} from "../../animation/const.js";

export default class TriggerPresenter {
  constructor(element, listPresenter) {
    this._element = element;
    this._listPresenter = listPresenter;

    this._TriggerView = MiniListTriggerView;

    this._triggerView = null;
    this._model = null;

    this._renderTimeout = null;
    this._destroyTimeout = null;

    this._requestAction = null;
    this._connectorUrl = null;

    this._handleMouseEnter = this._handleMouseEnter.bind(this);
    this._handleMouseLeave = this._handleMouseLeave.bind(this);
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._handleWindowResizeDebounced = debounce(this._handleWindowResize);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._triggerView = new this._TriggerView(this._element);
    this._triggerView.setMouseEnterHandler(this._handleMouseEnter);
    this._triggerView.setMouseLeaveHandler(this._handleMouseLeave);

    this._model = new MiniListTriggerModel(Object.assign(
        {},
        defaultMiniListTriggerModelData
    ));
    this._model.addObserver(this._handleChangeData);
  }

  destroy() {}

  getTriggerWidth() {
    return this._triggerView.getElementWidth();
  }

  clearDestroyTimeout() {
    clearTimeout(this._destroyTimeout);
  }

  destroySection() {
    if (!this._model.getData().isActive) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isActive: false
        }
    ), ActionType.DESTROY_SECTION);
  }

  updateTrigger() {}

  updateCounter() {
    if (this._model.getData().isCounterRendered) {
      const action = (this._model.getData().itemsCount > 0) ?
        ActionType.CHANGE_COUNT :
        ActionType.DESTROY_COUNTER;
      const isCounterRendered = (this._model.getData().itemsCount > 0) ? true : false;

      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            isCounterRendered
          }
      ), action);
    } else {
      if (this._model.getData().itemsCount === 0) {
        return;
      }

      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            isCounterRendered: true
          }
      ), ActionType.RENDER_COUNTER);
    }
  }

  _processRenderSection() {
    const requestArgs = {
      body: [
        `action=${this._requestAction}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);

        if (data.items.length === 0) {
          return;
        }

        this._listPresenter.renderSection(data);

        window.addEventListener(`resize`, this._handleWindowResizeDebounced, {
          passive: true
        });
      },
    };
    sendXhr(requestArgs);
  }

  _processDestroySection() {
    this._listPresenter.destroySection();

    window.removeEventListener(`resize`, this._handleWindowResizeDebounced, {
      passive: true
    });
  }

  _processUpdateTrigger() {}

  _processRenderCounter() {
    this._triggerView.setCount(this._model.getData().itemsCount);

    const counter = this._triggerView.getCounterElement();

    render(this._triggerView.getElement(), counter);

    this._listPresenter._animation.fadeIn(
        counter,
        null,
        null,
        AnimationClass.SHOW_FLEX
    );
  }

  _processDestroyCounter() {
    const counter = this._triggerView.getCounterElement();
    this._triggerView.removeCounterElement();

    this._listPresenter._animation.fadeOut(
        counter,
        null,
        () => {
          removeElement(counter);
        }
    );
  }

  _processChangeCount() {
    this._triggerView.setCount(this._model.getData().itemsCount);
  }

  _handleMouseEnter() {
    if (window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
      return;
    }

    clearTimeout(this._destroyTimeout);

    this._renderTimeout = setTimeout(() => {
      if (this._model.getData().isActive) {
        return;
      }

      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            isActive: true
          }
      ), ActionType.RENDER_SECTION);
    }, SECTION_RENDER_TIMEOUT);
  }

  _handleMouseLeave() {
    clearTimeout(this._renderTimeout);

    this._destroyTimeout = setTimeout(() => {
      this.destroySection();
    }, SECTION_DESTROY_TIMEOUT);
  }

  _handleWindowResize() {
    if (window.matchMedia(MOBILE_MEDIA_QUERY).matches) {
      this.destroySection();
    }
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.RENDER_SECTION:
        this._processRenderSection();
        break;
      case ActionType.DESTROY_SECTION:
        this._processDestroySection();
        break;
      case ActionType.UPDATE_TRIGGER:
        this._processUpdateTrigger();
        break;
      case ActionType.RENDER_COUNTER:
        this._processRenderCounter();
        break;
      case ActionType.DESTROY_COUNTER:
        this._processDestroyCounter();
        break;
      case ActionType.CHANGE_COUNT:
        this._processChangeCount();
        break;
    }
  }
}
