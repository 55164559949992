export const Notification = {
  NOT_EMPTY: `Поле не должно быть пустым.`,
  MORE_THAN_ZERO: `Введите значение больше нуля.`,
  EMAIL: `Введите email в формате ivan@domain.ru.`,
  PHONE: `Номер телефона должен состоять из 11 цифр.`,
  IS_CHECKED: `Необходимо ваше подтверждение.`
};

export const SubmitMessage = {
  SUBMITING: `Идет отправка формы. Пожалуйста, подождите.`,
  SUCCESS: `Спасибо за&nbsp;обращение! В&nbsp;ближайшее время с&nbsp;вами свяжется наш менеджер.`,
  ERROR: `Во время отправки формы произошла ошибка. Попробуйте повторить снова.`
};
