import ListView from "../view/list.js";
import ItemView from "../view/item.js";

import ItemModel from "../model/item.js";

import Animation from "../../animation/animation.js";

import {
  defaultFaqItemModelData,
  ActionType
} from "../const.js";

export default class FaqList {
  constructor(element) {
    this._element = element;

    this._animation = new Animation();

    this._listView = null;
    this._itemsViews = [];
    this._itemsModels = [];

    this._handleTitleClick = this._handleTitleClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._listView = new ListView(this._element);

    const itemsElements = this._listView.getItems();

    Array.from(itemsElements)
      .forEach((item, index) => {
        const view = new ItemView(item, index, this._animation);
        view.setTitleClickHandler(this._handleTitleClick);
        this._itemsViews.push(view);

        const model = new ItemModel(Object.assign(
            {},
            defaultFaqItemModelData
        ));
        model.addObserver(this._handleChangeData);
        this._itemsModels.push(model);
      });
  }

  _processExpand(index) {
    const view = this._itemsViews[index];
    view.expand();

    this._itemsModels.forEach((model, modelIndex) => {
      if (index === modelIndex) {
        return;
      }

      const modelData = model.getData();

      if (!modelData.active) {
        return;
      }

      model.changeData(Object.assign(
          {},
          modelData,
          {
            active: false
          }
      ), ActionType.SHRINK, {
        index: modelIndex
      });
    });
  }

  _processShrik(index) {
    const view = this._itemsViews[index];
    view.shrink();
  }

  _handleTitleClick(index) {
    const model = this._itemsModels[index];
    const modelData = model.getData();
    const action = (modelData.active) ? ActionType.SHRINK : ActionType.EXPAND;

    model.changeData(Object.assign(
        {},
        modelData,
        {
          active: !modelData.active
        }
    ), action, {
      index
    });
  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.EXPAND:
        this._processExpand(payload.index);
        break;
      case ActionType.SHRINK:
        this._processShrik(payload.index);
        break;
    }
  }
}
