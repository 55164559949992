import ModalSectionView from "../../modal/view/section.js";

export default class ModalFeedbackFormSection extends ModalSectionView {
  constructor() {
    super();

    this._closeButtonClass = `modal-feedback-form-close-button`;

    this._formElement = null;
    this._slideElement = null;
  }

  getFormElement() {
    if (!this._formElement) {
      this._formElement = this._element.querySelector(`form`);
    }

    return this._formElement;
  }

  getSlideElement() {
    if (!this._slideElement) {
      this._slideElement = this._element.closest(`.modal-feedback-form-wrapper .fancybox__slide`);
    }

    return this._slideElement;
  }
}
