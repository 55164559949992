import SectionView from "../../mini-list/view/section.js";

import {
  SECTION_HEADING,
  BUTTON_TEXT,
} from "../lexicon.js";

import {
  PAGE_URI
} from "../const.js";

const createMiniListSectionTemplate = () => {
  return (
    `<section class="mini-list-section">
      <span class="mini-list-section__decor"></span>
      <h2 class="visually-hidden">${SECTION_HEADING}</h2>
      <div class="mini-list-container">
        <div class="mini-list-wrapper">
          <ul class="mini-list">
          </ul>
        </div>
      </div>
      <div class="mini-list-summary">
        <a class="button button_mini-list-summary" href="/${PAGE_URI}">${BUTTON_TEXT}</a>
      </div>
    </section>`
  );
};

export default class MiniFavoriteSection extends SectionView {
  constructor() {
    super();
  }

  _getTemplate() {
    return createMiniListSectionTemplate();
  }
}
