export const debounce = (func, time = 300) => {
  let timeout;

  return function () {
    const funcCall = () => {
      // eslint-disable-next-line
      func.apply(null, arguments);
    };

    clearTimeout(timeout);
    timeout = setTimeout(funcCall, time);
  };
};
