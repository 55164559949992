import CatalogItemView from "../view/catalog-item.js";
import CatalogItemModel from "../model/catalog-item.js";

import {
  defaultCatalogItemModelData,
  ActionType
} from "../const.js";

export default class CatalogItem {
  constructor(element, index, animationComponent, scrollToElementComponent) {
    this._element = element;
    this._index = index;
    this._animation = animationComponent;
    this._scrollToElement = scrollToElementComponent;

    this._catalogItemView = null;
    this._catalogItemModel = null;

    this._handleItemsButtonClick = this._handleItemsButtonClick.bind(this);
    this._handleTitleButtonClick = this._handleTitleButtonClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._catalogItemView = new CatalogItemView(
        this._element,
        this._index,
        this._animation,
        this._scrollToElement
    );
    this._catalogItemView.setItemsButtonClickHandler(this._handleItemsButtonClick);
    this._catalogItemView.setTitleButtonClickHandler(this._handleTitleButtonClick);

    this._catalogItemModel = new CatalogItemModel(Object.assign(
        {},
        defaultCatalogItemModelData
    ));
    this._catalogItemModel.addObserver(this._handleChangeData);
  }

  addObserver(observer) {
    this._catalogItemModel.addObserver(observer);
  }

  reset() {
    const modelData = this._catalogItemModel.getData();

    if (!modelData.isItemsButtonActive || !modelData.isTitleButtonActive) {
      return;
    }

    this._catalogItemModel.changeData(Object.assign(
        {},
        modelData,
        {
          isItemsButtonActive: false,
          isTitleButtonActive: false
        }
    ), ActionType.HIDE_ITEMS);
  }

  _showItems() {
    this._catalogItemView.showItems();
    this._catalogItemView.setActive();
  }

  _hideItems() {
    this._catalogItemView.hideItems();
    this._catalogItemView.setDefault();
  }

  _processButtonClick() {
    const modelData = this._catalogItemModel.getData();
    const action = (modelData.isItemsButtonActive) ?
      ActionType.HIDE_ITEMS :
      ActionType.SHOW_ITEMS;
    const isItemsButtonActive = (modelData.isItemsButtonActive || modelData.isTitleButtonActive) ? false : true;
    const isTitleButtonActive = (modelData.isItemsButtonActive || modelData.isTitleButtonActive) ? false : true;

    this._catalogItemModel.changeData(Object.assign(
        {},
        modelData,
        {
          isItemsButtonActive,
          isTitleButtonActive
        }
    ), action, {
      index: this._index
    });
  }

  _handleItemsButtonClick() {
    this._processButtonClick();
  }

  _handleTitleButtonClick() {
    this._processButtonClick();
  }

  _handleChangeData(action) {
    switch (action) {
      case ActionType.SHOW_ITEMS:
        this._showItems();
        break;
      case ActionType.HIDE_ITEMS:
        this._hideItems();
        break;
    }
  }
}
