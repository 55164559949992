export const defaultListItemsModelData = {
  isExpanded: false,
  isResized: false
};

export const ActionType = {
  EXPAND: `EXPAND`,
  SHRINK: `SHRINK`,
  RESIZE: `RESIZE`
};
