export const MOCK_RESPONSE_DATA = {
  items: [
    {
      name: `Тип`,
      value: `Газобетон`,
      uri: `#`
    },
    {
      name: `Размеры (Длина x Высота x Ширина)`,
      value: `600x250x100 мм`,
    },
    {
      name: `Завод`,
      value: `г. Санкт-Петербург`,
    },
    {
      name: `Вес 1 шт.`,
      value: `8,7 кг`,
    },
    {
      name: `Количество шт. в м³`,
      value: `67`,
    },
    {
      name: `Количество шт. на поддоне.`,
      value: `120`,
    },
    {
      name: `Количество м³ на поддоне`,
      value: `1.8`,
    },
    {
      name: `Производитель`,
      value: `Газобетон СК`,
      uri: `#`
    },
    {
      name: `Марка плотности`,
      value: `D400`,
      uri: `#`
    },
    {
      name: `Ширина стены`,
      value: `100 мм`,
      uri: `#`
    },
    {
      name: `Расход клея на 1 м³ кладки`,
      value: `25 кг`,
    },
    {
      name: `Класс прочности на сжатие, не менее`,
      value: `B2.5`,
    },
    {
      name: `Усадка при высыхании`,
      value: `не более 0.3 мм/м`,
    },
    {
      name: `Коэффициент теплопроводности в сухом состоянии λ0`,
      value: `не более 0.096 Вт/(м оС)`,
    },
    {
      name: `Теплопроводность кладки на клею в условиях эксплуатации`,
      value: `0.110 – 0.130 Вт/(м*°С)`,
    },
    {
      name: `Качество продукции`,
      value: `Контролируется лабораторией и ОТК завода`,
    },
    {
      name: `Вес поддона`,
      value: `1082 кг`,
    },
    {
      name: `Усадка при высыхании`,
      value: `не более 0.3 мм/м`,
    },
    {
      name: `Коэффициент паропроницаемости`,
      value: `не менее 0.23 мг/(мчПа)`,
    },
    {
      name: `Предел огнестойкости`,
      value: `REI 240 EI`,
    },
    {
      name: `Марка по морозостойкости`,
      value: `F50`,
      uri: `#`
    },
    {
      name: `Тип блока`,
      value: `Перегородочный`,
      uri: `#`
    },
    {
      name: `Размер поддона`,
      value: `1500x1000 мм`,
      uri: `#`
    },
    {
      name: `Группа горючести`,
      value: `НГ`,
      uri: `#`
    },
    {
      name: `Нормативный документ`,
      value: `ГОСТ 31360-2007`,
    },
  ],
  productTitle: `Газобетон СК перегородочный D400 (B2.5) 600x250x100 мм`,
  productUri: `#`
};
