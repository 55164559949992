import ModalFeedbackTriggerPresenter from "../../modal-feedback-form/presenter/modal-feedback-trigger.js";
import AddReviewFormModalPresenter from "./modal.js";

import TriggerView from "../view/trigger.js";

export default class ModalAddReviewForm extends ModalFeedbackTriggerPresenter {
  constructor(triggerElement) {
    super(triggerElement);

    this._triggerView = new TriggerView(this._triggerElement);
  }

  init() {
    super.init();
  }

  _processShowModal() {
    this._modalFeedbackFormPresenter = new AddReviewFormModalPresenter(
        this._triggerView.getProductId(),
        this._triggerView.getProductTitle()
    );
    this._modalFeedbackFormPresenter.build();
  }
}
