export const defaultModalModelData = {
  isActive: false,
  isInit: false,
};

export const ActionType = {
  INIT_MODAL: `INIT_MODAL`,
  OPEN_MODAL: `OPEN_MODAL`,
  CLOSE_MODAL: `CLOSE_MODAL`
};
