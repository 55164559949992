import Abstract from "../../abstract-view/abstract.js";

export default class ComagicTrigger extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  hide() {
    this._element.visibility = `hidden`;
  }

  show() {
    this._element.visibility = null;
  }
}
