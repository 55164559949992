import Abstract from "../../abstract-view/abstract.js";

export default class Trigger extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._defaultText = null;

    this._clickHandler = this._clickHandler.bind(this);
  }

  isActive() {
    return this._element.classList.contains(`${this._element.classList[0]}_active`);
  }

  setActive(activeText) {
    this.getElement().classList.add(`${this.getElement().classList[0]}_active`);
    this._defaultText = this.getElement().textContent;

    this.getElement().textContent = activeText;
  }

  setInactive() {
    this.getElement().classList.remove(`${this.getElement().classList[0]}_active`);

    if (this._defaultText) {
      this.getElement().textContent = this._defaultText;
    }
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    this._element.addEventListener(`click`, this._clickHandler);
  }

  removeClickHandler() {
    this._element.removeEventListener(`click`, this._clickHandler);
    delete this._callback.click;
  }

  _clickHandler(evt) {
    this._callback.click(evt);
  }
}
