import Abstract from "../../abstract-view/abstract.js";

export default class Footer extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  setStickyRendered(paddingBottomValue) {
    this._element.classList.add(`${this._element.classList[0]}_slicky-rendered`);
    this._element.style.paddingBottom = `${paddingBottomValue}px`;
  }

  reset() {
    this._element.classList.remove(`${this._element.classList[0]}_slicky-rendered`);
    this._element.style.paddingBottom = null;
  }
}
