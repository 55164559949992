export const modalSliderClosing = {
  sliderRunAfterHandler() {
    if (this._isModalClosing) {
      this._isModalClosing = false;
      this._wrapperView.setActiveLink(this._activeSlideIndex);
    }
  },

  closingModalHandler(fancybox) {
    this._isModalClosing = true;
    this._activeSlideIndex = fancybox.getSlide().index;
    const perView = this._slider.settings.perView;
    const slidesCount = this._wrapperView.getSlides().length;

    if (this._activeSlideIndex + 1 <= perView) {
      this._slider.go(`<<`);
      return;
    }

    if (slidesCount - this._activeSlideIndex <= perView) {
      this._slider.go(`>>`);
    } else {
      this._slider.go(`=${this._activeSlideIndex}`);
    }
  }
};

modalSliderClosing._activeSlideIndex = modalSliderClosing._activeSlideIndex || null;
modalSliderClosing._isModalClosing = modalSliderClosing._isModalClosing || false;

