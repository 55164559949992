import LinksListPresenter from "../components/links-list/presenter/links-list";

const linksListsElements = document.querySelectorAll(`.category-links:not(.category-content .category-links)`);

if (linksListsElements) {
  Array.from(linksListsElements)
    .forEach((list) => {
      const linksListComponent = new LinksListPresenter(list);
      linksListComponent.init();
    });
}
