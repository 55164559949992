import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

const createLinkLayout = (title, href) => {
  return (
    `<div class="product-item-action-link-wrapper">
      <a class="product-item-action__link" target="_blank" href="${href}">
        <span class="product-item-action__link-title">${title}<span>
      </a>
    </div>`
  );
};

export default class Link extends Abstract {
  constructor(title, href) {
    super();

    this._title = title;
    this._href = href;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  _getTemplate() {
    return createLinkLayout(this._title, this._href);
  }
}
