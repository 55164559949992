export const DefaultCatalogModel = {
  isOpen: false,
  animation: false,
  isWrapperOpen: false,
  isMobileMenuRendered: false,
  isMobileModalOpened: false
};

export const DefaultCategoryLinkModel = {
  isActive: false
};

export const DefaultSubcategoriesItemModel = {
  isOpen: false
};

export const ActionType = {
  OPEN_CATALOG: `OPEN_CATALOG`,
  CLOSE_CATALOG: `CLOSE_CATALOG`,
  ANIMATION_END: `ANIMATION_END`,
  CATEGORY_LINK_ACTIVATE: `CATEGORY_LINK_ACTIVATE`,
  CATEGORY_LINK_DEACTIVATE: `CATEGORY_LINK_DEACTIVATE`,
  SUBCATEGORIES_ITEM_SHOW: `SUBCATEGORIES_ITEM_SHOW`,
  SUBCATEGORIES_ITEM_HIDE: `SUBCATEGORIES_ITEM_HIDE`,
  OPEN_SUBCATEGORIES_WRAPPER: `OPEN_SUBCATEGORIES_WRAPPER`,
  CLOSE_SUBCATEGORIES_WRAPPER: `CLOSE_SUBCATEGORIES_WRAPPER`,
  MOBILE_SUBCATEGORIES_MODAL_SHOW: `MOBILE_SUBCATEGORIES_MODAL_SHOW`,
  MOBILE_SUBCATEGORIES_MODAL_HIDE: `MOBILE_SUBCATEGORIES_MODAL_HIDE`,
  RENDER_MOBILE_MENU: `RENDER_MOBILE_MENU`
};

export const KeyboardKey = {
  ESC: [
    `Escape`,
    `Esc`
  ]
};

export const AnimationDuration = {
  CATALOG: 200,
  SUBCATEGORIES_WRAPPER: 100,
  MOBILE_SUBCATEGORIES_ITEM: 200
};

export const MOBILE_MEDIA_QUERY = `(max-width: 500px)`;
export const TABLET_MEDIA_QUERY = `(max-width: 768px) and (min-width: 501px)`;

export const mobileMenuData = [
  {
    link: `/dostavka/`,
    title: `Доставка`
  },
  {
    link: `/oplata/`,
    title: `Оплата`
  },
  {
    link: `/kontakty/`,
    title: `Контакты`
  },
  {
    link: `/o-kompanii/`,
    title: `О компании`
  },
];
