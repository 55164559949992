import Abstract from "./../../abstract-view/abstract.js";

export default class SuggestionsContainer extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._firstFocusableChild = null;

    this._setFirstFocusableChild();

    this._firstFocusableChildKeyDownHandler = this._firstFocusableChildKeyDownHandler.bind(this);
  }

  getFirstFocusableChild() {
    return this._firstFocusableChild;
  }

  setFirstFocusableChildKeyDownHandler(callback) {
    this._callback.firstFocusableChildKeyDown = callback;

    if (!this._firstFocusableChild) {
      return;
    }

    this._firstFocusableChild.addEventListener(`keydown`, this._firstFocusableChildKeyDownHandler);
  }

  removeFirstFocusableChildKeyDownHandler() {
    delete this._callback.firstFocusableChildKeyDown;
    this._firstFocusableChild.removeEventListener(`keydown`, this._firstFocusableChildKeyDownHandler);
  }

  _setFirstFocusableChild() {
    if (!this._firstFocusableChild) {
      this._firstFocusableChild = this._element.querySelector(`a`);
    }
  }

  _firstFocusableChildKeyDownHandler(evt) {
    this._callback.firstFocusableChildKeyDown(evt);
  }
}
