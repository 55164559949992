import Abstract from "../../abstract-view/abstract.js";
import {
  Status,
  LOADING_POSTFIX,
  IDLE_POSTFIX
} from "./../const.js";

import {
  createElement
} from "./../../render/render.js";

const createPreloaderTemplate = () => {
  return (
    `<div class="search-suggestions-preloader">
      <span class="visually-hidden">Подождите, идет загрузка результатов…</span>
    </div>`
  );
};

const createSectionTemplate = () => {
  return (
    `<section class="search-suggestions-section"></section>`
  );
};

export default class Section extends Abstract {
  constructor() {
    super();

    this._element = null;
    this._preloader = null;
  }

  setPosition(relativeElement) {
    const relativeElementBox = relativeElement.getBoundingClientRect();
    const offsetY = relativeElementBox.top + pageYOffset + relativeElement.offsetHeight;

    this._element.style.top = `${offsetY}px`;
  }

  setStatus(status) {
    switch (status) {
      case Status.LOADING:
        this._element.classList.add(this._element.classList[0] + `_` + LOADING_POSTFIX);

        if (this._preloader) {
          this._preloader.classList.remove(this._preloader.classList[0] + `_` + IDLE_POSTFIX);
          this._preloader.classList.add(this._preloader.classList[0] + `_` + LOADING_POSTFIX);
        }
        break;
      case Status.IDLE:
        setTimeout(() => {
          this._preloader.classList.remove(this._preloader.classList[0] + `_` + LOADING_POSTFIX);
          this._preloader.classList.add(this._preloader.classList[0] + `_` + IDLE_POSTFIX);
        }, 200);

        this._element.classList.remove(this._element.classList[0] + `_` + LOADING_POSTFIX);
        break;
    }
  }

  setHeight() {
    this._element.style.height = `${this._element.clientHeight}px`;
  }

  removeHeight() {
    this._element.style.height = null;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getPreloader() {
    if (!this._preloader) {
      this._preloader = createElement(this._getPreloaderTemplate());
    }

    return this._preloader;
  }

  getSectionTopOffset() {
    return this._element.getBoundingClientRect().top;
  }

  getSectionHeight() {
    return this._element.clientHeight;
  }

  _getTemplate() {
    return createSectionTemplate();
  }

  _getPreloaderTemplate() {
    return createPreloaderTemplate();
  }
}
