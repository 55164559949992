export const SECTION_HEADING = `Корзина`;
export const REMOVE_ITEM_LEXICON = `Удалить`;
export const SUMMARY_TEXT_LEXICON = `Итого`;
export const BUTTON_TEXT = `Оформить заказ`;
export const CURRENCY = `руб.`;

export const QUANTITY_UNIT_MEASURE = [
  `товар`,
  `товара`,
  `товаров`
];

export const EMPTY_TEXT = `Корзина`;
export const ON_REQUEST = `По запросу`;
