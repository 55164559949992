import Abstract from "../../abstract-view/abstract.js";

export default class ModalFeedbackTrigger extends Abstract {
  constructor(element) {
    super();

    this._element = element;

    this._clickHandler = this._clickHandler.bind(this);
  }

  getSubmitYmGoal() {
    return this._element.dataset.submitYmGoal;
  }

  getFirstClassname() {
    return this._element.classList[0];
  }

  getSecondClassname() {
    return this._element.classList[1];
  }

  setClickHandler(callback) {
    this._callback.click = callback;
    this._element.addEventListener(`click`, this._clickHandler);
  }

  _clickHandler() {
    this._callback.click();
  }
}
