import ContactsFormPresenter from "../components/contacts-form/presenter/contacts-form.js";

import {
  RuleName
} from "../components/submit-form/const.js";

const container = document.querySelector(`.contacts-wrapper`);
const elementRules = [
  {
    name: `phone`,
    rules: [
      RuleName.PHONE
    ]
  },
  {
    name: `policy`,
    rules: [
      RuleName.IS_CHECKED
    ]
  }
];

const contactsFormComponent = new ContactsFormPresenter({
  container,
  elementRules
});
contactsFormComponent.init();
