import Abstract from "../../abstract-view/abstract.js";

export default class Content extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  setHeight(height) {
    this._element.style.height = `${height}px`;
  }
}
