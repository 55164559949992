import ModalFeedbackFormPresenter from "../../modal-feedback-form/presenter/modal-feedback-form.js";
import ReviewsRatingPresenter from "../../reviews-rating/presenter/reviews-rating.js";
import FileAttachPresenter from "../../file-attach/presenter/file-attach.js";

import ModalSectionView from "../view/section.js";

import {
  MODAL_MAIN_CLASS,
  VALIDATION_RULES,
  CONNECTOR_URL,
  RequestAction
} from "../const.js";

import {
  SUBMIT_SUCCESS_TEXT
} from "../lexicon.js";

export default class ModalAddReviewForm extends ModalFeedbackFormPresenter {
  constructor(productId, productTitle) {
    super();

    this._productId = productId;
    this._productTitle = productTitle;

    this._defaultValidationRules = VALIDATION_RULES;

    this._modalMainClass = MODAL_MAIN_CLASS;
    this._modalSectionView = new ModalSectionView(this._productTitle);

    this._reviewsRatingPresenter = null;
    this._submitSuccessText = SUBMIT_SUCCESS_TEXT;
    this._requestUrl = CONNECTOR_URL;
    this._action = RequestAction.SAVE;
  }

  _processInit() {
    super._processInit();

    this._reviewsRatingPresenter = new ReviewsRatingPresenter(this._modalSectionView.getReviewsElement());
    this._reviewsRatingPresenter.init();

    const fileInputElement = this._modalSectionView.getFileInputElement();
    this._fileAttachPresenter = new FileAttachPresenter(fileInputElement);
    this._fileAttachPresenter.init();
  }
}
