import TriggerView from "../../modal-feedback-form/view/trigger.js";

export default class Trigger extends TriggerView {
  constructor(element) {
    super(element);
  }

  getProductId() {
    return +this._element.dataset.productId;
  }

  getProductTitle() {
    return this._element.dataset.productTitle;
  }
}
