import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

import {
  CLOSE_BUTTON_TEXT
} from "../lexicon.js";

const createModalTemplate = (title, href) => {
  return (
    `<section class="header-catalog-subcategories-modal" style="display: none;">
      <div class="header-catalog-subcategories-modal-title-wrapper">
        <button class="header-catalog-subcategories-modal__close-button">
          <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
        </button>
        <a class="header-catalog-subcategories-modal__link" href="${href}">${title}</a>
      </div>
      <div class="header-catalog-subcategories-modal-list-wrapper">
      </div>
    </section>`
  );
};

export default class SubcategoriesModal extends Abstract {
  constructor(title, href, categoryId) {
    super();

    this._title = title;
    this._href = href;
    this._categoryId = categoryId;

    this._closeButtonElement = null;
    this._listWrapperElement = null;
    this._subcategoriesListElement = null;

    this._closeButtonClickHandler = this._closeButtonClickHandler.bind(this);
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getSubcategoriesList() {
    if (!this._subcategoriesListElement) {
      this._subcategoriesListElement = this.getElement().querySelector(`.header-subcategories__subcategory`);
    }

    return this._subcategoriesListElement;
  }

  getCloseButtonElement() {
    if (!this._closeButtonElement) {
      this._closeButtonElement = this._element.querySelector(`.header-catalog-subcategories-modal__close-button`);
    }

    return this._closeButtonElement;
  }

  getListWrapperElement() {
    if (!this._listWrapperElement) {
      this._listWrapperElement = this._element.querySelector(`.header-catalog-subcategories-modal-list-wrapper`);
    }

    return this._listWrapperElement;
  }

  setZIndex(value) {
    this.getElement().style.zIndex = value;
  }

  setVisible() {
    this.getSubcategoriesList().style.display = null;
    this.getElement().style.display = null;
  }

  setCloseButtonClickHandler(callback) {
    this._callback.closeButtonClick = callback;
    this.getCloseButtonElement().addEventListener(`click`, this._closeButtonClickHandler);
  }

  _closeButtonClickHandler() {
    this._callback.closeButtonClick(this._categoryId);
  }

  _getTemplate() {
    return createModalTemplate(this._title, this._href);
  }
}
