import {
  RequestFieldConverter
} from "../const.js";

export default class UIS {
  constructor() {}

  addRequest(formData) {
    if (!this.isInit()) {
      return;
    }

    const leadData = {};
    const message = [];

    for (const pair of formData.entries()) {
      const name = pair[0];
      const value = pair[1];

      switch (name) {
        case `email`:
          leadData.email = value;
          break;
        case `phone`:
          leadData.phone = value;
          break;
        case `request-subject`:
          leadData[`form_name`] = value;
          break;
      }

      if (RequestFieldConverter[name]) {
        message.push(`${RequestFieldConverter[name]}: ${value}`);
      }
    }

    if (message.length > 0) {
      leadData.message = message.join(`\n`);
    }

    window.Comagic.addOfflineRequest(leadData);
  }

  isInit() {
    return (window.Comagic) ? true : false;
  }
}
