export default class Abstract {
  constructor() {
    this._element = null;
    this._callback = [];
  }

  getElement() {
    return this._element;
  }

  removeElement() {
    this._element = null;
  }
}
