import ModalFeedbackFormSection from "../../modal-feedback-form/view/modal-feedback-form-section.js";

import {
  feedbackFormSubject
} from "../../../mixins/feedback-form-subject.js";

import {
  REQUEST_SUBJECT
} from "../const.js";

import {
  CLOSE_BUTTON_TEXT
} from "../../modal/lexicon.js";

const createModalSectionTemplate = () => {
  return (
    `<section class="modal-feedback-form-section">
      <button class="modal-feedback-form-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
      <div class="modal-feedback-form-container">
        <form class="modal-feedback-form" method="post" enctype="multipart/form-data">
          ${feedbackFormSubject.getTemplate(REQUEST_SUBJECT)}
          <input type="hidden" name="MAX_FILE_SIZE" value="20971520" />
          ${createProductTitleMarkup()}
          ${createProductArticleMarkup()}
          ${createProductQuantityMarkup()}
          <div class="form-item-container">
            <label class="form-label" for="modal-text">
              Ваш комментарий
              <span class="form-label__required">*</span>
            </label>
            <div class="form-item-wrapper form-item-wrapper_no-margins">
              <textarea id="modal-text" class="form-input form-textarea" name="text" placeholder="Пожалуйста, подробно опишите ваше обращение"></textarea>
            </div>
          </div>
          <div class="form-item-container">
            <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_no-decoration">
              <label class="form-label form-label_file">
                <span class="form-label__text form-label__text_file">Прикрепите фото или документы</span>
                <input
                  class="form-input visually-hidden"
                  name="file[]"
                  type="file"
                  multiple>
              </label>
            </div>
          </div>
          <div class="form-item-container">
            <div class="form-item-wrapper form-item-wrapper_no-margins">
              <label class="form-label form-label_checkbox">
                <input class="form-checkbox visually-hidden" type="checkbox" name="modal-accept-call" checked>
                <span class="form-checkbox-icon"></span>
                <span class="form-checkbox-text form-checkbox-text_policy">Хочу, чтобы со мной связались</span>
              </label>
            </div>
          </div>
          <div class="form-items-columns">
            <div class="form-item-container form-items-column">
              <label class="form-label" for="modal-name">Ваше имя</label>
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <input
                id="modal-name"
                class="form-input"
                name="name"
                type="text"
                placeholder="Как к вам обращаться?"
                value>
              </div>
            </div>
            <div class="form-item-container form-items-column">
              <label class="form-label" for="modal-email">
                E-mail
                <span class="form-label__required">*</span>
              </label>
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <input
                id="modal-email"
                class="form-input"
                name="email"
                type="email"
                placeholder="Адрес вашей электронной почты"
                value>
              </div>
            </div>
          </div>
          <div class="form-items-columns">
            <div class="form-item-container form-items-column">
              <label class="form-label" for="modal-phone">
                Ваш телефон
                <span class="form-label__required">*</span>
              </label>
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <input
                id="modal-phone"
                class="form-input"
                name="phone"
                type="tel"
                placeholder="+7 ("
                value>
              </div>
            </div>
            <div class="form-item-container form-items-column">
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <button class="button button_width_full" type="submit">Отправить</button>
              </div>
            </div>
          </div>
          <div class="form-item-container form-item-container_last">
            <div class="form-item-wrapper  form-item-wrapper_no-margins">
              <label class="form-label form-label_checkbox">
                <input class="form-checkbox visually-hidden" type="checkbox" name="policy" checked>
                <span class="form-checkbox-icon"></span>
                <span class="form-checkbox-text form-checkbox-text_policy">Согласен с <a class="form-link" href="/policy/">политикой конфиденциальности</a></span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </section>`
  );
};

const createProductTitleMarkup = () => {
  const productTitleElement = document.querySelector(`h1`);

  return productTitleElement ? `<input type="hidden" name="product-title" value="${productTitleElement.innerText}">` : ``;
};

const createProductArticleMarkup = () => {
  const productArticleElement = document.querySelector(`.product-article span`);

  return productArticleElement ? `<input type="hidden" name="product-article" value="${productArticleElement.innerText}">` : ``;
};

const createProductQuantityMarkup = () => {
  const productQuantityElement = document.querySelector(`.form-input_product-quantity_product-info`);

  if (!productQuantityElement) {
    return ``;
  }

  let productQuantityText = productQuantityElement.value;

  const productQuantityMeasureUnitElement = document.querySelector(`.product-quantity-title span`);

  if (productQuantityMeasureUnitElement) {
    productQuantityText += ` ` + productQuantityMeasureUnitElement.innerText;
  }

  return `<input type="hidden" name="product-quantity" value="${productQuantityText}">`;
};

export default class ModalSection extends ModalFeedbackFormSection {
  constructor() {
    super();

    this._createModalSectionTemplate = createModalSectionTemplate;

    this._fileInput = null;
    this._requestSubjectInput = null;
  }

  getFileInputElement() {
    if (!this._fileInput) {
      this._fileInput = this._element.querySelector(`input[type="file"]`);
    }

    return this._fileInput;
  }

  setRequestSubject(subject) {
    if (!this._requestSubjectInput) {
      this._requestSubjectInput = this._element.querySelector(`input[name="request-subject"]`);
    }

    this._requestSubjectInput.value = subject;
  }
}
