import Abstract from "../../abstract-view/abstract.js";
import {
  createElement
} from "../../render/render.js";

import {
  SHOW,
  HIDE
} from "../lexicon.js";

const createButtonTemplate = () => {
  return (
    `<button class="button button_delivery-map-list">${SHOW}</button>`
  );
};

export default class Content extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._listElement = null;
    this._buttonElement = null;

    this._buttonClickHandler = this._buttonClickHandler.bind(this);
  }

  getListItems() {
    return this._element.querySelectorAll(`li`);
  }

  getHeight() {
    return this.getElement().offsetHeight;
  }

  getListElement() {
    if (!this._listElement) {
      this._listElement = this._element.querySelector(`ul`);
    }

    return this._listElement;
  }

  getButtonElement() {
    if (!this._buttonElement) {
      this._buttonElement = createElement(this._getButtonTemplate());
    }

    return this._buttonElement;
  }

  setButtonClickHandler(callback) {
    this._callback.buttonClick = callback;
    this.getButtonElement().addEventListener(`click`, this._buttonClickHandler);
  }

  setShow() {
    this.getButtonElement().textContent = SHOW;
  }

  setHide() {
    this.getButtonElement().textContent = HIDE;
  }

  _getButtonTemplate() {
    return createButtonTemplate();
  }

  _buttonClickHandler() {
    this._callback.buttonClick();
  }
}
