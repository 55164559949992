import TriggerPresenter from "../../mini-list/presenter/trigger.js";

import {
  CONNECTOR_URL,
  RequestAction,
  ActionType as FavoriteDataActionType
} from "../../favorite/favorite-data/const.js";

import {
  ActionType
} from "../../mini-list/const.js";
export default class Trigger extends TriggerPresenter {
  constructor(element, listPresenter) {
    super(element, listPresenter);

    this._connectorUrl = CONNECTOR_URL;
    this._requestAction = RequestAction.GET_PRODUCTS;

    this._handleFavoriteDataChange = this._handleFavoriteDataChange.bind(this);
  }

  init() {
    super.init();

    if (window.FavoriteData) {
      if (window.FavoriteData.getData().isInit) {
        this.updateTrigger();
      }

      window.FavoriteData.addObserver(this._handleFavoriteDataChange);
    }
  }

  destroy() {
    super.destroy();
    window.FavoriteData.removeObserver(this._handleFavoriteDataChange);
  }

  updateTrigger() {
    super.updateTrigger();

    const favoriteData = window.FavoriteData.getData();

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          itemsCount: favoriteData.quantity
        }
    ), ActionType.UPDATE_TRIGGER);
  }

  _processUpdateTrigger() {
    this.updateCounter();
  }

  _processGetFavoriteData() {
    if (!window.FavoriteData) {
      return;
    }

    const favoriteData = window.FavoriteData.getData();

    if (favoriteData.quantity === 0) {
      return;
    }

    this.updateTrigger();
  }

  _processUpdateFavoriteData() {
    const favoriteData = window.FavoriteData.getData();

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          itemsCount: favoriteData.quantity
        }
    ), ActionType.UPDATE_TRIGGER);
  }

  _handleFavoriteDataChange(action) {
    switch (action) {
      case FavoriteDataActionType.GET_DATA:
        this._processGetFavoriteData();
        break;
      case FavoriteDataActionType.ADD_PRODUCT:
      case FavoriteDataActionType.REMOVE_PRODUCT:
      case FavoriteDataActionType.UPDATE_DATA:
        this._processUpdateFavoriteData();
        break;
    }
  }
}
