import Abstract from "../../abstract-view/abstract.js";

export default class CatalogList extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._items = null;
  }

  getItems() {
    if (!this._items) {
      this._items = this._element.querySelectorAll(`.catalog-js-list__item`);
    }

    return this._items;
  }
}
