import Abstract from "../../abstract-view/abstract.js";

import {
  createElement
} from "../../render/render.js";

const createUploadedListTemplate = () => {
  return (
    `<div class="uploaded-files-wrapper" style="display: none">
      <p class="uploaded-files-title">Добавленные файлы:</p>
      <ul class="uploaded-files"></ul>
    </div>`
  );
};

export default class UploadedList extends Abstract {
  constructor() {
    super();

    this._listElement = null;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getListElement() {
    if (!this._listElement) {
      this._listElement = this._element.querySelector(`ul`);
    }

    return this._listElement;
  }

  _getTemplate() {
    return createUploadedListTemplate();
  }
}
