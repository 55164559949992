import ModalPresenter from "../../modal/presenter/modal.js";
import SubmitFormPresenter from "../../submit-form/presenter/submit-form.js";

import ModalSectionView from "../view/modal-feedback-form-section.js";

import {
  defaultModalModelData,
  MODAL_MAIN_CLASS,
  MODAL_CLOSE_AFTER_SUBMIT_TIMEOUT
} from "../const.js";

import {
  RuleName
} from "../../submit-form/const.js";

export default class ModalFeedbackForm extends ModalPresenter {
  constructor() {
    super();

    this._requestUrl = null;
    this._action = null;
    this._submitSuccessText = null;
    this._submitCallbacks = {};
    this._modalMainClass = MODAL_MAIN_CLASS;
    this._modalSectionView = new ModalSectionView();
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        defaultModalModelData
    ));

    this._submitFormPresenter = null;
    this._defaultValidationRules = [
      {
        name: `phone`,
        rules: [
          RuleName.PHONE
        ]
      },
      {
        name: `policy`,
        rules: [
          RuleName.IS_CHECKED
        ]
      }
    ];
  }

  _processInit() {
    const submitProperties = {
      formElement: this._modalSectionView.getFormElement(),
      submitStyle: `color_white`,
      callbacks: (this._submitCallbacks) ? this._submitCallbacks : null,
      submitSuccessText: this._submitSuccessText
    };

    submitProperties.afterSubmit = this._loadSuccessCallback.bind(this);

    if (this._requestUrl) {
      submitProperties.requestUrl = this._requestUrl;
    }

    if (this._action) {
      submitProperties.action = this._action;
    }

    this._submitFormPresenter = new SubmitFormPresenter(submitProperties);
    this._submitFormPresenter.init(this._defaultValidationRules);
    this._submitFormPresenter.setRelativeScrollContainer(this._modalSectionView.getSlideElement());
  }

  _loadSuccessCallback(response) {
    setTimeout(() => {
      this.closeModal(this._instanceId);
    }, MODAL_CLOSE_AFTER_SUBMIT_TIMEOUT);
  }
}
