import ModalFeedbackFormSection from "../../modal-feedback-form/view/modal-feedback-form-section.js";

import {
  CLOSE_BUTTON_TEXT
} from "../../modal/lexicon.js";

const createModalSectionTemplate = (productTitle) => {
  return (
    `<section class="modal-feedback-form-section">
      <button class="modal-feedback-form-close-button">
        <span class="visually-hidden">${CLOSE_BUTTON_TEXT}</span>
      </button>
      <div class="modal-feedback-form-container">
        <form class="modal-feedback-form" method="post" enctype="multipart/form-data">
          <input type="hidden" name="MAX_FILE_SIZE" value="20971520">
          <h2 class="heading heading_h3 add-review-heading">Оставьте отзыв о товаре:</h2>
          <p class="add-review-modal-product-title">${productTitle}</p>
          <div class="form-items-columns">
            <div class="form-item-container form-items-column">
              <label class="form-label" for="modal-plus">Достоинства</label>
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <textarea id="modal-plus" class="form-input form-textarea form-textarea_small-placeholder" name="plus" placeholder="Что вам больше всего понравилось?"></textarea>
              </div>
            </div>
            <div class="form-item-container form-items-column">
              <label class="form-label" for="modal-minus">Недостатки</label>
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <textarea id="modal-minus" class="form-input form-textarea form-textarea_small-placeholder" name="minus" placeholder="Что можно было бы улучшить?"></textarea>
              </div>
            </div>
          </div>
          <div class="form-items-columns form-items-columns_align-start">
            <div class="form-item-container form-items-column">
              <label class="form-label" for="modal-comment">
                Ваш комментарий
                <span class="form-label__required">*</span>
              </label>
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <textarea id="modal-comment" class="form-input form-textarea form-textarea_small-placeholder" name="comment" placeholder="Опишите ваши впечатления в свободной форме"></textarea>
              </div>
            </div>
            <div class="form-item-container form-items-column form-items-column_rating">
              <p class="add-review-modal-rating-text">Оцените товар по шкале от 1 до 5</p>
              <div class="reviews-rating reviews-rating_add-review-modal">
                <ul class="reviews-rating-items reviews-rating-items_active">
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                </ul>
                <ul class="reviews-rating-items">
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                  <li class="reviews-rating-item"></li>
                </ul>
                <input name="rating" type="hidden" value="5">
              </div>
            </div>
          </div>
          <div class="form-items-columns">
            <div class="form-item-container form-items-column">
              <div class="form-item-wrapper form-item-wrapper_no-margins form-item-wrapper_no-decoration">
                <label class="form-label form-label_file">
                  <span class="form-label__text form-label__text_file">Прикрепите фото</span>
                  <input
                    class="form-input visually-hidden"
                    name="file[]"
                    type="file"
                    multiple>
                </label>
              </div>
              <p class="form-item-descriptor">Вес изображения – не более 20 Мб.</p>
            </div>
          </div>
          <div class="form-items-columns">
            <div class="form-item-container form-items-column">
              <label class="form-label" for="modal-name">
                Ваше имя
                <span class="form-label__required">*</span>
              </label>
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <input
                id="modal-name"
                class="form-input"
                name="name"
                type="text"
                placeholder="Как к вам обращаться?"
                value>
              </div>
            </div>
            <div class="form-item-container form-items-column">
              <div class="form-item-wrapper form-item-wrapper_no-margins">
                <button class="button button_width_full" type="submit">Отправить</button>
              </div>
            </div>
          </div>
          <div class="form-item-container form-item-container_last">
            <div class="form-item-wrapper  form-item-wrapper_no-margins form-item-wrapper_consult">
              <label class="form-label form-label_checkbox">
                <input class="form-checkbox visually-hidden" type="checkbox" name="policy" checked>
                <span class="form-checkbox-icon"></span>
                <span class="form-checkbox-text form-checkbox-text_policy">Согласен с <a class="form-link" href="/policy/">политикой конфиденциальности</a></span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </section>`
  );
};

export default class ModalSection extends ModalFeedbackFormSection {
  constructor(productTitle) {
    super();

    this._createModalSectionTemplate = createModalSectionTemplate.bind(this, productTitle);

    this._reviewsElement = null;
  }

  getReviewsElement() {
    if (!this._reviewsElement) {
      this._reviewsElement = this._element.querySelector(`.reviews-rating`);
    }

    return this._reviewsElement;
  }

  getFileInputElement() {
    if (!this._fileInput) {
      this._fileInput = this._element.querySelector(`input[type="file"]`);
    }

    return this._fileInput;
  }
}
