import FeedbackFormPresenter from "../../feedback-form/presenter/feedback-form.js";
import UISPresenter from "../../uis/presenter/uis.js";

import ContactsFormView from "../view/contacts-form.js";

export default class ContactsForm extends FeedbackFormPresenter {
  constructor({
    container,
    elementRules,
    submitStyle
  }) {
    super({
      container,
      elementRules,
      submitStyle,
      callbacks: {
        loadSuccess: (response, formData) => {
          // Send the Yandex Metrika goal.
          if (window.ym) {
            window.ym(29276100, `reachGoal`, `zakazkontakt`);
          }

          const uisComponent = new UISPresenter();
          uisComponent.addRequest(formData);
        }
      }
    });

    this._formView = new ContactsFormView();
  }
}
