import TriggerPresenter from "./trigger.js";

import MiniListSectionView from "../view/section.js";
import MiniListItemView from "../view/item.js";

import MiniListModel from "../model/mini-list.js";

import Animation from "../../animation/animation.js";

import {
  removeElement,
  render,
  RenderPosition
} from "../../render/render.js";

import {
  debounce
} from "../../../utils/debounce.js";

import {
  ActionType
} from "../const.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

export default class MiniList {
  constructor(triggerElement) {
    this._triggerElement = triggerElement;

    this._TriggerPresenter = TriggerPresenter;
    this._SectionView = MiniListSectionView;
    this._ItemView = MiniListItemView;

    this._animation = new Animation();

    this._triggerPresenter = null;
    this._sectionView = null;
    this._itemsViews = [];

    this._model = null;

    this._handleItemRemoveButton = this._handleItemRemoveButton.bind(this);
    this._handleWindowResize = this._handleWindowResize.bind(this);
    this._handleWindowResizeDebounced = debounce(this._handleWindowResize);
    this._handleSectionMouseEnter = this._handleSectionMouseEnter.bind(this);
    this._handleSectionMouseLeave = this._handleSectionMouseLeave.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);
  }

  init() {
    this._triggerPresenter = new this._TriggerPresenter(this._triggerElement, this);
    this._triggerPresenter.init();

    this._model = new MiniListModel();
    this._model.addObserver(this._handleChangeData);
  }

  destroy() {
    this._triggerPresenter.destroy();
  }

  renderSection(data) {
    this._sectionView = new this._SectionView(data);
    render(this._triggerElement, this._sectionView.getElement(), RenderPosition.AFTEREND);
    this._renderSectionContent(data);
    this._adjustDecorPosition();

    this._sectionView.setElementMouseEnterHandler(this._handleSectionMouseEnter);
    this._sectionView.setElementMouseLeaveHandler(this._handleSectionMouseLeave);

    this._animation.fadeIn(
        this._sectionView.getElement()
    );

    window.addEventListener(`resize`, this._handleWindowResizeDebounced, {
      passive: true
    });
  }

  destroySection() {
    if (!this._sectionView) {
      return;
    }

    const sectionElement = this._sectionView.getElement();
    this._sectionView = null;

    this._animation.fadeOut(
        sectionElement,
        null,
        () => {
          removeElement(sectionElement);
        }
    );

    window.removeEventListener(`resize`, this._handleWindowResizeDebounced, {
      passive: true
    });
  }

  processRemoveItem(elementToRemove) {
    this._viewToRemove = this._itemsViews.find((view) => view.getElement() === elementToRemove);
    this._itemsViews = this._itemsViews.filter((view) => view !== this._viewToRemove);

    this._animation.fadeOut(
        this._viewToRemove.getElement(),
        null,
        () => {
          removeElement(this._viewToRemove.getElement());
        }
    );

    if (this._itemsViews.length === 0) {
      this._triggerPresenter.destroySection();
    }
  }

  _renderSectionContent(data) {
    this._itemsViews = [];

    data.items.forEach((itemData) => {
      const view = new this._ItemView(itemData);
      render(this._sectionView.getListElement(), view.getElement());
      view.setRemoveButtonClickHandler(this._handleItemRemoveButton);
      this._itemsViews.push(view);
    });
  }

  _adjustDecorPosition() {
    const offset = this._triggerPresenter.getTriggerWidth() / 2;
    this._sectionView.setDecorOffset(offset);
  }

  _handleWindowResize() {
    this._adjustDecorPosition();
  }

  _handleItemRemoveButton(elementToRemove, productId) {
    const requestArgs = {
      body: [
        `action=${this._requestAction}`,
        `product_id=${productId}`
      ],
      connectorUrl: this._connectorUrl,
      loadCallback: (response) => {
        const data = JSON.parse(response);

        if (data.error) {
          console.error(data.error);
        }

        this._model.changeData(Object.assign(
            {},
            this._model.getData()
        ), ActionType.REMOVE_ITEM, {
          elementToRemove,
          productId
        });
      },
    };
    sendXhr(requestArgs);
  }

  _handleSectionMouseEnter() {
    this._triggerPresenter.clearDestroyTimeout();
  }

  _handleSectionMouseLeave() {
    this._triggerPresenter.destroySection();
  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.REMOVE_ITEM:
        this.processRemoveItem(payload.elementToRemove, +payload.productId);
        break;
    }
  }
}
