export const defaultComparisonListModelData = {
  isStickySectionRender: false,
  isInit: false
};

export const defaultComparisonProductItemModelData = {
  isHovered: false,
  initComparisonTrigger: false,
  initAddToCartTrigger: true,
  initOneClickBuyTrigger: false,
};

export const defaultPropsListModelData = {
  touchX: null,
  isTouched: false,
  dragX: null,
  isDragged: false,
};

export const defaultItemsListModelData = {
  activeList: null
};

export const TypesFormValue = {
  ALL: `all`,
  DISTINCT: `distinct`
};

export const defaultTypesFormModelData = {
  type: TypesFormValue.ALL,
  disabled: false
};

export const defaultEmptyItemModelData = {
  isRendered: false
};

export const ActionType = {
  SLIDE: `SLIDE`,
  DRAG_START: `DRAG_START`,
  DRAG_END: `DRAG_END`,
  RENDER_STICKY: `RENDER_STICKY`,
  DESTROY_STICKY: `DESTROY_STICKY`,
  REMOVE_PRODUCT_FROM_LIST: `REMOVE_PRODUCT_FROM_LIST`,
  CLEAR_LIST: `CLEAR_LIST`,
  CHANGE_LIST: `CHANGE_LIST`,
  CHANGE_TYPES_FORM: `CHANGE_TYPES_FORM`,
  RENDER_EMPTY_ITEM: `RENDER_EMPTY_ITEM`,
  DISABLE_TYPE_FORM: `DISABLE_TYPE_FORM`,
  ENABLE_TYPE_FROM: `ENABLE_TYPE_FORM`
};

export const SLIDE_THRESHOLD = 30;

export const NavDirection = {
  NEXT: `next`,
  PREV: `prev`
};

export const ProductDataClasses = {
  IMAGE: `product-item__image`,
  TITLE: `product-item__title`,
  PRICE_VALUE: `product-item__price-value`,
  PRICE_INFO: `product-item__currency`
};

export const sliderDefaultOptions = {
  perView: 4,
  gap: 20,
  type: `slider`,
  bound: true,
  animationDuration: 500,
  keyboard: false,
  rewind: false,
  breakpoints: {
    1180: {
      perView: 3
    },
    870: {
      perView: 2,
      gap: 20
    },
    590: {
      perView: 2,
      gap: 10
    },
    360: {
      perView: 1,
      gap: 10
    }
  }
};

export const STICKY_SHOW_TIMEOUT_TIME = 200;
export const UPDATE_CHANGE_LIST_TIMEOUT_TIME = 300;

export const CATALOG_PAGE_HREF = `/catalog`;
export const LIST_ID_KEY = `list_id`;
export const CATALOG_PAGE_ID = 7;
