import {
  Fancybox
} from "@fancyapps/ui";

import ru from "@fancyapps/ui/src/Fancybox/l10n/ru";

import ModalModel from "../model/modal.js";

import ModalSectionView from "../view/section.js";
import ComagicTriggerView from "../view/comagic.js";

import Animation from "../../animation/animation.js";
import {
  AnimationClass
} from "../../animation/const.js";

import {
  generateId
} from "../../../utils/generate-id.js";

import {
  getMaxZIndex
} from "../../../utils/get-max-z-index.js";

import {
  defaultModalModelData,
  ActionType
} from "../const.js";


export default class Modal {
  constructor() {
    this._modalMainClass = ``;

    this._modalSectionView = new ModalSectionView();

    this._comagicTriggerView = null;
    this._modalInstance = null;
    this._observers = {
      modalDone: []
    };

    this._instanceId = generateId();
    this._animation = new Animation();

    this._handleModalReveal = this._handleModalReveal.bind(this);
    this._handleModalDone = this._handleModalDone.bind(this);
    this._handleModalDestroy = this._handleModalDestroy.bind(this);
    this._handleCloseButtonClick = this._handleCloseButtonClick.bind(this);
    this._handleChangeData = this._handleChangeData.bind(this);

    this._model = new ModalModel(Object.assign(
        {},
        defaultModalModelData
    ));
    this._model.addObserver(this._handleChangeData);
  }


  build() {
    const modelData = this._model.getData();

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isActive: true
        }
    ), ActionType.OPEN_MODAL);
  }

  addObserver(observer) {
    this._model.addObserver(observer);
  }

  removeObserver(observer) {
    this._model.removeObserver(observer);
  }

  getInstance() {
    return this._instanceId;
  }

  setRequestSubject(subject) {
    this._modalSectionView.setRequestSubject(subject);
  }

  addModalDoneObserver(observer) {
    this._observers.modalDone.push(observer);
  }

  closeModal(modalInstanceId) {
    const modelData = this._model.getData();

    this._model.changeData(Object.assign(
        {},
        modelData,
        {
          isActive: false,
          isInit: false
        }
    ), ActionType.CLOSE_MODAL, {
      modalInstanceId
    });
  }

  _processInit() {

  }

  _processBuild() {
    Fancybox.defaults.l10n = ru;
    Fancybox.show([
      {
        src: this._modalSectionView.getTemplate(),
        type: `html`
      }
    ], {
      mainClass: this._modalMainClass,
      Carousel: {
        Panzoom: {
          touch: false
        }
      },
      on: {
        reveal: this._handleModalReveal,
        done: this._handleModalDone,
        destroy: this._handleModalDestroy
      }
    });
  }

  _processDestroy() {
    this._modalInstance.close();
  }

  _hideComagicWidget() {
    this._comagicTriggerView = null;

    const triggers = document.querySelectorAll(`body > [class^=comagic]`);

    if (triggers.length === 0) {
      return;
    }

    const trigger = Array.from(triggers)
      .filter((item) => {
        const styles = window.getComputedStyle(item);
        return (+styles.opacity > 0) ? true : false;
      })[0];

    if (!trigger) {
      return;
    }

    this._comagicTriggerView = new ComagicTriggerView(trigger);

    this._animation.fadeOut(
        this._comagicTriggerView.getElement(),
        null,
        () => {
          this._comagicTriggerView.hide();
        }
    );
  }

  _showComagicWidget() {
    if (this._comagicTriggerView) {
      this._animation.fadeIn(
          this._comagicTriggerView.getElement(),
          null,
          () => {
            this._comagicTriggerView.show();
            this._comagicTriggerView = null;
          },
          AnimationClass.SHOW_FLEX
      );
    }
  }

  _handleModalReveal(fancybox, slide) {
    fancybox.$container.style.zIndex = getMaxZIndex() + 2;
    this._hideComagicWidget();

    this._modalSectionView.setElement(slide.$content.children[0]);
    this._modalInstance = fancybox;

    if (this._model.getData().isInit) {
      return;
    }

    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        {
          isInit: true
        }
    ), ActionType.INIT_MODAL, {
      modalInstanceId: this._instanceId
    });
  }

  _handleModalDone() {
    this._modalSectionView.setCloseButtonClickHandler(this._handleCloseButtonClick);

    if (
      this._observers &&
      this._observers.modalDone
    ) {
      this._observers.modalDone.forEach((observer) => observer());
    }
  }

  _handleModalDestroy() {
    this.closeModal(this._instanceId);

    this._modalInstance = null;
    this._modalSectionView.reset();

    this._showComagicWidget();
  }

  _handleCloseButtonClick() {
    this.closeModal();
  }

  _handleChangeData(action, payload) {
    switch (action) {
      case ActionType.OPEN_MODAL:
        this._processBuild();
        break;
      case ActionType.CLOSE_MODAL:
        this._processDestroy(payload.modalInstanceId);
        break;
      case ActionType.INIT_MODAL:
        this._processInit(payload.modalInstanceId);
        break;
    }
  }
}
