import ModalPresenter from "../../modal/presenter/modal.js";

import ModalSectionView from "../view/modal-product-properties-section.js";
import PropertyItemView from "../view/property-item.js";

import {
  sendXhr
} from "../../../utils/send-xhr/send-xhr.js";

import {
  render
} from "../../render/render.js";

import {
  ActionType,
  defaultModalModelData,
  MODAL_MAIN_CLASS,
  RequestAction,
  CONNECTOR_URL
} from "../const.js";

import {
  currentServerType,
  ServerType
} from "../../../const.js";

import {
  MOCK_RESPONSE_DATA
} from "../mock.js";

export default class ModalProductProperties extends ModalPresenter {
  constructor(productId) {
    super();

    this._productId = productId;

    this._modalMainClass = MODAL_MAIN_CLASS;
    this._modalSectionView = new ModalSectionView();
    this._model.changeData(Object.assign(
        {},
        this._model.getData(),
        defaultModalModelData
    ));

    this._propertiesItemsViews = [];
  }

  _processInit() {
    if (currentServerType === ServerType.LAYOUT) {
      const data = MOCK_RESPONSE_DATA;

      this._model.changeData(Object.assign(
          {},
          this._model.getData(),
          {
            propertiesData: data
          }
      ), ActionType.RENDER_PROPERTIES);
    } else {
      const requestArgs = {
        body: [
          `action=${RequestAction.GET_PROPERTIES}`,
          `product_id=${this._productId}`
        ],
        connectorUrl: CONNECTOR_URL,
        loadCallback: (response) => {
          const data = JSON.parse(response);

          this._model.changeData(Object.assign(
              {},
              this._model.getData(),
              {
                propertiesData: data
              }
          ), ActionType.RENDER_PROPERTIES);
        },
      };
      sendXhr(requestArgs);
    }
  }

  _processRenderProperties() {
    const propertiesData = this._model.getData().propertiesData;
    const listElement = this._modalSectionView.getProperitesListElement();
    this._modalSectionView.setHeadingLink(propertiesData.productTitle, propertiesData.productUri);

    propertiesData.items.forEach((item) => {
      const view = new PropertyItemView(item);
      render(listElement, view.getElement());
    });
  }

  _handleChangeData(action, payload) {
    super._handleChangeData(action, payload);

    switch (action) {
      case ActionType.RENDER_PROPERTIES:
        this._processRenderProperties();
        break;
    }
  }
}
