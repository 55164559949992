import Abstract from "./../../abstract-view/abstract.js";

import {
  FormClass,
  TagName
} from "./../const.js";


export default class FormElement extends Abstract {
  constructor(element) {
    super();

    this._element = element;
    this._type = element.tagName;
  }

  makeInvalid() {
    switch (this._element.tagName) {
      case TagName.TEXTAREA:
        this._element.classList.add(FormClass.TEXTAREA.INVALID);
        break;
      case TagName.INPUT:
        this._element.classList.add(FormClass.INPUT.INVALID);
        break;
      case TagName.SELECT:
        this._element.classList.add(FormClass.SELECT.INVALID);
        break;
    }
  }

  makeDefault() {
    switch (this._element.tagName) {
      case TagName.TEXTAREA:
        this._element.classList.remove(FormClass.TEXTAREA.INVALID);
        this._element.parentElement.classList.remove(FormClass.WRAPPER.VALID);
        break;
      case TagName.INPUT:
        this._element.classList.remove(FormClass.INPUT.INVALID);
        this._element.parentElement.classList.remove(FormClass.WRAPPER.VALID);
        break;
      case TagName.SELECT:
        this._element.classList.remove(FormClass.SELECT.INVALID);
        this._element.classList.remove(FormClass.SELECT.NOT_EMPTY);

        const label = this._element.previousElementSibling;

        if (label !== null) {
          this._element.previousElementSibling.classList.remove(FormClass.LABEL.SELECT_NOT_EMPTY);
        }
        break;
    }
  }

  makeValid() {
    switch (this._element.tagName) {
      case TagName.INPUT:
        this._element.parentElement.classList.add(FormClass.WRAPPER.VALID);
        break;
    }
  }

  makeNotEmpty() {
    switch (this._element.tagName) {
      case TagName.SELECT:
        this._element.classList.add(FormClass.SELECT.NOT_EMPTY);

        const label = this._element.previousElementSibling;

        if (label !== null) {
          this._element.previousElementSibling.classList.add(FormClass.LABEL.SELECT_NOT_EMPTY);
        }
        break;
    }
  }
}
