import Glide from "@glidejs/glide";

import {
  VisibleClass
} from "../../glide/components/visible-class";

import {
  NavigationDisabler
} from "../../glide/components/navigation-disabler.js";

import {
  ResizeGap
} from "../../glide/components/resize-gap.js";

import {
  ControlsExtended
} from "../../glide/components/controls-extended.js";

import ControlsView from "../../slider/controls/view/controls";
import WrapperView from "../view/wrapper.js";

import {
  render
} from "../../render/render.js";

export default class VendorsSlider {
  constructor(wrapperElement) {
    this._wrapperElement = wrapperElement;
    this._controlsView = null;
    this._wrapperView = null;

    this._slider = null;
  }

  init() {
    this._slider = new Glide(this._wrapperElement, {
      perView: 6,
      gap: 20,
      type: `slider`,
      bound: true,
      animationDuration: 500,
      keyboard: false,
      rewind: false,
      breakpoints: {
        1024: {
          perView: 5
        },
        800: {
          perView: 4
        },
        650: {
          perView: 3
        },
        490: {
          perView: 2
        }
      }
    });

    this._wrapperView = new WrapperView(this._wrapperElement);
    this._controlsView = new ControlsView();

    render(this._wrapperView.getElement(), this._controlsView.getElement());

    this._slider.mount({
      'ControlsExtended': ControlsExtended,
      'VisibleClass': VisibleClass,
      'NavigationDisabler': NavigationDisabler,
      'ResizeGap': ResizeGap
    });
  }
}
