import {generateId} from "../generate-id.js";

import {
  RequestMethod,
  RequestState,
  RequestStatusCode
} from "./const.js";

export const sendXhr = (args, ctx) => {
  const {
    method = RequestMethod.POST,
    body = [],
    connectorUrl,
    loadstartCallback,
    loadendCallback,
    loadCallback,
    errorCallback,
    abortCallback,
    timeoutCallback,
    requestQueue
  } = args;

  const xhr = new XMLHttpRequest();
  const requestId = generateId();

  xhr.addEventListener(`loadstart`, () => {
    if (requestQueue) {
      requestQueue[requestId] = xhr;
    }

    if (loadstartCallback) {
      loadstartCallback.call(ctx, xhr);
    }
  });

  if (loadCallback) {
    xhr.addEventListener(`load`, () => {
      if (
        (xhr.readyState === RequestState.READY) &&
        (xhr.status === RequestStatusCode.SUCCESS)
      ) {
        if (!xhr.response) {
          return;
        }

        loadCallback.call(ctx, xhr.response);
      }
    });
  }

  xhr.addEventListener(`loadend`, () => {
    if (requestQueue) {
      delete requestQueue[requestId];
    }

    if (loadendCallback) {
      loadendCallback.call(ctx, xhr);
    }
  });

  if (errorCallback) {
    xhr.addEventListener(`error`, (error) => {
      errorCallback.call(ctx, error);
    });
  }

  if (abortCallback) {
    xhr.addEventListener(`abort`, () => {
      abortCallback.call(ctx);
    });
  }

  if (timeoutCallback) {
    xhr.addEventListener(`timeout`, () => {
      timeoutCallback.call(ctx);
    });
  }

  xhr.open(method, connectorUrl, true);
  xhr.setRequestHeader(`Content-Type`, `application/x-www-form-urlencoded`);
  xhr.setRequestHeader(`X-Requested-With`, `XMLHttpRequest`);
  xhr.send(body.join(`&`));
};

export const clearRequestQueue = (requestQueue) => {
  if (Object.values(requestQueue).length > 0) {
    Object.entries(requestQueue)
      .forEach(([id, request]) => {
        delete requestQueue[id];
        request.abort();
      });
  }
};

export const sendXhrQueue = (args, ctx) => {
  const {
    method = RequestMethod.POST,
    body = [],
    connectorUrl,
    loadstartCallback,
    loadendCallback,
    loadCallback,
    errorCallback,
    abortCallback,
    timeoutCallback,
    requestQueue
  } = args;

  const xhr = new XMLHttpRequest();
  const requestId = generateId();

  clearRequestQueue(requestQueue);

  xhr.addEventListener(`loadstart`, () => {
    requestQueue[requestId] = xhr;

    if (loadstartCallback) {
      loadstartCallback.call(ctx, xhr);
    }
  });

  if (loadCallback) {
    xhr.addEventListener(`load`, () => {
      if (
        (xhr.readyState === RequestState.READY) &&
        (xhr.status === RequestStatusCode.SUCCESS)
      ) {
        if (!xhr.response) {
          return;
        }

        loadCallback.call(ctx, xhr.response);
      }
    });
  }

  xhr.addEventListener(`loadend`, () => {
    delete requestQueue[requestId];

    if (loadendCallback) {
      loadendCallback.call(ctx, xhr);
    }
  });


  if (errorCallback) {
    xhr.addEventListener(`error`, (error) => {
      errorCallback.call(ctx, error);
    });
  }

  if (abortCallback) {
    xhr.addEventListener(`abort`, () => {
      abortCallback.call(ctx);
    });
  }

  if (timeoutCallback) {
    xhr.addEventListener(`timeout`, () => {
      timeoutCallback.call(ctx);
    });
  }

  xhr.open(method, connectorUrl, true);
  xhr.setRequestHeader(`Content-Type`, `application/x-www-form-urlencoded`);
  xhr.setRequestHeader(`X-Requested-With`, `XMLHttpRequest`);
  xhr.send(body.join(`&`));
};


