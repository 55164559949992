import Abstract from "../../abstract-view/abstract.js";

const createSidebarTemplate = (postfix) => {
  return (
    `<div class="sidebar sidebar_${postfix}">
      <div class="sidebar__inner">
      </div>
    </div>`
  );
};

import {
  createElement
} from "../../render/render.js";

export default class Sidebar extends Abstract {
  constructor(contentElement) {
    super();

    this._contentElement = contentElement;
    this._innerElement = null;
  }

  getElement() {
    if (!this._element) {
      this._element = createElement(this._getTemplate());
    }

    return this._element;
  }

  getInnerElement() {
    if (!this._innerElement) {
      this._innerElement = this._element.querySelector(`.sidebar__inner`);
    }

    return this._innerElement;
  }

  _getTemplate() {
    return createSidebarTemplate(this._contentElement.classList[0]);
  }
}
