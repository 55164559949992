export const defaultReviewsRatingModelData = {
  rating: 0
};

export const ActionType = {
  LIST_LEAVE: `LIST_LEAVE`,
  ITEM_ENTER: `ITEM_ENTER`,
  ITEM_ACTIVATE: `ITEM_ACTIVATE`,
  ITEM_DEACTIVATE: `ITEM_DEACTIVATE`,
  ITEM_SELECT: `ITEM_SELECT`
};
