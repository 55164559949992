import Abstract from "../../abstract-view/abstract.js";

export default class ProductsList extends Abstract {
  constructor(element) {
    super();

    this._element = element;
  }

  getItems() {
    return this.getElement().querySelectorAll(`.product-item`);
  }

  getItemsWithInsertions() {
    return this.getElement().querySelectorAll(`.product-item, .category-insertion`);
  }

  getInsertions() {
    return this.getElement().querySelectorAll(`.category-insertion`);
  }
}
